// import { Logger } from 'aws-amplify';
// const logger = new Logger('Utils');


export const selectStyles = {
    option: base => ({
        ...base,
        "border-bottom": "1px solid #aeaeae",
        "margin-top": "2px",
        "padding": "2px",
        "margin-bottom": "2px",
        "padding-bottom": "2px",
    }),
    menuList: base => ({
        ...base,
        "minHeight": "600px"
    })
};


export function fullGroupTitle(groupId, orgs, thisOrg) {
    //look for groups in this org
    const group = thisOrg?.Groups.find(m => m.GroupId === groupId);
    if (group) {
        return group.Title;
    }
    //look for groups that have been shared in from another org
    else {
        const externalSharedGroup = thisOrg?.GroupsSharedIn.find(g => g.GroupId === groupId);
        if (externalSharedGroup) {
            return externalSharedGroup.GroupTitle + (" (" + externalSharedGroup.OrgSharedFromTitle + ")");
        }
        else {
            for (let index = 0; index < orgs?.length; index++) {
                const group = orgs[index]?.Groups.find(m => m.GroupId === groupId);
                if (group) {
                    return group.Title + (" (" + orgs[index].Title + ")");
                }

            }
        }
    }

    return "Unknown Group";
}

export function modelTitle(modelId, org) {
    let title = "unknown";
    org?.Projects.forEach(project => {
        const model = project.Models.find(model => model.ModelId === modelId);
        if (model) {
            title = model.Title;
        }
    });
    return title;
}

export function fullUsersName(user, orgs, includeOrg) {

    const name = user.Attributes?.find(a => a.Name === "Name")?.Value +
        " " +
        user.Attributes?.find(a => a.Name === "Surname")?.Value +
        (includeOrg ?
            " (" +
            orgs?.find(o => o.OrganisationId === user.OrganisationId)?.Title +
            ")"
            :
            "");

    return name;
}

export function userInitials(user, orgsInfo) {

    return user.Attributes.find(a => a.Name === "Initials")?.Value;
}

export function fullUserTitle(userId, orgs, thisOrg) {


    //look for users in this org
    const user = thisOrg?.Users.find(m => m.UserId === userId);
    if (user) {
        return fullUsersName(user, null, false);
    }
    //look for users that have been shared in from another org
    else {
        const externalSharedUser = thisOrg?.UsersSharedIn.find(u => u.UserId === userId);
        if (externalSharedUser) {
            return externalSharedUser.UserName + " (" + externalSharedUser.OrgSharedFromTitle + ")";
        }
        else {
            for (let index = 0; index < orgs?.length; index++) {
                const user = orgs[index]?.Users.find(m => m.UserId === userId);
                if (user) {
                    return fullUsersName(user, null, false) + " (" + orgs[index].Title + ")";
                }
            }
        }
    }

    return "Unknown User";
}

//get model title. if in a different org, prepend the org title too
export function fullModelTitle(modelId, orgs, thisOrg) {
    let title = "Unknown Model";
    for (let index = 0; index < orgs.length; index++) {
        const org = orgs[index];
        for (let pIndex = 0; pIndex < org.Projects.length; pIndex++) {
            const project = org.Projects[pIndex];
            const model = project.Models.find(m => m.ModelId === modelId);
            if (model) {
                title = project.Title + ": " + model.Title;
                if (org.OrganisationId !== thisOrg) {
                    title += " (" + (org.Title + ")");
                }
                return title;
            }
        }
    }

    return title;
}

//get model title. if in a different org, prepend the org title too
export function fullProjectTitle(projectId, orgs, thisOrg) {
    let title = "Unknown Project";
    for (let oIndex = 0; oIndex < orgs.length; oIndex++) {
        const org = orgs[oIndex];
        const project = org.Projects.find(m => m.ProjectId === projectId);
        if (project) {
            title = project.Title;
            if (org.OrganisationId !== thisOrg) {
                title += (" (" + org.Title + ")");
            }
            return title;
        }

    }

    return title;
}

export function orgIdFromModelId(modelId, orgs) {
    for (let orgIndex = 0; orgIndex < orgs?.length; orgIndex++) {
        const org = orgs[orgIndex];
        for (let projectIndex = 0; projectIndex < org.Projects.length; projectIndex++) {
            const project = org.Projects[projectIndex];

            if (project.Models.find(m => m.ModelId === modelId)) {
                return org.OrganisationId;
            }

        }

    }

    return null;
}

