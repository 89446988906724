import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api-License');





export async function CreateLicense(license) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: license
    }
    logger.debug("CreateLicense: ", license);

    let response = await API.post('Reveal4DApi', '/CreateLicense', options);
    logger.debug("CreateLicense , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function UpdateLicense(license) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: license
    }

    let response = await API.put('Reveal4DApi', '/UpdateLicense', options);
    logger.debug("UpdateLicense , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function DeleteLicense(licenseId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            licenseid: licenseId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteLicense', options);
    logger.debug("DeleteLicense , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}


export async function AssignLicenseToUser(licenseId, userId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            licenseid: licenseId,
            userid: userId
        }
    }

    let response = await API.post('Reveal4DApi', '/AssignLicenseToUser', options);
    logger.debug("AssignLicenseToUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function UnassignLicenseFromUser(licenseId, userId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            licenseid: licenseId,
            userid: userId
        }
    }

    let response = await API.del('Reveal4DApi', '/UnassignLicenseFromUser', options);
    logger.debug("UnassignLicenseFromUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}
