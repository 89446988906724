import React from 'react'
import Button from 'react-bootstrap/Button'
import toast from 'react-hot-toast';
import '../css/toast.css'
import { FcInfo, FcCancel, FcOk } from "react-icons/fc";
import { IconContext } from "react-icons";
import Spinner from 'react-bootstrap/Spinner';

import { Logger } from 'aws-amplify';
const logger = new Logger('Toaster');

const forever = 3600000; //todo: change to Infinite when react-hot-toast is 1.03

export function dismissAllToasts() {
    toast.dismiss();
}

function renderIcon(type) {

    switch (type) {
        case "Error":
            return (<FcCancel className="toast-icon" />);
        case "Loading":
            return (<Spinner variant="primary" size="sm" animation="border" className="toast-icon" />);
        case "Success":
            return (<FcOk className="toast-icon" />);
        default:
            return (<FcInfo className="toast-icon" />);
    }
}

function ToastMessageComponent(props) {
    return (
        <IconContext.Provider value={{ className: 'toast-icons' }}>
            <div className="toast-message-container">
                {renderIcon(props.type)}
                <div className="toast-contents-container">
                    {props.title && <div className="toast-from">{props.title}</div>}
                    <div>{props.message}</div>
                </div>
                <Button className="toast-button" size="sm" onClick={() => toast.dismiss(props.id)}>X</Button>
            </div>
        </IconContext.Provider>
    );
}

export function ToastError(title, errorText, toastid) {

    logger.debug("toasterror ", title, errorText);

    if (toastid === undefined) {
        return toast((t) => (

            <ToastMessageComponent type="Error" title={title} message={errorText} id={t.id} />
        ),
            {
                duration: forever
            }
        );
    }
    else {
        return toast((t) => (
            <ToastMessageComponent type="Error" title={title} message={errorText} id={t.id} />
        ),
            {
                duration: forever,
                id: toastid
            }
        );
    }
}

export function ToastSuccess(text, toastid, duration) {
    if (!duration) {
        duration = forever;
    }

    if (toastid === undefined) {
        return toast((t) => (
            <ToastMessageComponent type="Success" message={text} id={t.id} />
        ),
            { duration: duration });
    }
    else {

        return toast((t) => (

            <ToastMessageComponent type="Success" message={text} id={t.id} />
        ),
            { duration: duration, id: toastid });
    }
}

export function ToastLoading(text, toastid) {
    if (toastid === undefined) {
        return toast((t) => (
            <ToastMessageComponent type="Loading" message={text} id={t.id} />
        ),
            { duration: forever });
    }
    else {

        return toast((t) => (
            <ToastMessageComponent type="Loading" message={text} id={t.id} />
        ),
            { duration: forever, id: toastid });
    }
}

export function ToastMessage(text, from, textMessageType) {
    return toast((t) => (
        <ToastMessageComponent message={text} title={from} id={t.id} type={textMessageType} />
    ),
        {
            duration: forever
        });
}

export function ToastClose(toastid) {
    toast.dismiss(toastid);
}

export default ToastLoading;