import { userInitials } from '../App/Utils'
import { userTypeText } from '../App/UserPermissions'

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../css/navbar.css';

// import { Logger } from 'aws-amplify';
// const logger = new Logger('UserMenu');


export function UserMenu(props) {

    const { user, signOut, className, userOrg } = props;


    return (
        <div className={className}>
            <DropdownButton id="UserMenu" title={userInitials(user)} align="end">
                <Dropdown.Header >{"Signed in as " + userTypeText(user)?.toLowerCase() + ":"}</Dropdown.Header>
                <Dropdown.ItemText >{user.UserName}</Dropdown.ItemText>
                <Dropdown.Header >To organisation:</Dropdown.Header>
                <Dropdown.ItemText >{userOrg?.Title}</Dropdown.ItemText>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => signOut()}>Sign Out</Dropdown.Item>
            </DropdownButton>
        </div>

    );
}