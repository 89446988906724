import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import '../css/lists.css'

import { ShareGroup, ShareUser } from './ShareDialogs';
import { DeleteGroupShare, DeleteUserShare } from '../Api/Sharing'
import { ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { Confirm } from 'react-st-modal';
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('ShareLists');


export function GroupsSharedInList(props) {
    const { canEdit, currentOrg, parentHelpContext } = props;


    let groupShares = [];
    for (let index = 0; index < currentOrg?.GroupsSharedIn?.length; index++) {
        const item = currentOrg?.GroupsSharedIn[index];
        groupShares.push(item);
    }

    logger.debug("GroupSharedInList", props);

    const toastid = useRef(null);

    const [selectedItemId, setSelectedItemId] = useState(null);

    const listComponent = useRef();

    async function onDelete() {

        BeaconSetSuggestions(BeaconDialogMap.DeleteExternalGroupDialog);

        const result = await Confirm("Are you sure you want to delete?", "Delete Share");
        if (result) {

            try {
                await DeleteGroupShare(selectedItemId);
                ToastSuccess("Deleted Successfully", toastid.current, 5000);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
        }

        BeaconSetSuggestions(parentHelpContext);

    }

    function ListRowItem({ index, style }) {

        return (

            <div role="button"
                style={{
                    ...style,
                    backgroundColor: "#FAFAFA",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden"
                }}


                className={selectedItemId === groupShares[index]?.GroupShareId ?
                    "list-item list-item-selected" :
                    "list-item  list-item-not-selected"}
                onClick={() => {
                    if (canEdit) {
                        //set group and prompt re-draw
                        setSelectedItemId(groupShares[index]?.GroupShareId);
                    }
                }}
            >
                <div className="list-item-title">{groupShares[index]?.GroupTitle}</div>
                <div className="list-item-sub-item">
                    {"From " + groupShares[index]?.OrgSharedFromTitle}
                </div>
            </div>
        );
    }

    return (
        <div className="list-title-and-list list-short list-narrow">
            <div className="list-title-and-buttons">
                <div className="list-title" >External Groups:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" disabled={selectedItemId === null} onClick={onDelete}>Delete Share</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listComponent}
                            height={height}
                            itemCount={groupShares ? groupShares.length : 0}
                            itemSize={60}
                            width={340}
                            className="list-background-style"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );

}



export function GroupsSharedOutList(props) {
    const { canEdit, currentOrg, parentHelpContext } = props;

    let groupShares = [];
    for (let index = 0; index < currentOrg?.GroupsSharedOut?.length; index++) {
        const item = currentOrg.GroupsSharedOut[index];
        groupShares.push(item);
    }


    logger.debug("GroupShareList", props);

    const toastid = useRef(null);

    const [selectedItemId, setSelectedItemId] = useState(null);

    const [showShareGroup, setShowShareGroup] = useState(false);

    const listComponent = useRef();

    async function onDelete() {

        BeaconSetSuggestions(BeaconDialogMap.DeleteGroupShareDialog);

        const result = await Confirm("Are you sure you want to delete?", "Delete Share");
        if (result) {

            try {
                await DeleteGroupShare(selectedItemId);
                ToastSuccess("Deleted Successfully", toastid.current, 5000);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
        }

        BeaconSetSuggestions(parentHelpContext);
    }

    function ListRowItem({ index, style }) {

        return (

            <div role="button"
                style={{
                    ...style,
                    backgroundColor: "#FAFAFA",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden"
                }}


                className={selectedItemId === groupShares[index]?.GroupShareId ?
                    "list-item list-item-selected" :
                    "list-item  list-item-not-selected"}
                onClick={() => {
                    if (canEdit) {
                        //set group and prompt re-draw
                        setSelectedItemId(groupShares[index]?.GroupShareId);
                    }
                }}
            >
                <div className="list-item-title">{groupShares[index]?.GroupTitle}</div>
                <div className="list-item-sub-item">
                    {"Shared with " + groupShares[index]?.OrgSharedToTitle}
                </div>
            </div>
        );
    }

    return (
        <div className="list-title-and-list list-short list-narrow">
            <div className="list-title-and-buttons">
                <div className="list-title" >Groups Shared:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowShareGroup(true)}>New Share</Button>
                        <Button className="list-top-button" disabled={selectedItemId === null} onClick={onDelete}>Delete Share</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listComponent}
                            height={height}
                            itemCount={groupShares ? groupShares.length : 0}
                            itemSize={60}
                            width={340}
                            className="list-background-style"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <ShareGroup
                helpContext={BeaconDialogMap.NewGroupShareDialog}
                parentHelpContext={parentHelpContext}
                currentOrg={currentOrg}
                show={showShareGroup}
                setShow={setShowShareGroup}

            />

        </div>
    );

}


export function UsersSharedInList(props) {
    const { canEdit, currentOrg, parentHelpContext } = props;

    const toastid = useRef(null);

    let userShares = [];
    for (let index = 0; index < currentOrg?.UsersSharedIn?.length; index++) {
        const item = currentOrg?.UsersSharedIn[index];
        userShares.push(item);
    }

    const [selectedItemId, setSelectedItemId] = useState(null);

    const listComponent = useRef();


    async function onDelete() {
        BeaconSetSuggestions(BeaconDialogMap.DeleteExternalUserDialog);

        const result = await Confirm("Are you sure you want to delete?", "Delete Share");
        if (result) {

            try {
                await DeleteUserShare(selectedItemId);
                ToastSuccess("Deleted Successfully", toastid.current, 5000);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
        }

        BeaconSetSuggestions(parentHelpContext);
    }

    function ListRowItem({ index, style }) {

        return (

            <div role="button"
                style={{
                    ...style,
                    backgroundColor: "#FAFAFA",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden"
                }}


                className={selectedItemId === userShares[index].UserShareId ?
                    "list-item list-item-selected" :
                    "list-item list-item-not-selected"}
                onClick={() => {
                    if (canEdit) {
                        //set item and prompt re-draw
                        setSelectedItemId(userShares[index].UserShareId);
                    }
                }}
            >
                <div className="list-item-title">{userShares[index].UserName}</div>
                <div className="list-item-sub-item">
                    {"From " + userShares[index].OrgSharedFromTitle}
                </div>
            </div>
        );
    }

    return (
        <div className="list-title-and-list list-short list-narrow">
            <div className="list-title-and-buttons">
                <div className="list-title" >External Users:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" disabled={selectedItemId === null} onClick={onDelete}>Delete Share</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listComponent}
                            height={height}
                            itemCount={userShares ? userShares.length : 0}
                            itemSize={60}
                            width={340}
                            className="list-background-style"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );

}


export function UsersSharedOutList(props) {
    const { canEdit, currentOrg, parentHelpContext } = props;
    const [showShareUser, setShowShareUser] = useState(false);

    const toastid = useRef(null);

    let userShares = [];
    for (let index = 0; index < currentOrg?.UsersSharedOut?.length; index++) {
        const item = currentOrg.UsersSharedOut[index];
        userShares.push(item);
    }

    const [selectedItemId, setSelectedItemId] = useState(null);

    const listComponent = useRef();


    async function onDelete() {
        BeaconSetSuggestions(BeaconDialogMap.DeleteUserShareDialog);

        const result = await Confirm("Are you sure you want to delete?", "Delete Share");
        if (result) {

            try {
                await DeleteUserShare(selectedItemId);
                ToastSuccess("Deleted Successfully", toastid.current, 5000);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
        }

        BeaconSetSuggestions(parentHelpContext);
    }

    function ListRowItem({ index, style }) {

        return (

            <div role="button"
                style={{
                    ...style,
                    backgroundColor: "#FAFAFA",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflowX: "hidden"
                }}


                className={selectedItemId === userShares[index].UserShareId ?
                    "list-item list-item-selected" :
                    "list-item list-item-not-selected"}
                onClick={() => {
                    if (canEdit) {
                        //set group and prompt re-draw
                        setSelectedItemId(userShares[index].UserShareId);
                    }
                }}
            >
                <div className="list-item-title">{userShares[index].UserName}</div>
                <div className="list-item-sub-item">
                    {"Shared with " + userShares[index].OrgSharedToTitle}
                </div>
            </div>
        );
    }

    return (
        <div className="list-title-and-list list-short list-narrow">
            <div className="list-title-and-buttons">
                <div className="list-title" >Users Shared:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowShareUser(true)}>New Share</Button>
                        <Button className="list-top-button" disabled={selectedItemId === null} onClick={onDelete}>Delete Share</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={listComponent}
                            height={height}
                            itemCount={userShares ? userShares.length : 0}
                            itemSize={60}
                            width={340}
                            className="list-background-style"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <ShareUser
                helpContext={BeaconDialogMap.NewUserShareDialog}
                parentHelpContext={parentHelpContext}
                currentOrg={currentOrg}
                show={showShareUser}
                setShow={setShowShareUser} /
            >

        </div>
    );

}

