import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Constants from '../constants'
import Select from 'react-select'
import { addBuildToQueue } from '../App/queueAssetBuildSlice';
import '../css/annotation-properties.css'
import '../css/modal.css'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import { Uploader, humanFileSize } from '../ProjectsPage/Uploader'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { SystemPermissionFlags } from '../App/Permissions';
import { HasSystemPermission } from '../App/UserPermissions'
import { UserPermissionsInModelList, GroupPermissionsInModelList } from './PermissionList';
import { v4 as uuid } from "uuid";
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('QueuBuildDlg');

const buildTypesOptions = [
    { label: "Import Model", value: Constants.BuildTypes.AddModelAndBuildUnityAssetBundle },
    { label: "Build Assets Only", value: Constants.BuildTypes.BuildUnityAssetBundle }
];


//copy user permissions from project
function copyProjectUserPermissions(project) {
    return project?.UserPermissions?.map(p => ({
        ModelUserPermissionId: uuid(),
        UserId: p.UserId,
        Permissions: p.Permissions
    }));
}

//copy group permissions from project
function copyProjectGroupPermissions(project) {
    return project?.GroupPermissions?.map(p => ({
        ModelGroupPermissionId: uuid(),
        GroupId: p.GroupId,
        Permissions: p.Permissions
    }));
}

export function QueueAssetBuildComponent(props) {
    logger.debug(props);
    const { modelFileName, setShow, fileStore, currentProject, currentUser, helpContext, parentHelpContext } = props;

    const dispatch = useDispatch();
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const form = useRef(null);

    const [modelTitle, setModelTitle] = useState("");
    const [maxTextureSize, setMaxTextureSize] = useState(Constants.defaultMaxTextureSize);
    const [maxTextureSizeInvalid, setMaxTextureSizeInvalid] = useState(false);

    const [crsUnit, setCrsUnit] = useState(Constants.CrsUnits[0]);
    const [crsUnitValid, setCrsUnitValid] = useState(false);

    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileValid, setSelectedFileValid] = useState(false);

    const [validated, setValidated] = useState(false);

    const [buildType, setBuildType] = useState(buildTypesOptions[0]);


    const unityVersion = Constants.unityVersionsOptions[0];

    const crsUnitsOptions = [];
    Constants.CrsUnits.forEach(element => {
        crsUnitsOptions.push({ value: element, label: element })
    });

    const [userPermissions, setUserPermissions] = useState([]);
    const [groupPermissions, setGroupPermissions] = useState([]);


    React.useEffect(() => {
        setUserPermissions(copyProjectUserPermissions(currentProject));
        setGroupPermissions(copyProjectGroupPermissions(currentProject));
    }, [currentProject])

    React.useEffect(() => {
        if (props.show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, props.show])


    //save to local storage so that controls can be repopulated if page is reloaded.
    //file can't be saved as the path can't be known due to browser security
    React.useEffect(() => {
        try {
            setModelTitle(localStorage.getItem("assetBuildModelTitle") || "");
            setCrsUnit(JSON.parse(localStorage.getItem("assetBuildCrsUnit")) || "");
        }
        catch (err) { console.log("error getting storage " + err.message); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        localStorage.setItem("assetBuildModelTitle", modelTitle);
        localStorage.setItem("assetBuildCrsUnit", JSON.stringify(crsUnit));

    }, [modelTitle, crsUnit])


    function clearForm() {
        setValidated(false);
        setCrsUnit("");
        setSelectedFile(null);
        setModelTitle("");
    }

    function onSubmit(event) {

        event.preventDefault();

        const crsUnitValid = crsUnit.value || buildType.value === Constants.BuildTypes.AddModel ? true : false;
        const fileValid = selectedFile || buildType.value === Constants.BuildTypes.BuildUnityAssetBundle ? true : false;

        setCrsUnitValid(crsUnitValid);
        setSelectedFileValid(fileValid);
        setMaxTextureSizeInvalid(!maxTextureSize);

        if (form.current.checkValidity() &&
            crsUnitValid &&
            fileValid &&
            maxTextureSize
        ) {


            let assetBundleBuildCommand = {
                CommandType: buildType.value,
                ProjectId: currentProject.ProjectId,
                OrganisationId: currentProject.OrganisationId,
                UnityVersion: unityVersion.value
            };

            //add permissions
            assetBundleBuildCommand.ModelUserPermissions = userPermissions;
            assetBundleBuildCommand.ModelGroupPermissions = groupPermissions;
            assetBundleBuildCommand.ImportSettings = { MaxTextureSize: maxTextureSize };

            let buildObject = {};

            switch (buildType.value) {
                case Constants.BuildTypes.AddModelAndBuildUnityAssetBundle:
                    buildObject.filePath = selectedFile.path;
                    buildObject.fileSize = humanFileSize(selectedFile.size);
                    assetBundleBuildCommand.NativeCrsUnits = crsUnit.value;
                    assetBundleBuildCommand.ModelTitle = modelTitle;
                    assetBundleBuildCommand.ModelArchive = selectedFile.name;
                    //add file to array (can't be stored in the state as it is not serializable)
                    fileStore.push(selectedFile);

                    break;
                case Constants.BuildTypes.BuildUnityAssetBundle:
                    assetBundleBuildCommand.NativeCrsUnits = crsUnit.value;
                    assetBundleBuildCommand.ModelArchive = modelFileName;

                    break;
                default:
            }
            const cmd = { AssetBundleBuildCommand: assetBundleBuildCommand, BuildObject: buildObject };


            //unityversion of "" means all available versions will be built
            dispatch(addBuildToQueue(cmd));
            setShow(false);
            clearForm();
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function maxFileSizeBytes() {
        if (HasSystemPermission(currentUser, SystemPermissionFlags.RootUser)) {
            return 10000 * 1024 * 1024;
        }
        else {
            //find an org license for uploading
            var lic = currentUser.OrgLicenses.find(o => o.Type === 3);
            if (lic) {
                var item = lic.Payload.Items.find(i => i.Key === "MaxFileSize");
                if (item?.Value) {
                    logger.debug("max file size from license (MB):", item.Value);
                    return parseInt(item.Value) * 1024 * 1024;
                }
            }

        }

        return 10;
    }


    function onClose() {
        props.setShow(false);
    }


    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-900w"
        >
            <div class="modal-header" >
                <h4 class="modal-title">{buildType.label}
                    <br />
                    <div className="subtitle">Drag and drop a model file here, or click to select file. Files must be smaller than {humanFileSize(maxFileSizeBytes(), false, 0)}.<br />
                        Zip files must contain a single fbx or obj file and all associated files (which may include a material file and texture images).
                    </div>
                </h4>
            </div>
            <Modal.Body>

                <Container >
                    <Row>
                        <Col sm={6} >
                            <Form ref={form} noValidate validated={validated} onSubmit={onSubmit}>
                                {HasSystemPermission(currentUser, SystemPermissionFlags.RootUser) &&
                                    <>
                                        <Form.Group controlId="formGroupBuildType" as={Row}>
                                            <Form.Label >Build Type:</Form.Label>
                                            <Col sm={12}>
                                                <Select
                                                    value={buildType}
                                                    onChange={setBuildType}
                                                    closeMenuOnSelect={true}
                                                    name="buildType"
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    placeholder="Select a Build Type..."
                                                    options={buildTypesOptions} />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formMaxTexture" as={Row}>
                                            <Form.Label >Max Texture Size:</Form.Label>
                                            <Col sm={12}>
                                                <Form.Control required type="text" name="maxTextureSize" isInvalid={maxTextureSizeInvalid} defaultValue={Constants.defaultMaxTextureSize} onChange={(e) => setMaxTextureSize(Number(e.target.value))} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a max texture size.
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    </>
                                }

                                {buildType.value === Constants.BuildTypes.AddModelAndBuildUnityAssetBundle &&
                                    <Form.Group controlId="formModelTitle" as={Row}>
                                        <Form.Label >Model Title:</Form.Label>
                                        <Col sm={12}>
                                            <Form.Control required value={modelTitle} onChange={(e) => setModelTitle(e.target.value)} placeholder='Model Title...' />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a title.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>
                                }
                                <Form.Group controlId="formModelUnits" as={Row}>
                                    <Form.Label >Native CRS Units:</Form.Label>
                                    <Col sm={12}>
                                        <Select
                                            value={crsUnit}
                                            onChange={setCrsUnit}
                                            closeMenuOnSelect={true}
                                            name="crsUnit"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select a Unit..."
                                            options={crsUnitsOptions} />
                                        {(!crsUnitValid && validated) &&
                                            <Form.Text className="text-danger">
                                                Please select a unit.
                                            </Form.Text>
                                        }
                                    </Col>
                                </Form.Group>


                                {buildType.value === Constants.BuildTypes.AddModelAndBuildUnityAssetBundle &&
                                    <Form.Group controlId="formGroupModelFile" as={Row}>
                                        <Form.Label >Model archive:</Form.Label>
                                        <Col sm={12}>
                                            <Uploader maxFileSize={maxFileSizeBytes()} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />

                                            {(!selectedFileValid && validated) &&
                                                <Form.Text className="text-danger">
                                                    Please select a Model .zip or .glb to upload.
                                                </Form.Text>
                                            }
                                        </Col>
                                    </Form.Group>
                                }

                            </Form>
                        </Col>
                        <Col sm={5}>
                            <Row>
                                <UserPermissionsInModelList
                                    parentHelpContext={helpContext}
                                    userPermissions={userPermissions}
                                    setUserPermissions={setUserPermissions}
                                    canEdit={true}
                                    userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo?.currentUser?.OrganisationId)}
                                    orgs={orgsInfo?.organisations}
                                    isAdding={true}
                                    model={{ ModelId: null }}
                                    short
                                />
                            </Row>
                            <Row>
                                <GroupPermissionsInModelList
                                    parentHelpContext={helpContext}
                                    groupPermissions={groupPermissions}
                                    setGroupPermissions={setGroupPermissions}
                                    canEdit={true}
                                    userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo?.currentUser?.OrganisationId)}
                                    isAdding={true}
                                    orgs={orgsInfo?.organisations}
                                    model={{ ModelId: null }}
                                    short
                                />
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSubmit}>OK</Button>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );
}


export default QueueAssetBuildComponent;