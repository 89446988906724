import React, { useState, useRef } from "react";

import { CreateAnnotationStyleSheet, UpdateAnnotationStyleSheet, DeleteAnnotationStyleSheet } from '../Api/AnnotationPropertiesApi'
import { EditStyleSheet } from './EditStyleSheet'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { FixedSizeList as List } from 'react-window';
import Button from 'react-bootstrap/Button'
import { ConfirmModal } from '../Components/ConfirmModal'
import { CanEditAnnotationTemplatesOrStyleSheets } from '../App/UserPermissions'
import '../css/lists.css'
import '../css/annotation-properties.css'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('StyleSheetsDialog');


export function StyleSheetsDialog(props) {

    const { helpContext, parentHelpContext, project, show, setShow, currentUser } = props;

    logger.debug("StyleSheetsDialog", props);

    const styleSheetList = useRef();
    const [selectedStyleSheetId, setSelectedStyleSheetId] = useState(null);
    const [showEditStyleSheetDialog, setShowEditStyleSheetDialog] = useState(false);
    const [showAddStyleSheetDialog, setShowAddStyleSheetDialog] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const toastId = useRef();

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    function SelectedStyleSheet() {
        let selStyleSheet = {};
        if (project?.AnnotationStyleSheets) {

            const indexStyleSheet = project.AnnotationStyleSheets.findIndex(item => item.AnnotationStyleSheetId === selectedStyleSheetId);
            if (indexStyleSheet === -1) {
                selStyleSheet = null;
            }
            else {
                selStyleSheet = project.AnnotationStyleSheets[indexStyleSheet];
            }
        }
        return selStyleSheet;

    }


    //save and close
    async function onUpdateStyleSheet(styleSheet, setUiDisabled, closeAfterSaving) {

        setUiDisabled(true);

        if (styleSheet.AnnotationStyleSheetId) {

            toastId.current = ToastLoading("Updating Style Sheet");

            //copy style sheet 
            let ss = JSON.parse(JSON.stringify(styleSheet));

            UpdateAnnotationStyleSheet(ss)
                .then(res => {
                    ToastSuccess("Updated Style Sheet", toastId.current, 5000);
                    if (closeAfterSaving) {
                        setShowEditStyleSheetDialog(false);
                    }
                    setSelectedStyleSheetId(styleSheet.AnnotationStyleSheetId);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
        else {
            //add
            toastId.current = ToastLoading("Adding Style Sheet");

            //copy style sheet 
            let ss = JSON.parse(JSON.stringify(styleSheet));

            ss.ProjectId = project.ProjectId;

            CreateAnnotationStyleSheet(project.ProjectId, ss)
                .then(res => {
                    ToastSuccess("Added Style Sheet", toastId.current, 5000);
                    if (closeAfterSaving) {
                        setShowEditStyleSheetDialog(false);
                    }
                    setSelectedStyleSheetId(res);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });

        }

    }

    async function onDeleteStyleSheet() {

        if (SelectedStyleSheet()) {


            toastId.current = ToastLoading("Deleting Style Sheet");

            DeleteAnnotationStyleSheet(SelectedStyleSheet())
                .then(res => {
                    ToastSuccess("Deleted Style Sheet", toastId.current, 5000);
                    setSelectedStyleSheetId(null);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                });

        }
    }



    const ListRowStyleSheet = ({ index, style }) => (
        <div role="button" style={{
            ...style, backgroundColor: "#FAFAFA", whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden"
        }} className={selectedStyleSheetId === project.AnnotationStyleSheets[index].AnnotationStyleSheetId ?
            "p-2 border border-top-0 border-left-0 rounded border-dark  bg-primary text-white" :
            "p-2 border border-top-0 border-left-0 rounded border-dark"}
            onClick={() => {
                //set group and prompt re-draw
                setSelectedStyleSheetId(project.AnnotationStyleSheets[index].AnnotationStyleSheetId);
            }}
            onDoubleClick={() => {
                //set group and prompt re-draw
                setSelectedStyleSheetId(project.AnnotationStyleSheets[index].AnnotationStyleSheetId);
                setShowEditStyleSheetDialog(true);
            }} >
            {project.AnnotationStyleSheets[index].Title}
        </div>
    );

    function onClose() {
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="false"
            keyboard={false}
            centered
            dialogClassName="modal-500w"
        >
            <Modal.Header closeButton>
                <Modal.Title>Annotation Style Sheets:</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <div className="ps-3">
                        {CanEditAnnotationTemplatesOrStyleSheets(currentUser, project?.ProjectId, project?.OrganisationId) ?
                            <>
                                {project ? <Button style={{ float: "right" }} className="list-top-button" disabled={SelectedStyleSheet() == null} onClick={() => {
                                    setShowConfirmDeleteDialog(true);
                                }}>Delete</Button> : <></>}
                                {project ? <Button style={{ float: "right" }} className="list-top-button" disabled={SelectedStyleSheet() == null} onClick={() => {
                                    setShowEditStyleSheetDialog(true);
                                }}>Edit</Button> : <></>}
                                {project ? <Button style={{ float: "right" }} className="list-top-button" onClick={() => {
                                    setShowAddStyleSheetDialog(true);
                                }}>Add</Button> : <></>}
                            </>
                            : <></>
                        }

                    </div>
                </Row>

                <List
                    ref={styleSheetList}
                    height={600}
                    itemCount={project && project.AnnotationStyleSheets ? project.AnnotationStyleSheets.length : 0}
                    itemSize={44}
                    width={470}
                    className="mt-2 border border-dark rounded"
                    style={{ backgroundColor: "#EEEEEE" }}
                >
                    {ListRowStyleSheet}
                </List>

                <EditStyleSheet
                    helpContext={BeaconDialogMap.EditAnnotationStyleSheetDialog} parentHelpContext={helpContext}
                    currentUser={currentUser}
                    onSave={onUpdateStyleSheet}
                    show={showEditStyleSheetDialog}
                    setShow={setShowEditStyleSheetDialog}
                    dialogTitle="Edit Style Sheet"
                    annotationStyleSheet={SelectedStyleSheet()}
                    project={project} />
                <EditStyleSheet
                    helpContext={BeaconDialogMap.EditAnnotationStyleSheetDialog} parentHelpContext={helpContext}
                    currentUser={currentUser}
                    onSave={onUpdateStyleSheet}
                    show={showAddStyleSheetDialog}
                    setShow={setShowAddStyleSheetDialog}
                    dialogTitle="Add Style Sheet"
                    annotationStyleSheet={{ AnnotationStyleRules: [], Title: "" }}
                    project={project} />
                <ConfirmModal
                    helpContext={BeaconDialogMap.DeleteAnnotationStyleSheetDialog} parentHelpContext={helpContext}
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteStyleSheet}
                    okText="Delete"
                    dialogTitle="Delete Style Sheet"
                    dialogBody="Are you sure you want to delete?"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>


        </Modal>

    );
}