import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'


import { WithContext as ReactTags } from 'react-tag-input';
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditPropertyTemplate');

const propertyTypesOptions = [
    { label: "Text", value: 0 },
    { label: "Integer", value: 1 },
    { label: "Float", value: 2 },
    { label: "Single-Select List", value: 3 },
    { label: "Multi-Select List", value: 4 }
];

const propertyValueOnlyTypesOptions = [
    { label: "Text", value: 0 },
    { label: "Integer", value: 1 },
    { label: "Float", value: 2 },
];

const propertyListOnlyTypesOptions = [
    { label: "Single-List", value: 3 },
    { label: "Multi-List", value: 4 }
];

const KeyCodes = {
    enter: 13
};

function listItemsToTagList(items) {
    if (items) {
        return items.map((item) => ({ id: item.ListPropertyTemplateListItemId, text: item.ItemText }))
    }
    else {
        return [];
    }
};

function tagListToListItems(items) {
    if (items) {
        let newList = items.map((item, index) => ({ ListPropertyTemplateListItemId: item.id, ItemText: item.text, Order: index }))

        //validate list to see new ones added - id will be the same as text - need to zero the id 
        //otherwise will not be converted to guid properlly
        newList.forEach((element, index) => {

            if (element.ListPropertyTemplateListItemId === element.ItemText) {
                newList[index].ListPropertyTemplateListItemId = "00000000-0000-0000-0000-000000000000";
            }
        });

        return newList;

    }
    else {
        return [];
    }
};

const delimiters = [KeyCodes.enter];

function getInitialType(propertyTemplate) {

    if (propertyTemplate.PropertyType === 1) {
        if (propertyTemplate.MultiSelect) {
            return 4;
        }
        else {
            return 3;
        }
    }
    else {
        if (propertyTemplate.PropertyType === 0) {
            return propertyTemplate.ValueType;
        }
        else {
            return 0;
        }
    }
}

export function EditPropertyTemplate(props) {

    const { isEditing, show, setShow, dialogTitle, propertyTemplate, existingPropertyTemplates, helpContext, parentHelpContext } = props;


    logger.debug("EditPropertyTemplate props", props);

    const form = useRef(null);
    const [propertyValueType, setPropertyValueType] = useState(propertyTypesOptions[getInitialType(propertyTemplate)]);
    const [isPositive, setIsPositive] = useState(propertyTemplate.PropertyType === 0 ? propertyTemplate.PositiveOnly : false);
    const [listItems, setListItems] = useState(propertyTemplate.PropertyType === 1 ? listItemsToTagList(propertyTemplate.ListItems) : []);
    const [listItemsValid, setListItemsValid] = useState(true);
    const [title, setTitle] = useState(propertyTemplate.Title);
    const [titleUnique, setTitleUnique] = useState(true);

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);


    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    const handleListItemDelete = i => {
        setListItems(listItems.filter((item, index) => index !== i));
    };

    const handleListItemAddition = item => {
        setListItems([...listItems, item]);
    };

    const handleListItemDrag = (item, currPos, newPos) => {
        const newItems = listItems.slice();

        newItems.splice(currPos, 1);
        newItems.splice(newPos, 0, item);

        // re-render
        setListItems(newItems);
    };

    const onListItemUpdate = (i, newListItem) => {
        const updatedListItems = listItems.slice();
        updatedListItems.splice(i, 1, newListItem);
        setListItems(updatedListItems);
    };


    function onSave(event) {
        event.preventDefault();

        if (propertyValueType.value > 2) {
            setListItemsValid(listItems.length > 0)
        }

        const existingTemplate = existingPropertyTemplates.find(p => p.Title.toUpperCase() === title.toUpperCase() && p.PropertyTemplateId !== propertyTemplate.PropertyTemplateId);
        setTitleUnique(existingTemplate == null);

        if (form.current.checkValidity() && listItemsValid && !existingTemplate) {

            //copy prop template
            let newPropertyTemplate = JSON.parse(JSON.stringify(propertyTemplate));

            //update with form values
            newPropertyTemplate.Title = title;

            if (propertyValueType.value <= 2) {
                newPropertyTemplate.PropertyType = 0;
                newPropertyTemplate.PositiveOnly = isPositive;
                newPropertyTemplate.ValueType = propertyValueType.value;
            }
            else {
                newPropertyTemplate.PropertyType = 1;
                newPropertyTemplate.MultiSelect = propertyValueType.value === 4;
                newPropertyTemplate.ListItems = tagListToListItems(listItems);
            }

            props.onSave(newPropertyTemplate, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }

    //if editing, don't allow to change between list and value
    function getTypeOptions() {
        if (isEditing) {

            if (propertyTemplate.PropertyType === 0) {
                return propertyValueOnlyTypesOptions;
            }
            else {
                return propertyListOnlyTypesOptions;
            }

        }
        else {
            return propertyTypesOptions;
        }

    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group className="my-2" controlId="formPropertyTemplateTitle" as={Row}>
                            <Form.Label column sm={3} >Title:</Form.Label>
                            <Col sm={9}>
                                <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Property Template Title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                                {(!titleUnique && validated) &&
                                    <Form.Text className="text-danger">A template with that name already exists.</Form.Text>}

                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formPropertyType" as={Row}>
                            <Form.Label column sm={3} >Type:</Form.Label>
                            <Col sm={9}>
                                <Select
                                    value={propertyValueType}
                                    onChange={setPropertyValueType}
                                    closeMenuOnSelect={true}
                                    name="valueType"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select a Value Type..."
                                    options={getTypeOptions()} />
                            </Col>
                        </Form.Group>
                        {/*value type*/}
                        {propertyValueType.value <= 2 &&
                            <>
                                {propertyValueType.value !== 0 &&
                                    <Form.Group controlId="formPositiveOnly" as={Row}>
                                        <Form.Label column sm={3} >Positive Only:</Form.Label>
                                        <Col sm={9}>
                                            <FormCheck
                                                className="mt-2"
                                                type="switch"
                                                id="isPositive"
                                                label=""
                                                checked={isPositive}

                                                onChange={(e) => setIsPositive(!isPositive)} />
                                        </Col>
                                    </Form.Group>}
                            </>
                        }
                        {propertyValueType.value > 2 &&
                            <>
                                <Form.Group controlId="formMultiSelect" as={Row}>
                                    <Form.Label column sm={3} >List Items:</Form.Label>
                                    <Col sm={9}>
                                        <ReactTags

                                            tags={listItems}
                                            delimiters={delimiters}
                                            handleDelete={handleListItemDelete}
                                            handleAddition={handleListItemAddition}
                                            handleDrag={handleListItemDrag}
                                            onTagUpdate={onListItemUpdate}
                                            inputFieldPosition="bottom"
                                            placeholder="Enter to add..."
                                            editable={true}
                                        />
                                        {(!listItemsValid && validated) &&
                                            <Form.Text className="text-danger">Please add list items.</Form.Text>}
                                    </Col>
                                </Form.Group>
                            </>
                        }
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default EditPropertyTemplate;