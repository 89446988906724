import { createSlice } from '@reduxjs/toolkit'



export const assetBuildQueueSlice = createSlice({
    name: 'assetBuildQueue',
    initialState: {
        queue: [],
        currentBuild: null,
        progress: 0,
        status: ''
    },
    reducers: {

        clearQueueAssetBuild: state => {
            state.queue = [];
            state.currentBuild = null;
            state.progress = 0;
            state.status = '';
        },
        popQueueItem(state) {
            //get first item from queue
            state.currentBuild = state.queue.shift();
        },
        updateProgress(state, action) {
            state.progress = action.payload;
        },
        updateState(state, action) {
            state.status = action.payload;
        },
        clearCurrentBuild(state) {
            state.currentBuild = null;
        },
        addBuildToQueue(state, action) {
            state.queue.push(action.payload);
        }
    },
});

export const { clearQueueAssetBuild, popQueueItem, updateProgress, updateState, clearCurrentBuild, addBuildToQueue } = assetBuildQueueSlice.actions;

export const selectAssetBuildQueue = state => state.assetBuildQueue;
export default assetBuildQueueSlice.reducer