import { Logger } from 'aws-amplify';
const logger = new Logger('BeaconManager');


//articles in helpscout and their IDs
const BeaconArticleMap = {
    HelpPage: { text: 'Reveal4D Knowledge Base', url: 'https://help.reveal4d.com/' },
    ObvisHomePage: { text: 'Obvis Home Page', url: 'https://www.obvis.com', },
    WebAppMenus: '66ba2190082392452a07743a',
    EditStyleSheet: '668e7db2d5f6cf2baa1f2568',
    AnnotationStyling: '64e8b41eea163e54ee5bfe5a',
    ImportingModels: '668bac40077fa94da4654d90',
    ObjModelsRotated: '66b0cada082392452a0761d3',
    LoggingInAndOut: '64e8c9c197d36a08d5e4b4f5',
    DesktopVsWebInterface: '64d900eba723ae205da3731f',
    GettingStarted: '6687bfead5f6cf2baa1f1778',
    ProjectsAndModels: '668bae2f463661770bfb6f7d',
    AnnotationIntroduction: '64e8b3a397d36a08d5e4b467',
    AnnotationTableWebApp: '66b21d7a7c359113ce1ad688',
    CRSs: '668eaba4f0fdf93e4cf10653',
    EditingCRSs: '66b21eaa7b676f20796866f9',
    EditCrsDialog: '66b22d367b676f207968673a',
    EditProjectDialog: '66b230d316e6a80d1e1d25c9',
    DeleteProjectDialog: '66b382ca082392452a076906',
    EditModelDialog: '66b383030d7d861662420e67',
    DeleteModelDialog: '66b3832b082392452a076909',
    CopyAnnotationSetDialog: '66b38346a62a7505fcf33ff3',
    EditAnnotationSetDialog: '66b3836d082392452a07690b',
    DeleteAnnotationSetDialog: '66b383947b676f2079686b4c',
    EditAnnotationPropertiesDialog: '66b383c77b676f2079686b4e',
    EditMultipleAnnotationPropertiesDialog: '66b383eaa62a7505fcf33ff7',
    AnnotationStyleSheetDialog: '66b3840a16e6a80d1e1d299c',
    DeleteAnnotationStyleSheetDialog: '66b3843b7c359113ce1adac0',
    EditAnnotationStyleRuleDialog: '66b3845c16e6a80d1e1d299e',
    DeleteAnnotationStyleRuleDialog: '66b3847e0d7d861662420e72',
    AnnotationPropertyTemplatesDialog: '66b384a5082392452a076914',
    DeleteAnnotationPropertyTemplatesDialog: '66b384c316e6a80d1e1d29a4',
    EditAnnotationPropertyTemplatesDialog: '66b384e57b676f2079686b58',
    DeleteCRSDialog: '66b38512a62a7505fcf33ffe',
    EditCRSTransformDialog: '66b385397c359113ce1adac5',
    DeleteCRSTransformDialog: '66b385587c359113ce1adac8',
    OrganisationSettingsPage: '66b385f67b676f2079686b61',
    NewUserShareDialog: '66b3860f0d7d861662420e81',
    DeleteUserShareDialog: '66b3862a0d7d861662420e83',
    DeleteExternalUserDialog: '66b3864a7b676f2079686b64',
    AssignUserToLicenseDialog: '66b38664082392452a07691f',
    NewGroupShareDialog: '66b3868216e6a80d1e1d29b0',
    DeleteGroupShareDialog: '66b3869da62a7505fcf34006',
    DeleteExternalGroupDialog: '66b386b5a62a7505fcf34009',
    OrganisationUsersPage: '66b386d116e6a80d1e1d29b3',
    AddingUser: '66b386ea7b676f2079686b66',
    EditUserDialog: '66b38743a62a7505fcf34010',
    AssignLicenseToUserDialog: '66b388617b676f2079686b6f',
    OrganisationGroupsPage: '66b3888c7c359113ce1adade',
    EditGroupDialog: '66b388a516e6a80d1e1d29bd',
    DeleteGroupDialog: '66b388bf0d7d861662420e8f',
    ModelImportLogsPage: '66b388df16e6a80d1e1d29c2',
    AddEmptyOrganisationDialog: '66b388fa0d7d861662420e91',
    AddOrganisationFromTemplateDialog: '66b38915a62a7505fcf34018',
    SystemSettingsPage: '66b3892d7b676f2079686b73',
    EditLicenseDialog: '66bb72860d7d861662421c9f',
    AddUserLicenseDialog: '66bb72b87c359113ce1ae934',
    AddOrgLicenseDialog: '66bb72e40d7d861662421ca0',
    DeleteDeactivateUserDialog: '66bb731a7c359113ce1ae939',
    MustDeactivateUserDialog: '66bb733616e6a80d1e1d37d4',
    EditOrganisationDialog: '66bb738416e6a80d1e1d37d5',
    ShareWithAnExternalUserDialog: '66bb7c187b676f2079687a54',
    EditPermissionsDialog: '66bc72477b676f2079687ddb',
    DeletePermissionsDialog: '66bc72607b676f2079687ddd'
}


//a map of locations in the web app that will have a list of suggested pages set up in the beacon
export const BeaconDialogMap = {
    SignInPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.LoggingInAndOut, BeaconArticleMap.GettingStarted, BeaconArticleMap.DesktopVsWebInterface, BeaconArticleMap.ObvisHomePage],
    HomePageAnnotationsTab: [BeaconArticleMap.HelpPage, BeaconArticleMap.AnnotationTableWebApp, BeaconArticleMap.AnnotationIntroduction, BeaconArticleMap.ProjectsAndModels, BeaconArticleMap.GettingStarted, BeaconArticleMap.DesktopVsWebInterface, BeaconArticleMap.ObvisHomePage],
    HomePageCRSTab: [BeaconArticleMap.HelpPage, BeaconArticleMap.CRSs, BeaconArticleMap.EditingCRSs, BeaconArticleMap.ProjectsAndModels, BeaconArticleMap.GettingStarted, BeaconArticleMap.DesktopVsWebInterface, BeaconArticleMap.ObvisHomePage],
    OrganisationSettingsPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.OrganisationSettingsPage, BeaconArticleMap.ObvisHomePage],
    SystemSettingsPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.SystemSettingsPage, BeaconArticleMap.ObvisHomePage],
    OrganisationUsersPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.OrganisationUsersPage, BeaconArticleMap.ObvisHomePage],
    OrganisationGroupsPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.OrganisationGroupsPage, BeaconArticleMap.ObvisHomePage],
    ModelImportLogsPage: [BeaconArticleMap.HelpPage, BeaconArticleMap.ModelImportLogsPage, BeaconArticleMap.ObvisHomePage],
    EditCRSDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditCrsDialog, BeaconArticleMap.ObvisHomePage],
    DeleteCRSDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteCRSDialog, BeaconArticleMap.ObvisHomePage],
    EditCRSTransformDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditCRSTransformDialog, BeaconArticleMap.ObvisHomePage],
    DeleteCRSTransformDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteCRSTransformDialog, BeaconArticleMap.ObvisHomePage],
    AnnotationPropertyTemplatesDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AnnotationPropertyTemplatesDialog, BeaconArticleMap.ObvisHomePage],
    DeleteAnnotationPropertyTemplateDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteAnnotationPropertyTemplatesDialog, BeaconArticleMap.ObvisHomePage],
    EditAnnotationPropertyTemplateDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationPropertyTemplatesDialog, BeaconArticleMap.ObvisHomePage],
    AnnotationStyleSheetsDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AnnotationStyleSheetDialog, BeaconArticleMap.ObvisHomePage],
    EditAnnotationStyleSheetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditStyleSheet, BeaconArticleMap.AnnotationStyling, BeaconArticleMap.ObvisHomePage],
    DeleteAnnotationStyleSheetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteAnnotationStyleSheetDialog, BeaconArticleMap.ObvisHomePage],
    EditAnnotationStyleRuleDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationStyleRuleDialog, BeaconArticleMap.ObvisHomePage],
    DeleteAnnotationStyleRuleDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteAnnotationStyleRuleDialog, BeaconArticleMap.ObvisHomePage],
    EditProjectDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditProjectDialog, BeaconArticleMap.ObvisHomePage],
    AddProjectDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditProjectDialog, BeaconArticleMap.ObvisHomePage],
    DeleteProjectDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteProjectDialog, BeaconArticleMap.ObvisHomePage],
    EditModelDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditModelDialog, BeaconArticleMap.ObvisHomePage],
    ImportModelDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.ImportingModels, BeaconArticleMap.EditModelDialog, BeaconArticleMap.ObjModelsRotated, BeaconArticleMap.ObvisHomePage],
    DeleteModelDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteModelDialog, BeaconArticleMap.ObvisHomePage],
    CopyAnnotationSetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.CopyAnnotationSetDialog, BeaconArticleMap.ObvisHomePage],
    EditAnnotationSetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationSetDialog, BeaconArticleMap.ObvisHomePage],
    AddAnnotationSetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationSetDialog, BeaconArticleMap.ObvisHomePage],
    DeleteAnnotationSetDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteAnnotationSetDialog, BeaconArticleMap.ObvisHomePage],
    EditSingleAnnotationPropertiesDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationPropertiesDialog, BeaconArticleMap.ObvisHomePage],
    EditMultipleAnnotationPropertiesDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditAnnotationPropertiesDialog, BeaconArticleMap.EditMultipleAnnotationPropertiesDialog, BeaconArticleMap.ObvisHomePage],
    ShareWithAnExternalUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.ShareWithAnExternalUserDialog, BeaconArticleMap.ObvisObvisHomePage],
    EditPermissionsDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditPermissionsDialog, BeaconArticleMap.ObvisObvisHomePage],
    DeletePermissionsDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeletePermissionsDialog, BeaconArticleMap.ObvisObvisHomePage],
    EditUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditUserDialog, BeaconArticleMap.ObvisHomePage],
    DeleteDeactivateUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteDeactivateUserDialog, BeaconArticleMap.ObvisHomePage],
    MustDeactivateUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.MustDeactivateUserDialog, BeaconArticleMap.ObvisHomePage],
    AddUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AddingUser, BeaconArticleMap.ObvisHomePage],
    AssignLicenseToUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AssignLicenseToUserDialog, BeaconArticleMap.ObvisHomePage],
    EditGroupDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditGroupDialog, BeaconArticleMap.ObvisHomePage],
    DeleteExternalUserDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteExternalUserDialog, BeaconArticleMap.ObvisHomePage],
    NewUserShareDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.NewUserShareDialog, BeaconArticleMap.ObvisHomePage],
    DeleteUserShareDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteUserShareDialog, BeaconArticleMap.ObvisHomePage],
    NewGroupShareDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.NewGroupShareDialog, BeaconArticleMap.ObvisHomePage],
    DeleteGroupShareDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteGroupShareDialog, BeaconArticleMap.ObvisHomePage],
    DeleteExternalGroupDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteExternalGroupDialog, BeaconArticleMap.ObvisHomeObvisHomePage],
    EditLicenseDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditLicenseDialog, BeaconArticleMap.ObvisHomeObvisHomePage],
    AddUserLicenseDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AddUserLicenseDialog, BeaconArticleMap.ObvisHomeObvisHomePage],
    AddOrgLicenseDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AddOrgLicenseDialog, BeaconArticleMap.ObvisHomeObvisHomePage],
    AssignUserToLicenseDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AssignUserToLicenseDialog, BeaconArticleMap.ObvisHomePage],
    DeleteGroupDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.DeleteGroupDialog, BeaconArticleMap.ObvisHomePage],
    EditOrganisationDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.EditOrganisationDialog, BeaconArticleMap.ObvisObvisHomePage],
    AddEmptyOrganisationDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AddEmptyOrganisationDialog, BeaconArticleMap.ObvisObvisHomePage],
    AddOrganisationFromTemplateDialog: [BeaconArticleMap.HelpPage, BeaconArticleMap.AddOrganisationFromTemplateDialog, BeaconArticleMap.ObvisObvisHomePage],

}

//set list of articles
export function BeaconSetSuggestions(suggestions) {
    logger.debug("SetSuggestions", suggestions);
    window.Beacon('suggest', suggestions);
}

