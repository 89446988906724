import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');


export async function CreateProject(project) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: project
    }

    let response = await API.post('Reveal4DApi', '/CreateProject', options);
    logger.debug("CreateProject , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;//ProjectId
    }

}

export async function GetProject(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    const unityVersion = "[\"all\"]";



    let response = await API.get('Reveal4DApi', '/GetProjectWithoutAnnotations?projectid=' + id + "&unityversion=" + unityVersion, options);
    logger.debug("GetProject , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}

export async function StartCopyProjectStateMachine(projectId, orgIdDest, defaultUserId, modelIdList) {

    logger.debug("StartCopyProjectStateMachine", projectId, orgIdDest, defaultUserId, modelIdList);

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            projectid: projectId,
            organisationid: orgIdDest,
            userid: defaultUserId,
            modelids: modelIdList
        }
    }


    let response = await API.post('Reveal4DApi', '/StartCopyProjectStateMachine', options);
    logger.debug("StartCopyProjectStateMachine , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}

export async function UpdateProject(project) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: project
    }

    let response = await API.put('Reveal4DApi', '/UpdateProject', options);
    logger.debug("UpdateProject , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return { project };
    }
}



export async function DeleteProject(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            projectid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteProject', options);
    logger.debug("DeleteProject , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}
