import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ToastLoading, ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { selectOrganisationsInfo, getConnectedWebSocketUsers } from '../App/organisationsInfoSlice';
import Select from 'react-select'
import { BroadcastTextMessage } from '../Api//SystemApi'


const messageTypeOptions = [

    { value: "info", label: "Information" },
    { value: "error", label: "Error" },
    { value: "success", label: "Success" },
];


export function BroadcastTextMessageComponent() {
    const dispatch = useDispatch();
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const toastid = useRef(null);

    const [formDisabled, setFormDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedUsersValid, setSelectedUsersValid] = useState(false);
    const [typeValid, setTypeValid] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [type, setType] = useState(null);
    const [availableUsersOptions, setAvailableUsersOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [from, setFrom] = useState("");

    React.useEffect(() => {


        if (orgsInfo?.connectedWebSocketUsers.length > 0) {

            let options = [];
            orgsInfo.connectedWebSocketUsers.forEach(user => {
                options.push({ value: user.CognitoId, label: user.UserName });
            })

            setAvailableUsersOptions(options);
        }

    }, [dispatch, orgsInfo?.connectedWebSocketUsers]);


    function onSubmitClicked(event) {
        event.preventDefault();

        const form = event.currentTarget;

        let cids = [];
        selectedUsers.forEach(userElement => {
            cids.push(userElement.value);
        });

        setSelectedUsersValid(cids.length > 0);
        setTypeValid(type);

        if (form.checkValidity() && cids.length > 0 && type) {
            setFormDisabled(true);
            toastid.current = ToastLoading("Contacting server");

            BroadcastTextMessage(cids, message, type.value, from)
                .then(res => {
                    ToastSuccess("Message sent", toastid.current, 2000);
                    setFormDisabled(false);
                })
                .catch(err => {
                    setFormDisabled(false);
                    ToastError(err.message, err.cause, toastid.current);
                });

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function getUsers() {
        setFormDisabled(true);
        dispatch(getConnectedWebSocketUsers()).then(() => setFormDisabled(false));
    }

    return (
        <Container className="border border-dark mx-2 my-2">
            <h2>Broadcast Message</h2>
            <Form noValidate validated={validated} onSubmit={onSubmitClicked}>
                <Form.Group controlId="formGroupDbAddress" as={Row}>
                    <Form.Label column sm={2} >To Users:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={selectedUsers}
                            onChange={setSelectedUsers}
                            closeMenuOnSelect={true}
                            isMulti
                            name="users"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Users..."
                            options={availableUsersOptions} />
                        {(!selectedUsersValid && validated) &&
                            <Form.Text className="text-danger">Please select users.</Form.Text>}
                    </Col>
                    <Col  >
                        <Button disabled={formDisabled} onClick={() => getUsers()}>Get Users</Button>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formFrom" as={Row}>
                    <Form.Label column sm={2} >From:</Form.Label>
                    <Col sm={10}>
                        <Form.Control disabled={formDisabled} required value={from} onChange={(e) => setFrom(e.target.value)} placeholder='From...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter a 'from' field.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formMessage" as={Row}>
                    <Form.Label column sm={2} >Message:</Form.Label>
                    <Col sm={10}>
                        <Form.Control disabled={formDisabled} required value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Message...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter a message.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupType" as={Row}>
                    <Form.Label column sm={2} >Type:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={type}
                            onChange={setType}
                            closeMenuOnSelect={true}
                            isMulti={false}
                            name="types"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Type..."
                            options={messageTypeOptions} />
                        {(!typeValid && validated) &&
                            <Form.Text className="text-danger">Please select Type.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupButton" as={Row}>
                    <Col sm={{ offset: 2, span: 2 }} >
                        <Button disabled={formDisabled} type='submit'>Send</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );

}

export default BroadcastTextMessageComponent;