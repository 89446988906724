import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import * as Constants from '../constants.js'
import { v4 as uuid } from "uuid";
import { InputGroup } from "react-bootstrap";

export function RotateAboutAxisStep(props) {

    const [valid, setValid] = useState(true);


    function handleChange(event) {
        props.transformStep.Angle = event.target.value;
        validateChange(event.target.value)
    }

    function validateChange(value) {

        let num = Number(value);
        const isValid = !isNaN(num) && value !== "" && value !== "";
        try {
            props.transformStep.isInvalid = !isValid;
        }
        catch (e) { }
        setValid(isValid);
    }

    useEffect(() => {
        validateChange(props.transformStep.Angle);
    });

    return (
        <Form noValidate>
            <Row >
                <Col sm={4} className="ms-1">
                    <Form.Label >Rotate {props.axis} axis:</Form.Label>
                </Col>
                <Col className="me-1">
                    <InputGroup hasValidation>
                        <InputGroup.Text size="sm">{props.axis}</InputGroup.Text>
                        <Form.Control
                            required
                            type="text"
                            name={props.axis}
                            isInvalid={!valid}
                            defaultValue={props.transformStep.Angle}
                            onChange={handleChange} >

                        </Form.Control>
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );
}
RotateAboutAxisStep.propTypes = {
    readOnly: PropTypes.bool,
    transformStep: PropTypes.object,
};


export function TranslateStep(props) {
    const [xValid, setXValid] = useState(true);
    const [yValid, setYValid] = useState(true);
    const [zValid, setZValid] = useState(true);


    function handleChange(event) {

        if (event.target.name === "x") {
            props.transformStep.Translation.x = event.target.value;
        }
        else if (event.target.name === "y") {
            props.transformStep.Translation.y = event.target.value;
        }
        else if (event.target.name === "z") {
            props.transformStep.Translation.z = event.target.value;
        }

        validateChange(props.transformStep.Translation);
    }

    function validateChange(Translation) {
        setXValid(!isNaN(Translation.x) && Translation.x !== "");
        setYValid(!isNaN(Translation.y) && Translation.y !== "");
        setZValid(!isNaN(Translation.z) && Translation.z !== "");
    }

    useEffect(() => {
        validateChange(props.transformStep.Translation);
    });

    useEffect(() => {
        try {
            props.transformStep.isInvalid = !(xValid && yValid && zValid);
        } catch (e) { }

    }, [props, props.transformStep, xValid, yValid, zValid]
    )


    return (
        <Form noValidate>
            <Row >
                <Col sm={3} className="ml-1">
                    <Form.Label >Translate:</Form.Label>
                </Col>
                <Col>
                    <InputGroup hasValidation >
                        <InputGroup.Text>X</InputGroup.Text>
                        <Form.Control required type="text" name="x" isInvalid={!xValid} defaultValue={props.transformStep.Translation.x} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation >
                        <InputGroup.Text>Y</InputGroup.Text>
                        <Form.Control required type="text" name="y" isInvalid={!yValid} defaultValue={props.transformStep.Translation.y} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="mr-1">
                    <InputGroup hasValidation>

                        <InputGroup.Text>Z</InputGroup.Text>

                        <Form.Control required type="text" name="z" isInvalid={!zValid} defaultValue={props.transformStep.Translation.z} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );
}
TranslateStep.propTypes = {
    readOnly: PropTypes.bool,
    transformStep: PropTypes.object,
};

export function ScaleStep(props) {


    const [xValid, setXValid] = useState(true);
    const [yValid, setYValid] = useState(true);
    const [zValid, setZValid] = useState(true);


    function handleChange(event) {

        if (event.target.name === "x") {
            props.transformStep.Scale.x = event.target.value;
        }
        else if (event.target.name === "y") {
            props.transformStep.Scale.y = event.target.value;
        }
        else if (event.target.name === "z") {
            props.transformStep.Scale.z = event.target.value;
        }

        validateChange(props.transformStep.Scale);
    }

    function validateChange(Scale) {
        setXValid(!isNaN(Scale.x) && Scale.x !== "");
        setYValid(!isNaN(Scale.y) && Scale.y !== "");
        setZValid(!isNaN(Scale.z) && Scale.z !== "");
    }

    useEffect(() => {
        validateChange(props.transformStep.Scale);
    });

    useEffect(() => {
        try {
            props.transformStep.isInvalid = !(xValid && yValid && zValid);
        } catch (e) { }

    }, [props, props.transformStep, xValid, yValid, zValid]
    );

    return (
        <Form noValidate>
            <Row >
                <Col sm={3} className="ms-1">
                    <Form.Label >Scale:</Form.Label>
                </Col>
                <Col>
                    <InputGroup hasValidation >

                        <InputGroup.Text>X</InputGroup.Text>

                        <Form.Control required type="text" name="x" isInvalid={!xValid} defaultValue={props.transformStep.Scale.x} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation >

                        <InputGroup.Text>Y</InputGroup.Text>

                        <Form.Control required type="text" name="y" isInvalid={!yValid} defaultValue={props.transformStep.Scale.y} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="me-1">
                    <InputGroup hasValidation>

                        <InputGroup.Text>Z</InputGroup.Text>

                        <Form.Control required type="text" name="z" isInvalid={!zValid} defaultValue={props.transformStep.Scale.z} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );

}
ScaleStep.propTypes = {
    readOnly: PropTypes.bool,
    transformStep: PropTypes.object,
};

export function EulerStep(props) {

    const [xValid, setXValid] = useState(true);
    const [yValid, setYValid] = useState(true);
    const [zValid, setZValid] = useState(true);


    function handleChange(event) {

        if (event.target.name === "x") {
            props.transformStep.Rotation.x = event.target.value;
        }
        else if (event.target.name === "y") {
            props.transformStep.Rotation.y = event.target.value;
        }
        else if (event.target.name === "z") {
            props.transformStep.Rotation.z = event.target.value;
        }

        validateChange(props.transformStep.Rotation);
    }

    function validateChange(Rotation) {
        setXValid(!isNaN(Rotation.x) && Rotation.x !== "");
        setYValid(!isNaN(Rotation.y) && Rotation.y !== "");
        setZValid(!isNaN(Rotation.z) && Rotation.z !== "");
    }

    useEffect(() => {
        validateChange(props.transformStep.Rotation);
    });

    useEffect(() => {
        try {
            props.transformStep.isInvalid = !(xValid && yValid && zValid);
        } catch (e) { }

    }, [props, props.transformStep, xValid, yValid, zValid]
    );

    return (
        <Form noValidate>
            <Row >
                <Col sm={3} className="ml-1">
                    <Form.Label >Euler:</Form.Label>
                </Col>
                <Col>
                    <InputGroup hasValidation >

                        <InputGroup.Text>X</InputGroup.Text>

                        <Form.Control required type="text" name="x" isInvalid={!xValid} defaultValue={props.transformStep.Rotation.x} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation >

                        <InputGroup.Text>Y</InputGroup.Text>

                        <Form.Control required type="text" name="y" isInvalid={!yValid} defaultValue={props.transformStep.Rotation.y} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="mr-1">
                    <InputGroup hasValidation>

                        <InputGroup.Text>Z</InputGroup.Text>

                        <Form.Control required type="text" name="z" isInvalid={!zValid} defaultValue={props.transformStep.Rotation.z} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
        </Form>
    );
}
EulerStep.propTypes = {
    readOnly: PropTypes.bool,
    transformStep: PropTypes.object,
};

export function MatrixStep(props) {

    const [m00Valid, setM00Valid] = useState(true);
    const [m01Valid, setM01Valid] = useState(true);
    const [m02Valid, setM02Valid] = useState(true);
    const [m03Valid, setM03Valid] = useState(true);
    const [m10Valid, setM10Valid] = useState(true);
    const [m11Valid, setM11Valid] = useState(true);
    const [m12Valid, setM12Valid] = useState(true);
    const [m13Valid, setM13Valid] = useState(true);
    const [m20Valid, setM20Valid] = useState(true);
    const [m21Valid, setM21Valid] = useState(true);
    const [m22Valid, setM22Valid] = useState(true);
    const [m23Valid, setM23Valid] = useState(true);
    const [m30Valid, setM30Valid] = useState(true);
    const [m31Valid, setM31Valid] = useState(true);
    const [m32Valid, setM32Valid] = useState(true);
    const [m33Valid, setM33Valid] = useState(true);


    function handleChange(event) {
        if (event.target.name === "m00") {
            props.transformStep.Matrix.m00 = event.target.value;
        }
        if (event.target.name === "m01") {
            props.transformStep.Matrix.m01 = event.target.value;
        }
        if (event.target.name === "m02") {
            props.transformStep.Matrix.m02 = event.target.value;
        }
        if (event.target.name === "m03") {
            props.transformStep.Matrix.m03 = event.target.value;
        }
        if (event.target.name === "m10") {
            props.transformStep.Matrix.m10 = event.target.value;
        }
        if (event.target.name === "m11") {
            props.transformStep.Matrix.m11 = event.target.value;
        }
        if (event.target.name === "m12") {
            props.transformStep.Matrix.m12 = event.target.value;
        }
        if (event.target.name === "m13") {
            props.transformStep.Matrix.m13 = event.target.value;
        }
        if (event.target.name === "m20") {
            props.transformStep.Matrix.m20 = event.target.value;
        }
        if (event.target.name === "m21") {
            props.transformStep.Matrix.m21 = event.target.value;
        }
        if (event.target.name === "m22") {
            props.transformStep.Matrix.m22 = event.target.value;
        }
        if (event.target.name === "m23") {
            props.transformStep.Matrix.m23 = event.target.value;
        }
        if (event.target.name === "m30") {
            props.transformStep.Matrix.m30 = event.target.value;
        }
        if (event.target.name === "m31") {
            props.transformStep.Matrix.m31 = event.target.value;
        }
        if (event.target.name === "m32") {
            props.transformStep.Matrix.m32 = event.target.value;
        }
        if (event.target.name === "m33") {
            props.transformStep.Matrix.m33 = event.target.value;
        }

        validateChange(props.transformStep.Matrix);
    }

    function validateChange(Matrix) {
        setM00Valid(!isNaN(Matrix.m00) && Matrix.m00 !== "");
        setM10Valid(!isNaN(Matrix.m10) && Matrix.m10 !== "");
        setM20Valid(!isNaN(Matrix.m20) && Matrix.m20 !== "");
        setM30Valid(!isNaN(Matrix.m30) && Matrix.m30 !== "");
        setM01Valid(!isNaN(Matrix.m01) && Matrix.m01 !== "");
        setM11Valid(!isNaN(Matrix.m11) && Matrix.m11 !== "");
        setM21Valid(!isNaN(Matrix.m21) && Matrix.m21 !== "");
        setM31Valid(!isNaN(Matrix.m31) && Matrix.m31 !== "");
        setM02Valid(!isNaN(Matrix.m02) && Matrix.m02 !== "");
        setM12Valid(!isNaN(Matrix.m12) && Matrix.m12 !== "");
        setM22Valid(!isNaN(Matrix.m22) && Matrix.m22 !== "");
        setM32Valid(!isNaN(Matrix.m32) && Matrix.m32 !== "");
        setM03Valid(!isNaN(Matrix.m03) && Matrix.m03 !== "");
        setM13Valid(!isNaN(Matrix.m13) && Matrix.m13 !== "");
        setM23Valid(!isNaN(Matrix.m23) && Matrix.m23 !== "");
        setM33Valid(!isNaN(Matrix.m33) && Matrix.m33 !== "");
    }

    useEffect(() => {
        validateChange(props.transformStep.Matrix);
    });

    useEffect(() => {
        try {
            props.transformStep.isInvalid = !(m00Valid && m01Valid && m02Valid && m03Valid &&
                m10Valid && m11Valid && m12Valid && m33Valid &&
                m20Valid && m21Valid && m22Valid && m33Valid &&
                m30Valid && m31Valid && m32Valid && m33Valid);
        } catch (e) { }

    }, [m00Valid, m01Valid, m02Valid, m03Valid, m10Valid, m11Valid, m12Valid, m20Valid, m21Valid, m22Valid, m30Valid, m31Valid, m32Valid, m33Valid, props, props.transformStep]
    );
    return (
        <Row className="pe-2">
            <Row className="ms-1 ps-3 pb-1">
                Matrix:
            </Row>
            <Row>
                <Col className="ps-4">
                    <InputGroup hasValidation>
                        <InputGroup.Text>00</InputGroup.Text>
                        <Form.Control required type="text" name="m00" isInvalid={!m00Valid} defaultValue={props.transformStep.Matrix.m00} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>01</InputGroup.Text>

                        <Form.Control required type="text" name="m01" isInvalid={!m01Valid} defaultValue={props.transformStep.Matrix.m01} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col >
                    <InputGroup hasValidation>

                        <InputGroup.Text>02</InputGroup.Text>

                        <Form.Control required type="text" name="m02" isInvalid={!m02Valid} defaultValue={props.transformStep.Matrix.m02} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="pe-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>03</InputGroup.Text>

                        <Form.Control required type="text" name="m03" isInvalid={!m03Valid} defaultValue={props.transformStep.Matrix.m03} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="ps-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>10</InputGroup.Text>

                        <Form.Control required type="text" name="m10" isInvalid={!m10Valid} defaultValue={props.transformStep.Matrix.m10} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>11</InputGroup.Text>

                        <Form.Control required type="text" name="m11" isInvalid={!m11Valid} defaultValue={props.transformStep.Matrix.m11} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>12</InputGroup.Text>

                        <Form.Control required type="text" name="m12" isInvalid={!m12Valid} defaultValue={props.transformStep.Matrix.m12} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="pe-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>13</InputGroup.Text>

                        <Form.Control required type="text" name="m13" isInvalid={!m13Valid} defaultValue={props.transformStep.Matrix.m13} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="ps-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>20</InputGroup.Text>

                        <Form.Control required type="text" name="m20" isInvalid={!m20Valid} defaultValue={props.transformStep.Matrix.m20} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>21</InputGroup.Text>

                        <Form.Control required type="text" name="m21" isInvalid={!m21Valid} defaultValue={props.transformStep.Matrix.m21} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>22</InputGroup.Text>

                        <Form.Control required type="text" name="m22" isInvalid={!m22Valid} defaultValue={props.transformStep.Matrix.m22} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="pe-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>23</InputGroup.Text>

                        <Form.Control required type="text" name="m23" isInvalid={!m23Valid} defaultValue={props.transformStep.Matrix.m23} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="ps-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>30</InputGroup.Text>

                        <Form.Control required type="text" name="m30" isInvalid={!m30Valid} defaultValue={props.transformStep.Matrix.m30} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>31</InputGroup.Text>

                        <Form.Control required type="text" name="m31" isInvalid={!m31Valid} defaultValue={props.transformStep.Matrix.m31} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col>
                    <InputGroup hasValidation>

                        <InputGroup.Text>32</InputGroup.Text>

                        <Form.Control required type="text" name="m32" isInvalid={!m32Valid} defaultValue={props.transformStep.Matrix.m32} onChange={handleChange} />
                    </InputGroup>
                </Col>
                <Col className="pe-4">
                    <InputGroup hasValidation>

                        <InputGroup.Text>33</InputGroup.Text>

                        <Form.Control required type="text" name="m33" isInvalid={!m33Valid} defaultValue={props.transformStep.Matrix.m33} onChange={handleChange} />
                    </InputGroup>
                </Col>
            </Row>

        </Row>
    );
}
MatrixStep.propTypes = {
    readOnly: PropTypes.bool,
    transformStep: PropTypes.object,
};


export function TransformStepTypeElement(props) {

    let text = "";
    switch (Number(props.transformStep.Type)) {

        case Constants.TransformStepType.RotateAboutX:
            text = "Rotate About X Axis";
            break;
        case Constants.TransformStepType.RotateAboutY:
            text = "Rotate About Y Axis";
            break;
        case Constants.TransformStepType.RotateAboutZ:
            text = "Rotate About Z Axis";
            break;
        case Constants.TransformStepType.Scale:
            text = "Scale";
            break;
        case Constants.TransformStepType.Translate:
            text = "Translate";
            break;
        case Constants.TransformStepType.Matrix:
            text = "Matrix Transform";
            break;
        case Constants.TransformStepType.EulerXYZRightHanded:
            text = "Euler Rotation (X-Y-Z RH)";
            break;

        default:
            text = "";
            break;
    }

    return (

        <div className={props.className}>
            {text}
        </div>

    );

}
TransformStepTypeElement.propTypes = {
    index: PropTypes.number,
    transformStep: PropTypes.object
};


export function TransformStepElementComponent(props) {
    switch (props.transformStep.Type) {

        case Constants.TransformStepType.RotateAboutX:
            return <RotateAboutAxisStep {...props} axis="X" />
        case Constants.TransformStepType.RotateAboutY:
            return <RotateAboutAxisStep {...props} axis="Y" />
        case Constants.TransformStepType.RotateAboutZ:
            return <RotateAboutAxisStep {...props} axis="Z" />
        case Constants.TransformStepType.Scale:
            return <ScaleStep {...props} />
        case Constants.TransformStepType.Translate:
            return <TranslateStep {...props} />
        case Constants.TransformStepType.Matrix:
            return <MatrixStep {...props} />
        case Constants.TransformStepType.EulerXYZRightHanded:
            return <EulerStep {...props} />
        default:
            return <></>
    }
}
TransformStepElement.propTypes = {
    index: PropTypes.number,
    Data: PropTypes.array
};


export function TransformStepElement(props) {

    return (
        <Container fluid className={props.className + " border border-dark rounded pt-2 pb-2 mt-2 me-1"}>
            <TransformStepElementComponent {...props} />
        </Container>
    );

}


export function createStepObject(type) {

    switch (type) {
        case Constants.TransformStepType.RotateAboutX:
            return { id: uuid(), Type: type, Angle: 0 }
        case Constants.TransformStepType.RotateAboutY:
            return { id: uuid(), Type: type, Angle: 0 }
        case Constants.TransformStepType.RotateAboutZ:
            return { id: uuid(), Type: type, Angle: 0 }
        case Constants.TransformStepType.Scale:
            return { id: uuid(), Type: type, Scale: { x: 1, y: 1, z: 1 } }
        case Constants.TransformStepType.Translate:
            return { id: uuid(), Type: type, Translation: { x: 0, y: 0, z: 0 } }
        case Constants.TransformStepType.Matrix:
            return { id: uuid(), Type: type, Matrix: { m00: 0, m01: 0, m02: 0, m03: 0, m10: 0, m11: 0, m12: 0, m13: 0, m20: 0, m21: 0, m22: 0, m23: 0, m30: 0, m31: 0, m32: 0, m33: 0 } }
        case Constants.TransformStepType.EulerXYZRightHanded:
            return { id: uuid(), Type: type, Rotation: { x: 0, y: 0, z: 0 } }
        default:
            return { id: uuid(), Type: type }
    }

}

