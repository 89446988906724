import React, { useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import '../css/lists.css'
import '../css/annotation-properties.css'

import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { v4 as uuid } from "uuid";
import { CreateAnnotationStyleRule, DeleteAnnotationStyleRule, UpdateAnnotationStyleRule } from '../Api/AnnotationPropertiesApi'
import * as Constants from '../constants.js';
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { EditStyleRule } from './EditStyleRule'
import { ConfirmModal } from '../Components/ConfirmModal'
import { CanEditAnnotationTemplatesOrStyleSheets } from '../App/UserPermissions'
import { ColourSwatch } from '../Components/ColourSwatch.js'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditStyleSheet');

function SubjectParameterView(subjectParameter) {


    switch (subjectParameter?.StyleSubject) {
        //is visible
        case Constants.subjects.IsVisible:
            return (
                <div className="parameter-display">
                    {subjectParameter?.IsVisible ? "[Visible]" : "[Not Visible]"}
                </div>
            );
        //colours
        case Constants.subjects.LabelColour:
        case Constants.subjects.TextColour:
        case Constants.subjects.LineColour:
            return (
                <div className="parameter-display">
                    <ColourSwatch colour={'#' + subjectParameter?.Colour} small />
                    <div>[#{subjectParameter?.Colour}]</div>
                </div>
            );
        //fill
        case Constants.subjects.ShapeFillColour:
            return (
                <div className="parameter-display">
                    {subjectParameter?.Colour !== "NOFILL" ?
                        <div className="colour-display">
                            <ColourSwatch colour={'#' + subjectParameter?.Colour} small />
                            <div>[#{subjectParameter?.Colour}]</div>
                        </div>
                        :

                        "[None]"
                    }
                </div>
            );

        //label text
        case Constants.subjects.LabelText:
            return (
                <div className="parameter-display">
                    {subjectParameter?.PropertyTemplate?.Title &&
                        <>
                            {"Property: [" + subjectParameter?.PropertyTemplate?.Title + "]"}
                        </>}
                    {(subjectParameter?.PropertyTemplate?.Title && subjectParameter?.Text) &&
                        <>
                            , or if empty:
                        </>
                    }
                    {subjectParameter?.Text &&
                        <>
                            {" [\"" + subjectParameter?.Text + "\"]"}
                        </>}
                </div>
            );
        //line width
        case Constants.subjects.LineWidth:
            return (
                <div className="parameter-display">
                    {"[" + subjectParameter?.LineWidth + "]"}
                </div>
            );

        default:
            break;
    }
}


function TypeToString(type) {

    switch (type) {
        case 0:
            return "Value"
        case 1:
            return "List"
        case 2:
            return "DateCreated"
        case 3:
            return "DateChanged"
        case 4:
            return "Occluded by model"

        default:
            return "??"
    }

};

function SubjectToString(subject) {
    switch (subject) {
        case Constants.subjects.IsVisible:
            return "Is Visible"
        case Constants.subjects.LabelColour:
            return "Label Colour"
        case Constants.subjects.TextColour:
            return "Text Colour"
        case Constants.subjects.LabelText:
            return "Label Text"
        case Constants.subjects.LineWidth:
            return "Line Width"
        case Constants.subjects.ShapeFillColour:
            return "Shape Fill Colour"
        case Constants.subjects.LineColour:
            return "Line Colour"
        default:
            return "??"
    }
};

function OperatorToString(op) {

    switch (op) {
        case Constants.operators.Equals:
            return "="
        case Constants.operators.NotEquals:
            return "!="
        case Constants.operators.Contains:
            return "Contains"
        case Constants.operators.NotContains:
            return "Doesn't Contain"
        case Constants.operators.GreaterThan:
            return ">"
        case Constants.operators.GreaterThanOrEqual:
            return ">="
        case Constants.operators.LessThan:
            return "<"
        case Constants.operators.LessThanOrEqual:
            return "<="
        case Constants.operators.Empty:
            return "Is Empty"
        case Constants.operators.NotEmpty:
            return "Is Not Empty"
        case Constants.operators.AnyOf:
            return "Any Of"
        case Constants.operators.AllOf:
            return "All Of"

        default:
            return "??"
    }

};


function styleSheetRules(rules) {

    //currently enabled style rules
    let copiedEnabledStyleRules = rules ?
        JSON.parse(JSON.stringify(rules)).sort((a, b) => (a.Order - b.Order)) :
        [];
    let copiedStyleRules = copiedEnabledStyleRules.map(item => item.AnnotationStyleRule);

    copiedStyleRules.forEach((element, index, array) => {
        array[index].id = "id:" + uuid();
    });

    return copiedStyleRules;

}

//################################################################################################
// render fn
//################################################################################################
export function EditStyleSheet(props) {

    logger.debug("EditStyleSheet props", props);




    const [styleSheetStyleRules, setStyleSheetStyleRules] = useState(styleSheetRules(props.annotationStyleSheet?.AnnotationStyleRules));
    const [projectStyleRules, setProjectStyleRules] = useState([]);
    const [title, setTitle] = useState(props.annotationStyleSheet?.Title);

    const [selectedStyleRuleId, setSelectedStyleRuleId] = useState("");
    const [showStyleRuleAddDialog, setShowStyleRuleAddDialog] = useState(false);
    const [showStyleRuleEditDialog, setShowStyleRuleEditDialog] = useState(false);

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    const toastId = useRef();

    React.useEffect(() => {
        if (props.show) {
            BeaconSetSuggestions(props.helpContext);
        }
        else {
            BeaconSetSuggestions(props.parentHelpContext);
        }
    }, [props.helpContext, props.parentHelpContext, props.show])


    React.useEffect(() => {

        //copy style rules
        //add ids for list reordereing
        if (props.show) {

            //available style rules
            //copy project rules 
            let availableStyleRules = JSON.parse(JSON.stringify(props.project.AnnotationStyleRules));
            availableStyleRules.forEach((element, index, array) => {
                array[index].id = "id:" + uuid();
            });

            setProjectStyleRules(availableStyleRules);

        }
        else {
            setStyleSheetStyleRules([]);
        }
    }, [props?.project?.AnnotationStyleRules, props.show])


    function PropertyTemplateTitle(ComparePropertyTemplate) {
        const template = props.project.PropertyTemplates.find(pt => pt.PropertyTemplateId === ComparePropertyTemplate.PropertyTemplateId);
        if (template) {
            return template.Title;
        }
        else {
            return ComparePropertyTemplate.Title;
        }
    }

    function StyleRuleItem(props) {

        //which type - value or list
        if (props?.styleRule) {
            //value
            if (props.styleRule?.Type === Constants.propertyType.Value) {
                return (
                    <div className={props.className + " styleRuleContents p-2 border border-top-1 border-left-1 rounded border-dark  text-black"}>

                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1 "}>
                                {"When: [" + PropertyTemplateTitle(props.styleRule?.ComparePropertyTemplate) + "]"}
                            </div>
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1 "}>
                                {OperatorToString(props.styleRule?.Operator)}
                            </div>
                            {!(props.styleRule?.Operator === Constants.operators.Empty || props.styleRule?.Operator === Constants.operators.NotEmpty) ?
                                <div className={"me-1 mt-1 p-0 ps-1 pe-1 "}>
                                    {props.styleRule?.ComparePropertyTemplate?.ValueType === Constants.valuePropertyTypeType.Text ?
                                        props.styleRule?.Parameter === null ? ("[\"\"]") : ("[\"" + props.styleRule?.Parameter + "\"]")
                                        :
                                        ("[" + props.styleRule?.Parameter + "]")}
                                </div>
                                : <></>}
                        </div>
                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1  "}>
                                {"Set [" + SubjectToString(props.styleRule?.SubjectParameter?.StyleSubject) + "] to : "}
                            </div>
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1  "}>
                                {SubjectParameterView(props.styleRule?.SubjectParameter)}
                            </div>
                        </div>
                    </div>
                );
            }
            //list
            else if (props.styleRule.Type === Constants.propertyType.List) {
                let parameterItems;
                if (props.styleRule?.ListParameter?.length === 0) {
                    parameterItems =
                        <div key={"nothing"}
                            className={"me-1 mt-1 p-0 ps-1 pe-1 rounded border border-dark"}>
                            No Items
                        </div>
                }
                else if (props.styleRule?.ListParameter?.length === 1 && props.styleRule?.ListParameter[0].ListPropertyTemplateListItemId === "00000000-0000-0000-0000-000000000000") {
                    parameterItems =
                        <div key={"nothing"}
                            className={"me-1 mt-1 p-0 ps-1 pe-1 rounded border border-dark"}>
                            No Items
                        </div>
                }
                else {
                    parameterItems = props.styleRule.ListParameter ? props.styleRule?.ListParameter.map((item) =>
                        <div key={item.ListPropertyTemplateListItemId}
                            className={"me-1 mt-1 p-0 ps-1 pe-1 rounded border border-light bg-primary text-white"}>
                            {item.ItemText}
                        </div>
                    ) : <></>
                }

                return (

                    <div className={props.className + " styleRuleContents p-2 border border-top-1 border-left-1 rounded border-dark text-black"} >
                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1 "}>
                                {"When: [" + props.styleRule?.ComparePropertyTemplate?.Title + "]"}
                            </div>
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1 "}>
                                {OperatorToString(props.styleRule?.Operator)}
                            </div>
                            <div className="styleRuleSubject">
                                {parameterItems}
                            </div>
                        </div>
                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1  "}>
                                {"Set [" + SubjectToString(props.styleRule?.SubjectParameter?.StyleSubject) + "] to: "}
                            </div>
                            <div className={"me-1 mt-1 p-0 ps-1 pe-1  "}>
                                {SubjectParameterView(props.styleRule?.SubjectParameter)}
                            </div>
                        </div>
                    </div>
                );
            }
            //date 
            else if (props.styleRule.Type === Constants.propertyType.DateChanged || props.styleRule.Type === Constants.propertyType.DateCreated) {
                return (
                    <div className={props.className + " styleRuleContents p-2 border border-top-1 border-left-1 rounded border-dark text-black"}>

                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0  "}>
                                {"When: [" + TypeToString(props.styleRule.Type) + "]"}
                            </div>
                            <div className={"me-1 mt-1 p-0  "}>
                                {OperatorToString(props.styleRule?.Operator)}
                            </div>
                            <div className={"me-1 mt-1 p-0  "}>
                                {"[" + props.styleRule?.Parameter + "]"}
                            </div>
                        </div>
                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0   "}>
                                {"Set [" + SubjectToString(props.styleRule?.SubjectParameter?.StyleSubject) + "] to: "}
                            </div>
                            <div className={"me-1 mt-1 p-0   "}>
                                {SubjectParameterView(props.styleRule?.SubjectParameter)}
                            </div>
                        </div>

                    </div>
                );
            }
            else if (props.styleRule.Type === Constants.propertyType.Occluded) {
                return (
                    <div className={props.className + " styleRuleContents p-2 border border-top-1 border-left-1 rounded border-dark text-black"}>

                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0  "}>
                                {"When: [" + TypeToString(props.styleRule.Type) + "]"}
                            </div>
                        </div>
                        <div className="styleRuleSubject">
                            <div className={"me-1 mt-1 p-0   "}>
                                {"Set [" + SubjectToString(props.styleRule?.SubjectParameter?.StyleSubject) + "] to: "}
                            </div>
                            <div className={"me-1 mt-1 p-0   "}>
                                {SubjectParameterView(props.styleRule?.SubjectParameter)}
                            </div>
                        </div>

                    </div>
                );
            }
            else {
                return (<></>);
            }

        }
        else {
            return (<></>);
        }


    }



    function ListRowStyleSheet(props) {
        const item = props.data[props.index];
        return (
            <Draggable draggableId={item.id} index={props.index} key={item.id}
            >
                {(provided, snapshot) =>
                (

                    <div
                        // style={getStyle(provided, props.style, snapshot.isDragging)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <StyleRuleItem className={snapshot.isDragging ? "styleRuleItemDragging" : "styleRuleItem"} styleRule={item} />
                    </div>

                )}
            </Draggable>
        );
    }


    // This method is needed for rendering clones of draggables, as they are being dragged about
    const getRenderItem = (items, style) => (provided, snapshot, rubric) => {
        const item = items[rubric.source.index];
        return (
            <>
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <StyleRuleItem className={getListRowClassName(snapshot)} styleRule={item} />
                </div>
            </>
        );
    };

    function copyStyleRule(styleRule) {

        //copy it
        let newStyleRule = JSON.parse(JSON.stringify(styleRule));
        newStyleRule.id = uuid();
        return newStyleRule;
    }

    function reorderStyleRules(startIndex, endIndex) {
        const reordered = Array.from(styleSheetStyleRules);
        const [removed] = reordered.splice(startIndex, 1);
        reordered.splice(endIndex, 0, removed);

        setStyleSheetStyleRules(reordered);
    }

    function moveToStyleSheetStyleRules(projectIndex, styleSheetIndex) {

        //insert project style rule into StyleSheet array
        const styleSheetStyleRulesCopy = Array.from(styleSheetStyleRules);
        styleSheetStyleRulesCopy.splice(styleSheetIndex, 0, copyStyleRule(projectStyleRules[projectIndex]));
        setStyleSheetStyleRules(styleSheetStyleRulesCopy);


        // //remove from project style rules
        // const projectStyleRulesCopy = Array.from(projectStyleRules);
        // projectStyleRulesCopy.splice(projectIndex, 1);
        // setProjectStyleRules(projectStyleRulesCopy);

    }

    function moveToProjectStyleRules(projectIndex, styleSheetIndex) {

        // //insert StyleSheet style rule into project array
        // const projectStyleRulesCopy = Array.from(projectStyleRules);
        // projectStyleRulesCopy.splice(projectIndex, 0, copyStyleRule(styleSheetStyleRules[styleSheetIndex]));
        // setProjectStyleRules(projectStyleRulesCopy);

        //remove from StyleSheet style rules
        const styleSheetStyleRulesCopy = Array.from(styleSheetStyleRules);
        styleSheetStyleRulesCopy.splice(styleSheetIndex, 1);
        setStyleSheetStyleRules(styleSheetStyleRulesCopy);

    }


    function onDragEnd(result) {

        //source - from the StyleSheet
        if (result.source.droppableId === "styleSheetStyleRule") {

            if (!result.destination || result.destination.droppableId === "projectStyleRule") {
                moveToProjectStyleRules(result.destination ? result.destination.index : 0, result.source.index);
                return;
            }
            else if (result.destination.droppableId === "styleSheetStyleRule") {
                if (result.source.index !== result.destination.index) {
                    reorderStyleRules(result.source.index, result.destination.index);
                    return;
                }
            }
        }
        //source - from the project
        else if (result.source.droppableId === "projectStyleRule") {

            if (!result.destination) {
                return;
            }
            else if (result.destination.droppableId === "styleSheetStyleRule") {
                moveToStyleSheetStyleRules(result.source.index, result.destination.index);
                return;
            }
        }

    }
    //just hide the dialog - cancel.
    function onClose() {

        props.setShow(false);
    }

    //save without closing
    function onApply() {

        //copy input style sheet
        let ss = JSON.parse(JSON.stringify(props.annotationStyleSheet));

        ss.Title = title;

        //copy new steps to the original conversion and pass up to the parent
        ss.AnnotationStyleRules = styleSheetStyleRules.map((item, index) => ({ Order: index, AnnotationStyleRule: item }))
        props.onSave(ss, setUiDisabled, false);

    }

    //save and close
    function onSave() {

        //copy input style sheet
        let ss = JSON.parse(JSON.stringify(props.annotationStyleSheet));

        ss.Title = title;

        //copy new steps to the original conversion and pass up to the parent
        ss.AnnotationStyleRules = styleSheetStyleRules.map((item, index) => ({ Order: index, AnnotationStyleRule: item }))
        props.onSave(ss, setUiDisabled, true);

    }

    function getDraggingOverStyleRuleClassName(snapshot) {

        if (snapshot.isDraggingOver && !snapshot.draggingFromThisWith) {
            return " isDraggingOverStyleRule "
        }

        return "";
    }

    //when dragging - show if it is about to be deleted or in the
    //right place to drop - or static
    function getListRowClassName(snapshot) {

        if (snapshot.isDragging) {
            if (!snapshot.draggingOver) {
                if (snapshot.isDropAnimating) {
                    return " hideDropAnimation "
                }
                else {
                    return " draggingItemToDelete ";
                }
            }
            else {
                return " transformStepTypeItemDragging ";
            }
        }
        return " transformStepTypeItem ";

    }


    function StyleSheetStyleRuleList(props) {
        return (
            <Droppable
                renderClone={getRenderItem(props.items, props.className)}
                droppableId="styleSheetStyleRule"
            >
                {(provided, snapshot) => (
                    <ul
                        ref={provided.innerRef}
                        className={"styleRuleList" + getDraggingOverStyleRuleClassName(snapshot)}
                    >
                        {props.items.map((item, index) => {
                            return (
                                <li key={item.id}>
                                    <ListRowStyleSheet className={getListRowClassName(snapshot)} data={props.items} index={index} />
                                </li>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>
        );
    }

    //static row in the list
    function ProjectStyleRuleListRow(props) {
        const item = props.data[props.index];
        return (
            <Draggable draggableId={item.id} index={props.index} key={item.id}
            >
                {(provided, snapshot) =>
                (

                    <div

                        onClick={(event) => {

                            // marking the event as used
                            event.preventDefault();

                            //set item as selected
                            setSelectedStyleRuleId(item.AnnotationStyleRuleId);
                        }}

                        onDoubleClick={(event) => {

                            // marking the event as used
                            event.preventDefault();

                            //set item as selected
                            setSelectedStyleRuleId(item.AnnotationStyleRuleId);

                            if (SelectedStyleRule()) {
                                setShowStyleRuleEditDialog(true);
                            }
                        }}

                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <StyleRuleItem className={snapshot.isDragging ? "styleRuleItemDragging" : item.AnnotationStyleRuleId === selectedStyleRuleId ? "styleRuleItemSelected" : "styleRuleItem"} styleRule={item} />
                    </div>

                )}
            </Draggable>
        );
    }


    //a fixed size list that copies items dragged from it
    function ProjectStyleRuleList(props) {
        return (
            <Droppable
                renderClone={getRenderItem(props.items, props.className)}
                droppableId="projectStyleRule"
                isDropDisabled={true}
            >
                {(provided, snapshot) => (
                    <div
                        className={props.className}
                        ref={provided.innerRef}
                    >
                        {
                            props.items.map((item, index) => {
                                const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
                                return (
                                    <React.Fragment key={item.id}>
                                        {shouldRenderClone ? (
                                            <StyleRuleItem className="react-beatiful-dnd-copy transformStepTypeItem" styleRule={item} />
                                        ) : (
                                            <ProjectStyleRuleListRow data={props.items} index={index} />
                                        )}

                                    </React.Fragment>
                                );
                            })
                        }
                        {provided.placeholder}
                    </div>
                )
                }
            </Droppable>
        );
    }


    function onAdd(newStyleRule, setUiDisabled) {


        toastId.current = ToastLoading("Adding Annotation Style Rule");
        setUiDisabled(true);

        CreateAnnotationStyleRule(newStyleRule)
            .then(res => {
                ToastSuccess("Added Style Rule", toastId.current, 5000);
                setShowStyleRuleAddDialog(false);

                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });
    };


    function onUpdate(updatedStyleRule, setUiDisabled) {
        toastId.current = ToastLoading("Updating Annotation Style Rule");
        setUiDisabled(true);

        UpdateAnnotationStyleRule(updatedStyleRule)
            .then(res => {
                ToastSuccess("Updated  Style Rule", toastId.current, 5000);
                setShowStyleRuleEditDialog(false);

                setUiDisabled(false);

                //update if  in current style sheet
                let rIndex = styleSheetStyleRules.findIndex(item => item.AnnotationStyleRuleId === updatedStyleRule?.AnnotationStyleRuleId)
                if (rIndex >= 0) {
                    const styleSheetStyleRulesCopy = Array.from(styleSheetStyleRules);
                    styleSheetStyleRulesCopy[rIndex] = updatedStyleRule;
                    setStyleSheetStyleRules(styleSheetStyleRulesCopy);
                }


            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });


    };


    async function onDelete() {

        if (selectedStyleRuleId !== "") {

            //const result = await Confirm("Are you sure you want to delete the style rule ?", "Delete Style Rule");
            // const result = await CustomDialog(<CustomDialogContent />, { className: "modal-z-order" });
            // if (result) {
            toastId.current = ToastLoading("Deleting Style Rule");
            DeleteAnnotationStyleRule(selectedStyleRuleId)
                .then(res => {
                    ToastSuccess("Deleted Style Rule", toastId.current, 5000);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                });
            //            }
        }
    }

    function SelectedStyleRule() {

        const ss = props.project.AnnotationStyleRules.find(item => item.AnnotationStyleRuleId === selectedStyleRuleId);
        return ss
    }



    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="false"
            keyboard={false}
            centered
            dialogClassName="edit-style-sheet-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container >
                        <Form.Group className="my-2" controlId="formProjectTitle" as={Row}>
                            <Form.Label column sm={2} >Title:</Form.Label>
                            <Col sm={10}>
                                <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Style Sheet Title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Row>
                            <div className="ps-4 pb-4">
                                Drag new style rules onto the Style Sheet to add them. Drag them back to remove.
                                <br />
                                Drag to re-order; the Rules will be applied in the order shown.
                            </div>
                        </Row>

                        <Row>
                            <Col>
                                <div className="project-graph-title" >Style Sheet:</div>
                                <StyleSheetStyleRuleList items={styleSheetStyleRules} />
                            </Col>
                            <Col>
                                <Row>
                                    <div className="ps-3 pe-4">
                                        <div className="project-graph-title" >Annotation Style Rules:</div>
                                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project.ProjectId, props.project.OrganisationId) ?
                                            <Button style={{ float: "right" }} className="list-top-button" onClick={() => setShowStyleRuleAddDialog(true)}>Add</Button>
                                            : <></>
                                        }
                                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project.ProjectId, props.project.OrganisationId) ?
                                            <Button style={{ float: "right" }} className="list-top-button" disabled={SelectedStyleRule() === undefined} onClick={() => { if (SelectedStyleRule()) setShowStyleRuleEditDialog(true) }}>Edit</Button>
                                            : <></>
                                        }
                                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project.ProjectId, props.project.OrganisationId) ?
                                            <Button style={{ float: "right" }} className="list-top-button" disabled={SelectedStyleRule() === undefined} onClick={() => { setShowConfirmDeleteDialog(true); }}>Delete</Button>
                                            : <></>
                                        }

                                    </div>
                                </Row>
                                <ProjectStyleRuleList items={projectStyleRules} className="styleRuleList" />

                                <EditStyleRule
                                    helpContext={BeaconDialogMap.EditAnnotationStyleRuleDialog} parentHelpContext={props.helpContext}
                                    onSave={onAdd}
                                    isEditing={false}
                                    show={showStyleRuleAddDialog}
                                    setShow={setShowStyleRuleAddDialog}
                                    dialogTitle="Add Style Rule"
                                    project={props.project}
                                    styleRule={{
                                        Type: 0,
                                        StyleSubject: null,
                                        Operator: null,
                                        ProjectId: props.project.ProjectId
                                    }}
                                />
                                {SelectedStyleRule() &&
                                    <EditStyleRule
                                        helpContext={BeaconDialogMap.EditAnnotationStyleRuleDialog} parentHelpContext={props.helpContext}
                                        onSave={onUpdate}
                                        isEditing={true}
                                        show={showStyleRuleEditDialog}
                                        setShow={setShowStyleRuleEditDialog}
                                        dialogTitle="Edit Style Rule"
                                        project={props.project}
                                        styleRule={SelectedStyleRule()}
                                    />
                                }
                                <ConfirmModal
                                    helpContext={BeaconDialogMap.DeleteAnnotationStyleRuleDialog} parentHelpContext={props.helpContext}
                                    show={showConfirmDeleteDialog}
                                    setShow={setShowConfirmDeleteDialog}
                                    onOK={onDelete}
                                    okText="Delete"
                                    dialogBody="Are you sure you want to delete?"
                                    dialogTitle="Delete Style Rule"
                                />

                            </Col>
                        </Row>
                    </Container>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onApply}>Apply</Button>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>Save</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>


        </Modal>

    );
}

export default EditStyleSheet;
