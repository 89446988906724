import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { fullUserTitle, fullGroupTitle } from '../App/Utils'
import { availableModelPermissionOptions, modelPermissionsToOptions, optionsToModelPermissionFlag } from '../App/Permissions'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { SystemPermissionFlags } from '../App/Permissions'
import { filterPermissionsOptions, UserStatus } from '../App/Permissions'
import { fullUsersName } from '../App/Utils'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('EditModelPermissions');

function UsersToOptions(org, existingUserIds, currentUser, orgs, currentOrgId) {

    let options;
    const currentOrg = orgs.find(o => o.OrganisationId === currentOrgId);

    //if root - use all users in current org
    if ((currentUser?.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        options = currentOrg?.Users?.filter(u => u.Status === UserStatus.Active)?.map(u => ({ value: u.UserId, label: fullUsersName(u, null, false) }));

        //add any shared-in users
        for (let index = 0; index < currentOrg?.UsersSharedIn.length; index++) {
            const userShare = currentOrg.UsersSharedIn[index];
            options.push({ value: userShare.UserId, label: userShare.UserName + (" (" + userShare.OrgSharedFromTitle + ")") });
        }
    }
    else {
        //otherwise use users in the current user's org

        options = org?.Users?.filter(u => u.Status === UserStatus.Active)?.map(u => ({ value: u.UserId, label: fullUsersName(u, null, false) }));

        //add any shared-in users
        for (let index = 0; index < org?.UsersSharedIn.length; index++) {
            const userShare = org.UsersSharedIn[index];
            options.push({ value: userShare.UserId, label: userShare.UserName + (" (" + userShare.OrgSharedFromTitle + ")") });
        }
    }


    return options?.filter(option => {
        return !existingUserIds?.includes(option.value);
    });
}

function GroupsToOptions(org, existingGroupIds, currentUser, orgs, currentOrgId) {
    let options;
    const currentOrg = orgs.find(o => o.OrganisationId === currentOrgId);

    //if root - use all users in current org
    if ((currentUser?.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        options = currentOrg?.Groups.map(u => ({ value: u.GroupId, label: u.Title }));

        //add any shared-in groups
        for (let index = 0; index < currentOrg?.GroupsSharedIn?.length; index++) {
            const groupShare = currentOrg.GroupsSharedIn[index];
            options.push({ value: groupShare.GroupId, label: groupShare.GroupTitle + (" (" + groupShare.OrgSharedFromTitle + ")") });
        }
    }
    else {
        //otherwise use users in the current user's org
        options = org?.Groups.map(u => ({ value: u.GroupId, label: u.Title }));

        //add any shared-in groups
        for (let index = 0; index < org?.GroupsSharedIn.length; index++) {
            const groupShare = org.GroupsSharedIn[index];
            options.push({ value: groupShare.GroupId, label: groupShare.GroupTitle + (" (" + groupShare.OrgSharedFromTitle + ")") });
        }
    }
    return options?.filter(option => {
        return !existingGroupIds?.includes(option.value);
    });
}

//so we can have a fixed options item
const multiSelectStyles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};




export function EditUserPermissions(props) {

    const { userOrg, orgs, userPermission, showDialog,
        setShowDialog, onSavePermission, isAdding,
        existingUserIds, showpropagateToModel, showpropagateToAnSet, showUploadPermission, helpContext, parentHelpContext } = props;

    logger.debug("EditUserPermissions", props);

    const orgsInfo = useSelector(selectOrganisationsInfo);
    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [selectedUserOption, setSelectedUserOption] = useState(null);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [permissionsValid, setPermissionsValid] = useState(true);
    const [userValid, setUserValid] = useState(true);
    const [propagatePermissionsToModel, setpropagatePermissionsToModel] = useState(false);
    const [propagatePermissionsToAnSet, setpropagatePermissionsToAnSet] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    function setPermissionOptionsIntercept(value) {
        setPermissionOptions(filterPermissionsOptions(value));
    }

    React.useEffect(() => {
        if (showDialog) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, showDialog])


    React.useEffect(() => {
        if (showDialog) {
            setSelectedUserOption(userPermission?.UserId ? { value: userPermission?.UserId, label: fullUserTitle(userPermission?.UserId, orgs, userOrg) } : null);
            setPermissionOptions(filterPermissionsOptions(modelPermissionsToOptions(userPermission?.Permissions, showUploadPermission)));
        }
    }, [userOrg, userPermission, showDialog, showUploadPermission, orgs])

    React.useEffect(() => {
        if (!propagatePermissionsToModel && propagatePermissionsToAnSet && showpropagateToModel) {
            setpropagatePermissionsToAnSet(false);
        }
    }, [propagatePermissionsToModel, propagatePermissionsToAnSet, showpropagateToModel])

    function onSave(event) {
        event.preventDefault();
        setUserValid(selectedUserOption?.value ? true : false);
        setPermissionsValid(optionsToModelPermissionFlag(permissionOptions) ? true : false);

        if (selectedUserOption?.value && optionsToModelPermissionFlag(permissionOptions)) {

            let newPermission = (JSON.parse(JSON.stringify(userPermission)));

            newPermission.Permissions = optionsToModelPermissionFlag(permissionOptions);
            newPermission.UserId = selectedUserOption?.value;

            onSavePermission(newPermission, propagatePermissionsToModel, propagatePermissionsToAnSet, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShowDialog(false);
    }

    return (
        <Modal
            show={showDialog}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>User Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupUser" as={Row}>
                            <Form.Label column sm={12} >User:</Form.Label>
                            <Col sm={12}>
                                {isAdding ?
                                    <>
                                        <Select
                                            value={selectedUserOption}
                                            onChange={setSelectedUserOption}
                                            closeMenuOnSelect={true}
                                            name="user"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select a User..."
                                            options={UsersToOptions(userOrg, existingUserIds, orgsInfo.currentUser, orgs, orgsInfo.currentOrgId)} />
                                        {(!userValid && validated) &&
                                            <Form.Text className="text-danger">
                                                Please select a User.
                                            </Form.Text>
                                        }
                                    </>
                                    :
                                    <Form.Label column >{selectedUserOption?.label}</Form.Label>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formModelPermissions" as={Row}>
                            <Form.Label column sm={12} >Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    styles={multiSelectStyles}
                                    value={permissionOptions}
                                    onChange={setPermissionOptionsIntercept}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableModelPermissionOptions(showUploadPermission)} />
                                {(!permissionsValid && validated) &&
                                    <Form.Text className="text-danger">
                                        Please select permissions.
                                    </Form.Text>
                                }
                            </Col>
                        </Form.Group>
                        {showpropagateToModel &&
                            <Form.Group controlId="formPositiveOnly" as={Row}>
                                {selectedUserOption?.label ?
                                    <Form.Label column sm={12} >{"Also replace permissions for [" + selectedUserOption?.label + "] in all models in the project?"}</Form.Label>
                                    :
                                    <Form.Label column sm={12} >{"Also replace permissions in all models in the project?"}</Form.Label>
                                }
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="isPositive"
                                        label=""
                                        checked={propagatePermissionsToModel}

                                        onChange={(e) => setpropagatePermissionsToModel(!propagatePermissionsToModel)} />
                                </Col>
                            </Form.Group>}

                        {showpropagateToAnSet &&
                            <Form.Group controlId="formPositiveOnly" as={Row}>
                                {selectedUserOption?.label ?
                                    <Form.Label column sm={12} >{"Also replace permissions for [" + selectedUserOption?.label + "] in all annotation sets in the model?"}</Form.Label>
                                    :
                                    <Form.Label column sm={12} >{"Also replace permissions in all annotation sets in the model?"}</Form.Label>
                                }
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="isPositive"
                                        label=""
                                        checked={propagatePermissionsToAnSet}

                                        onChange={(e) => setpropagatePermissionsToAnSet(!propagatePermissionsToAnSet)} />
                                </Col>
                            </Form.Group>}
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}


export function EditGroupPermissions(props) {

    const { currentOrg, groupPermission, showDialog,
        setShowDialog, onSavePermission, isAdding,
        existingGroupIds, showpropagateToModel, showpropagateToAnSet, showUploadPermission, orgs, helpContext, parentHelpContext } = props;

    logger.debug("EditGroupPermissions", props);

    const form = useRef(null);
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const [validated, setValidated] = useState(false);
    const [selectedGroupOption, setSelectedGroupOption] = useState(null);
    const [selectedPermissionOptions, setSelectedPermissionOptions] = useState([]);
    const [permissionsValid, setPermissionsValid] = useState(true);
    const [groupValid, setGroupValid] = useState(true);
    const [propagatePermissionsToModel, setpropagatePermissionsToModel] = useState(false);
    const [propagatePermissionsToAnSet, setpropagatePermissionsToAnSet] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    function setSelectedPermissionOptionsIntercept(value) {
        setSelectedPermissionOptions(filterPermissionsOptions(value));
    }

    React.useEffect(() => {
        if (showDialog) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, showDialog])


    React.useEffect(() => {
        if (showDialog) {
            setSelectedGroupOption(groupPermission?.GroupId ? { value: groupPermission?.GroupId, label: fullGroupTitle(groupPermission?.GroupId, orgs, currentOrg) } : null);
            setSelectedPermissionOptions(filterPermissionsOptions(modelPermissionsToOptions(groupPermission?.Permissions, showUploadPermission)));
        }
    }, [currentOrg, groupPermission, orgs, showDialog, showUploadPermission])

    React.useEffect(() => {
        if (!propagatePermissionsToModel && propagatePermissionsToAnSet && showpropagateToModel) {
            setpropagatePermissionsToAnSet(false);
        }
    }, [propagatePermissionsToModel, propagatePermissionsToAnSet, showpropagateToModel])


    function onSave(event) {
        event.preventDefault();
        setGroupValid(selectedGroupOption?.value ? true : false);
        setPermissionsValid(optionsToModelPermissionFlag(selectedPermissionOptions) ? true : false);

        if (selectedGroupOption?.value && optionsToModelPermissionFlag(selectedPermissionOptions)) {
            let newPermission = (JSON.parse(JSON.stringify(groupPermission)));
            newPermission.GroupId = selectedGroupOption.value;
            newPermission.Permissions = optionsToModelPermissionFlag(selectedPermissionOptions);
            onSavePermission(newPermission, propagatePermissionsToModel, propagatePermissionsToAnSet, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShowDialog(false);
    }



    return (
        <Modal
            show={showDialog}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Group Permissions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupGroup" as={Row}>
                            <Form.Label column sm={12} >Group:</Form.Label>
                            <Col sm={12}>
                                {isAdding ?
                                    <>

                                        <Select
                                            value={selectedGroupOption}
                                            onChange={setSelectedGroupOption}
                                            closeMenuOnSelect={true}
                                            name="group"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select a Group..."
                                            options={GroupsToOptions(currentOrg, existingGroupIds, orgsInfo.currentUser, orgsInfo.organisations, orgsInfo.currentOrgId)} />
                                        {(!groupValid && validated) &&
                                            <Form.Text className="text-danger">
                                                Please select a Group.
                                            </Form.Text>
                                        }
                                    </>
                                    :
                                    <Form.Label column >{selectedGroupOption?.label}</Form.Label>
                                }
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formModelPermissions" as={Row}>
                            <Form.Label column sm={12} >Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    styles={multiSelectStyles}
                                    value={selectedPermissionOptions}
                                    onChange={setSelectedPermissionOptionsIntercept}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableModelPermissionOptions(showUploadPermission)} />
                                {(!permissionsValid && validated) &&
                                    <Form.Text className="text-danger">
                                        Please select permissions.
                                    </Form.Text>
                                }
                            </Col>
                        </Form.Group>
                        {showpropagateToModel &&
                            <Form.Group controlId="formPositiveOnly" as={Row}>
                                {selectedGroupOption?.label ?
                                    <Form.Label column sm={12} >{"Also replace permissions for [" + selectedGroupOption?.label + "] in all models in project?"}</Form.Label>
                                    :
                                    <Form.Label column sm={12} >{"Also replace permissions in all models in project?:"}</Form.Label>
                                }
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="isPositive"
                                        label=""
                                        checked={propagatePermissionsToModel}

                                        onChange={(e) => setpropagatePermissionsToModel(!propagatePermissionsToModel)} />
                                </Col>
                            </Form.Group>}
                        {showpropagateToAnSet &&
                            <Form.Group controlId="formPositiveOnly" as={Row}>
                                {selectedGroupOption?.label ?
                                    <Form.Label column sm={12} >{"Also replace permissions for [" + selectedGroupOption?.label + "] in all annotation sets in model?"}</Form.Label>
                                    :
                                    <Form.Label column sm={12} >{"Also replace permissions in all annotation sets in model?:"}</Form.Label>
                                }
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="isPositive"
                                        label=""
                                        checked={propagatePermissionsToAnSet}

                                        onChange={(e) => setpropagatePermissionsToAnSet(!propagatePermissionsToAnSet)} />
                                </Col>
                            </Form.Group>}
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}






















export function ExternalUserPermissions(props) {

    const { showDialog, setShowDialog, onSavePermission, showpropagate, showUploadPermission, subItemTitle, helpContext, parentHelpContext } = props;

    const form = useRef(null);

    logger.debug("ExternalUserPermissions props", props);

    const [validated, setValidated] = useState(false);
    const [permissionOptions, setPermissionOptions] = useState([]);
    const [permissionsValid, setPermissionsValid] = useState(true);
    const [propagatePermissions, setpropagatePermissions] = useState(false);
    const [externalUserName, setExternalUserName] = useState("");

    React.useEffect(() => {
        if (showDialog) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, showDialog])


    function setPermissionOptionsIntercept(value) {
        setPermissionOptions(filterPermissionsOptions(value));
    }

    function onSave(event) {
        event.preventDefault();
        setPermissionsValid(optionsToModelPermissionFlag(permissionOptions) ? true : false);

        if (form.current.checkValidity() && optionsToModelPermissionFlag(permissionOptions)) {

            var newPermission = {
                Permissions: optionsToModelPermissionFlag(permissionOptions),
                UserName: externalUserName,
                Propagate: propagatePermissions
            };

            onSavePermission(newPermission);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShowDialog(false);
    }

    return (
        <Modal
            show={showDialog}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <div class="modal-header" >
                <h4 class="modal-title">Share with an external User
                    <br />
                    <div className="subtitle">Share this item with a user in another org.<br />
                        The user will get an email notifying them of the share. <br />
                        They will then be able to access this item, depending on the permissions you have given them.<br />
                        Once this is done the user will appear in the normal user permissions lists with their org name in brackets.<br />
                        The user can be managed in the org settings - 'External Users' list.
                    </div>
                </h4>
            </div>
            <Modal.Body>
                <Container>
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={12} >User from another organisation:</Form.Label>
                            <Col sm={12}>
                                <Form.Control type="email" required value={externalUserName} onChange={(e) => setExternalUserName(e.target.value)} placeholder='Email address...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formModelPermissions" as={Row}>
                            <Form.Label column sm={12} >Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    styles={multiSelectStyles}
                                    value={permissionOptions}
                                    onChange={setPermissionOptionsIntercept}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableModelPermissionOptions(showUploadPermission)} />
                                {(!permissionsValid && validated) &&
                                    <Form.Text className="text-danger">
                                        Please select permissions.
                                    </Form.Text>
                                }
                            </Col>
                        </Form.Group>
                        {showpropagate &&
                            <Form.Group controlId="idReplaceInChildren" as={Row}>
                                <Form.Label column sm={12} >{"Also add permissions for all " + subItemTitle + "?:"}</Form.Label>
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="idReplace"
                                        label=""
                                        checked={propagatePermissions}

                                        onChange={(e) => setpropagatePermissions(!propagatePermissions)} />
                                </Col>
                            </Form.Group>}

                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onSave}>OK</Button>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}
