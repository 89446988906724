import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');


export async function SendEmailToUser(userId, subject, bodyHtml) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            userid: userId,
            subject: subject,
            bodyhtml: bodyHtml,
        }

    }

    let response = await API.post('Reveal4DApi', '/SendEmailToUser', options);
    logger.debug("SendEmailToUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}