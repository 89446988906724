import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');


export async function CreateOrganisation(organisation) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: organisation
    }

    let response = await API.post('Reveal4DApi', '/CreateOrganisation', options);
    logger.debug("CreateOrganisation , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function StartCopyOrganisationStateMachine(organisationId, title, userName, attributes, suppressEmail) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            organisationid: organisationId,
            organisationtitle: title,
            username: userName,
            userattributes: attributes,
            suppressemail: suppressEmail
        }
    }

    let response = await API.post('Reveal4DApi', '/StartCopyOrganisationStateMachine', options);
    logger.debug("StartCopyOrganisationStateMachine , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function GetOrganisations() {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    const unityVersion = "[\"all\"]";

    let response = await API.get('Reveal4DApi', '/GetOrganisations?unityversion=' + unityVersion, options);

    logger.debug("GetOrganisations", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}


export async function UpdateOrganisation(organisation) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: organisation
    }

    let response = await API.put('Reveal4DApi', '/UpdateOrganisation', options);
    logger.debug("UpdateOrganisation , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return organisation;
    }

}


export async function DeleteOrganisation(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            organisationid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteOrganisation', options);
    logger.debug("DeleteOrganisation , response: ", response, options.body);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}
