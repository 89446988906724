import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');



export async function CreateAnnotationStyleRule(styleRule) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            stylerule: styleRule
        }
    }

    let response = await API.post('Reveal4DApi', '/CreateAnnotationStyleRule', options);
    logger.debug("CreateAnnotationStyleRule , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function UpdateAnnotationStyleRule(styleRule) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            stylerule: styleRule
        }
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationStyleRule', options);
    logger.debug("CreateAnnotationStyleRule , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function DeleteAnnotationStyleRule(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            styleruleid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotationStyleRule', options);
    logger.debug("CreateAnnotationStyleRule , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function CreateListPropertyTemplate(template) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplate: template
        }
    }

    let response = await API.post('Reveal4DApi', '/CreateListPropertyTemplate', options);
    logger.debug("CreateListPropertyTemplate , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}
export async function CreateValuePropertyTemplate(template) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplate: template
        }
    }

    let response = await API.post('Reveal4DApi', '/CreateValuePropertyTemplate', options);
    logger.debug("CreateValuePropertyTemplate , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function DeleteValuePropertyTemplate(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplateid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteValuePropertyTemplate', options);
    logger.debug("DeleteValuePropertyTemplate , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}

export async function DeleteListPropertyTemplate(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplateid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteListPropertyTemplate', options);
    logger.debug("DeleteListPropertyTemplate , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return id;
    }

}


export async function UpdateListPropertyTemplate(template) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplate: template
        }
    }

    let response = await API.put('Reveal4DApi', '/UpdateListPropertyTemplate', options);
    logger.debug("UpdateListPropertyTemplate , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return { template };
    }

}
export async function UpdateValuePropertyTemplate(template) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            propertytemplate: template
        }
    }

    let response = await API.put('Reveal4DApi', '/UpdateValuePropertyTemplate', options);
    logger.debug("UpdateValuePropertyTemplate , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return { template };
    }

}


export async function CreateAnnotationStyleSheet(projectId, styleSheet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            stylesheet: styleSheet,
            projectid: projectId
        }
    }

    let response = await API.post('Reveal4DApi', '/CreateAnnotationStyleSheet', options);
    logger.debug("CreateAnnotationStyleSheet, response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}


export async function UpdateAnnotationStyleSheet(styleSheet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            stylesheet: styleSheet,
        }
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationStyleSheet', options);
    logger.debug("UpdateAnnotationStyleSheet, response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function DeleteAnnotationStyleSheet(styleSheet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            stylesheet: styleSheet,
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotationStyleSheet', options);
    logger.debug("DeleteAnnotationStyleSheet, response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function UpdateAnnotationProperties(annotationId, annotationProperties) {

    logger.debug("UpdateAnnotationProperties, annotationProperties: ", annotationProperties);
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            annotationproperties: annotationProperties,
            annotationid: annotationId
        }
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationProperties', options);
    logger.debug("UpdateAnnotationProperties, response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return annotationProperties;
    }

}
