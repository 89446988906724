import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'react-bootstrap/Button'
import { SystemPermissionsDisplay } from '../Components/SystemPermissionsDisplay';

import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { DeleteGroup, UpdateGroup, CreateGroup, } from '../Api/GroupApi'
import { FixedSizeList as List } from 'react-window';
import { Confirm } from 'react-st-modal';
import AutoSizer from 'react-virtualized-auto-sizer';
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { CanEditGroup, CanDeleteGroup } from "../App/UserPermissions";
import { GenericPermissionsList } from '../Components/PermissionList';
import { fullModelTitle, fullProjectTitle } from '../App/Utils'
import { EditGroup } from './EditGroup'
import { GroupTypes } from '../App/Permissions';
import { BsLockFill } from "react-icons/bs";
import { fullUsersName } from '../App/Utils'
import { UserStatus } from '../App/Permissions'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import './style.css'
import '../css/page.css'
import '../css/lists.css'


// import { Logger } from 'aws-amplify';
// const logger = new Logger('GroupsPage');

function filterGroupNames(filter, groupSet) {

    let groups = [];

    if (filter === "") {
        groups = groupSet;
    }
    else {
        groupSet.forEach(g => {
            const reg = new RegExp(filter, 'gi');
            if (g.Title.match(reg)) {
                groups.push(g);
            }
        })
    }

    return groups;
}


function GroupsPage(props) {

    const { setMainPageHelpContext } = props;


    const groupsList = useRef();
    const usersList = useRef();
    const toastId = useRef();
    const dispatch = useDispatch();
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const [selectedGroupId, setSelectedGroupId] = useState(localStorage.getItem("groupsPageSelectedGroupId"));

    const [showFilter, setShowFilter] = useState(false);
    const [groupNameFilter, setGroupNameFilter] = useState("");
    const [filteredGroups, setFilteredGroups] = useState([]);

    const [showGroupEdit, setShowGroupEdit] = useState(false);
    const [showGroupCreate, setShowGroupCreate] = useState(false);

    const helpContext = BeaconDialogMap.OrganisationGroupsPage;

    React.useEffect(() => {
        setMainPageHelpContext(helpContext);

        BeaconSetSuggestions(helpContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const currentOrg = orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId);

    //save to local storage so that controls can be repopulated if page is reloaded.
    React.useEffect(() => {
        if (selectedGroupId) {
            localStorage.setItem("groupsPageSelectedGroupId", selectedGroupId);
        }
    }, [selectedGroupId])


    React.useEffect(() => {

        if (currentOrg?.Groups.length) {

            //load selected group from storage
            const groupId = localStorage.getItem("groupsPageSelectedGroupId");

            //if in current list, select it
            if (currentOrg?.Groups.find(g => g.Id === groupId) !== null) {
                setSelectedGroupId(groupId);
            }
            else {
                setSelectedGroupId(currentOrg?.Groups[0]?.Id);
            }
        }
        filterGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentOrg?.Groups])

    //make sure selected group is visible
    React.useEffect(() => {

        //if list up and running
        if (groupsList.current && selectedGroupId) {
            scrollIntoView();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, groupsList.current, filteredGroups?.length])

    function scrollIntoView() {
        for (let index = 0; index < filteredGroups?.length; index++) {
            const element = filteredGroups[index];
            if (element.Id === selectedGroupId) {
                groupsList.current.scrollToItem(index, "center");
                break;
            }
        }
    }


    React.useEffect(() => {

        filterGroups();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, groupNameFilter])

    function filterGroups() {
        let groups = filterGroupNames(groupNameFilter, currentOrg?.Groups);
        setFilteredGroups(groups);
    }

    const selectedGroup = currentOrg?.Groups.find(item => item.GroupId === selectedGroupId);


    async function onEditGroup(group, setUiDisabled) {

        if (selectedGroupId) {

            toastId.current = ToastLoading("Updating Group");
            setUiDisabled(true);

            UpdateGroup(group)
                .then(res => {
                    ToastSuccess("Updated [" + group.Title + "]", toastId.current, 5000);
                    setShowGroupEdit(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });

        }
    }

    async function onCreateGroup(group, setUiDisabled) {

        group.OrganisationId = currentOrg.OrganisationId;

        setUiDisabled(true);

        CreateGroup(group)
            .then(res => {
                setSelectedGroupId(res);
                ToastSuccess("Added [" + group.Title + "]", toastId.current, 5000);
                setShowGroupCreate(false);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });
    }

    async function onDeleteGroup() {
        if (selectedGroupId) {
            const groupToDelete = currentOrg?.Groups.find(item => item.GroupId === selectedGroupId);

            BeaconSetSuggestions(BeaconDialogMap.DeleteGroupDialog);
            const result = await Confirm("Are you sure you want to delete [" + groupToDelete.Title + "] ?", "Delete Group");
            if (result) {
                toastId.current = ToastLoading("Deleting Group");

                DeleteGroup(selectedGroupId)
                    .then(unwrapResult)
                    .then(res => {
                        ToastSuccess("Deleted [" + groupToDelete.Title + "]", toastId.current, 5000);
                    })
                    .catch(err => {
                        ToastError(err.message, err.cause, toastId.current);
                    });

            }
            BeaconSetSuggestions(helpContext);
        }
    }

    const ListRowGroup = ({ index, style }) => (
        <div role="button" style={{
            ...style,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden"
        }}
            className={selectedGroupId === filteredGroups[index].GroupId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                //set group and prompt re-draw
                setSelectedGroupId(filteredGroups[index].GroupId);
            }}
            onDoubleClick={() => {
                //set group and prompt re-draw
                setSelectedGroupId(filteredGroups[index].GroupId);
                onEditGroup();
            }} >
            <>
                <div className="list-item-title" >{filteredGroups[index].Title}{(filteredGroups[index]?.Type === GroupTypes.Everyone) && <BsLockFill className="ms-1 mb-2" />}</div>
                {(filteredGroups[index]?.Permissions !== 0) &&
                    <div className="list-item-split-row">
                        <div >System Permissions</div>
                        <SystemPermissionsDisplay permissions={filteredGroups[index]?.Permissions} isSelected={selectedGroupId === filteredGroups[index].GroupId} />
                    </div>
                }

            </>
        </div>
    );


    const ListRowUser = ({ index, style }) => (
        <div style={{
            ...style,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden"
        }}
            className="list-item list-item-not-selected">
            <div className="list-item-title" >{fullUsersName(selectedGroup?.Users?.filter(u => u.Status === UserStatus.Active)[index], null, false)}</div>
        </div>
    );

    function toggleFilter() {
        if (showFilter) {
            setGroupNameFilter("");
        }
        setShowFilter(!showFilter);
    }

    return (
        <div className="page-bottom-panel ">
            <div className="page-left-panel">
                <div className="list-title-and-list list-full-height list-wide">

                    <div className="list-title-and-buttons">
                        <div className="list-title" >Groups:</div>

                        <div className="list-buttons">
                            <Button className="list-top-button" onClick={toggleFilter}>{showFilter ? <RiFilterOffLine /> : <RiFilterLine />}</Button>
                            {
                                CanEditGroup(orgsInfo.currentUser, currentOrg?.OrganisationId) &&
                                <>
                                    <Button disabled={!selectedGroup} className="list-top-button" onClick={() => setShowGroupEdit(true)}>Edit</Button>
                                    <Button className="list-top-button" onClick={() => setShowGroupCreate(true)}>Add</Button>
                                    <Button disabled={!selectedGroup || !CanDeleteGroup(orgsInfo.currentUser, selectedGroup)}
                                        className="list-top-button" onClick={onDeleteGroup}>
                                        Delete
                                    </Button>
                                </>
                            }
                        </div>
                    </div>

                    {showFilter &&
                        <div className="list-filter">
                            Group Name:
                            <input autoFocus type="text" onChange={(event) => { setGroupNameFilter(event.target.value) }} />
                        </div>
                    }

                    <div className="list-container">
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={groupsList}
                                    height={height}
                                    itemCount={filteredGroups?.length}
                                    itemSize={75}
                                    width={390}
                                    className="list-background-style"
                                >
                                    {ListRowGroup}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>

            <div className="page-content-panel">
                {/* Users Column */}
                <div className="list-title-and-list list-short list-narrow">

                    <div className="list-title-and-buttons">
                        <div className="list-title" >Users:</div>
                    </div>
                    <div className="list-container">
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={usersList}
                                    height={height}
                                    itemCount={selectedGroup?.Users?.filter(u => u.Status === UserStatus.Active).length}
                                    itemSize={44}
                                    width={340}
                                    className="border border-dark rounded"
                                    style={{ backgroundColor: "#EEEEEE" }}
                                >
                                    {ListRowUser}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                </div>
                <EditGroup
                    helpContext={BeaconDialogMap.EditGroupDialog}
                    parentHelpContext={helpContext}
                    currentUser={orgsInfo.currentUser}
                    group={selectedGroup}
                    show={showGroupEdit}
                    setShow={setShowGroupEdit}
                    save={onEditGroup}

                />
                <EditGroup
                    helpContext={BeaconDialogMap.EditGroupDialog}
                    parentHelpContext={helpContext}
                    currentUser={orgsInfo.currentUser}
                    group={{ Title: "", Permissions: 0 }}
                    show={showGroupCreate}
                    setShow={setShowGroupCreate}
                    save={onCreateGroup}

                />

                <GenericPermissionsList showUploadPermission={false} title={"Model Permissions"} permissionArray={selectedGroup?.ModelPermissions.map((p, index) => ({ "title": fullModelTitle(p.ModelId, orgsInfo.organisations, selectedGroup?.OrganisationId), "Permissions": p.Permissions, "Id": index }))} />
                <GenericPermissionsList showUploadPermission={true} title={"Project Permissions"} permissionArray={selectedGroup?.ProjectPermissions.map((p, index) => ({ "title": fullProjectTitle(p.ProjectId, orgsInfo.organisations, selectedGroup?.OrganisationId), "Permissions": p.Permissions, "Id": index }))} />
            </div>
        </div>
    );

}



export default GroupsPage;