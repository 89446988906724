import React, { useState } from "react";
import { unwrapResult } from '@reduxjs/toolkit';
import { CrsDialog } from './CrsDialog';
import { CrsTransformDialog } from './CrsTransformDialog';
import ReactFlow, {
    Controls,
    Background,
    getConnectedEdges,
    getOutgoers,
    getIncomers,
    addEdge,
    useNodesState,
    useEdgesState
} from 'react-flow-renderer';
import dagre from 'dagre';
import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';
import "../css/crs.css";
import '../css/annotation-table.css'

import { Confirm, Prompt } from 'react-st-modal';

import { ToastClose, ToastError, ToastLoading } from '../Components/ToastDisplay';
import {
    DeleteCrs, CreateCrs,
    UpdateCrs, CreateCrsTransform,
    UpdateCrsTransform, DeleteCrsTransform,
    ChangeCrsLock, ChangeCrsTransformLock,
} from '../Api/CrsApi'
import Button from 'react-bootstrap/Button';
import { CanUpdateCrs, CanChangeCrsLock } from '../App/UserPermissions'
import { BeaconSetSuggestions, BeaconDialogMap } from '../Components/BeaconDialogMap'


import { Logger } from 'aws-amplify';
const logger = new Logger('CRSView');


const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 150;
const nodeHeight = 30;


function getLayoutStore() {

    const layoutStoreJson = localStorage.getItem("crsLayout");
    let layoutStore = null;

    //has the layout store been setup?
    try {
        layoutStore = JSON.parse(layoutStoreJson);
    }
    catch (e) {
        localStorage.setItem("crsLayout", "");
    }

    if (!layoutStore) {
        layoutStore = {};
    }

    return layoutStore;
}


const getPositionedNodes = (projectId, nodes, edges) => {

    const isHorizontal = true; //false for vertical

    let layoutStore = getLayoutStore();

    //is there an entry for this project
    if (layoutStore[projectId]) {

        let unknownPos = -10;

        //retrieve the position of each element
        let mappedNodes = nodes.map((node) => {

            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';

            //found a position for this node
            let elPos = layoutStore[projectId][node.id];
            if (!elPos) {

                let xmin = 1000;
                let ymin = 1000;

                for (var element in layoutStore[projectId]) {
                    const position = layoutStore[projectId][element];

                    xmin = Math.min(xmin, position.x);
                    ymin = Math.min(ymin, position.y);

                };


                //node position not stored - stack it at the top
                node.position = {
                    x: xmin + unknownPos,
                    y: ymin + unknownPos,
                };

                layoutStore[projectId][node.id] = node.position;
                unknownPos -= 20;
            }
            else {

                node.position = {
                    x: layoutStore[projectId][node.id].x,
                    y: layoutStore[projectId][node.id].y,
                };
            }

            return node;
        });

        //save any positioned nodes
        localStorage.setItem("crsLayout", JSON.stringify(layoutStore));

        return mappedNodes;

    }
    else {

        //new survewy has been selected but not the api call to get a project hasbn't
        //returned, so just quit so an empty layoutstore element isn't created.
        if (nodes.length === 0) {
            return [];
        }

        layoutStore[projectId] = {};

        dagreGraph.setGraph({ rankdir: "LR" });//'TB' for vertical

        nodes.forEach((node) => {
            dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
        });

        edges.forEach((edge) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        let mappedNodes = nodes.map((node) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';

            // unfortunately we need this little hack to pass a slighltiy different position
            // to notify react flow about the change. More over we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
                y: nodeWithPosition.y - nodeHeight / 2,
            };

            layoutStore[projectId][node.id] = node.position;

            return node;
        });

        localStorage.setItem("crsLayout", JSON.stringify(layoutStore));

        return mappedNodes;
    }

};

function onNodeDragStop(event, node, evntNodes) {

    logger.debug("onNodeDragStop", event, node, evntNodes);

    //save the node position
    let layoutStore = getLayoutStore();
    layoutStore[node.data.projectId][node.id] = node.position;

    localStorage.setItem("crsLayout", JSON.stringify(layoutStore));

}

const edgeTypes = {
    custom: CustomEdge,
};

const nodeTypes = {
    customNode: CustomNode,
};

//=============================================================================================================

export function CrsView(props) {

    logger.debug("props", props);

    const { selectedProjectId, currentOrg, orgsInfo, helpContext } = props;

    const selectedProject = currentOrg.Projects.find(item => item.ProjectId === selectedProjectId);


    const addCrsTransformToastId = 1;
    const deleteCrsTransformToastId = 2;
    const deleteCrsToastId = 3;
    const lockCrsTransformToastId = 4;
    const lockCrsToastId = 5;
    const addCrsToastId = 6;
    const updateCrsToastId = 7;
    const updateCrsTransformToastId = 8;

    const [isBusy, setIsBusy] = useState(false);
    const canEdit = !isBusy;

    const [showCrsAddDialog, setShowCrsAddDialog] = useState(false);
    const [showCrsEditDialog, setShowCrsEditDialog] = useState(false);
    const [showCrsTransformEditDialog, setShowCrsTransformEditDialog] = useState(false);
    const [currentCrs, setCurrentCrs] = useState(null);
    const [currentCrsTransform, setCurrentCrsTransform] = useState(null);
    const [project, setProject] = useState(selectedProject);

    const [frontCrs, setFrontCrs] = useState(null);

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);


    React.useEffect(() => {

        setProject(currentOrg.Projects.find(item => item.ProjectId === selectedProjectId));
    }, [selectedProjectId, currentOrg?.Projects])

    //brings the crs node to the front, with all edges too
    React.useEffect(() => {

        if (frontCrs) {


            //find node
            const n = nodes.find((e) => Number(e.id) === frontCrs.CrsId);
            if (n) {
                bringNodeToFront(n, nodes, edges);
            }
            //find edge
            const e = edges.find((e) => Number(e.id) === frontCrs.CrsId);
            if (e) {
                bringEdgeToFront(e, edges);
            }

            setFrontCrs(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frontCrs])



    //if a crs is native to a model - it should take the title from the model
    function getCrsTitle(crs) {

        if (crs?.IsNative) {
            //find name of model this native crs comes from
            for (let index = 0; index < selectedProject?.Models?.length; index++) {
                const model = selectedProject?.Models[index];
                if (model?.NativeCrsId === crs?.CrsId) {
                    return "[Native CRS] " + model?.Title;
                }
            }
            return "[Native] ";
        }
        else {
            return crs?.Title;
        }
    }


    React.useEffect(() => {


        //build up nodes from crs and edges from crscconversions

        let tmpNodes = [];
        let tmpEdges = [];

        if (project?.Crss && project?.CrsTransforms) {
            project.Crss.forEach(crs => {
                tmpNodes.push(
                    {
                        id: crs.CrsId.toString(),
                        data: {
                            crsTitle: getCrsTitle(crs),
                            crs: crs,
                            onEditCrs: onEditCrs,
                            onDeleteCrs: onDeleteCrs,
                            onMouseOver: bringCrsToFront,
                            onToggleCrsLock: onToggleCrsLock,
                            projectId: project.ProjectId,
                            permissionEdit: permissionEdit,
                            permissionLock: permissionLock,
                        },
                        position: { x: 100, y: 100 },
                        type: "customNode"//can choose 'custom'
                    }
                );
            });

            project?.CrsTransforms.forEach(crsTransform => {
                tmpEdges.push(
                    {
                        id: "e" + crsTransform.CrsIdFrom + "-" + crsTransform.CrsIdTo,
                        source: crsTransform.CrsIdFrom,
                        target: crsTransform.CrsIdTo,
                        position: { x: 100, y: 100 },
                        type: 'custom',
                        data: {
                            crsTransform: crsTransform,
                            onTransformDelete: onDeleteCrsTransform,
                            onTransformEdit: onEditCrsTransform,
                            onToggleCrsTransformLock: onToggleCrsTransformLock,
                            permissionEdit: permissionEdit,
                            permissionLock: permissionLock,
                        }
                        //,
                        //arrowHeadType: 'arrowclosed'
                    }
                )
            });
        }

        if (project) {
            const layoutedNodes = getPositionedNodes(project.ProjectId, tmpNodes, tmpEdges);
            setNodes(layoutedNodes);
            setEdges(tmpEdges);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project?.Crss, project?.CrsTransforms])


    function isThereACyclicPath(edgesToTest) {

        let visitedNodes = {};

        //pick out all the nodes and mark them all not yet visited
        for (let index = 0; index < nodes.length; index++) {
            const element = nodes[index];
            visitedNodes[element.id] = false;
        }

        // Call the recursive helper function
        // to detect cycle in different trees
        for (let index = 0; index < nodes.length; index++) {
            const node = nodes[index];

            // Don't recurse from here if already visited
            if (!visitedNodes[node.id]) {

                if (isCyclicUtil(node, visitedNodes, -1, edgesToTest)) {
                    return true;
                }
            }
        }

        return false;
    }


    // A recursive function that uses visited[]
    // and parent to detect cycle in subgraph
    // reachable from node
    function isCyclicUtil(currentNode, visitedNodes, parentNodeId, edgesToTest) {

        // Mark the current node as visited
        visitedNodes[currentNode.id] = true;

        //get adjacent nodes
        const outgoers = getOutgoers(currentNode, nodes, edgesToTest);
        const incomers = getIncomers(currentNode, nodes, edgesToTest);
        const connectedNodes = outgoers.concat(incomers);

        // Recurse for all the nodes
        // adjacent to this node
        for (let index = 0; index < connectedNodes.length; index++) {
            const connectedNode = connectedNodes[index];

            // If an adjacent node is not visited,
            // then recur for that node
            if (!visitedNodes[connectedNode.id]) {
                if (isCyclicUtil(connectedNode, visitedNodes, currentNode.id, edgesToTest)) {
                    return true;
                }
            }

            // If an adjacent node is visited and
            // not parent of current vertex,
            // then there is a cycle.
            else if (connectedNode.id !== parentNodeId) {
                return true
            }
        }

        //no cycle found yet
        return false;



    }

    const onConnectNodes = (params) => {


        const newEdge = {
            id: "e" + params.source + "-" + params.target,
            source: params.source,
            target: params.target,
            position: { x: 100, y: 100 },
            type: 'custom',
            data: {
            }
        }


        //first check for circular paths
        let edgesToTest = addEdge(newEdge, edges);

        if (isThereACyclicPath(edgesToTest)) {
            ToastError("Invalid Connection", "That connection would create a loop");
        }
        else {

            ToastLoading("Adding Crs Transform", addCrsTransformToastId);
            const crsTransform =
            {
                ProjectId: selectedProject.ProjectId,
                TransformSteps: [],
                CrsIdFrom: params.source,
                CrsIdTo: params.target
            };
            CreateCrsTransform(crsTransform)

                .then(res => {
                    ToastClose(addCrsTransformToastId);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, addCrsTransformToastId);
                });
        }
    };

    function onEditCrs(crs) {
        setCurrentCrs(crs);
        setShowCrsEditDialog(true);
    }


    async function onDeleteCrsTransform(crsTransform) {
        if (crsTransform) {
            BeaconSetSuggestions(BeaconDialogMap.DeleteCRSTransformDialog);

            const result = await Confirm("Are you sure you want to delete this transform ?", "Delete Transform");
            if (result) {


                ToastLoading("Deleting Transform", deleteCrsTransformToastId);

                try {
                    await DeleteCrsTransform(crsTransform.CrsTransformId);
                }
                catch (err) {
                    ToastError(err.message, err.cause, deleteCrsTransformToastId);
                }

                ToastClose(deleteCrsTransformToastId);
            }

            BeaconSetSuggestions(props.helpContext);
        }

    }


    async function onDeleteCrs(crs) {
        if (crs) {

            //check not native
            if (crs.IsNative) {
                Prompt("Can't delete a native CRS");
                return;
            }

            BeaconSetSuggestions(BeaconDialogMap.DeleteCRSDialog);

            const result = await Confirm("Are you sure you want to delete this CRS and attached transforms ?", "Delete CRS");
            if (result) {

                //find attached crs conversions and delete first

                let crsTransformsToDelete = [];
                for (let index = 0; index < project?.CrsTransforms.length; index++) {
                    const crsTransform = project?.CrsTransforms[index];

                    if (crsTransform.CrsIdFrom === crs.CrsId || crsTransform.CrsIdTo === crs.CrsId) {


                        if (!crsTransformsToDelete.find((c) => c.CrsTransformId === crsTransform.CrsTransformId)) {
                            crsTransformsToDelete.push(crsTransform.CrsTransformId)
                        }
                    }
                };

                for (let index = 0; index < crsTransformsToDelete.length; index++) {

                    ToastLoading("Deleting Transform", deleteCrsToastId);

                    try {
                        await DeleteCrsTransform(crsTransformsToDelete[index]);
                    }
                    catch (err) {
                        ToastError(err.message, err.cause, deleteCrsToastId);
                        return;
                    }
                }

                ToastLoading("Deleting CRS", deleteCrsToastId);

                try {
                    await DeleteCrs(crs.CrsId);
                    ToastClose(deleteCrsToastId);
                }
                catch (err) {
                    ToastError(err.message, err.cause, deleteCrsToastId);
                }

            }

            BeaconSetSuggestions(props.helpContext);


        }

    }


    function onToggleCrsTransformLock(crsTransform) {

        setIsBusy(true);

        ToastLoading(crsTransform.IsLocked ? "Unlocking" : "Locking", lockCrsTransformToastId);

        ChangeCrsTransformLock({ crsTransformId: crsTransform.CrsTransformId, isLocked: !crsTransform.IsLocked, projectId: project.ProjectId })
            .then(unwrapResult)
            .then(res => {
                setIsBusy(false);
                ToastClose(lockCrsTransformToastId);
            })
            .catch(err => {
                setIsBusy(false);
                ToastError(err.message, err.cause, lockCrsTransformToastId);
            });

    }

    function onToggleCrsLock(crs) {

        setIsBusy(true);

        ToastLoading(crs.IsLocked ? "Unlocking" : "Locking", lockCrsToastId);

        ChangeCrsLock({ crsId: crs.CrsId, isLocked: !crs.IsLocked, projectId: project.ProjectId })
            .then(unwrapResult)
            .then(res => {
                setIsBusy(false);
                ToastClose(lockCrsToastId);
            })
            .catch(err => {
                setIsBusy(false);
                ToastError(err.message, err.cause, lockCrsToastId);
            });

    }

    function onEditCrsTransform(crsTransform) {
        setCurrentCrsTransform(crsTransform);
        setShowCrsTransformEditDialog(true);
    }


    function onCreateCrs(crs, setUiDisabled) {

        ToastLoading("Adding CRS " + crs.Title, addCrsToastId);
        setIsBusy(true);
        setUiDisabled(true);

        crs.ProjectId = selectedProject.ProjectId;
        CreateCrs(crs)
            .then(unwrapResult)
            .then(res => {
                setIsBusy(false);
                ToastClose(addCrsToastId);
                setShowCrsAddDialog(false);
                setUiDisabled(false);
                BeaconSetSuggestions(helpContext);
            })
            .catch(err => {
                setIsBusy(false);
                ToastError(err.message, err.cause, addCrsToastId);
                setUiDisabled(false);
            });

    }


    function onUpdateCrs(crs, setUiDisabled) {

        ToastLoading("Updating CRS " + crs.Title, updateCrsToastId);
        setUiDisabled(true);

        setIsBusy(true);
        crs.ProjectId = selectedProject.ProjectId;
        UpdateCrs(crs)
            .then(unwrapResult)
            .then(res => {
                ToastClose(updateCrsToastId);
                setShowCrsEditDialog(false);
                setCurrentCrs(null);
                setIsBusy(false);
                setUiDisabled(false);
                BeaconSetSuggestions(helpContext);
            })
            .catch(err => {
                setIsBusy(false);
                ToastError(err.message, err.cause, updateCrsToastId);
                setUiDisabled(false);
            });

    }


    function onUpdateCrsTransform(crsTransform, setUiDisabled) {

        ToastLoading("Updating CRS Transform", updateCrsTransformToastId);

        setUiDisabled(true);

        setIsBusy(true);
        crsTransform.ProjectId = selectedProject.ProjectId;
        UpdateCrsTransform(crsTransform)
            .then(unwrapResult)
            .then(res => {
                ToastClose(updateCrsTransformToastId);
                setShowCrsTransformEditDialog(false);
                setCurrentCrsTransform(null);
                setIsBusy(false);
                setUiDisabled(false);
            })
            .catch(err => {
                setIsBusy(false);
                ToastError(err.message, err.cause, updateCrsTransformToastId);
                setUiDisabled(false);
            });
    }


    //bring edge to end of element so it is drawn first
    function bringEdgeToFront(edge, edges) {
        const tmpEdges = [...edges];

        //find index of edge in array
        const i = tmpEdges.findIndex((e) => e.id === edge.id);
        if (i > -1) {
            //move it to the end
            const elToMove = tmpEdges[i];
            tmpEdges.splice(i, 1);
            tmpEdges.push(elToMove);
        }

        setEdges(tmpEdges);
    }

    //find all connected edges and bring them to front
    function bringNodeToFront(node, nodes, edges) {


        const connected = getConnectedEdges([node], edges);
        connected.forEach(edge => {
            bringEdgeToFront(edge, edges);
        });

    }

    //a element is selected - bring it to front
    function onSelectionChange(param) {

        logger.debug("onselchange ", param.nodes, param.edges);

        if (param.nodes) {
            if (param.nodes.length >= 1) {
                bringNodeToFront(param.nodes[0], nodes, edges);
            }
        }
        if (param.edges) {

            if (param.edges.length >= 1) {
                bringEdgeToFront(param.edges[0], edges);
            }
        }
    }

    function bringCrsToFront(crs) {
        setFrontCrs(crs);
    }

    function permissionEdit() {
        return CanUpdateCrs(orgsInfo.currentUser, selectedProjectId, selectedProject?.OrganisationId);
    }
    function permissionLock() {
        return CanChangeCrsLock(orgsInfo.currentUser, selectedProjectId, selectedProject?.OrganisationId);
    }


    return (

        <>
            {nodes &&
                <>
                    <div className="view-top-panel">
                        <div className="view-title" >Coordinate Reference Systems</div>

                        {permissionEdit() &&
                            <div className="view-button-panel">
                                <Button disabled={selectedProjectId == null} onClick={
                                    () => {
                                        setShowCrsAddDialog(true);
                                    }
                                }>
                                    Add CRS
                                </Button>
                            </div>
                        }
                    </div>
                    <div className="crs-graph-container">
                        <ReactFlow

                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            className="crs-graph"
                            edgeTypes={edgeTypes}
                            nodeTypes={nodeTypes}
                            nodesConnectable={canEdit && permissionEdit()}
                            zoomOnScroll={false}
                            onConnect={onConnectNodes}
                            onSelectionChange={onSelectionChange}
                            onNodeDragStop={onNodeDragStop}
                            fitView
                        >
                            <Background />
                            <Controls />
                        </ReactFlow>
                    </div>
                </>
            }
            <CrsDialog helpContext={BeaconDialogMap.EditCRSDialog} parentHelpContext={helpContext} onSave={onCreateCrs} show={showCrsAddDialog} setShow={setShowCrsAddDialog} dialogTitle="Add CRS" crs={{ Title: "", Notes: "", Unit: -1 }} />
            {currentCrs && <CrsDialog helpContext={BeaconDialogMap.EditCRSDialog} parentHelpContext={helpContext} onSave={onUpdateCrs} show={showCrsEditDialog} setShow={setShowCrsEditDialog} crsTitle={getCrsTitle(currentCrs)} dialogTitle="Edit CRS" crs={currentCrs} />}
            {currentCrsTransform && <CrsTransformDialog helpContext={BeaconDialogMap.EditCRSTransformDialog} parentHelpContext={helpContext} onSave={onUpdateCrsTransform} show={showCrsTransformEditDialog} setShow={setShowCrsTransformEditDialog} dialogTitle="Edit CRS Transform" crsTransform={currentCrsTransform} />}
        </>
    );
}

export default CrsView;