import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');

// {AnnotationIds: ann[], ProjectId: sid }
export async function DeleteAnnotations(annotationsParam) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            ProjectId: annotationsParam.ProjectId,
            AnnotationIds: annotationsParam.AnnotationIds
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotations', options);
    logger.debug("DeleteAnnotations , response: ", response);
    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });

    }
    else {

        return annotationsParam;
    }

}

export async function CreateAnnotationSet(annotationSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: annotationSet
    }

    let response = await API.post('Reveal4DApi', '/CreateAnnotationSet', options);
    logger.debug("CreateAnnotationSet , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function UpdateAnnotationSet(annotationSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: annotationSet
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationSet', options);
    logger.debug("CreateAnnotationSet , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
}

export async function DeleteAnnotationSet(annotationSetId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            annotationsetid: annotationSetId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotationSet', options);
    logger.debug("DeleteAnnotationSet , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }

}

export async function CopyAnnotationSet(annotationSetId, modelToId, title) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            annotationsetid: annotationSetId,
            modelid: modelToId,
            title: title
        }
    }

    let response = await API.post('Reveal4DApi', '/CopyAnnotationSet', options);
    logger.debug("CopyAnnotationSet , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function CopyAnnotations(annotationSetFromId, annotationSetToId, annotationIdCursor) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            annotationsetfromid: annotationSetFromId,
            annotationsettoid: annotationSetToId,
            annotationidcursor: annotationIdCursor
        }
    }

    let response = await API.post('Reveal4DApi', '/CopyAnnotations', options);
    logger.debug("CopyAnnotations , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}


export async function GetAnnotations(annotationSetId, annotationCursorId, pageSize) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    let response = await API.get('Reveal4DApi', '/GetAnnotations?annotationsetid=' + annotationSetId + '&annotationidcursor=' + annotationCursorId + '&annotationpagesize=' + pageSize, options);
    logger.debug("GetAnnotations , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}
