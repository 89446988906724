import React, { useState } from 'react'

import { SystemPermissionFlags } from '../App/Permissions'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { availableSystemPermissionsGroup } from '../App/Permissions'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

function OptionsToPermission(options) {
    let permissions = 0;

    if (options) {
        options.forEach(option => {
            permissions |= option.value;
        });
    }
    return permissions;
}

function PermissionsToOptions(permissions) {

    let options = [];

    if (permissions & SystemPermissionFlags.OrganisationAdmin) {
        options.push({ value: SystemPermissionFlags.OrganisationAdmin, label: 'Organisation Admin' });
    }
    if (permissions & SystemPermissionFlags.BuildStandalone) {
        options.push({ value: SystemPermissionFlags.BuildStandalone, label: 'Build Standalone' });
    }

    return options;
}

export function EditGroup(props) {

    const { show, setShow, group, save, currentUser, helpContext, parentHelpContext } = props;

    const [title, setTitle] = useState();
    const [selectedPermissions, setSelectedPermissions] = useState();

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (props.show) {

            setTitle(group ? group.Title : "");
            setSelectedPermissions(PermissionsToOptions(group ? group.Permissions : []));

            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [group, helpContext, parentHelpContext, props.show])


    async function onSave(event) {
        event.preventDefault();

        let permissions = OptionsToPermission(selectedPermissions);

        //valid - send command
        if (title) {
            let newGroup = JSON.parse(JSON.stringify(group));

            newGroup.Title = title;
            newGroup.Permissions = permissions;

            save(newGroup, setUiDisabled);

        }
        //failed validation
        else {
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }


    return (

        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupTitle" as={Row}>
                            <Form.Label column sm={12} >Title:</Form.Label>
                            <Col sm={12}>
                                <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Group Title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupPermissions" as={Row}>
                            <Form.Label column sm={12} >System Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedPermissions}
                                    onChange={setSelectedPermissions}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableSystemPermissionsGroup(currentUser)} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

}


export default EditGroup;