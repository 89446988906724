import { configureStore } from '@reduxjs/toolkit'

import organisationsInfoReducer from './organisationsInfoSlice'
import assetBuildQueueReducer from './queueAssetBuildSlice'
import systemInfoReducer from './systemInfoSlice'

export default configureStore({
    reducer: {
        organisationsInfo: organisationsInfoReducer,
        assetBuildQueue: assetBuildQueueReducer,
        systemInfo: systemInfoReducer,
    }
})