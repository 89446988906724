import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');


export async function GetApiSettings() {

    let response = await API.get("Reveal4DApi", "/GetApiSettings");
    logger.debug("GetApiSettings , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}



export async function GetSystemSettings() {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    let response = await API.get('Reveal4DApi', '/GetSystemSettings', options);
    logger.debug("GetSystemSettings , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function UpdateSystemSettings(systemInfo) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: systemInfo
    }

    let response = await API.put('Reveal4DApi', '/UpdateSystemSettings', options);
    logger.debug("UpdateSystemSettings , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function UpgradeDatabase(dbName, dbAddress, dbUsername, dbPassword) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            dbname: dbName,
            dbaddress: dbAddress,
            dbUser: dbUsername,
            dbPassword: dbPassword
        }
    };

    let response = await API.put('Reveal4DApi', '/UpgradeDatabase', options);
    logger.debug("UpgradeDatabase , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function MigrateDatabase(dbName, dbAddress, dbUsername, dbPassword) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            dbname: dbName,
            dbaddress: dbAddress,
            dbUser: dbUsername,
            dbPassword: dbPassword
        }
    };

    let response = await API.put('Reveal4DApi', '/MigrateDatabase', options);
    logger.debug("MigrateDatabase , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

function Reveal4DException(errorData) {
    this.name = errorData.ErrorName;
    this.details = errorData.ErrorDetails;
    this.code = errorData.ErrorCode;
}

export async function InitSystem() {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    };

    let response = await API.put('Reveal4DApi', '/InitSystem', options);
    logger.debug("InitSystem , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Reveal4DException(response.ErrorData);
    }
    else {
        return response.Data;
    }
}


export async function BroadcastTextMessage(cids, message, type, from) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            textmessage: {
                Cids: cids,
                Message: message,
                MessageType: type,
                From: from
            }
        }
    };

    let response = await API.put('Reveal4DApi', '/BroadcastTextMessage', options)
    logger.debug("BroadcastTextMessage , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}

export async function GetConnectedWebSocketUsers() {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    };

    let response = await API.get('Reveal4DApi', '/GetConnectedWebSocketUsers', options)
    logger.debug("GetConnectedWebSocketUsers , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}
