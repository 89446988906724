import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import '../css/lists.css'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('AssignUser');


export function ListSelector(props) {

    const { title, itemName, items, show, setShow, onSaveItem, helpContext, parentHelpContext } = props;

    logger.debug("ListSelector", props);

    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [selectedItemOption, setSelectedItemOption] = useState(null);
    const [itemValid, setItemValid] = useState(true);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    function onSave(event) {
        event.preventDefault();
        setItemValid(selectedItemOption?.value ? true : false);

        if (selectedItemOption?.value) {

            onSaveItem(selectedItemOption.value, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupUser" as={Row}>
                            <Form.Label column sm={12} >{itemName}:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedItemOption}
                                    onChange={setSelectedItemOption}
                                    closeMenuOnSelect={true}
                                    name="user"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder={"Select " + itemName + "..."}
                                    options={items.map(item => ({ value: item.id, label: item.label }))} />
                                {(!itemValid && validated) &&
                                    <Form.Text className="text-danger">
                                        {"Please Select " + itemName + "."}
                                    </Form.Text>
                                }
                            </Col>
                        </Form.Group>

                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

