import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import FormCheck from 'react-bootstrap/FormCheck'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { ColourSwatch } from '../Components/ColourSwatch.js'
import '../css/annotation-properties.css'
import { HexAlphaColorPicker, HexColorPicker, HexColorInput } from 'react-colorful';

import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import * as Constants from '../constants.js';
import { Logger } from 'aws-amplify';
const logger = new Logger('EditStyleRule');


const styleRuleSubjectOptions = [
    { label: "Is Visible", value: Constants.subjects.IsVisible },
    { label: "Label Colour", value: Constants.subjects.LabelColour },
    { label: "Text Colour", value: Constants.subjects.TextColour },
    { label: "Label Text", value: Constants.subjects.LabelText },
    { label: "Line Width", value: Constants.subjects.LineWidth },
    { label: "Shape Fill Colour", value: Constants.subjects.ShapeFillColour },
    { label: "Line Colour", value: Constants.subjects.LineColour }
];

const styleRuleSubjectOccludedOptions = [
    { label: "Label Colour", value: Constants.subjects.LabelColour },
    { label: "Text Colour", value: Constants.subjects.TextColour }
];


const OperatorListFull = [
    { label: "=", value: Constants.operators.Equals },
    { label: "!=", value: Constants.operators.NotEquals },
    { label: "Contains", value: Constants.operators.Contains },
    { label: "Not Contains", value: Constants.operators.NotContains },
    { label: ">", value: Constants.operators.GreaterThan },
    { label: ">=", value: Constants.operators.GreaterThanOrEqual },
    { label: "<", value: Constants.operators.LessThan },
    { label: "<=", value: Constants.operators.LessThanOrEqual },
    { label: "Empty", value: Constants.operators.Empty },
    { label: "Not Empty", value: Constants.operators.NotEmpty },
    { label: "Any Of", value: Constants.operators.AnyOf },
    { label: "All Of", value: Constants.operators.AllOf },
];


function OperatorListDates() {
    const list = [
        { label: "=", value: Constants.operators.Equals },
        { label: "!=", value: Constants.operators.NotEquals },
        { label: ">", value: Constants.operators.GreaterThan },
        { label: ">=", value: Constants.operators.GreaterThanOrEqual },
        { label: "<", value: Constants.operators.LessThan },
        { label: "<=", value: Constants.operators.LessThanOrEqual }];

    return list;
};

function OperatorListMultiListProperty() {
    const list = [
        { label: "=", value: Constants.operators.Equals },
        { label: "!=", value: Constants.operators.NotEquals },
        { label: "Any Of", value: Constants.operators.AnyOf },
        { label: "All Of", value: Constants.operators.AllOf },
        { label: "None Of", value: Constants.operators.NotContains },
        { label: "Empty", value: Constants.operators.Empty },
        { label: "Not Empty", value: Constants.operators.NotEmpty },
    ];

    return list;
};

function OperatorListSingleListProperty() {
    const list = [
        { label: "=", value: Constants.operators.Equals },
        { label: "!=", value: Constants.operators.NotEquals },
        { label: "Empty", value: Constants.operators.Empty },
        { label: "Not Empty", value: Constants.operators.NotEmpty },
        { label: "Any Of", value: Constants.operators.AnyOf },
        { label: "None Of", value: Constants.operators.NotContains },
    ];

    return list;
};

function OperatorListTextValueProperty() {
    const list = [
        { label: "=", value: Constants.operators.Equals },
        { label: "!=", value: Constants.operators.NotEquals },
        { label: "Contains", value: Constants.operators.Contains },
        { label: "Not Contains", value: Constants.operators.NotContains },
        { label: "Empty", value: Constants.operators.Empty },
        { label: "Not Empty", value: Constants.operators.NotEmpty },

    ];

    return list;
};

function OperatorListNumberValueProperty() {
    const list = [
        { label: "=", value: Constants.operators.Equals },
        { label: "!=", value: Constants.operators.NotEquals },
        { label: ">", value: Constants.operators.GreaterThan },
        { label: ">=", value: Constants.operators.GreaterThanOrEqual },
        { label: "<", value: Constants.operators.LessThan },
        { label: "<=", value: Constants.operators.LessThanOrEqual },
        { label: "Empty", value: Constants.operators.Empty },
        { label: "Not Empty", value: Constants.operators.NotEmpty },
    ];

    return list;
};


function propertyTemplatesToOptions(templates) {
    let list = templates.map(t => ({ label: t.Title, value: t.PropertyTemplateId }));
    list.splice(0, 0, { label: "Date Created", value: "0" });
    list.splice(1, 0, { label: "Date Changed", value: "1" });
    list.splice(2, 0, { label: "Occluded by model", value: "2" });
    return list;
}

function propertyTemplatesToOptionsFull(templates) {
    let list = templates.map(t => ({ label: t.Title, value: t }));
    return list;
}

function ListItemsToOptions(listItems) {
    if (listItems) {
        return listItems.map(item => ({ label: item.ItemText, value: item.ListPropertyTemplateListItemId }));
    }
    else {
        return null;
    }
}

function operandToListItem(styleRule) {
    if (styleRule?.ComparePropertyTemplate) {
        return { label: styleRule?.ComparePropertyTemplate.Title, value: styleRule?.ComparePropertyTemplate.PropertyTemplateId };
    }
    else if (styleRule.Type === Constants.propertyType.DateCreated) {
        return { label: "Date Created", value: "0" };
    }
    else if (styleRule.Type === Constants.propertyType.DateChanged) {
        return { label: "Date Changed", value: "1" };
    }
    else if (styleRule.Type === Constants.propertyType.Occluded) {
        return { label: "Occluded", value: "2" };
    }

}

function InitialParameterList(styleRule, project) {

    const template = project.PropertyTemplates.find(pt => pt.PropertyTemplateId === styleRule?.ComparePropertyTemplate.PropertyTemplateId);


    if (template.MultiSelect || styleRule?.Operator === Constants.operators.AnyOf) {
        return styleRule.ListParameter.map(item => ({ label: item.ItemText, value: item.ListPropertyTemplateListItemId }));
    }
    else {
        if (styleRule.ListParameter) {
            return { label: styleRule.ListParameter[0].ItemText, value: styleRule.ListParameter[0].ListPropertyTemplateListItemId };
        }
        else {
            return null;
        }
    }
}

//
//
//
//
export function EditStyleRule(props) {

    logger.debug("EditStyleRule props", props);


    const form = useRef(null);

    const [subject, setSubject] = useState(props.styleRule?.SubjectParameter?.StyleSubject > -1 ? styleRuleSubjectOptions[props.styleRule.SubjectParameter.StyleSubject] : null);
    const [subjectValid, setSubjectValid] = useState(true);

    const [subjectColour, setSubjectColour] = useState(props.styleRule?.SubjectParameter?.Colour ? "#" + props.styleRule?.SubjectParameter?.Colour : "#888888");
    const [subjectVisible, setSubjectVisible] = useState(props.styleRule?.SubjectParameter?.IsVisible ? props.styleRule?.SubjectParameter?.IsVisible : false);
    const [subjectLabelText, setSubjectLabelText] = useState(props.styleRule?.SubjectParameter?.Text ? props.styleRule?.SubjectParameter?.Text : "");
    const [subjectLabelPropertyTemplate, setSubjectLabelPropertyTemplate] = useState(props.styleRule?.SubjectParameter?.PropertyTemplate ? { value: props.styleRule?.SubjectParameter?.PropertyTemplate, label: props.styleRule?.SubjectParameter?.PropertyTemplate.Title } : null);
    const [subjectLineWidth, setSubjectLineWidth] = useState(props.styleRule?.SubjectParameter?.LineWidth ? props.styleRule?.SubjectParameter?.LineWidth : 3);
    const [subjectLineWidthValid, setSubjectLineWidthValid] = useState(true);
    const [subjectFilled, setSubjectFilled] = useState(props.styleRule?.SubjectParameter?.Colour === "NOFILL" ? false : true);

    const [operand, setOperand] = useState(operandToListItem(props.styleRule));
    const [operandValid, setOperandValid] = useState(true);

    const [parameterText, setParameterText] = useState(props.styleRule?.Type !== 1 ? props.styleRule.Parameter : "");
    const [parameterTextValid, setParameterTextValid] = useState(true);

    const [parameterList, setParameterList] = useState(props.styleRule?.Type === 1 ? InitialParameterList(props.styleRule, props.project) : []);
    const [parameterListValid, setParameterListValid] = useState(true);

    const [operator, setOperator] = useState(props.styleRule?.Operator > -1 ? OperatorListFull[props.styleRule?.Operator] : null);
    const [operatorValid, setOperatorValid] = useState(true);

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    const [colourPickerVisible, setColourPickerVisible] = useState(false);
    const popover = useRef();

    React.useEffect(() => {
        if (props.show) {
            BeaconSetSuggestions(props.helpContext);
        }
        else {
            BeaconSetSuggestions(props.parentHelpContext);
        }
    }, [props.helpContext, props.parentHelpContext, props.show])

    //if operand changes, zero the currently selected operator
    function listSetOperand(operand) {

        setParameterList(null);
        setOperator(null);
        setOperand(operand);
    }


    //if operator changes, zero the currently selected parameter if it's a list
    function listSetOperator(newOperator) {

        //if changing from a multi-select to single select and we have a single select property template then zero list

        var template = props?.project?.PropertyTemplates?.find(o => o.PropertyTemplateId === operand?.value);

        if (template && (template.PropertyType === 1) && !template.MultiSelect) {

            //single select list type
            if (operator?.value === Constants.operators.AnyOf && newOperator?.value !== operator?.value) {
                if (parameterList?.length > 1) {
                    setParameterList(null);
                }
            }

        }

        //setParameterList(null);
        setOperator(newOperator);
    }



    function OperatorList(operand) {
        //date creted, date updated
        if (operand?.value === "0" || operand?.value === "1") {
            return OperatorListDates();
        }
        //occluded by model
        else if (operand?.value === "2") {
            return [];
        }
        //find a template?
        const template = props.project.PropertyTemplates.find(pt => pt.PropertyTemplateId === operand?.value);
        if (template) {
            //value
            if (template.PropertyType === 0) {
                //text
                if (template.ValueType === 0) {
                    return OperatorListTextValueProperty()
                }
                //number
                else {
                    return OperatorListNumberValueProperty()
                }
            }
            //list
            else {
                if (template.MultiSelect) {
                    return OperatorListMultiListProperty();
                }
                else {
                    return OperatorListSingleListProperty();
                }
            }
        }
    }

    function generateStyleRuleSubjectOptions(operand) {
        if (operand?.value === "2") {
            return styleRuleSubjectOccludedOptions;
        }
        else {
            return styleRuleSubjectOptions;
        }
    }


    function ParameterView(operand, operator) {

        if (!operand || !operator) {
            return <></>
        }

        //date created, date updated
        if (operand.value === "0" || operand.value === "1") {
            return (<>
                <Form.Control type="date" value={parameterText} onChange={(e) => setParameterText(e.target.value)} placeholder='Date...' />
                {(!parameterTextValid && validated) &&
                    <Form.Text className="text-danger">Please select a valid date.</Form.Text>}
            </>
            )
        }
        //no value for 'occluded by model'
        else if (operand.value === "2") {
            return <></>
        }


        const template = props.project.PropertyTemplates.find(pt => pt.PropertyTemplateId === operand?.value);
        if (template) {
            //value
            if (template.PropertyType === Constants.propertyType.Value) {
                if (operator.value === Constants.operators.Empty || operator.value === Constants.operators.NotEmpty) {
                    return <></>
                }
                else {
                    //text
                    if (template.ValueType === 0) {
                        return (<>
                            <Form.Control value={parameterText} onChange={(e) => setParameterText(e.target.value)} placeholder='Text...' />
                            {(!parameterTextValid && validated) &&
                                <Form.Text className="text-danger">Please enter text.</Form.Text>}
                        </>
                        )
                    }
                    else {
                        return (<>
                            <Form.Control type="number" value={parameterText} onChange={(e) => setParameterText(e.target.value)} placeholder='Number...' />
                            {(!parameterTextValid && validated) &&
                                <Form.Text className="text-danger">Please enter a valid number.</Form.Text>}
                        </>
                        )
                    }
                }
            }
            else {//list

                function isMulti() {
                    //always multi select
                    if (operator.value === Constants.operators.AnyOf || operator.value === Constants.operators.NotContains) {
                        return true;
                    }

                    return template.MultiSelect
                };

                if (operator.value === Constants.operators.Empty || operator.value === Constants.operators.NotEmpty) {
                    return <></>
                }
                else {
                    return (
                        <>
                            <Select
                                value={parameterList}
                                onChange={setParameterList}
                                closeMenuOnSelect={isMulti() ? false : true}
                                name="parameterList"
                                className="basic-multi-select"
                                isMulti={isMulti()}
                                classNamePrefix="select"
                                placeholder={isMulti() ? "Select Item(s)..." : "Select Item..."}
                                options={ListItemsToOptions(template.ListItems)} />
                            {(!parameterListValid && validated && isMulti()) &&
                                <Form.Text className="text-danger">Please choose at least one item.</Form.Text>}
                            {(!parameterListValid && validated && !isMulti()) &&
                                <Form.Text className="text-danger">Please choose an item.</Form.Text>}
                        </>
                    )
                }
            }
        }
    }

    function SubjectView(subject) {

        switch (subject?.value) {

            //isvisible
            case Constants.subjects.IsVisible:
                return (
                    <Col sm={9}>
                        <FormCheck
                            className="mt-2"
                            type="switch"
                            label=""
                            checked={subjectVisible}
                            onChange={(e) => setSubjectVisible(!subjectVisible)} />
                    </Col>
                );
            //colours
            case Constants.subjects.LabelColour:
            case Constants.subjects.TextColour:
                return (
                    <Col sm={9}>
                        <div className="d-flex flex-row">
                            <HexColorInput prefixed color={subjectColour} onChange={setSubjectColour} />
                            <ColourSwatch colour={subjectColour} onClick={() => setColourPickerVisible(!colourPickerVisible)} />
                            {colourPickerVisible &&
                                <div className="popover z-behind" ref={popover}>
                                    <HexColorPicker
                                        className="z-behind"
                                        color={subjectColour}
                                        onChange={setSubjectColour}
                                    />
                                </div>
                            }

                        </div>
                    </Col>
                );
            case Constants.subjects.LineColour:
                return (
                    <Col sm={9}>
                        <div className="d-flex flex-row">
                            <HexColorInput alpha prefixed color={subjectColour} onChange={setSubjectColour} />
                            <ColourSwatch colour={subjectColour} onClick={() => setColourPickerVisible(!colourPickerVisible)} />
                            {colourPickerVisible &&
                                <div className="popover z-behind" ref={popover}>
                                    <HexAlphaColorPicker
                                        className="z-behind"
                                        color={subjectColour}
                                        onChange={setSubjectColour}
                                    />
                                </div>
                            }

                        </div>
                    </Col>
                );
            //fill
            case Constants.subjects.ShapeFillColour:
                return (
                    <>
                        <Col >

                            <div className="styleRuleSubject" >
                                <FormCheck
                                    className="mt-2 me-2"
                                    type="switch"
                                    label={subjectFilled ? "Filled:" : "Not Filled"}
                                    checked={subjectFilled}
                                    onChange={(e) => setSubjectFilled(!subjectFilled)} />

                            </div>
                            {subjectFilled &&
                                <Col sm={9} >
                                    <div className="d-flex flex-row">
                                        <HexColorInput alpha prefixed color={subjectColour} onChange={setSubjectColour} />
                                        <ColourSwatch colour={subjectColour} onClick={() => setColourPickerVisible(!colourPickerVisible)} />
                                        {colourPickerVisible &&
                                            <div className="popover z-behind" ref={popover}>
                                                <HexAlphaColorPicker
                                                    className="z-behind"
                                                    color={subjectColour}
                                                    onChange={setSubjectColour}
                                                />
                                            </div>
                                        }

                                    </div>
                                </Col>
                            }

                        </Col>
                    </>
                );
            //label text
            case Constants.subjects.LabelText:
                return (
                    <Col>
                        <Select
                            value={subjectLabelPropertyTemplate}
                            onChange={setSubjectLabelPropertyTemplate}
                            closeMenuOnSelect={true}
                            name="parameterListLabel"
                            className="basic-multi-select"
                            isMulti={false}
                            classNamePrefix="select"
                            placeholder={"Select Item..."}
                            options={propertyTemplatesToOptionsFull(props.project.PropertyTemplates)}

                        />
                        or, if empty:
                        <Form.Control
                            type="text"
                            value={subjectLabelText}
                            onChange={(e) => setSubjectLabelText(e.target.value)}
                            placeholder="Label Text..."
                        />
                    </Col>
                );
            //line width
            case Constants.subjects.LineWidth:
                return (
                    <Col>
                        <Form.Control
                            type="number"
                            value={subjectLineWidth}
                            onChange={(e) => setSubjectLineWidth(e.target.value)}
                            placeholder="Line Width..."
                        />
                        {(!subjectLineWidthValid && validated) &&
                            <Form.Text className="text-danger">Please enter a positive number.</Form.Text>}
                    </Col>
                );

            default:
                return (<></>);
        }

    }


    function onSave(event) {
        event.preventDefault();

        //check validity

        logger.debug("subject: ", subject);
        logger.debug("operand: ", operand);
        logger.debug("operator: ", operator);
        logger.debug("parameterText", parameterText);
        logger.debug("parameterList", parameterList);


        setSubjectValid(subject?.value > -1);
        let isValid = subject?.value > -1;

        if (isValid) {
            setOperandValid(operand != null);
            isValid = operand != null;

            if (isValid) {
                //line width
                if (subject.value === Constants.subjects.LineWidth) {
                    setSubjectLineWidthValid(subjectLineWidth >= 0);
                    isValid = subjectLineWidth >= 0
                }

                if (isValid) {
                    setOperatorValid(operator?.value > -1 || operand?.value === "2"); //if 'occluded by model then we don't have an operator'
                    isValid = operator?.value > -1 || operand?.value === "2"/**/;
                    if (isValid) {
                        //date
                        if (operand.value === "0"/**/ || operand.value === "1"/**/) {
                            //validate date
                            setParameterTextValid(parameterText !== undefined && parameterText !== "");
                            isValid = parameterText !== undefined && parameterText !== "";

                        }
                        else {
                            const template = props.project.PropertyTemplates.find(pt => pt.PropertyTemplateId === operand?.value);
                            if (template) {
                                //value
                                if (template.PropertyType === Constants.propertyType.Value) {
                                    //text - anything is valid
                                    if (template.ValueType === Constants.valuePropertyTypeType.Number) {
                                        //number, don't check if using these operators
                                        if (!(operator?.value === Constants.operators.Empty || operator?.value === Constants.operators.NotEmpty)) {
                                            let num = Number(parameterText);
                                            setParameterTextValid(!isNaN(num) && parameterText !== "");
                                            isValid = (!isNaN(num) && parameterText !== "");

                                        }
                                    }
                                }
                                //list property type
                                else {
                                    //don't check if using these operators
                                    if (!(operator?.value === Constants.operators.Empty || operator?.value === Constants.operators.NotEmpty)) {
                                        if (template.MultiSelect || operator.value === Constants.operators.AnyOf || operator.value === Constants.operators.NotContains) {
                                            setParameterListValid(parameterList?.length);
                                            isValid = parameterList?.length;
                                        }
                                        else {
                                            setParameterListValid(parameterList?.value);
                                            isValid = parameterList?.value;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (isValid) {
            //copy style rule
            let newStyleRule = JSON.parse(JSON.stringify(props.styleRule));

            //update with form values
            switch (subject.value) {
                case 0:
                    newStyleRule.SubjectParameter = { StyleSubject: subject.value, IsVisible: subjectVisible };
                    break;
                //colours
                case 1:
                case 2:
                case 6:
                    newStyleRule.SubjectParameter = { StyleSubject: subject.value, Colour: subjectColour.replace('#', '').toUpperCase() };
                    break;
                //fill
                case 5:
                    if (subjectFilled) {
                        newStyleRule.SubjectParameter = { StyleSubject: subject.value, Colour: subjectColour.replace('#', '').toUpperCase() };
                    }
                    else {
                        newStyleRule.SubjectParameter = { StyleSubject: subject.value, Colour: "NOFILL" };
                    }
                    break;
                //label
                case 3:
                    newStyleRule.SubjectParameter = { StyleSubject: subject.value, Text: subjectLabelText, PropertyTemplate: subjectLabelPropertyTemplate?.value };
                    break;
                case 4:
                    newStyleRule.SubjectParameter = { StyleSubject: subject.value, LineWidth: subjectLineWidth };
                    break;

                default:
                    break;
            }
            newStyleRule.Operator = operator?.value ? operator?.value : 0; //if 'occluded by model' then just set operator to whatever

            //date created
            if (operand.value === "0") {
                newStyleRule.Type = 2;
                newStyleRule.Parameter = parameterText;
            }
            //date changed
            else if (operand.value === "1") {
                newStyleRule.Type = 3;
                newStyleRule.Parameter = parameterText;
            }
            //occluded by model
            else if (operand.value === "2") {
                newStyleRule.Type = 4;
                newStyleRule.Parameter = "";
            }
            else {
                const template = props.project.PropertyTemplates.find(pt => pt.PropertyTemplateId === operand?.value);
                if (template) {
                    newStyleRule.ComparePropertyTemplate = template;
                    if (template.PropertyType === 0) {
                        newStyleRule.Type = 0;
                        newStyleRule.Parameter = parameterText;
                    }
                    else {
                        newStyleRule.Type = 1;
                        if (!(operator.value === Constants.operators.Empty || operator.value === Constants.operators.NotEmpty)) {
                            if (template.MultiSelect || operator.value === Constants.operators.AnyOf || operator.value === Constants.operators.NotContains) {
                                newStyleRule.ListParameter = parameterList.map(param => ({ ListPropertyTemplateListItemId: param.value, ItemText: param.label }));
                            }
                            else {
                                newStyleRule.ListParameter = [{ ListPropertyTemplateListItemId: parameterList.value, ItemText: parameterList.label }];
                            }
                        }
                    }
                }
            }
            props.onSave(newStyleRule, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        props.setShow(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Form ref={form} noValidate onSubmit={onSave}>
                        <Form.Group controlId="formOperand" as={Row}>
                            <Form.Label column sm={3} >When:</Form.Label>
                            <Col sm={9}>
                                <Select
                                    value={operand}
                                    onChange={listSetOperand}
                                    closeMenuOnSelect={true}
                                    name="template"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Property..."
                                    options={propertyTemplatesToOptions(props.project.PropertyTemplates)} />
                                {(!operandValid && validated) &&
                                    <Form.Text className="text-danger">Please select an item.</Form.Text>}
                            </Col>
                        </Form.Group>
                        {/* don't display if occluded by model */}
                        {(operand?.value !== "2") &&
                            <Form.Group controlId="formOp" as={Row}>
                                <Form.Label column sm={3} ></Form.Label>
                                <Col sm={9}>
                                    <Select
                                        value={operator}
                                        onChange={listSetOperator}
                                        closeMenuOnSelect={true}
                                        name="operator"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Operator..."
                                        options={OperatorList(operand)} />
                                    {(!operatorValid && validated) &&
                                        <Form.Text className="text-danger">Please select an item.</Form.Text>}
                                </Col>
                            </Form.Group>
                        }
                        <Form.Group controlId="formParam" as={Row}>
                            <Form.Label column sm={3} ></Form.Label>
                            <Col sm={9}>
                                {ParameterView(operand, operator)}
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formType" as={Row}>
                            <Form.Label column sm={3} >Then:</Form.Label>
                            <Col sm={9}>
                                <Select
                                    value={subject}
                                    onChange={setSubject}
                                    closeMenuOnSelect={true}
                                    name="subject"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select Subject..."
                                    options={generateStyleRuleSubjectOptions(operand)} />
                                {(!subjectValid && validated) &&
                                    <Form.Text className="text-danger">Please select an item.</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formParam" as={Row}>
                            <Form.Label column sm={3} >Is set to:</Form.Label>
                            {SubjectView(subject)}
                        </Form.Group>


                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default EditStyleRule;