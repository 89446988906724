import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');




export async function GetUrlForModelUpload(modelFileName, projectId) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    let response = await API.get('Reveal4DApi', '/GetUrlForModelUpload?modelfilename=' + modelFileName + "&projectid=" + projectId, options);
    logger.debug("GetUrlForModelUpload , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function AddModelAndBuildUnityAssetBundle(assetBundleBuildCommand) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: assetBundleBuildCommand
    };

    let response = await API.put('Reveal4DApi', '/BuildUnityAssetBundle', options);
    logger.debug("AddModelAndBuildUnityAssetBundle , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function BuildUnityAssetBundle(assetBundleBuildCommand) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: assetBundleBuildCommand
    };

    logger.debug("BuildUnityAssetBundle , options: ", options);

    let response = await API.put('Reveal4DApi', '/BuildUnityAssetBundle', options);
    logger.debug("BuildUnityAssetBundle , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else if (!response.Data) {
        throw new Error("Failed to queue import", { cause: "Server could not queue import message, please try again." });
    }
    else {
        return response.Data;
    }
}

export async function BuildAllUnityAssetBundles(unityVersion) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            unityversion: unityVersion
        }
    };

    logger.debug("BuildAllUnityAssetBundles , options: ", options);

    let response = await API.put('Reveal4DApi', '/BuildAllUnityAssetBundles', options);
    logger.debug("BuildAllUnityAssetBundles , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else if (!response.Data) {
        throw new Error("Failed to queue build all", { cause: "Server could not queue build message, please try again." });
    }
    else {
        return response.Data;
    }
}



export async function GetAllBuildLogRecords(orgId) {

    logger.debug("api GetAllBuildLogRecords()", orgId);

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    };

    let response = await API.get('Reveal4DApi', '/GetAllBuildLogRecords?organisationid=' + orgId, options)
    logger.debug("GetAllBuildLogRecords , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}

export async function GetBuildLogRecordLog(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    };

    let response = await API.get('Reveal4DApi', '/GetBuildLogRecordLog?buildlogrecordid=' + id, options)
    logger.debug("GetBuildLogRecordLog , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}

export async function DeleteBuildLogRecord(id) {
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    };

    let response = await API.del('Reveal4DApi', '/DeleteBuildLogRecord?buildlogrecordid=' + id, options)
    logger.debug("DeleteBuildLogRecord , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }

    return true;
}
