

export const subjects = {

    IsVisible: 0,
    LabelColour: 1,
    TextColour: 2,
    LabelText: 3,
    LineWidth: 4,
    ShapeFillColour: 5,
    LineColour: 6
}

export const operators = {
    Equals: 0,
    NotEquals: 1,
    Contains: 2,
    NotContains: 3,
    GreaterThan: 4,
    GreaterThanOrEqual: 5,
    LessThan: 6,
    LessThanOrEqual: 7,
    Empty: 8,
    NotEmpty: 9,
    AnyOf: 10,
    AllOf: 11
}

export const propertyType = {
    Value: 0,
    List: 1,
    DateCreated: 2,
    DateChanged: 3,
    Occluded: 4
}
export const valueType = {
    TextProperty: 0,
    IntegerProperty: 1,
    FloatProperty: 2
}


export const valuePropertyTypeType = {
    Text: 0,
    Number: 1,
}

export const BuildTypes = {
    BuildUnityAssetBundle: 0,
    AddModelAndBuildUnityAssetBundle: 1,
}

export const TransformStepType = {
    RotateAboutX: 0,
    RotateAboutY: 1,
    RotateAboutZ: 2,
    Scale: 3,
    Translate: 4,
    Matrix: 5,
    EulerXYZRightHanded: 6
}

export const unityVersionsOptions = [
    { label: "All", value: "" },
    { label: "2020.3.20f1", value: "2020.3.20f1" }
];

export const unityVersionsList = "2020.3.20f1";

export const defaultMaxTextureSize = 8192;

export const CrsUnits = ["undefined", "millimetre", "metre", "foot", "inch"];

export const ErrorCodeEnum = {
    OK: 0,
    ErrorAddingDatabaseUser: 1,
    ErrorOpeningDatabase: 2,
    ErrorCreatingSchema: 3,
    MissingParameter: 4,
    CognitoUserAlreadyExists: 5,
    ErrorCreatingCognitoUser: 6,
    UserMustBeAdmin: 7,
    DatabaseUserAlreadyExists: 8,
    ErrorGettingProjectList: 9,
    ErrorGettingDatabaseUser: 10,
    ErrorGettingGroup: 11,
    ErrorQueryingDatabase: 12,
    ErrorGettingAssetBundleUrl: 13,
    ErrorGettingAssetBundleMeta: 14,
    UserNotAuthorised: 15,
    ErrorGettingAnnotations: 16,
    ErrorUpdatingAnnotations: 17,
    ErrorCreatingAssetBundleMeta: 18,
    ErrorCreatingProject: 19,
    ErrorGettingCurrentUser: 20,//20
    ErrorLoggingIn: 21,
    ChangePasswordRequired: 22,
    UserMustLogin: 23,
    ErrorGettingGroupList: 24,//24
    ErrorAddingUserToGroup: 25,
    ErrorGettingAllGroupList: 26,
    ErrorUpgradingDatabase: 27,//27
    ErrorGettingApiSettings: 28,
    FailedToInitialiseApiClient: 29,
    ErrorGettingSystemInfo: 30,
    ErrorAddingProjectToGroup: 31,
    ErrorCreatingDatabase: 32,
    ErrorRefreshingUserToken: 33,
    ErrorConnectingToInternet: 34
}

export function ErrorToString(e) {
    var errorText = "";
    switch (e) {
        case ErrorCodeEnum.OK:
            errorText = "";
            break;

        case ErrorCodeEnum.ErrorAddingDatabaseUser:
            errorText = "Error Adding Database User";
            break;

        case ErrorCodeEnum.ErrorOpeningDatabase:
            errorText = "Error Opening Database";
            break;

        case ErrorCodeEnum.ErrorCreatingSchema:
            errorText = "Error Creating Schema";
            break;

        case ErrorCodeEnum.MissingParameter:
            errorText = "Missing Parameter";
            break;

        case ErrorCodeEnum.CognitoUserAlreadyExists:
            errorText = "Cognito User Already Exists";
            break;

        case ErrorCodeEnum.ErrorCreatingCognitoUser:
            errorText = "Error Creating Cognito User";
            break;

        case ErrorCodeEnum.UserMustBeAdmin:
            errorText = "User Must Be Admin";
            break;

        case ErrorCodeEnum.DatabaseUserAlreadyExists:
            errorText = "Database User Already Exists";
            break;

        case ErrorCodeEnum.ErrorGettingProjectList:
            errorText = "Error Getting Project List";
            break;

        case ErrorCodeEnum.ErrorGettingDatabaseUser:
            errorText = "Error Getting Database User";
            break;

        case ErrorCodeEnum.ErrorGettingGroup:
            errorText = "Error Getting Group";
            break;

        case ErrorCodeEnum.ErrorQueryingDatabase:
            errorText = "Error Querying Database";
            break;

        case ErrorCodeEnum.ErrorGettingAssetBundleUrl:
            errorText = "Error Getting Asset Bundle Url";
            break;

        case ErrorCodeEnum.ErrorLoggingIn:
            errorText = "Error Logging In";
            break;

        case ErrorCodeEnum.ErrorGettingAssetBundleMeta:
            errorText = "Error Getting Asset Bundle Meta";
            break;

        case ErrorCodeEnum.UserNotAuthorised:
            errorText = "User Not Authorised";
            break;

        case ErrorCodeEnum.ErrorGettingAnnotations:
            errorText = "Error Getting Annotations";
            break;

        case ErrorCodeEnum.ErrorUpdatingAnnotations:
            errorText = "Error Updating Annotations";
            break;

        case ErrorCodeEnum.ErrorCreatingAssetBundleMeta:
            errorText = "Error Creating Asset Bundle Meta";
            break;

        case ErrorCodeEnum.ErrorCreatingProject:
            errorText = "Error Creating Project";
            break;

        case ErrorCodeEnum.ChangePasswordRequired:
            errorText = "Change Password Required";
            break;

        case ErrorCodeEnum.UserMustLogin:
            errorText = "User Must Login";
            break;

        case ErrorCodeEnum.FailedToInitialiseApiClient:
            errorText = "Initial contact to server failed";
            break;

        case ErrorCodeEnum.ErrorGettingGroupList:
            errorText = "Error Getting Group List";
            break;

        case ErrorCodeEnum.ErrorAddingUserToGroup:
            errorText = "Error Adding User To Group";
            break;

        case ErrorCodeEnum.ErrorGettingAllGroupList:
            errorText = "Error Getting All Group List";
            break;

        case ErrorCodeEnum.ErrorUpgradingDatabase:
            errorText = "Error Upgrading Database";
            break;

        case ErrorCodeEnum.ErrorGettingApiSettings:
            errorText = "Error Getting Api Settings";
            break;

        case ErrorCodeEnum.ErrorGettingSystemInfo:
            errorText = "Error Getting Database Settings";
            break;

        case ErrorCodeEnum.ErrorAddingProjectToGroup:
            errorText = "Error Adding Project To Group";
            break;

        case ErrorCodeEnum.ErrorCreatingDatabase:
            errorText = "Error Creating Database";
            break;

        case ErrorCodeEnum.ErrorRefreshingUserToken:
            errorText = "Error Refreshing token";
            break;

        case ErrorCodeEnum.ErrorConnectingToInternet:
            errorText = "Error Connecting To The Internet";
            break;

        default:
            errorText = "Unknown error";
            break;
    }


    return errorText;
}


export default ErrorToString;