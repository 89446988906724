
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../css/navbar.css';

//import { Logger } from 'aws-amplify';
//const logger = new Logger('UserMenu');


export function HelpMenu(props) {

    const { className } = props;


    return (
        <div className={className}>
            <DropdownButton id="UserMenu" title="Help" align="end">
                <Dropdown.Header >Reveal4D Help Site:</Dropdown.Header>
                <Dropdown.ItemText>
                    <a href="https://help.reveal4d.com" rel="noreferrer" target="_blank">
                        help.reveal4d.com
                    </a>
                </Dropdown.ItemText>
                <Dropdown.Header >Email for support:</Dropdown.Header>
                <Dropdown.ItemText>
                    <a href="mailto://support@reveal4d.com" rel="noreferrer" target="_blank">
                        support@reveal4d.com
                    </a>
                </Dropdown.ItemText>
            </DropdownButton>
        </div>
    );
}