import React, { useState, useRef } from 'react'
import { useDispatch, } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ToastLoading, ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { UpdateSystemSettings } from '../Api//SystemApi';
import { getSystemSettings, } from '../App/systemInfoSlice';
//import { Logger } from 'aws-amplify';
//const logger = new Logger('Reveal4D-Api');

export function EditSystemSettings() {


    const dispatch = useDispatch();

    const toastid = useRef(null);

    const [motd, setMotd] = useState("");
    const [currentVersion, setCurrentVersion] = useState("");
    const [releaseNotes, setReleaseNotes] = useState("");
    const [maxOfflineDays, setMaxOfflineDays] = useState("");
    const [clientVersionShowWarning, setClientVersionShowWarning] = useState("");
    const [versionWarningMessage, setVersionWarningMessage] = useState("");
    const [oldestAllowedVersion, setOldestAllowedVersion] = useState("");
    const [manualUrl, setManualUrl] = useState("");
    const [supportEmail, setSupportEmail] = useState("");
    const [systemEmailReplyAddress, setSystemEmailReplyAddress] = useState("");

    const [formDisabled, setFormDisabled] = useState(false);
    const [validated, setValidated] = useState(false);

    const [settingsRetrieved, setSettingsRetrieved] = useState(false);

    function onSubmitClicked(event) {
        event.preventDefault();

        const form = event.currentTarget;

        if (form.checkValidity()) {
            setFormDisabled(true);
            toastid.current = ToastLoading("Contacting server");
            UpdateSystemSettings({
                Motd: motd,
                CurrentVersion: currentVersion,
                ReleaseNotes: releaseNotes,
                MaxOfflineDays: maxOfflineDays,
                ClientVersionShowWarning: clientVersionShowWarning,
                VersionWarningMessage: versionWarningMessage,
                OldestAllowedVersion: oldestAllowedVersion,
                ManualUrl: manualUrl,
                SupportEmail: supportEmail,
                SystemEmailReplyAddress: systemEmailReplyAddress
            })
                .then(res => {
                    ToastSuccess("Updated", toastid.current, 2000);
                    setFormDisabled(false);
                })
                .catch(err => {
                    setFormDisabled(false);
                    ToastError(err.message, err.cause, toastid.current);
                });

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function getSystemSettingsClicked() {

        dispatch(getSystemSettings())
            .then(unwrapResult)
            .then(res => {
                setMotd(res.Motd);
                setCurrentVersion(res.CurrentVersion);
                setReleaseNotes(res.ReleaseNotes);
                setMaxOfflineDays(res.MaxOfflineDays);
                setClientVersionShowWarning(res.ClientVersionShowWarning);
                setVersionWarningMessage(res.VersionWarningMessage);
                setOldestAllowedVersion(res.OldestAllowedVersion);
                setManualUrl(res.ManualUrl);
                setSupportEmail(res.SupportEmail);
                setSystemEmailReplyAddress(res.SystemEmailReplyAddress);
                setSettingsRetrieved(true);
            })
            .catch(err => {
                ToastError(err.message, err.cause, err.message);
            });

    }

    return (
        <Container className="border border-dark mx-2 my-2 rounded">
            <h2>System Settings</h2>
            See <a href="http://digitalnativestudios.com/textmeshpro/docs/rich-text/">TextMeshPro Rich Text Formatting</a> for text formatting tags. Or use styles: {"<style=\"Quote\"></style>, Link, Title, H1 H2, H3, p"}
            <Form noValidate validated={validated} onSubmit={onSubmitClicked}>
                <Form.Group controlId="formGroupMotd" as={Col} className="mt-3">
                    <Form.Label  >Message of the Day, Shown Once a Day: </Form.Label>
                    <Col>
                        <Form.Control as="textarea" rows={5} disabled={formDisabled} value={motd} onChange={(e) => setMotd(e.target.value)} placeholder='MOTD...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupCurrentVersion" as={Col} className="mt-3">
                    <Form.Label >Current Client Version. The upgrade dialog will be shown if client version is less than this:</Form.Label>
                    <Col>
                        <Form.Control disabled={formDisabled} required value={currentVersion} onChange={(e) => setCurrentVersion(e.target.value)} placeholder='Current Client Version...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter Current Client Version.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupReleaseNotes" as={Col} className="mt-3">
                    <Form.Label>Release Notes. Shown on the upgrade dialog:</Form.Label>
                    <Col>
                        <Form.Control as="textarea" rows={5} disabled={formDisabled} required value={releaseNotes} onChange={(e) => setReleaseNotes(e.target.value)} placeholder='Release Notes...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter Release Notes.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupMaxOfflineDays" as={Col} className="mt-3">
                    <Form.Label>Max Offline Days. Client will not stay offline longer than this:</Form.Label>
                    <Col>
                        <Form.Control type="number" disabled={formDisabled} required value={maxOfflineDays} onChange={(e) => setMaxOfflineDays(e.target.value)} placeholder='Max Offline Days...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter Max Offline Days.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupClientVersionShowWarning" as={Col} className="mt-3">
                    <Form.Label >Client Warning Version. The client will show an upgrade warning message if client version is less than this:</Form.Label>
                    <Col >
                        <Form.Control disabled={formDisabled} value={clientVersionShowWarning} onChange={(e) => setClientVersionShowWarning(e.target.value)} placeholder='Client Warning Version...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formVersionWarningMessage" as={Col} className="mt-3">
                    <Form.Label >Version Warning Message. The message to show if the above is true:</Form.Label>
                    <Col >
                        <Form.Control as="textarea" rows={3} disabled={formDisabled} value={versionWarningMessage} onChange={(e) => setVersionWarningMessage(e.target.value)} placeholder='Version Warning Message...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupOldestAllowedVersion" as={Col} className="mt-3">
                    <Form.Label >Oldest Allowed Version. Client versions lower than this cannot run:</Form.Label>
                    <Col >
                        <Form.Control disabled={formDisabled} required value={oldestAllowedVersion} onChange={(e) => setOldestAllowedVersion(e.target.value)} placeholder='Oldest Allowed Version...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter Oldest Allowed Version.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupManualUrl" as={Row} className="mt-3">
                    <Form.Label >Manual Url:</Form.Label>
                    <Col>
                        <Form.Control disabled={formDisabled} value={manualUrl} onChange={(e) => setManualUrl(e.target.value)} placeholder='Manual URL...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupSupportEmail" as={Row} className="mt-3">
                    <Form.Label>Support Email Address:</Form.Label>
                    <Col>
                        <Form.Control type="email" disabled={formDisabled} value={supportEmail} onChange={(e) => setSupportEmail(e.target.value)} placeholder='Support Email Address...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupSystemEmailReplyToAddress" as={Row} className="mt-3">
                    <Form.Label>System Email Reply To Address (replay address to automatic system emails) changing this will send a email to this address with a validation link:</Form.Label>
                    <Col>
                        <Form.Control type="email" disabled={formDisabled} value={systemEmailReplyAddress} onChange={(e) => setSystemEmailReplyAddress(e.target.value)} placeholder='System Email Reply To Address...' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupButton" as={Row} className="mt-3">
                    <Col md={{ offset: 8 }} xs="auto" className="my-2">
                        <Button disabled={formDisabled} onClick={() => getSystemSettingsClicked()}>Get Settings </Button>
                    </Col>
                    <Col xs="auto" className="my-2">
                        <Button disabled={formDisabled || !settingsRetrieved} type='submit'>Update Settings</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );

}