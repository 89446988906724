import React, { useState, useRef } from 'react';
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { QueueAssetBuildComponent } from './QueueAssetBuild'
import { EditModel } from './EditModel';
import {
    CanDeleteModel, CanUpdateModel,
    CanUploadModelArchive
} from '../App/UserPermissions';
import { DeleteModel, UpdateModel } from '../Api/ModelApi'
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { ConfirmModal } from './ConfirmModal'
import { Tooltip } from 'react-tooltip';

import '../css/lists.css'
import '../css/navbar.css';
import { selectStyles } from '../App/Utils'
import { humanFileSize } from '../ProjectsPage/Uploader'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('ModelSelect');


//find latest asset bundle and get ImportSettings
function importSettings(model) {
    if (model) {
        if (model?.AssetBundles?.length > 0) {
            const latestAsset = model.AssetBundles[model.AssetBundles.length - 1];
            if (latestAsset.UnityVersion !== "2022.3.13f1") {
                return "No compatible Asset";
            }
            if (model.AssetBundles[model.AssetBundles.length - 1].ImportSettings.MaxTextureSizeSpecified) {
                return "Max texture size: " + model.AssetBundles[model.AssetBundles.length - 1].ImportSettings.MaxTextureSize + ". Asset size: " + humanFileSize(latestAsset.FileSize);
            }
            else {
                return "Max texture size: Unknown. Asset size: " + humanFileSize(latestAsset.FileSize);
            }
        }
        else {
            return "No compatible Asset";
        }
    }
    return "";
}

function modelToOption(model) {

    return {
        value: model?.ModelId,
        label: model?.Title,
        fileName: "File: " + model?.FileName,
        dateCreated: "Created: " + (new Date(model?.DateCreated)).toLocaleString(),
        importSettings: importSettings(model)
    };
}

function ModelsToOptions(models) {
    return models?.map(model => (modelToOption(model)));
}

//=======================================

export function ModelSelect(props) {

    const toastId = useRef();

    logger.debug(props);

    const { project, selectedModelId, setSelectedModelId, orgsInfo, selectedProjectId, parentHelpContext } = props;

    const [availableModelOptions, setAvailableModelOptions] = useState(project?.Models ? ModelsToOptions(project.Models) : []);
    const [showQueueBuild, setShowQueueBuild] = useState(false);
    const [showModelEditDialog, setShowModelEditDialog] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [modelValid, setModelValid] = useState(false);

    //look for project change
    React.useEffect(() => {

        if (project) {
            setAvailableModelOptions(ModelsToOptions(project?.Models));
        }
        else {
            setAvailableModelOptions([]);
        }

        if (!project?.Models?.find(p => p.ModelId === selectedModelId)) {
            if (project?.Models?.length > 0) {
                setSelectedModelId(project?.Models[0]?.ModelId);
                setModelValid(true);
            }
            else {
                setModelValid(false);
            }
        }
        else {
            setModelValid(true);
        }


    }, [project, project?.Models, selectedModelId, setSelectedModelId])

    function setSelected(selectedOption) {

        setSelectedModelId(selectedOption.value);
    }

    function onEditModel() {
        setShowModelEditDialog(true);
    }

    function onQueueBuild() {
        setShowQueueBuild(true);
    }

    async function onUpdateModel(model, setUiDisabled) {

        toastId.current = ToastLoading("Updating Model");
        setUiDisabled(true);

        //update the model title and default crs
        UpdateModel(model)
            .then(res => {
                setUiDisabled(false);
                ToastSuccess("Updated [" + model.Title + "]", toastId.current, 5000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });
    }

    async function onDeleteModel() {
        setShowConfirmDeleteDialog(true);
    }

    async function onDeleteModelConfirmed() {

        let modelToDelete = project.Models.find((item) => item.ModelId === selectedModelId)

        if (modelToDelete) {
            toastId.current = ToastLoading("Deleting Model");

            DeleteModel(selectedModelId)
                .then(res => {
                    ToastSuccess("Deleted [" + modelToDelete.Title + "]", toastId.current, 5000);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function hreflink() {
        if (selectedModelId === null || selectedModelId === undefined) {
            return <></>
        }
        else {
            const hreftext = "reveal4dclient:loadmodel?modelid=" + selectedModelId;

            return <a href={hreftext}>View</a>
        }
    }


    function formatOptionLabel(option, meta) {

        const { value, label, fileName, dateCreated, importSettings } = option;
        const { context } = meta;

        if (value === null || value === undefined) {
            return <div className="text-muted">No Models</div>
        }
        else if (context === "menu") {

            return (
                <div role="button" className={value === selectedModelId ? "select-list-item select-list-item-selected" : "select-list-item select-list-item-not-selected"}>
                    <div className="list-item-title">{label}</div>
                    <div className="list-item-sub-item">{fileName}</div>
                    <div className="list-item-sub-item">{dateCreated}</div>
                    <div className="list-item-sub-item">{importSettings}</div>
                </div>
            );
        }
        else {
            return <div>
                {label}

            </div>
        }
    }

    return (
        <div className="me-2">
            <div className="list-title-and-list list-wide">
                <div className="list-title-and-buttons">
                    <div className="list-title" >
                        Model:
                    </div>
                    <div className="list-buttons">
                        {CanUploadModelArchive(orgsInfo.currentUser, selectedProjectId, project?.OrganisationId) ?
                            <Button disabled={selectedProjectId === null} className="list-top-button" onClick={onQueueBuild}>Import</Button>
                            : <></>
                        }
                        {(CanUpdateModel(orgsInfo.currentUser, selectedModelId, project?.OrganisationId)) ?
                            <Button className="list-top-button" disabled={project == null || project?.Models?.length === 0} onClick={onEditModel}>Edit</Button>
                            : <></>
                        }
                        {CanDeleteModel(orgsInfo.currentUser, selectedModelId, project?.OrganisationId) ?
                            <Button className="list-top-button" disabled={project == null || project?.Models?.length === 0} onClick={onDeleteModel}>Delete</Button>
                            : <></>
                        }
                    </div>
                </div>

            </div>
            <div className="list-container">
                <div className="list-item-split-row-spaced">
                    <Select
                        value={modelValid ? modelToOption(project?.Models?.find(m => m?.ModelId === selectedModelId)) : null}
                        onChange={setSelected}
                        closeMenuOnSelect={true}
                        name="modelselect"
                        className="basic-multi-select list-model-select ms-0 me-0"
                        classNamePrefix="select"
                        placeholder="Select a Model..."
                        options={availableModelOptions}
                        formatOptionLabel={formatOptionLabel}
                        styles={selectStyles}
                    />
                    <div data-tooltip-id="model-view-tooltip" data-tooltip-content="View Model in Desktop Client">
                        <div className="me-3 mt-1">{hreflink()}</div>
                    </div>
                </div>
            </div>
            <QueueAssetBuildComponent
                helpContext={BeaconDialogMap.ImportModelDialog} parentHelpContext={parentHelpContext}
                modelFileName={project?.Models?.find(m => m.ModelId === selectedModelId)?.FileName}
                modelId={selectedModelId}
                show={showQueueBuild}
                setShow={setShowQueueBuild}
                fileStore={props.fileStore}
                currentProject={project}
                currentUser={orgsInfo?.currentUser}
            />
            {project?.Models?.find(m => m.ModelId === selectedModelId) &&
                <EditModel
                    helpContext={BeaconDialogMap.EditModelDialog} parentHelpContext={parentHelpContext}
                    onSave={onUpdateModel}
                    show={showModelEditDialog}
                    setShow={setShowModelEditDialog}
                    dialogTitle="Edit Model"
                    model={project?.Models?.find(m => m.ModelId === selectedModelId)}
                    project={project}
                />
            }

            {project?.Models?.find(m => m.ModelId === selectedModelId) &&
                <ConfirmModal
                    helpContext={BeaconDialogMap.DeleteModelDialog} parentHelpContext={parentHelpContext}
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteModelConfirmed}
                    okText={"Delete"}
                    dialogBody={"Caution! All Annotation Sets and Annotations will be Deleted!"}
                    dialogTitle={"Delete Model: " + project?.Models?.find(m => m.ModelId === selectedModelId)?.Title}
                    passPhrase={project?.Models?.find(m => m.ModelId === selectedModelId)?.Title}
                    passPhrasePrompt="Enter Model title to confirm deletion:"

                />
            }
            <Tooltip className="tooltip-style" id="model-view-tooltip" place="left" />

        </div>
    );
}