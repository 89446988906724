import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { LicenseTypes } from '../App/Permissions'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('AddLicense');

export function AddLicense(props) {
    logger.debug("props", props)
    const { show, setShow, currentOrg, createLicense, orgs, availableLicenseTypes, helpContext, parentHelpContext } = props;

    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [licenseTitle, setLicenseTitle] = useState("");
    const [numCopies, setNumCopies] = useState(1);
    const [maxFileSize, setMaxFileSize] = useState(1000);
    const [licenseTypeOption, setLicenseTypeOption] = useState(null);
    const [licenseTypeValid, setLicenseTypeValid] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    const [orgOption, setOrgOption] = useState(null);
    const [orgValid, setOrgValid] = useState(false);
    const [projectOption, setProjectOption] = useState(null);
    const [projectValid, setProjectValid] = useState(null);
    const [modelOption, setModelOption] = useState(null);
    const [modelValid, setModelValid] = useState(null);

    const selectedOrg = orgs.find(o => o.OrganisationId === orgOption?.value);
    const selectedProject = selectedOrg?.Projects?.find(p => p.ProjectId === projectOption?.value);
    const selectedModel = selectedProject?.Models?.find(m => m.ModelId === modelOption?.value);


    React.useEffect(() => {
        if (props.show) {
            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [helpContext, parentHelpContext, props.show])

    function onClose() {
        setShow(false);
    }

    function onSave(event) {

        event.preventDefault();
        setLicenseTypeValid(licenseTypeOption?.value !== null);
        // setModelIdValid((licenseTypeOption.value === LicenseTypes.ModelSpecificRead || licenseTypeOption.value === LicenseTypes.ModelSpecificWrite) ?
        //                 modelId !== "" : true);
        // setProjectIdValid((licenseTypeOption.value === LicenseTypes.ModelSpecificRead || licenseTypeOption.value === LicenseTypes.ModelSpecificWrite) ?
        //     modelId !== "" : true);

        let extraOptionValid = true;
        switch (licenseTypeOption?.value) {

            case LicenseTypes.ModelSpecificRead:
            case LicenseTypes.ModelSpecificWrite:
                setOrgValid(orgOption?.value)
                setProjectValid(projectOption?.value)
                setModelValid(modelOption?.value);
                extraOptionValid = modelOption?.value && projectOption?.value && orgOption?.value;
                break;
            case LicenseTypes.ProjectSpecificRead:
            case LicenseTypes.ProjectSpecificWrite:
                setOrgValid(orgOption?.value)
                setProjectValid(projectOption?.value);
                extraOptionValid = projectOption?.value && orgOption?.value;
                break;
            default:
                break;
        }

        let numLicenseCopies = Number(numCopies);

        //number of copies is irrelevant if not read or write access licenses
        if (!(licenseTypeOption?.value === LicenseTypes.ReadAccess || licenseTypeOption?.value === LicenseTypes.WriteAccess)) {
            numLicenseCopies = 1;
        }



        if (form.current.checkValidity() && licenseTypeOption?.value !== null && numCopies && extraOptionValid) {

            setUiDisabled(true);

            let license = {
                OrganisationId: currentOrg.OrganisationId,
                Title: licenseTitle,
                Type: licenseTypeOption.value,
                Payload: {},
                IsActive: true,
                StatusText: ""
            };

            if (licenseTypeOption.value === LicenseTypes.ModelSpecificRead || licenseTypeOption.value === LicenseTypes.ModelSpecificWrite) {
                license.Payload = { ItemId: modelOption.value };
            }
            if (licenseTypeOption.value === LicenseTypes.ProjectSpecificRead || licenseTypeOption.value === LicenseTypes.ProjectSpecificWrite) {
                license.Payload = { ItemId: projectOption.value };
            }
            if (licenseTypeOption.value === LicenseTypes.AllowUploadModels) {
                license.Payload = { Items: [{ Key: "MaxFileSize", Value: maxFileSize.toString() }] }
            }

            for (let index = 0; index < numLicenseCopies; index++) {
                createLicense(license);
            }

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    // AllowUploadModels: 2,
    //     ModelSpecificRead: 4,
    //         ModelSpecificWrite: 8,
    //             ProjectSpecificRead: 16,
    //                 ProjectSpecificWrite: 32

    //set the title accordingly
    React.useEffect(() => {
        switch (licenseTypeOption?.value) {
            case LicenseTypes.ReadAccess:
                setLicenseTitle("Read Access");
                break;
            case LicenseTypes.WriteAccess:
                setLicenseTitle("Write Access");
                break;
            case LicenseTypes.AllowUploadModels:
                setLicenseTitle("Import Models");
                break;

            case LicenseTypes.ModelSpecificRead:
                if (selectedModel) {
                    setLicenseTitle("Read Model: " + selectedModel.Title);
                }
                else {
                    setLicenseTitle("Read Model");
                }
                break;
            case LicenseTypes.ModelSpecificWrite:
                if (selectedModel) {
                    setLicenseTitle("Read/Write Model: " + selectedModel.Title);
                }
                else {
                    setLicenseTitle("Read/Write Model");
                }
                break;
            case LicenseTypes.ProjectSpecificRead:
                if (selectedProject) {
                    setLicenseTitle("Read Project: " + selectedProject.Title);
                }
                else {
                    setLicenseTitle("Read Project");
                }

                break;
            case LicenseTypes.ProjectSpecificWrite:
                if (selectedProject) {
                    setLicenseTitle("Read/Write Project: " + selectedProject.Title);
                }
                else {
                    setLicenseTitle("Read/Write Project");
                }
                break;
            default:
                break;
        }

    }, [licenseTypeOption, selectedModel, selectedProject])




    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add License</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupPermissions" as={Row}>
                            <Form.Label column sm={12} >License Type:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={licenseTypeOption}
                                    onChange={setLicenseTypeOption}
                                    closeMenuOnSelect={true}
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select License Type..."
                                    options={availableLicenseTypes}
                                />
                                {(!licenseTypeValid && validated) &&
                                    <Form.Text className="text-danger">Please select License Type.</Form.Text>}
                            </Col>
                        </Form.Group>
                        {(licenseTypeOption?.value === LicenseTypes.ModelSpecificRead || licenseTypeOption?.value === LicenseTypes.ModelSpecificWrite ||
                            licenseTypeOption?.value === LicenseTypes.ProjectSpecificRead || licenseTypeOption?.value === LicenseTypes.ProjectSpecificWrite) &&
                            <>
                                <Form.Group controlId="formGroupPermissions" as={Row}>
                                    <Form.Label column sm={12} >Organisation:</Form.Label>
                                    <Col sm={12}>
                                        <Select
                                            value={orgOption}
                                            onChange={setOrgOption}
                                            closeMenuOnSelect={true}
                                            name="permissions"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Click to Select Organisation..."
                                            options={orgs.map(org => ({ value: org.OrganisationId, label: org.Title }))}
                                        />
                                        {(!orgValid && validated) &&
                                            <Form.Text className="text-danger">Please select Organisation</Form.Text>}
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="formGroupProjects" as={Row}>
                                    <Form.Label column sm={12} >Project:</Form.Label>
                                    <Col sm={12}>
                                        <Select
                                            value={projectOption}
                                            onChange={setProjectOption}
                                            closeMenuOnSelect={true}
                                            name="permissions"
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Click to Select Project..."
                                            options={selectedOrg?.Projects?.map(project => ({ value: project.ProjectId, label: project.Title }))}
                                        />
                                        {(!projectValid && validated) &&
                                            <Form.Text className="text-danger">Please select Project</Form.Text>}
                                    </Col>
                                </Form.Group>
                                {(licenseTypeOption?.value === LicenseTypes.ModelSpecificRead || licenseTypeOption?.value === LicenseTypes.ModelSpecificWrite) &&
                                    <Form.Group controlId="formGroupModels" as={Row}>
                                        <Form.Label column sm={12} >Model:</Form.Label>
                                        <Col sm={12}>
                                            <Select
                                                value={modelOption}
                                                onChange={setModelOption}
                                                closeMenuOnSelect={true}
                                                name="permissions"
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder="Click to Select Model..."
                                                options={selectedProject?.Models?.map(model => ({ value: model.ModelId, label: model.Title }))}
                                            />
                                            {(!modelValid && validated) &&
                                                <Form.Text className="text-danger">Please select Model</Form.Text>}
                                        </Col>
                                    </Form.Group>}

                            </>
                        }
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={12} >License Title:</Form.Label>
                            <Col sm={12}>
                                <Form.Control required value={licenseTitle} onChange={(e) => setLicenseTitle(e.target.value)} placeholder='License title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        {(licenseTypeOption?.value === LicenseTypes.AllowUploadModels) &&
                            <Form.Group controlId="formGroupUserName" as={Row}>
                                <Form.Label column sm={12} >Max file size (MB):</Form.Label>
                                <Col sm={12}>
                                    <Form.Control type="number" min="1" max="10000" required value={maxFileSize} onChange={(e) => setMaxFileSize(e.target.value)} placeholder='Max file size...' />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid file size (1-10000 MB).
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                        }
                        {(licenseTypeOption?.value === LicenseTypes.ReadAccess || licenseTypeOption?.value === LicenseTypes.WriteAccess) &&
                            <Form.Group controlId="formGroupUserName" as={Row}>
                                <Form.Label column sm={12} >Copies:</Form.Label>
                                <Col sm={12}>
                                    <Form.Control type="number" min="1" max="30" required value={numCopies} onChange={(e) => setNumCopies(e.target.value)} placeholder='Number of Copies...' />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter valid number of license copies (1-30).
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        }

                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );
}