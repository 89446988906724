import React from "react";

import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { BuildLogTable } from "./BuildLogTable"

function BuildLogsPage(props) {
    const { setMainPageHelpContext } = props;


    React.useEffect(() => {
        BeaconSetSuggestions(BeaconDialogMap.ModelImportLogsPage);
        setMainPageHelpContext(BeaconDialogMap.ModelImportLogsPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BuildLogTable />
    );

}

export default BuildLogsPage;