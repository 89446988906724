import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');




export async function CreateUser(user, suppressEmail) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: user
    };

    let response = await API.post('Reveal4DApi', '/CreateUser?suppressemail=' + suppressEmail, options)
    logger.debug("CreateUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function CreateCognitoRecordAndLinkExistingUser(user, suppressEmail) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: user
    };

    let response = await API.post('Reveal4DApi', '/CreateCognitoRecordAndLinkExistingUser?suppressemail=' + suppressEmail, options)
    logger.debug("CreateCognitoRecordAndLinkExistingUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}



export async function ResetUser(user) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: user
    };

    let response = await API.post('Reveal4DApi', '/ResetUser', options)
    logger.debug("ResetUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function CanDeleteUser(userId) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }
    let response = await API.get('Reveal4DApi', '/CanDeleteUser?userid=' + userId, options);
    logger.debug("CanDeleteUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        logger.debug("CanDeleteUser error ");
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function GetCurrentUser() {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }
    let response = await API.get('Reveal4DApi', '/GetCurrentUser', options);
    logger.debug("GetCurrentUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        logger.debug("GetCurrentUser error ");
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function UpdateUser(user) {
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: user
    };

    let response = await API.put('Reveal4DApi', '/UpdateUser', options)
    logger.debug("UpdateUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}



export async function DeleteUser(userId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            userid: userId
        }
    };

    let response = await API.del('Reveal4DApi', '/DeleteUser', options)
    logger.debug("DeleteUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function DeactivateUser(userId, removeUserPrescence) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            userid: userId,
            removeuserpresence: removeUserPrescence
        }
    };

    let response = await API.del('Reveal4DApi', '/DeactivateUser', options)
    logger.debug("DeactivateUser , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

}


export async function GenerateUserApiKey(userId) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }
    let response = await API.get('Reveal4DApi', '/GenerateUserApiKey?userid=' + userId, options);
    logger.debug("GenerateUserApiKey , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return { userId: userId, apiKey: response.Data };
    }

}



export async function GetUserCognitoInfo(userId) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    let response = await API.get('Reveal4DApi', '/GetUserCognitoInfo?userid=' + userId, options);
    logger.debug("GetUserCognitoInfo , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

    /*
    
    {
        "Enabled": true,
        "MFAOptions": [],
        "PreferredMfaSetting": null,
        "UserAttributes": [
            {
                "Name": "sub",
                "Value": "4aed1b25-2de3-4567-ab4c-be0d13b51534"
            },
            {
                "Name": "email_verified",
                "Value": "true"
            },
            {
                "Name": "phone_number_verified",
                "Value": "true"
            },
            {
                "Name": "phone_number",
                "Value": "+447970198792"
            },
            {
                "Name": "email",
                "Value": "stuff@keithcassidy.com"
            }
        ],
        "UserCreateDate": "2019-09-23T09:35:50.853Z",
        "UserLastModifiedDate": "2020-11-09T16:24:47.011Z",
        "UserMFASettingList": [],
        "Username": "4aed1b25-2de3-4567-ab4c-be0d13b51534",
        "UserStatus": {
            "Value": "CONFIRMED"
        },
        "ResponseMetadata": {
            "RequestId": "ca7122bc-b97a-4b44-ad3a-f7d1cf45ee56",
            "Metadata": []
        },
        "ContentLength": 433,
        "HttpStatusCode": 200
    }
    
    */



}

export async function GetUserCognitoDevices(userId) {

    let session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        }
    }

    let response = await API.get('Reveal4DApi', '/GetUserCognitoDevices?userid=' + userId, options);
    logger.debug("GetUserCognitoDevices , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }

    /*
    devices:
    [
    {
        "DeviceAttributes": [
            {
                "Name": "device_status",
                "Value": "valid"
            },
            {
                "Name": "device_name",
                "Value": "WindowsClient"
            },
            {
                "Name": "dev:device_arn",
                "Value": "arn:aws:cognito-idp:eu-west-2:722052948767:owner/2b6224e5-d4cd-4f1a-80ae-25caf2408234.eu-west-2_d8iSqf2EG/device/eu-west-2_05e7d271-b1be-460b-8c2a-0cdf1fb72aad"
            },
            {
                "Name": "dev:device_owner",
                "Value": "2b6224e5-d4cd-4f1a-80ae-25caf2408234.eu-west-2_d8iSqf2EG"
            },
            {
                "Name": "last_ip_used",
                "Value": "2.24.254.60"
            },
            {
                "Name": "dev:device_remembered_status",
                "Value": "remembered"
            },
            {
                "Name": "dev:device_sdk",
                "Value": "aws-sdk-dotnet-coreclr-3.7.101.9"
            }
        ],
        "DeviceCreateDate": "2022-11-07T15:55:21.241Z",
        "DeviceKey": "eu-west-2_05e7d271-b1be-460b-8c2a-0cdf1fb72aad",
        "DeviceLastAuthenticatedDate": "2022-11-07T16:42:48Z",
        "DeviceLastModifiedDate": "2022-11-07T16:42:48.88Z"
    },
    {
        "DeviceAttributes": [
            {
                "Name": "device_status",
                "Value": "valid"
            },
            {
                "Name": "device_name",
                "Value": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36"
            },
            {
                "Name": "dev:device_arn",
                "Value": "arn:aws:cognito-idp:eu-west-2:722052948767:owner/2b6224e5-d4cd-4f1a-80ae-25caf2408234.eu-west-2_d8iSqf2EG/device/eu-west-2_91df2681-03bd-4e6d-8f41-239ae697cbd1"
            },
            {
                "Name": "dev:device_owner",
                "Value": "2b6224e5-d4cd-4f1a-80ae-25caf2408234.eu-west-2_d8iSqf2EG"
            },
            {
                "Name": "last_ip_used",
                "Value": "2.24.254.60"
            },
            {
                "Name": "dev:device_remembered_status",
                "Value": "remembered"
            },
            {
                "Name": "dev:device_sdk",
                "Value": "aws-sdk-unknown-unknown"
            }
        ],
        "DeviceCreateDate": "2022-11-08T09:13:24.665Z",
        "DeviceKey": "eu-west-2_91df2681-03bd-4e6d-8f41-239ae697cbd1",
        "DeviceLastAuthenticatedDate": "2022-11-08T09:13:24Z",
        "DeviceLastModifiedDate": "2022-11-08T09:13:24.665Z"
    }
]
    
    */



}
