import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ToastLoading, ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import Select from 'react-select'
import { SendEmailToUser } from '../Api/EmailApi'
import { UserStatus } from '../App/Permissions'

function allUsersOptions(orgs) {
    let options = [];
    orgs.forEach(o => {
        o.Users.filter(u => u.Status === UserStatus.Active).forEach(u => {
            options.push({ value: u.UserId, label: u.UserName });
        })
    })

    return options;
}

export function SendSystemEmailComponent() {
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const toastid = useRef(null);

    const [formDisabled, setFormDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedUsersValid, setSelectedUsersValid] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [subject, setSubject] = useState("");
    const [bodyHtml, setBodyHtml] = useState("");


    function onSubmitClicked(event) {
        event.preventDefault();

        const form = event.currentTarget;

        setSelectedUsersValid(selectedUsers.length > 0);

        if (form.checkValidity() && selectedUsers.length > 0) {
            setFormDisabled(true);
            toastid.current = ToastLoading("Contacting server");

            selectedUsers.forEach(userElement => {
                SendEmailToUser(userElement.value, subject, bodyHtml)
                    .then(res => {
                        ToastSuccess("Email sent to " + userElement.label, toastid.current, 2000);
                    })
                    .catch(err => {
                        ToastError("Fail sending Email to: " + userElement.label + err.message, err.cause, toastid.current);
                    });

            });


            setFormDisabled(false);

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    return (
        <Container className="border border-dark mx-2 my-2">
            <h2>Send System Email To Users</h2>
            <Form noValidate validated={validated} onSubmit={onSubmitClicked}>
                <Form.Group controlId="formGroupDbAddress" as={Row}>
                    <Form.Label column sm={2} >To Users:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={selectedUsers}
                            onChange={setSelectedUsers}
                            closeMenuOnSelect={true}
                            isMulti
                            name="users"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Users..."
                            options={allUsersOptions(orgsInfo.organisations)} />
                        {(!selectedUsersValid && validated) &&
                            <Form.Text className="text-danger">Please select users.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formMessage" as={Row}>
                    <Form.Label column sm={2} >Subject:</Form.Label>
                    <Col sm={10}>
                        <Form.Control disabled={formDisabled} required value={subject} onChange={(e) => setSubject(e.target.value)} placeholder='Subject...' />
                        <Form.Control.Feedback type="invalid">
                            Please enter a subject.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formBodyHtml" as={Row}>
                    <Form.Label column sm={2} >Body (Html):</Form.Label>
                    <Col sm={10}>
                        <Form.Control disabled={formDisabled} value={bodyHtml} onChange={(e) => setBodyHtml(e.target.value)} placeholder='Body Html. [Optional]' />
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupButton" as={Row}>
                    <Col sm={{ offset: 2, span: 2 }} >
                        <Button disabled={formDisabled} type='submit'>Send</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );

}

export default SendSystemEmailComponent;