import React from "react";
import '../css/lists.css'

export function OrgInfoPanel(props) {

    const { currentOrg } = props;

    return (

        <div className="list-title-and-list list-extremely-short  list-narrow">
            <div className="list-title-and-buttons">
                <div className="list-title" >Org Info:</div>
            </div>

            <div className="org-info-main-panel">
                <div className="org-info-left-col">
                    <div >Title:</div>
                    <div >ID:</div>
                </div>

                <div className="org-info-right-col">
                    <div className="list-item-title">{currentOrg.Title}</div>
                    <div className="list-item-sub-item mt-1">{currentOrg.OrganisationId}</div>
                </div>
            </div>
        </div>
    );
}


