import React, { useState, useEffect } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import InputGroup from 'react-bootstrap/InputGroup'
import { v4 as uuid } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PropertyTemplateDisplay } from './PropertyTemplatesView'
import '../css/annotation-properties.css'
import './list-style.css'
import '../css/modal.css'
import * as Constants from '../constants.js';
import { BsCheck2All } from "react-icons/bs";
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditAnnotationProperties');




export function ListPropertyItem(props) {

    const [parameterList, setParameterList] = useState(props.property.valuesTheSame ? ListItemsToOptions(props?.property?.Items) : []);
    const [inAllAnnotations, setInAllAnnotations] = useState(props.property.inAllAnnotations);
    const [hasBeenEdited, setHasBeenEdited] = useState(props.property.valueHasBeenEdited);

    function onTickAll() {
        props.property.inAllAnnotations = true;
        setInAllAnnotations(true);
    }

    function getClassNameContainer() {
        if (inAllAnnotations) {
            return "annotationPropertyItemContainerBackgroundLight";
        }
        else {
            return "annotationPropertyItemContainerBackgroundDark"
        }
    }
    function getValueColour() {
        if (props.property.valuesTheSame || hasBeenEdited) {
            return '#ffffff';
        }
        else {
            return '#b5b5b5'
        }
    }

    function setItems(items) {
        setParameterList(items);

        props.property.Items = OptionsToListItems(items);
        props.property.valueHasBeenEdited = true;

        if (props.onPropertyChange) {
            props.onPropertyChange(props.property);
        }
        setHasBeenEdited(true);
    }

    function clearItems() {
        setParameterList(null);

        props.property.Items = [];
        props.property.valueHasBeenEdited = true;

        if (props.onPropertyChange) {
            props.onPropertyChange(props.property);
        }
        setHasBeenEdited(true);

    }

    function ListItemsToOptions(listItems) {
        if (listItems) {
            if (listItems.length) {
                return listItems.map(item => ({ label: item.ItemText, value: item.ListPropertyTemplateListItemId }));
            } else if (listItems.ItemText) {
                return [{ label: listItems.ItemText, value: listItems.ListPropertyTemplateListItemId }];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    function OptionsToListItems(options) {
        if (options) {
            if (options.length) {
                return options.map((item, index) => ({ ItemText: item.label, ListPropertyTemplateListItemId: item.value, Order: index }));
            } else if (options.label) {
                return [{ ItemText: options.label, ListPropertyTemplateListItemId: options.value, Order: 0 }];
            }
            else {
                return [];
            }
        }
        else {
            return [];
        }
    }


    return (
        <div className={"annotationPropertyItemContainer m-2 p-2 border rounded border-dark " + getClassNameContainer()}>
            <div className="annotationPropertyItemLabel">
                {props.property?.PropertyTemplate.Title}
            </div>
            {!inAllAnnotations &&
                <div><Button onClick={onTickAll}><BsCheck2All /></Button></div>}
            <div className="annotationPropertyItemValue">
                <Select
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        control: base => ({ ...base, backgroundColor: getValueColour() })
                    }}
                    value={parameterList}
                    onChange={setItems}
                    closeMenuOnSelect={props.property.PropertyTemplate.MultiSelect ? false : true}
                    name="parameterList"
                    className={"basic-multi-select "}
                    isMulti={props.property.PropertyTemplate.MultiSelect}

                    placeholder={props.property.PropertyTemplate.MultiSelect ? "Select Item(s)..." : "Select Item..."}
                    options={ListItemsToOptions(props.property.PropertyTemplate.ListItems)} />

            </div>
            {(!props.property.valuesTheSame && !hasBeenEdited) && <div><Button size="sm" onClick={clearItems} >Clear All</Button></div>}
        </div>
    );
}
//properties []
//templates []
//show bool
export function EditAnnotationProperties(props) {

    const { show, setShow, onSave, templates, propertiesParam, helpContext, parentHelpContext } = props;


    logger.debug("EditAnnotationProperties props", props);

    const [properties, setProperties] = useState([]);
    const [propertyTemplates, setPropertyTemplates] = useState([]);

    const [controlsDisabled, setControlsDisabled] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    React.useEffect(() => {

        //copy properties and templates
        //add ids for list reordereing
        if (show) {
            //templates
            let copiedTemplates = JSON.parse(JSON.stringify(templates));
            //add id for the drag list
            copiedTemplates.forEach((element, index, array) => {
                array[index].draggableId = "temid:" + element.PropertyTemplateId;
            });


            let copiedProperties = [];
            //for each annotation
            for (let anIndex = 0; anIndex < propertiesParam.annotations.length; anIndex++) {
                const an = propertiesParam.annotations[anIndex];

                //loop over its properties

                for (let anPropIndex = 0; anPropIndex < an?.Properties?.length; anPropIndex++) {
                    const annotationProperty = an.Properties[anPropIndex];
                    //is this property already in the array?
                    let existingPropIndex = copiedProperties.findIndex(p => p.PropertyTemplate.PropertyTemplateId === annotationProperty.PropertyTemplate.PropertyTemplateId);
                    //not found so copy and add it
                    if (existingPropIndex === -1) {
                        let newProp = (JSON.parse(JSON.stringify(annotationProperty)));

                        //unique id for the drag list
                        newProp.draggableId = "propid:" + newProp.PropertyId;


                        //add some meta info to keep track
                        //count the number of instances of this property
                        newProp.numInstances = 1;
                        //keep a track of all the annotations this is in
                        newProp.annotationList = [];
                        newProp.annotationList.push(an.AnnotationId);

                        newProp.valuesTheSame = true;
                        newProp.valueHasBeenEdited = false;//for detecting whether property has been left alone

                        //save it for showing in the dialog and editing
                        copiedProperties.push(newProp);

                    }
                    else {

                        //already present from another annotation - update meta info
                        copiedProperties[existingPropIndex].numInstances++;
                        copiedProperties[existingPropIndex].annotationList.push(an.AnnotationId);

                        //if values are the same then see if we have a different one
                        if (copiedProperties[existingPropIndex].valuesTheSame) {

                            //compare values
                            if (copiedProperties[existingPropIndex].PropertyType === Constants.propertyType.Value) {
                                copiedProperties[existingPropIndex].valuesTheSame = copiedProperties[existingPropIndex].Value === annotationProperty.Value;
                                if (!copiedProperties[existingPropIndex].valuesTheSame) {
                                    copiedProperties[existingPropIndex].Value = "";
                                }
                            }
                            else if (copiedProperties[existingPropIndex].PropertyType === Constants.propertyType.List) {

                                if (annotationProperty.Items.length === copiedProperties[existingPropIndex].Items.length) {
                                    //length the same, compare each item
                                    for (let index = 0; index < annotationProperty.Items.length; index++) {
                                        if (annotationProperty.Items[index].ListPropertyTemplateListItemId !== copiedProperties[existingPropIndex].Items[index].ListPropertyTemplateListItemId) {
                                            copiedProperties[existingPropIndex].valuesTheSame = false;
                                            break;
                                        }
                                    }
                                    if (!copiedProperties[existingPropIndex].valuesTheSame) {
                                        copiedProperties[existingPropIndex].Items = [];
                                    }
                                }
                                else {
                                    copiedProperties[existingPropIndex].valuesTheSame = false;
                                }
                            }
                        }

                    }

                }

            }


            //determine if prop is on every annotation
            for (let propIndex = 0; propIndex < copiedProperties.length; propIndex++) {
                copiedProperties[propIndex].inAllAnnotations = copiedProperties[propIndex].numInstances === propertiesParam.annotations.length;
            }

            //add id for the drag list
            for (let index = 0; index < copiedProperties.length; index++) {
                const property = copiedProperties[index];

                //remove this from the template array - has already been added
                const availIndex = copiedTemplates.findIndex((item) => item.PropertyTemplateId === property.PropertyTemplate.PropertyTemplateId);
                if (availIndex >= 0) {
                    copiedTemplates.splice(availIndex, 1)
                }
            }

            setProperties(copiedProperties);
            setPropertyTemplates(copiedTemplates);

        }
        else {
            setProperties([]);
            setPropertyTemplates([]);
        }
    }, [propertiesParam, templates, show])


    //just hide the dialog - cancel.
    function onClose() {

        setControlsDisabled(false);
        setShow(false);
    }

    function onSaveProperties() {
        setControlsDisabled(true);
        onSave({ properties: properties, selectedAnnotationIds: propertiesParam.annotations.map(a => a.AnnotationId) });

    }

    function reorderProperties(startIndex, endIndex) {

        const reordered = Array.from(properties);
        const [removed] = reordered.splice(startIndex, 1);
        reordered.splice(endIndex, 0, removed);

        setProperties(reordered);
    }

    function moveToProperties(templateIndex, propertyIndex) {

        //create property from template
        const propertiesCopy = Array.from(properties);

        //value
        let newProperty = {
            AnnotationId: "00000000-0000-0000-0000-000000000000",
            PropertyId: "00000000-0000-0000-0000-000000000000",
            PropertyTemplate: JSON.parse(JSON.stringify(propertyTemplates[templateIndex])),
            PropertyType: 0,
            //for dragdrop list 
            draggableId: "prop" + uuid(),
            valueHasBeenEdited: true,
            valuesTheSame: true,
            annotationList: propertiesParam.annotations.map(a => a.AnnotationId),
            inAllAnnotations: true
        };
        if (propertyTemplates[templateIndex]?.PropertyType === 0) {
            newProperty.Value = "";
        }
        //list
        else {
            newProperty.Items = [];
            newProperty.PropertyType = 1;
        }

        //add to properties
        propertiesCopy.splice(propertyIndex, 0, newProperty);
        setProperties(propertiesCopy);

        //remove from templates
        const templatesCopy = Array.from(propertyTemplates);
        templatesCopy.splice(templateIndex, 1);
        setPropertyTemplates(templatesCopy);
    }

    function moveToTemplates(templateIndex, propertyIndex) {
        const propertiesCopy = Array.from(properties);
        const templatesCopy = Array.from(propertyTemplates);
        const newTemplate = JSON.parse(JSON.stringify(properties[propertyIndex].PropertyTemplate));
        newTemplate.draggableId = "temp" + newTemplate.PropertyTemplateId;

        //add back to templates
        templatesCopy.splice(templateIndex, 0, newTemplate);
        setPropertyTemplates(templatesCopy);

        //remove from properties
        propertiesCopy.splice(propertyIndex, 1);
        setProperties(propertiesCopy);
    }

    function onDragEnd(result) {

        //source - from the annotation properties
        if (result.source.droppableId === "properties") {

            if (!result.destination) {
                return;
            }
            else if (result.destination.droppableId === "templates") {
                moveToTemplates(result.destination ? result.destination.index : 0, result.source.index);
                return;
            }
            else if (result.destination.droppableId === "properties") {
                if (result.source.index !== result.destination.index) {
                    reorderProperties(result.source.index, result.destination.index);
                    return;
                }
            }
        }
        //source - from the templates
        else if (result.source.droppableId === "templates") {

            if (!result.destination) {
                return;
            }
            else if (result.destination.droppableId === "properties") {
                moveToProperties(result.source.index, result.destination.index);
                return;
            }
        }

    }


    function getDraggingOverStyleRuleClassName(snapshot) {

        if (snapshot.isDraggingOver && !snapshot.draggingFromThisWith) {
            return " isDraggingOverStyleRule "
        }

        return "";
    }


    function TemplateItem(itemProps) {

        return (
            <PropertyTemplateDisplay propertyTemplate={itemProps.template} className={"m-2 p-2 border rounded border-dark bg-light"} />
        );
    }


    function ValuePropertyItem(itemProps) {

        const [valid, setValid] = useState(true);
        const [inAllAnnotations, setInAllAnnotations] = useState(itemProps.property.inAllAnnotations);
        const [hasBeenEdited, setHasBeenEdited] = useState(itemProps.property.valueHasBeenEdited);
        const [value, setValue] = useState(itemProps.property.Value);
        function onTickAll() {
            itemProps.property.inAllAnnotations = true;
            setInAllAnnotations(true);
        }


        function handleChange(event) {
            itemProps.property.Value = event.target.value;
            itemProps.property.valueHasBeenEdited = true;
            validateChange(event.target.value)
            setHasBeenEdited(true);
            setValue(event.target.value);
        }

        function clearValues() {
            itemProps.property.Value = "";
            itemProps.property.valueHasBeenEdited = true;
            setHasBeenEdited(true);
            setValue("");
            validateChange("")
        }

        function validateChange(value) {

            //text
            if (itemProps.property.PropertyTemplate.ValueType === 0 || value === "") {
                setValid(true);
            }
            //int
            else {
                let num = Number(value);
                const isValid = !isNaN(num) && value !== "";
                try {
                    itemProps.transformStep.isInvalid = !isValid;
                }
                catch (e) { }

                setValid(isValid);
            }

        }

        useEffect(() => {
            validateChange(itemProps.property.Value);
        });

        function getClassNameContainer() {
            if (itemProps.property.inAllAnnotations) {
                return "annotationPropertyItemContainerBackgroundLight";
            }
            else {
                return "annotationPropertyItemContainerBackgroundDark"
            }
        }
        function getClassNameValue() {
            if (itemProps.property.valuesTheSame || itemProps.property.valueHasBeenEdited) {
                return "annotationPropertyItemContainerBackgroundLight";
            }
            else {
                return "annotationPropertyItemContainerBackgroundDark"
            }
        }
        return (
            <div className={"annotationPropertyItemContainer m-2 p-2 border rounded border-dark " + getClassNameContainer()} >
                <div className="annotationPropertyItemLabel">
                    {itemProps.property.PropertyTemplate.Title}
                </div>
                {!inAllAnnotations &&
                    <div><Button onClick={onTickAll}><BsCheck2All /></Button></div>}
                <div className="annotationPropertyItemValue">
                    <InputGroup hasValidation>
                        <InputGroup.Text size="sm">{"Value"}</InputGroup.Text>
                        <Form.Control
                            className={getClassNameValue()}
                            required
                            type="text"
                            name="a"
                            isInvalid={!valid}
                            value={value}
                            onChange={handleChange} />


                        {(!itemProps.property.valuesTheSame && !hasBeenEdited) && <div><Button onClick={clearValues} >Clear All</Button></div>}
                    </InputGroup>
                </div>
            </div>
        );
    }


    function PropertyComponent(itemProps) {
        switch (itemProps.property.PropertyTemplate.PropertyType) {

            //value
            case 0:
                return <ValuePropertyItem {...itemProps} />
            //list
            case 1:
                return <ListPropertyItem {...itemProps} />
            default:
                return <></>
        }
    }


    //static row in the list
    function TemplateListRow(itemProps) {
        const item = itemProps.data[itemProps.index];
        return (
            <Draggable draggableId={item.draggableId} index={itemProps.index} key={item.draggableId}>
                {(provided, snapshot) =>
                (

                    <div
                        // style={getStyle(provided, props.style, snapshot.isDragging)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <TemplateItem className={snapshot.isDragging ? "styleRuleItemDragging" : "styleRuleItem"} template={item} />
                    </div>

                )}
            </Draggable>
        );
    }

    function PropertyListRow(itemProps) {
        const item = itemProps.data[itemProps.index];
        return (
            <Draggable draggableId={item.draggableId} index={itemProps.index} key={item.draggableId}>
                {(provided, snapshot) =>
                (

                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <PropertyComponent property={item} />
                    </div>

                )}
            </Draggable>
        );
    }

    // This method is needed for rendering clones of draggables, as they are being dragged about
    const getRenderItemTemplates = (items, style) => (provided, snapshot, rubric) => {
        const item = items[rubric.source.index];
        return (
            <>
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <TemplateItem className={snapshot.isDragging ? "styleRuleItemDragging" : "styleRuleItem"} template={item} />
                </div>
            </>
        );
    };
    // This method is needed for rendering clones of draggables, as they are being dragged about
    const getRenderItemProperties = (items, style) => (provided, snapshot, rubric) => {
        const item = items[rubric.source.index];
        return (
            <>
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <PropertyComponent property={item} />
                </div>
            </>
        );
    };

    function TemplatesList(itemProps) {
        return (
            <Droppable
                renderClone={getRenderItemTemplates(itemProps.items, itemProps.className)}
                droppableId="templates"
            >
                {(provided, snapshot) => (
                    <ul
                        ref={provided.innerRef}
                        className={"styleRuleList" + getDraggingOverStyleRuleClassName(snapshot)}
                    >
                        {itemProps.items.map((item, index) => {
                            return (
                                <li key={item.draggableId}>
                                    <TemplateListRow data={itemProps.items} index={index} />
                                </li>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>
        );
    }


    function PropertiesList(itemProps) {
        return (
            <Droppable
                renderClone={getRenderItemProperties(itemProps.items, itemProps.className)}
                droppableId="properties"
            >
                {(provided, snapshot) => (
                    <ul
                        ref={provided.innerRef}
                        className={"styleRuleList" + getDraggingOverStyleRuleClassName(snapshot)}
                    >
                        {itemProps.items.map((item, index) => {
                            return (
                                <li key={item.draggableId}>
                                    <PropertyListRow data={itemProps.items} index={index} />
                                </li>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>
        );
    }


    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-1000w"
        >
            <Modal.Header closeButton>
                {propertiesParam?.annotations?.length > 1 ?
                    <Modal.Title>Edit Multiple Annotations</Modal.Title> :
                    <Modal.Title>Edit Single Annotation</Modal.Title>}

            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container >
                        <Row>
                            <div className="ps-4 pb-1">
                                Drag templates across to create properties, then fill in the appropriate values.
                                <br />
                                Drag back to delete.
                                <br />
                                Optionally, enter values for the properties.
                                {(propertiesParam?.annotations?.length > 1) &&
                                    <>
                                        <br />
                                        <br />
                                        <h5>Editing Multiple Selected Annotations:</h5>
                                        A grey property background indicates that property is not present in all selected annotations. Click on the <BsCheck2All /> button to copy it and its value to all selected annotations.
                                        <br />
                                        A grey value background indicates the selected properties have different values. A save in this state will leave the values unchanged. Edit the value to apply it to all the selected annotations.
                                    </>}
                            </div>
                        </Row>

                        <Row>
                            <Col>
                                <div className="project-graph-title" >Annotation Properties:</div>
                                <PropertiesList items={properties} />
                            </Col>
                            <Col>
                                <div className="project-graph-title" >Property Templates:</div>
                                <TemplatesList items={propertyTemplates} />
                            </Col>
                        </Row>
                    </Container>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={controlsDisabled} onClick={onSaveProperties}>Save</Button>
                <Button variant="secondary" disabled={controlsDisabled} onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>


    );
}