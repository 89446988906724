import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import AddUser from './AddUser';
import Button from 'react-bootstrap/Button'
import EditUser from './EditUser'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { CanEditUser, CanAddUser } from "../App/UserPermissions";
import { SystemPermissionsDisplay } from '../Components/SystemPermissionsDisplay';
import Select from 'react-select'
import { LicenseListUser } from '../Components/LicenseListUser';
import { UserDetails } from './UserDetails';
import { GenericPermissionsList } from '../Components/PermissionList';
import { fullModelTitle, fullProjectTitle } from '../App/Utils'
import { CanGenerateUserApiKey } from '../App/UserPermissions';
import { CognitoInfo } from './CognitoInfo'

import { RiDeleteBinLine } from "react-icons/ri";
import { UserStatus } from '../App/Permissions'

import '../css/page.css'
import '../css/lists.css'

import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('UsersPage');




function GroupsToOptions(groups) {
    let options = [];

    if (groups) {
        groups.forEach(group =>
            options.push({ value: group.GroupId, label: group.Title })
        );
    }
    return options;
}




function UsersPage(props) {

    const { setMainPageHelpContext } = props;

    const orgsInfo = useSelector(selectOrganisationsInfo);

    const currentOrg = orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo?.currentOrgId);


    logger.debug("orgsInfo", orgsInfo);

    const list = useRef();
    const dispatch = useDispatch();


    const [cognitoInfo, setCognitoInfo] = useState(null);
    const [cognitoDevices, setCognitoDevices] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState();
    const [showEditUser, setShowEditUser] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState(currentOrg?.Users ? currentOrg?.Users : []);

    const [showFilter, setShowFilter] = useState(false);
    const [userNameFilter, setUserNameFilter] = useState("");
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [showInActiveUsers, setShowInActiveUsers] = useState(false);
    let availableGroupsOptions = GroupsToOptions(currentOrg?.Groups);

    const helpContext = BeaconDialogMap.OrganisationUsersPage;

    React.useEffect(() => {
        BeaconSetSuggestions(helpContext);
        setMainPageHelpContext(helpContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //save to local storage so that controls can be repopulated if page is reloaded.
    React.useEffect(() => {
        if (selectedUserId) {
            localStorage.setItem("usersPageSelectedUserId", selectedUserId);
        }
    }, [selectedUserId])

    React.useEffect(() => {

        if (currentOrg?.Users?.length) {


            //load selected user from storage
            const savedSelectedUserId = localStorage.getItem("usersPageSelectedUserId");

            //in current list?
            if (currentOrg?.Users.find(u => u.UserId === savedSelectedUserId) !== null) {
                setSelectedUserId(savedSelectedUserId);
            }
            else {
                setSelectedUserId(currentOrg?.Users[0]?.UserId);
            }

        }
        filterUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentOrg?.Users])

    React.useEffect(() => {

        //if list up and running
        if (list.current && selectedUserId) {
            scrollIntoView();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, list.current, filteredUsers?.length])

    function scrollIntoView() {
        for (let index = 0; index < filteredUsers?.length; index++) {
            const element = filteredUsers[index];
            if (element.UserId === selectedUserId) {
                list.current.scrollToItem(index, "center");
                break;
            }
        }
    }

    React.useEffect(() => {

        filterUsers();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userNameFilter, selectedGroups, showInActiveUsers])

    function filterUsers() {
        let users = filterUserNames(userNameFilter, currentOrg?.Users);

        //add current user if not in list and is in same org
        if (orgsInfo?.currentUser?.OrganisationId === currentOrg.OrganisationId) {
            if (!users.find(u => u.UserId === orgsInfo?.currentUser?.UserId)) {
                logger.debug("orgsInfo?.currentUser", orgsInfo?.currentUser);
                users = [...users, orgsInfo?.currentUser];
            }
        }

        users = users.filter(u => u.Status === UserStatus.Active || (u.Status === UserStatus.Inactive && showInActiveUsers));

        setFilteredUsers(filterGroups(selectedGroups, users));
    }

    function filterGroups(groupFilter, userSet) {
        let users = [];

        if (groupFilter?.length) {

            for (let index = 0; index < userSet.length; index++) {
                const user = userSet[index];

                if (groupFilter.every(group => {
                    return user.Groups.find(g => g.GroupId === group.value);
                })) {
                    users.push(user);
                }
            }
        }
        else {
            users = userSet;
        }

        return users;
    }


    function filterUserNames(filter, userSet) {

        let users = [];

        if (filter === "") {
            users = userSet;
        }
        else {
            userSet.forEach(user => {
                const reg = new RegExp(filter, 'gi');
                if (user.UserName.match(reg)) {
                    users.push(user);
                }
            })
        }

        return users;
    }

    function onEditUser() {

        if (selectedUserId) {
            setShowEditUser(true);
        }
    }

    function onAddUser() {
        setShowAddUser(true);
    }



    let itemClassName = "me-1 mt-0 p-1 border border-light";

    function GroupsDisplay(props) {
        const styleItemNormal = { fontSize: 12, backgroundColor: "#EEEEEE" };
        const styleItemSelected = { fontSize: 12, foregroundColor: "#FFFFFF", backgroundColor: "#111111" };
        const styleitem = props.isSelected ? styleItemSelected : styleItemNormal;

        const items = props.groups ? props.groups.map((group) =>
            <div key={group.Title} className={itemClassName} style={styleitem}>
                {group.Title}
            </div>
        ) : <></>


        return (
            <div className="d-flex flex-wrap ps-0 ms-4 mb-0">
                {items}
            </div>
        );
    }

    const ListRow = ({ index, style }) => (
        <div role="button" style={{
            ...style,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "hidden"
        }}

            className={
                selectedUserId === filteredUsers[index].UserId ?
                    "list-item list-item-selected" :
                    "list-item list-item-not-selected"
            }

            onClick={() => { setSelectedUserId(filteredUsers[index].UserId) }}
            onDoubleClick={() => {
                if (CanEditUser(orgsInfo.currentUser, selectedUser())) {
                    setSelectedUserId(filteredUsers[index].UserId);
                    onEditUser();
                }
            }} >
            <div className="list-item-split-row">
                <div className="list-item-title">{filteredUsers[index].UserName}</div>
                {filteredUsers[index].Status === UserStatus.Inactive && <RiDeleteBinLine />}
            </div>
            <div className="list-item-split-row">
                <div >Groups</div>
                <GroupsDisplay groups={filteredUsers[index]?.Groups} isSelected={selectedUserId === filteredUsers[index].UserId} />
            </div>
            <div className="list-item-split-row">
                <div >System Permissions</div>
                <SystemPermissionsDisplay permissions={filteredUsers[index]?.Permissions} isSelected={selectedUserId === filteredUsers[index].UserId} />
            </div>
        </div>
    );

    function toggleFilter() {
        if (showFilter) {
            setUserNameFilter("");
            setSelectedGroups([]);
        }
        setShowFilter(!showFilter);
    }

    function selectedUser() {
        return filteredUsers?.find(u => u.UserId === selectedUserId);
    }

    return (
        <div className="page-bottom-panel">
            <div className="page-left-panel">
                <div className="list-title-and-list list-full-height list-wide">
                    <div className="list-title-and-buttons">
                        <div className="list-title" >Users:</div>
                        <div className="list-buttons">
                            <Button className="list-top-button" onClick={toggleFilter}>{showFilter ? <RiFilterOffLine /> : <RiFilterLine />}</Button>
                            {CanEditUser(orgsInfo.currentUser, selectedUser()) &&

                                <Button className="list-top-button" onClick={onEditUser}>Edit</Button>
                            }
                            {CanAddUser(orgsInfo.currentUser) &&
                                <Button className="list-top-button" onClick={onAddUser}>Add</Button>

                            }
                        </div>
                    </div>


                    {showFilter &&
                        <>
                            <div className="list-filter">
                                User:
                                <input autoFocus type="text" onChange={(event) => { setUserNameFilter(event.target.value) }} />
                            </div>
                            <div className="list-filter">
                                Groups:
                                <Select
                                    value={selectedGroups}
                                    onChange={setSelectedGroups}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="groups"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select groups..."
                                    options={availableGroupsOptions} />
                            </div>
                            {(currentOrg?.Users.find(u => u.Status === UserStatus.Inactive)) &&
                                <div className="list-item-split-row">
                                    <div className="list-item-split-row-item-left">Show Inactive Users:</div>
                                    <div className="ms-2 pt-1">
                                        <input
                                            type='checkbox'
                                            checked={showInActiveUsers}
                                            onChange={(event) => {
                                                setShowInActiveUsers(event.currentTarget.checked)
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                        </>
                    }

                    <div className="list-container">
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    ref={list}
                                    height={height}
                                    itemCount={filteredUsers?.length}
                                    itemSize={100}
                                    width={390}
                                    className="list-background-style"
                                >
                                    {ListRow}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
            <div className="page-content-panel">
                <LicenseListUser
                    parentHelpContext={helpContext}
                    currentOrg={currentOrg}
                    orgs={orgsInfo?.organisations}
                    licenses={selectedUser()?.Licenses}
                    currentUser={orgsInfo?.currentUser}
                    user={selectedUser()
                    }
                />
                <GenericPermissionsList
                    showUploadPermission={true}
                    title={"Project Permissions"}
                    permissionArray={
                        selectedUser()?.ProjectPermissions.map((p, index) => ({
                            "title": fullProjectTitle(p.ProjectId, orgsInfo.organisations, selectedUser().OrganisationId),
                            "Permissions": p.Permissions,
                            "Id": index
                        }))
                    }
                />
                <GenericPermissionsList
                    showUploadPermission={false}
                    title={"Model Permissions"}
                    permissionArray={
                        selectedUser()?.ModelPermissions.map((p, index) => ({
                            "title": fullModelTitle(p.ModelId, orgsInfo.organisations, selectedUser().OrganisationId),
                            "Permissions": p.Permissions,
                            "Id": index
                        }))
                    }

                />
                {CanGenerateUserApiKey(orgsInfo?.currentUser) &&
                    <UserDetails currentUser={orgsInfo?.currentUser} user={selectedUser()}
                        cognitoInfo={cognitoInfo} setCognitoInfo={setCognitoInfo}
                        cognitoDevices={cognitoDevices} setCognitoDevices={setCognitoDevices}
                    />
                }
                {cognitoInfo && <CognitoInfo cognito={cognitoInfo} devices={cognitoDevices} />}

            </div>

            <EditUser
                helpContext={BeaconDialogMap.EditUserDialog}
                parentHelpContext={helpContext}
                currentUser={orgsInfo?.currentUser}
                show={showEditUser}
                setShow={setShowEditUser}
                users={currentOrg.Users}
                selectedUserId={selectedUserId}
                currentOrg={currentOrg}

            />
            <AddUser
                helpContext={BeaconDialogMap.AddUserDialog}
                parentHelpContext={helpContext}
                currentUser={orgsInfo?.currentUser}
                show={showAddUser}
                setShow={setShowAddUser}
                setSelectedUserId={setSelectedUserId}
                currentOrg={currentOrg}

            />
        </div>
    );

}

export default UsersPage;