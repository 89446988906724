import { Auth } from 'aws-amplify';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { GetOrganisations } from '../Api/OrganisationApi'
import { GetProject } from '../Api/ProjectApi'
import { GetAnnotations } from '../Api/AnnotationApi';
import { GetCurrentUser } from '../Api/UserApi'
import { GetConnectedWebSocketUsers } from '../Api/SystemApi'

import { current } from '@reduxjs/toolkit' //for debugging state - eg logger.debug(current(state.projects));
import { Logger } from 'aws-amplify';
const logger = new Logger('orgsInfoSlice');

export const getConnectedWebSocketUsers = createAsyncThunk('usersInfo/getConnectedWebSocketUsers', async () => {
    return await GetConnectedWebSocketUsers();
}
)


export const signOut = createAsyncThunk('organisationsInfo/signOut', async () => {
    return await Auth.signOut();
});

export const getCurrentUser = createAsyncThunk('organisationsInfo/getCurrentUser', async () => {
    return await GetCurrentUser();
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
});


export const getOrganisations = createAsyncThunk('organisationsInfo/getOrganisations', async () => {
    return await GetOrganisations();
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
})

export const getProject = createAsyncThunk('organisationsInfo/getProject', async (id, obj) => {

    return await GetProject(id);
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
})

export const getAnnotations = createAsyncThunk('organisationsInfo/getAnnotations', async (info, obj) => {

    return await GetAnnotations(info.AnnotationSetId, info.AnnotationCursorId, 100);
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
})



export const organisationsInfoSlice = createSlice({
    name: 'organisationsInfo',
    initialState: {
        connectedWebSocketUsers: [],
        currentUser: null,

        organisations: [],
        currentOrgId: null,
        initialised: false,
        organisationsRetrieved: false,

    },
    reducers: {
        clearOrganisationsInfo: state => {
            state.organisations = [];
            state.currentOrgId = null;
            state.currentUser = null;
            state.organisationsRetrieved = false;
            state.connectedWebSocketUsers = [];
        },
        stateSetCurrentOrg: (state, action) => {
            state.currentOrgId = action.payload;
            localStorage.setItem("currentOrgId", state.organisations.find(o => o.OrganisationId === action.payload)?.OrganisationId);
        },
        stateDeleteBuildLogRecord: (state, action) => {

            state.organisations.forEach(org => {
                const index = org.BuildLogRecords.findIndex(item => item.BuildLogRecordId === action.payload);
                if (index >= 0) {
                    org.BuildLogRecords.splice(index, 1);
                }
            });

        },
        stateUpdateBuildLogRecord: (state, action) => {

            const buildLogRecord = action.payload;
            const org = state.organisations.find(org => org.OrganisationId === buildLogRecord.OrganisationId);

            if (org) {

                let index = org?.BuildLogRecords.findIndex((item) => item.BuildLogRecordId === buildLogRecord.BuildLogRecordId);
                if (index >= 0) {
                    org.BuildLogRecords[index] = { ...org?.BuildLogRecords[index], ...buildLogRecord };
                }
                else {
                    org.BuildLogRecords.push(action.payload);
                }
            }

        },
        stateDeleteUser: (state, action) => {

            state.organisations.forEach(org => {
                const index = org.Users.findIndex(item => item.UserId === action.payload);
                if (index >= 0) {
                    org.Users.splice(index, 1);
                }
            });
        },
        stateUpdateUser: (state, action) => {

            const user = action.payload;

            const org = state.organisations.find(org => org.OrganisationId === user.OrganisationId)
            if (org) {

                //update changed user in parent user list
                let index = org.Users.findIndex((item) => item.UserId === user.UserId);
                if (index >= 0) {
                    org.Users[index] = user;
                }
                else {
                    org.Users.push(user);
                }
                org.Users.sort((a, b) => {

                    var nameA = a.UserName.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.UserName.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;

                });


            }
            if (state.currentUser.UserId === user.UserId) {
                logger.debug("Setcurrent user");
                state.currentUser = user;
            }

        },
        stateDeleteGroup: (state, action) => {
            const groupId = action.payload;

            state.organisations.forEach(org => {
                const index = org?.Groups.findIndex(g => g.GroupId === groupId);
                if (index >= 0) {
                    org?.Groups.splice(index, 1);
                }
            });
        },
        stateDeleteProject: (state, action) => {
            const projectId = action.payload;

            state.organisations.forEach(org => {
                const index = org?.Projects.findIndex(item => item.ProjectId === projectId);
                if (index >= 0) {
                    org?.Projects.splice(index, 1);
                }
            });
        },
        stateUpdateModelGroupPermission: (state, action) => {
            //find the model and group pertaining and add it to them / update if existing
            const modelId = action.payload.ModelId;
            const groupId = action.payload.GroupId;
            const permission = action.payload;

            state.organisations.forEach(org => {
                const groupIndex = org.Groups.findIndex(g => g.GroupId === groupId);
                if (groupIndex >= 0) {
                    const permissionIndex = org.Groups[groupIndex].ModelPermissions.findIndex(p => p.ModelGroupPermissionId === permission.ModelGroupPermissionId);
                    if (permissionIndex >= 0) {
                        org.Groups[groupIndex].ModelPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Groups[groupIndex].ModelPermissions.push(permission);
                    }
                }
                org.Projects.forEach(project => {
                    const modelIndex = project.Models.findIndex(m => m.ModelId === modelId);
                    if (modelIndex >= 0) {
                        const permissionIndex = project.Models[modelIndex].GroupPermissions.findIndex(p => p.ModelGroupPermissionId === permission.ModelGroupPermissionId);
                        if (permissionIndex >= 0) {
                            project.Models[modelIndex].GroupPermissions[permissionIndex] = permission;
                        }
                        else {
                            project.Models[modelIndex].GroupPermissions.push(permission);
                        }
                    }
                });
            });
        },
        stateUpdateModelUserPermission: (state, action) => {
            //find the model and user pertaining and add it to them / update if existing
            const modelId = action.payload.ModelId;
            const userId = action.payload.UserId;
            const permission = action.payload;

            state.organisations.forEach(org => {
                const userIndex = org.Users.findIndex(g => g.UserId === userId);
                if (userIndex >= 0) {
                    const permissionIndex = org.Users[userIndex].ModelPermissions.findIndex(p => p.ModelUserPermissionId === permission.ModelUserPermissionId);
                    if (permissionIndex >= 0) {
                        org.Users[userIndex].ModelPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Users[userIndex].ModelPermissions.push(permission);
                    }
                }
                org.Projects.forEach(project => {
                    const modelIndex = project.Models.findIndex(m => m.ModelId === modelId);
                    if (modelIndex >= 0) {
                        const permissionIndex = project.Models[modelIndex].UserPermissions.findIndex(p => p.ModelUserPermissionId === permission.ModelUserPermissionId);
                        if (permissionIndex >= 0) {
                            project.Models[modelIndex].UserPermissions[permissionIndex] = permission;
                        }
                        else {
                            project.Models[modelIndex].UserPermissions.push(permission);
                        }
                    }
                });
            });
        },
        stateUpdateProjectUserPermission: (state, action) => {

            //find the project and user pertaining and add it to them / update
            const projectId = action.payload.ProjectId;
            const userId = action.payload.UserId;
            const permission = action.payload;

            state.organisations.forEach(org => {
                const userIndex = org.Users.findIndex(g => g.UserId === userId);
                if (userIndex >= 0) {
                    const permissionIndex = org.Users[userIndex].ProjectPermissions.findIndex(p => p.ProjectUserPermissionId === permission.ProjectUserPermissionId);
                    if (permissionIndex >= 0) {
                        org.Users[userIndex].ProjectPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Users[userIndex].ProjectPermissions.push(permission);
                    }

                    if (state.currentUser?.UserId === org.Users[userIndex].UserId) {
                        state.currentUser = org.Users[userIndex];
                    }
                }
                const projectIndex = org.Projects.findIndex(p => p.ProjectId === projectId);
                if (projectIndex >= 0) {

                    const permissionIndex = org.Projects[projectIndex].UserPermissions.findIndex(p => p.ProjectUserPermissionId === permission.ProjectUserPermissionId);

                    if (permissionIndex >= 0) {
                        org.Projects[projectIndex].UserPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Projects[projectIndex].UserPermissions.push(permission);
                    }
                }

            });
        },
        stateUpdateProjectGroupPermission: (state, action) => {
            //find the project and group pertaining and add it to them
            const projectId = action.payload.ProjectId;
            const groupId = action.payload.UserId;
            const permission = action.payload;

            state.organisations.forEach(org => {
                const groupIndex = org.Groups.findIndex(g => g.GroupId === groupId);
                if (groupIndex >= 0) {
                    const permissionIndex = org.Groups[groupIndex].ProjectPermissions.findIndex(p => p.ProjectGroupPermissionId === permission.ProjectGroupPermissionId);
                    if (permissionIndex >= 0) {
                        org.Groups[groupIndex].ProjectPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Groups[groupIndex].ProjectPermissions.push(permission);
                    }
                }
                const projectIndex = org.Projects.findIndex(p => p.ProjectId === projectId);
                if (projectIndex >= 0) {
                    const permissionIndex = org.Projects[projectIndex].GroupPermissions.findIndex(p => p.ProjectGroupPermissionId === permission.ProjectGroupPermissionId);
                    if (permissionIndex >= 0) {
                        org.Projects[projectIndex].GroupPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Projects[projectIndex].GroupPermissions.push(permission);
                    }
                }
            });
        },


        stateUpdateAnnotationSetUserPermission: (state, action) => {

            //find the project and user pertaining and add it to them / update

            const userId = action.payload.UserId;
            const permission = action.payload;

            state.organisations.forEach(org => {
                const userIndex = org.Users.findIndex(g => g.UserId === userId);
                if (userIndex >= 0) {
                    const permissionIndex = org.Users[userIndex].AnnotationSetPermissions.findIndex(p => p.AnnotationSetUserPermissionId === permission.AnnotationSetUserPermissionId);
                    if (permissionIndex >= 0) {
                        org.Users[userIndex].AnnotationSetPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Users[userIndex].AnnotationSetPermissions.push(permission);
                    }

                    if (state.currentUser?.UserId === org.Users[userIndex].UserId) {
                        state.currentUser = org.Users[userIndex];
                    }
                }

                //find the model with this annotation set
                for (let projectIndex = 0; projectIndex < org.Projects.length; projectIndex++) {
                    const project = org.Projects[projectIndex];

                    for (let modelIndex = 0; modelIndex < project.Models.length; modelIndex++) {
                        const model = project.Models[modelIndex];

                        for (let annotationSetIndex = 0; annotationSetIndex < model.AnnotationSets.length; annotationSetIndex++) {
                            const annotationSet = model.AnnotationSets[annotationSetIndex];

                            if (annotationSet.AnnotationSetId === permission.AnnotationSetId) {

                                const permissionIndex = annotationSet.UserPermissions.findIndex(p => p.AnnotationSetUserPermissionId === permission.AnnotationSetUserPermissionId);

                                if (permissionIndex >= 0) {
                                    annotationSet.UserPermissions[permissionIndex] = permission;
                                }
                                else {
                                    annotationSet.UserPermissions.push(permission);
                                }
                            }
                        }
                    }
                }

            });
        },
        stateUpdateAnnotationSetGroupPermission: (state, action) => {

            //find the project and user pertaining and add it to them / update
            const groupId = action.payload.GroupId;
            const permission = action.payload;

            logger.debug(current(state.organisations));

            state.organisations.forEach(org => {
                const groupIndex = org.Groups.findIndex(g => g.GroupId === groupId);
                if (groupIndex >= 0) {
                    const permissionIndex = org.Groups[groupIndex].AnnotationSetPermissions.findIndex(p => p.AnnotationSetGroupPermissionId === permission.AnnotationSetGroupPermissionId);
                    if (permissionIndex >= 0) {
                        org.Groups[groupIndex].AnnotationSetPermissions[permissionIndex] = permission;
                    }
                    else {
                        org.Groups[groupIndex].AnnotationSetPermissions.push(permission);
                    }
                }

                //find the model with this annotation set
                for (let projectIndex = 0; projectIndex < org.Projects.length; projectIndex++) {
                    const project = org.Projects[projectIndex];

                    for (let modelIndex = 0; modelIndex < project.Models.length; modelIndex++) {
                        const model = project.Models[modelIndex];

                        for (let annotationSetIndex = 0; annotationSetIndex < model.AnnotationSets.length; annotationSetIndex++) {
                            const annotationSet = model.AnnotationSets[annotationSetIndex];

                            if (annotationSet.AnnotationSetId === permission.AnnotationSetId) {

                                const permissionIndex = annotationSet.GroupPermissions.findIndex(p => p.AnnotationSetGroupPermissionId === permission.AnnotationSetGroupPermissionId);

                                if (permissionIndex >= 0) {
                                    annotationSet.GroupPermissions[permissionIndex] = permission;
                                }
                                else {
                                    annotationSet.GroupPermissions.push(permission);
                                }
                            }
                        }
                    }
                }

            });
        },

        stateDeleteAnnotationSetUserPermission: (state, action) => {
            //find the annotation set and user pertaining and remove it from them
            const permissionId = action.payload;

            state.organisations.forEach(org => {
                org.Users.forEach(user => {
                    const permissionIndex = user.AnnotationSetPermissions.findIndex(p => p.AnnotationSetUserPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        user.AnnotationSetPermissions.splice(permissionIndex, 1);

                        if (state.currentUser?.UserId === user.UserId) {
                            state.currentUser = user;
                        }

                    }
                });


                //find the model with this annotation set
                for (let projectIndex = 0; projectIndex < org.Projects.length; projectIndex++) {
                    const project = org.Projects[projectIndex];

                    for (let modelIndex = 0; modelIndex < project.Models.length; modelIndex++) {
                        const model = project.Models[modelIndex];

                        for (let annotationSetIndex = 0; annotationSetIndex < model.AnnotationSets.length; annotationSetIndex++) {
                            const annotationSet = model.AnnotationSets[annotationSetIndex];
                            const permissionIndex = annotationSet.UserPermissions.findIndex(p => p.AnnotationSetUserPermissionId === permissionId);

                            if (permissionIndex >= 0) {
                                annotationSet.UserPermissions.splice(permissionIndex, 1);
                            }
                        }
                    }
                }




            });
        },

        stateDeleteAnnotationSetGroupPermission: (state, action) => {
            //find the annotation set and user pertaining and remove it from them
            const permissionId = action.payload;

            state.organisations.forEach(org => {
                org.Groups.forEach(user => {
                    const permissionIndex = user.AnnotationSetPermissions.findIndex(p => p.AnnotationSetGroupPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        user.AnnotationSetPermissions.splice(permissionIndex, 1);
                    }
                });


                //find the model with this annotation set
                for (let projectIndex = 0; projectIndex < org.Projects.length; projectIndex++) {
                    const project = org.Projects[projectIndex];

                    for (let modelIndex = 0; modelIndex < project.Models.length; modelIndex++) {
                        const model = project.Models[modelIndex];

                        for (let annotationSetIndex = 0; annotationSetIndex < model.AnnotationSets.length; annotationSetIndex++) {
                            const annotationSet = model.AnnotationSets[annotationSetIndex];
                            const permissionIndex = annotationSet.GroupPermissions.findIndex(p => p.AnnotationSetGroupPermissionId === permissionId);

                            if (permissionIndex >= 0) {
                                annotationSet.GroupPermissions.splice(permissionIndex, 1);
                            }
                        }
                    }
                }




            });
        },


        stateUpdateUserShare: (state, action) => {

            const userShare = action.payload;

            //shared in or out?
            const orgSharedTo = state.organisations.find(o => o.OrganisationId === userShare.OrgSharedToId);
            const orgSharedFrom = state.organisations.find(o => o.OrganisationId === userShare.OrgSharedFromId);

            if (orgSharedTo) {
                const shareInIndex = orgSharedTo.UsersSharedIn.findIndex(us => us.UserShareId === userShare.UserShareId);
                if (shareInIndex >= 0) {
                    orgSharedTo.UsersSharedIn[shareInIndex] = userShare;
                }
                else {
                    orgSharedTo.UsersSharedIn.push(userShare);
                }
            }

            if (orgSharedFrom) {
                const shareOutIndex = orgSharedFrom.UsersSharedOut.findIndex(us => us.UserShareId === userShare.UserShareId);
                if (shareOutIndex >= 0) {
                    orgSharedFrom.UsersSharedOut[shareOutIndex] = userShare;
                }
                else {
                    orgSharedFrom.UsersSharedOut.push(userShare);
                }
            }

        },
        stateUpdateGroupShare: (state, action) => {
            const groupShare = action.payload;

            //shared in or out?
            const orgSharedTo = state.organisations.find(o => o.OrganisationId === groupShare.OrgSharedToId);
            const orgSharedFrom = state.organisations.find(o => o.OrganisationId === groupShare.OrgSharedFromId);

            if (orgSharedTo) {
                const shareInIndex = orgSharedTo.GroupsSharedIn?.findIndex(us => us.GroupShareId === groupShare.GroupShareId);
                if (shareInIndex >= 0) {
                    orgSharedTo.GroupsSharedIn[shareInIndex] = groupShare;
                }
                else {
                    orgSharedTo.GroupsSharedIn?.push(groupShare);
                }
            }

            if (orgSharedFrom) {
                const shareOutIndex = orgSharedFrom.GroupsSharedOut.findIndex(us => us.GroupShareId === groupShare.GroupShareId);
                if (shareOutIndex >= 0) {
                    orgSharedFrom.GroupsSharedOut[shareOutIndex] = groupShare;
                }
                else {
                    orgSharedFrom.GroupsSharedOut.push(groupShare);
                }
            }
        },
        stateDeleteUserShare: (state, action) => {

            const userShareId = action.payload;

            state.organisations.forEach(org => {
                const shareInIndex = org.UsersSharedIn.findIndex(us => us.UserShareId === userShareId);
                if (shareInIndex >= 0) {
                    org.UsersSharedIn.splice(shareInIndex, 1,);
                }
                const shareOutIndex = org.UsersSharedOut.findIndex(us => us.UserShareId === userShareId);
                if (shareOutIndex >= 0) {
                    org.UsersSharedOut.splice(shareOutIndex, 1,);
                }
            });
        },
        stateDeleteGroupShare: (state, action) => {

            const groupShareId = action.payload;

            state.organisations.forEach(org => {
                const shareInIndex = org.GroupsSharedIn.findIndex(gs => gs.GroupShareId === groupShareId);
                if (shareInIndex >= 0) {
                    org.GroupsSharedIn.splice(shareInIndex, 1,);
                }
                const shareOutIndex = org.GroupsSharedOut.findIndex(gs => gs.GroupShareId === groupShareId);
                if (shareOutIndex >= 0) {
                    org.GroupsSharedOut.splice(shareOutIndex, 1,);
                }
            });
        },
        stateDeleteModelGroupPermission: (state, action) => {
            //find the model and group pertaining and remove it from them
            const permissionId = action.payload;

            state.organisations.forEach(org => {
                org.Groups.forEach(group => {
                    const permissionIndex = group.ModelPermissions.findIndex(p => p.ModelGroupPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        group.ModelPermissions.splice(permissionIndex, 1);
                    }
                });
                org.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        const permissionIndex = model.GroupPermissions.findIndex(p => p.ModelGroupPermissionId === permissionId);
                        if (permissionIndex >= 0) {
                            model.GroupPermissions.splice(permissionIndex, 1);
                        }
                    });
                });
            });
        },


        stateDeleteModelUserPermission: (state, action) => {
            //find the model and user pertaining and remove it from them
            const permissionId = action.payload;

            state.organisations.forEach(org => {
                org.Users.forEach(user => {
                    const permissionIndex = user.ModelPermissions.findIndex(p => p.ModelUserPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        user.ModelPermissions.splice(permissionIndex, 1);
                    }
                });
                org.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        const permissionIndex = model.UserPermissions.findIndex(p => p.ModelUserPermissionId === permissionId);
                        if (permissionIndex >= 0) {
                            model.UserPermissions.splice(permissionIndex, 1);
                        }
                    });
                });
            });
        },
        stateDeleteProjectGroupPermission: (state, action) => {
            //find the project and group pertaining and remove it from them
            const permissionId = action.payload;
            state.organisations.forEach(org => {
                org.Groups.forEach(group => {
                    const permissionIndex = group.ProjectPermissions.findIndex(p => p.ProjectGroupPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        group.ModelPermissions.splice(permissionIndex, 1);
                    }
                });
                org.Projects.forEach(project => {
                    const permissionIndex = project.GroupPermissions.findIndex(p => p.ProjectGroupPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        project.GroupPermissions.splice(permissionIndex, 1);
                    }
                });
            });
        },
        stateDeleteProjectUserPermission: (state, action) => {
            //find the project and user pertaining and remove it from them
            const permissionId = action.payload;
            for (let orgIndex = 0; orgIndex < state.organisations.length; orgIndex++) {
                const org = state.organisations[orgIndex];


                org.Users.forEach(user => {
                    const permissionIndex = user.ProjectPermissions.findIndex(p => p.ProjectUserPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        user.ProjectPermissions.splice(permissionIndex, 1);
                    }
                });
                org.Projects.forEach(project => {
                    const permissionIndex = project.UserPermissions.findIndex(p => p.ProjectUserPermissionId === permissionId);
                    if (permissionIndex >= 0) {
                        project.UserPermissions.splice(permissionIndex, 1);
                    }
                });
            };
        },

        stateDeleteOrganisation: (state, action) => {
            const orgIdDeleted = action.payload;

            const index = state.organisations.findIndex(item => item.OrganisationId === orgIdDeleted);
            if (index >= 0) {
                state.organisations.splice(index, 1);

                //if current org is deleted, make it the next in the list of orgs
                if (orgIdDeleted === state.currentOrgId) {
                    if (index <= state.organisations.length) {
                        state.currentOrgId = state.organisations[index]?.OrganisationId;
                    }
                    else if (state.organisations.length > 0) {
                        state.currentOrgId = state.organisations[state.organisations.length - 1]?.OrganisationId;
                    }
                }


            }
        },
        stateDeleteAnnotation: (state, action) => {

            const anId = action.payload;

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project?.Models.forEach(model => {
                        model?.AnnotationSets.forEach(anSet => {
                            let index = anSet?.Annotations.findIndex((item) => item.AnnotationId === anId);
                            if (index >= 0) {
                                anSet.Annotations.splice(index, 1);
                            }

                        })
                    })
                });
            });
        },
        stateDeleteModel: (state, action) => {

            const modelId = action.payload;

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    if (project.Models) {
                        let index = project.Models.findIndex((item) => item.ModelId === modelId);
                        if (index >= 0) {
                            project.Models.splice(index, 1);
                        }
                    }
                });
            });
        },
        stateDeleteCrs: (state, action) => {
            const crsId = action.payload;

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    if (project.Crss) {
                        let index = project.Crss.findIndex((item) => item.CrsId === crsId);
                        if (index >= 0) {
                            project.Crss.splice(index, 1);
                        }
                    }
                });
            });

        },
        stateDeleteCrsTransform: (state, action) => {
            logger.debug("stateDeleteCrsTransform ", action);

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    if (project.CrsTransforms) {
                        let index = project.CrsTransforms.findIndex((item) => item.CrsTransformId === action.payload);
                        if (index >= 0) {
                            project.CrsTransforms.splice(index, 1);
                        }
                    }
                });
            });
        },
        stateDeleteAssetBundle: (state, action) => {
            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project?.Models.forEach(model => {
                        if (model.AssetBundles) {
                            let index = model.AssetBundles.findIndex((item) => item.AssetBundleId === action.payload);
                            if (index >= 0) {
                                model.AssetBundles.splice(index, 1);
                            }
                        }
                    });
                });

            });
        },
        stateDeletePropertyTemplate: (state, action) => {
            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    if (project.PropertyTemplates) {
                        let index = project.PropertyTemplates.findIndex((item) => item.PropertyTemplateId === action.payload);
                        if (index >= 0) {
                            project.PropertyTemplates.splice(index, 1);
                        }
                    }
                });
            });
        },
        stateDeleteAnnotationStyleRule: (state, action) => {

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    if (project.AnnotationStyleRules) {
                        let index = project.AnnotationStyleRules.findIndex((item) => item.AnnotationStyleRuleId === action.payload);
                        if (index >= 0) {
                            project.AnnotationStyleRules.splice(index, 1);
                        }
                    }
                });
            });
        },
        stateUpdateProject: (state, action) => {

            const project = action.payload;
            const org = state.organisations.find(org => org.OrganisationId === project.OrganisationId);

            if (org) {

                let index = org?.Projects.findIndex((item) => item.ProjectId === project.ProjectId);
                if (index >= 0) {
                    org.Projects[index] = { ...org?.Projects[index], ...project };
                }
                else {
                    org.Projects.push(action.payload);
                }
                org.Projects.sort((a, b) => {

                    var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;

                });
            }
        },
        stateUpdateOrganisation: (state, action) => {

            const org = action.payload;

            let index = state.organisations.findIndex((item) => item.OrganisationId === org.OrganisationId);
            if (index >= 0) {
                state.organisations[index] = { ...state.organisations[index], ...org };
            }
            else {
                state.organisations.push(org);
            }
            state.organisations.sort((a, b) => {

                var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;

            });

        },

        stateUpdateAssetBundle: (state, action) => {

            state.organisations.forEach(org => {

                let project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {

                    if (project?.AssetBundles) {
                        let index = project.AssetBundles.findIndex((item) => item.AssetBundleId === action.payload.AssetBundleId);
                        if (index >= 0) {
                            project.AssetBundles[index] = { ...project.AssetBundles[index], ...action.payload };
                        }
                        else {
                            project.AssetBundles.push(action.payload);
                        }
                    }
                }


            });
        },
        stateUpdateModel: (state, action) => {
            state.organisations.forEach(org => {

                let project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    if (project?.Models) {
                        let index = project.Models.findIndex((item) => item.ModelId === action.payload.ModelId);
                        if (index >= 0) {
                            project.Models[index].Title = action.payload.Title;
                            project.Models[index].DefaultCrsId = action.payload.DefaultCrsId;
                            project.Models[index].DefaultStyleSheetId = action.payload.DefaultStyleSheetId;
                            project.Models[index].CRSs = action.payload.CRSs;
                        }
                        else {
                            project.Models.push(action.payload);
                        }
                        project.Models.sort((a, b) => {

                            var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;

                        });

                    }
                }
            });

        },
        stateUpdateCrsTransform: (state, action) => {

            state.organisations.forEach(org => {

                let project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    if (project?.CrsTransforms) {
                        let index = project.CrsTransforms.findIndex(crsTransform => crsTransform.CrsTransformId === action.payload.CrsTransformId);
                        if (index > -1) {
                            project.CrsTransforms[index] = { ...project.CrsTransforms[index], ...action.payload };
                        }
                        else {
                            project.CrsTransforms.push(action.payload);
                        }
                    };
                }
            });
        },
        stateUpdateCrs: (state, action) => {

            state.organisations.forEach(org => {

                let project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    if (project?.Crss) {
                        let crsIndex = project.Crss.findIndex(crs => crs.CrsId === action.payload.CrsId);
                        if (crsIndex > -1) {
                            project.Crss[crsIndex] = { ...project.Crss[crsIndex], ...action.payload };
                        }
                        else {
                            project.Crss.push(action.payload);
                        }
                    }
                }
            });
        },
        stateUpdateGroup: (state, action) => {

            const group = action.payload;

            const org = state.organisations.find(o => o.OrganisationId === group.OrganisationId);
            if (org) {
                let groupIndex = org.Groups.findIndex(g => g.GroupId === group.GroupId);
                if (groupIndex > -1) {
                    org.Groups[groupIndex] = { ...org.Groups[groupIndex], ...group };
                }
                else {
                    org.Groups.push(group);
                }

            }
        },

        stateUpdateAnnotation: (state, action) => {
            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        const anSet = model.AnnotationSets.find(a => a.AnnotationSetId === action.payload.AnnotationSetId);

                        if (anSet) {
                            if (anSet?.Annotations) {
                                let anIndex = anSet.Annotations.findIndex(annotation => annotation.AnnotationId === action.payload.AnnotationId);
                                if (anIndex >= 0) {
                                    anSet.Annotations[anIndex] = { ...anSet.Annotations[anIndex], ...action.payload };
                                }
                                else {
                                    anSet.Annotations.push(action.payload);
                                }
                            }

                        }

                    });
                });
            });
        },
        stateUpdatePropertyTemplate: (state, action) => {
            state.organisations.forEach(org => {

                const project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    if (project?.PropertyTemplates) {
                        let anIndex = project.PropertyTemplates.findIndex(item => item.PropertyTemplateId === action.payload.PropertyTemplateId);
                        if (anIndex >= 0) {
                            project.PropertyTemplates[anIndex] = { ...project.PropertyTemplates[anIndex], ...action.payload };

                            //also check if we need to update any style rules
                            project?.AnnotationStyleRules.forEach((ss, index, styleRules) => {
                                if (ss.ComparePropertyTemplate) {
                                    if (ss.ComparePropertyTemplate.PropertyTemplateId === action.payload.PropertyTemplateId) {
                                        styleRules[index].ComparePropertyTemplate = action.payload;
                                    }
                                }
                            });


                        }
                        else {
                            project.PropertyTemplates.push(action.payload);
                        }
                    }
                }
            });
        },
        stateUpdateAnnotationStyleRule: (state, action) => {
            state.organisations.forEach(org => {

                const project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    if (project?.AnnotationStyleRules) {
                        let anIndex = project.AnnotationStyleRules.findIndex(item => item.AnnotationStyleRuleId === action.payload.AnnotationStyleRuleId);
                        if (anIndex >= 0) {
                            project.AnnotationStyleRules[anIndex] = { ...project.AnnotationStyleRules[anIndex], ...action.payload };
                        }
                        else {
                            project.AnnotationStyleRules.push(action.payload);
                        }
                    }

                    //update any style sheets that have this rule 
                    org?.Projects.forEach((project, pIndex, array) => {
                        project?.AnnotationStyleSheets?.forEach((ss, ssIndex, array) => {
                            let rIndex = ss.AnnotationStyleRules.findIndex(item => item.AnnotationStyleRule.AnnotationStyleRuleId === action.payload.AnnotationStyleRuleId)
                            if (rIndex >= 0) {
                                ss.AnnotationStyleRules[rIndex].AnnotationStyleRule = action.payload;
                            }
                        });
                    });
                }
            });

        },
        stateUpdateAnnotationStyleSheet: (state, action) => {

            state.organisations.forEach(org => {

                const project = org?.Projects.find(project => project.ProjectId === action.payload.ProjectId);
                if (project) {
                    const ssIndex = project?.AnnotationStyleSheets.findIndex(ss => ss.AnnotationStyleSheetId === action.payload.AnnotationStyleSheet.AnnotationStyleSheetId);
                    if (ssIndex >= 0) {
                        project.AnnotationStyleSheets[ssIndex] = action.payload.AnnotationStyleSheet;
                    }
                    else {
                        if (!project.AnnotationStyleSheets) {
                            project.AnnotationStyleSheets = [];
                        }
                        project.AnnotationStyleSheets.push(action.payload.AnnotationStyleSheet);
                    }
                }
            });
        },
        stateDeleteAnnotationStyleSheet: (state, action) => {

            state.organisations.forEach(org => {

                org?.Projects.forEach(project => {
                    if (project.AnnotationStyleSheets) {
                        const ssIndex = project?.AnnotationStyleSheets.findIndex(ss => ss.AnnotationStyleSheetId === action.payload);
                        if (ssIndex >= 0) {
                            project.AnnotationStyleSheets.splice(ssIndex, 1);
                        }
                    }
                })
            });
        },

        stateUpdateAnnotationSet: (state, action) => {
            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {

                    const model = project.Models.find(m => m.ModelId === action.payload.ModelId);
                    if (model) {
                        const anSetIndex = model.AnnotationSets.findIndex(as => as.AnnotationSetId === action.payload.AnnotationSetId);
                        if (anSetIndex >= 0) {
                            model.AnnotationSets[anSetIndex] = action.payload;
                        }
                        else {
                            model.AnnotationSets.push(action.payload);
                        }

                        //sort all the model annotation sets
                        model?.AnnotationSets.sort((a, b) => {

                            var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                            var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        });

                    }

                });
            });

        },
        stateDeleteAnnotationSet: (state, action) => {
            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        const anSetIndex = model.AnnotationSets.findIndex(as => as.AnnotationSetId === action.payload);
                        if (anSetIndex >= 0) {
                            model.AnnotationSets.splice(anSetIndex, 1);
                        }
                    });
                });
            });
        },
        stateUpdateAnnotationProperties: (state, action) => {

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        model.AnnotationSets.forEach((annotationSet, index, annotationSets) => {
                            if (annotationSet?.Annotations) {
                                let anIndex = annotationSet.Annotations.findIndex(ann => ann.AnnotationId === action.payload.AnnotationId);
                                if (anIndex >= 0) {
                                    annotationSets[index].Annotations[anIndex].Properties = action.payload.AnnotationProperties;
                                }
                            }
                        });
                    });

                });
            });

        },
        stateUpdateLicense: (state, action) => {
            const license = action.payload;

            const org = state.organisations.find(o => o.OrganisationId === license.OrganisationId);
            if (org) {
                let licenseIndex = org.Licenses.findIndex(g => g.LicenseId === license.LicenseId);
                if (licenseIndex > -1) {
                    org.Licenses[licenseIndex] = { ...org.Licenses[licenseIndex], ...license };
                }
                else {
                    org.Licenses.push(license);
                }
            }

            //if license assigned to user, update it there too
            if (license.UserId !== "00000000-0000-0000-0000-000000000000") {
                state.organisations.forEach(org => {
                    //find any users that have this license and update
                    org.Users.forEach(user => {
                        const lindex = user.Licenses.findIndex(l => l.LicenseId === license.LicenseId)
                        if (lindex >= 0) {
                            user.Licenses[lindex] = license;
                        }
                    });
                });
            }
        },
        stateDeleteLicense: (state, action) => {
            const licenseId = action.payload;

            state.organisations.forEach(org => {
                const index = org?.Licenses.findIndex(g => g.LicenseId === licenseId);
                if (index >= 0) {
                    org?.Licenses.splice(index, 1);
                }

                //find any users that have this license and remove
                org.Users.forEach(user => {
                    const lindex = user.Licenses.findIndex(l => l.LicenseId === licenseId)
                    if (lindex >= 0) {
                        user.Licenses.splice(lindex, 1);
                    }
                });
            });
        },
    },
    extraReducers: {
        [getOrganisations.fulfilled]: (state, action) => {
            state.organisations = action.payload;
            state.organisations.sort((a, b) => {

                var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;

            });


            //get last seleted org
            const currentOrgId = localStorage.getItem("currentOrgId");

            if (state.organisations.length > 0) {

                if (!state.organisations.find(o => o.OrganisationId === currentOrgId)) {

                    //select user's home organization
                    if (state.organisations.find(o => o.OrganisationId === state.currentUser?.OrganisationId)) {
                        state.currentOrgId = state.currentUser?.OrganisationId;
                    }
                    else {
                        state.currentOrgId = state.organisations[0].OrganisationId;
                    }
                    localStorage.setItem("currentOrgId", state.organisations[0].OrganisationId);
                }
                else {
                    state.currentOrgId = currentOrgId;
                }
            }
            state.organisationsRetrieved = true;
        },
        [getAnnotations.fulfilled]: (state, action) => {

            const annotationPage = action.payload;
            const annotationSetId = annotationPage?.Annotations[0]?.AnnotationSetId;

            state.organisations.forEach(org => {
                org?.Projects.forEach(project => {
                    project.Models.forEach(model => {
                        const anSet = model.AnnotationSets?.find(a => a.AnnotationSetId === annotationSetId);
                        if (anSet) {
                            annotationPage.Annotations.forEach(annotation => {
                                if (anSet.Annotations.findIndex(a => a.AnnotationId === annotation.AnnotationId) < 0) {
                                    anSet.Annotations.push(annotation);
                                }
                            });
                            anSet.AnnotationCursorId = annotationPage.AnnotationCursorId;
                        }
                    });
                });
            });

        },
        [getProject.fulfilled]: (state, action) => {


            const project = action.payload;

            if (project) {

                let org = state.organisations.find(org => org.OrganisationId === project.OrganisationId);
                if (org) {
                    let projectIndex = org.Projects.findIndex((item) => item.ProjectId === action.payload.ProjectId);

                    let project;
                    if (projectIndex >= 0) {
                        org.Projects[projectIndex] = action.payload;
                        project = org.Projects[projectIndex];
                    }
                    else {
                        org.Projects.push(action.payload);
                        project = org.Projects[org.Projects.length - 1];
                    }

                    //sort this project's models
                    project.Models.sort((a, b) => {

                        var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });

                    //sort all the model annotation sets
                    project.Models?.forEach(
                        (m) => {
                            m?.AnnotationSets.sort((a, b) => {

                                var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                                var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }

                                // names must be equal
                                return 0;
                            });
                        }
                    );

                    org.Projects.sort((a, b) => {

                        var nameA = a.Title.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.Title.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;

                    });


                }
            }

        },

        [getConnectedWebSocketUsers.fulfilled]: (state, action) => {

            state.connectedWebSocketUsers = action.payload;

        },


        [signOut.fulfilled]: (state, action) => {
            state.currentUser = null;
            state.organisations = [];
            state.organisationsRetrieved = false;
        },

        [getCurrentUser.fulfilled]: (state, action) => {


            const org = state.organisations.find(org => org.OrganisationId === action.payload.OrganisationId)
            if (org) {

                let index = org.Users.findIndex((item) => item.UserId === action.payload.UserId);
                if (index === -1) {
                    org.Users.push(action.payload);
                }
            }

            state.currentUser = action.payload;

        },

    }
});

export const { clearOrganisationsInfo,
    stateSetCurrentOrg,
    stateDeleteBuildLogRecord, stateUpdateBuildLogRecord,
    stateUpdateOrganisation, stateDeleteOrganisation,
    stateUpdateProject, stateDeleteProject,
    stateUpdateAnnotation, stateDeleteAnnotation,
    stateUpdateModel, stateDeleteModel,
    stateUpdateCrs, stateDeleteCrs,
    stateUpdateCrsTransform, stateDeleteCrsTransform,
    stateUpdateAssetBundle, stateDeleteAssetBundle,
    stateUpdatePropertyTemplate, stateDeletePropertyTemplate,
    stateUpdateAnnotationStyleRule, stateDeleteAnnotationStyleRule,
    stateUpdateAnnotationStyleSheet, stateDeleteAnnotationStyleSheet,
    stateUpdateAnnotationProperties,
    signedIn, stateUpdateUser, stateDeleteUser,
    stateCreateGroup, stateUpdateGroup, stateDeleteGroup,
    stateUpdateModelGroupPermission, stateUpdateModelUserPermission,
    stateUpdateProjectGroupPermission, stateUpdateProjectUserPermission,
    stateDeleteModelGroupPermission, stateDeleteModelUserPermission,
    stateDeleteProjectGroupPermission, stateDeleteProjectUserPermission,
    stateDeleteUserShare, stateDeleteGroupShare,
    stateUpdateUserShare, stateUpdateGroupShare,
    stateUpdateLicense, stateDeleteLicense,
    stateUpdateAnnotationSet, stateDeleteAnnotationSet,
    stateUpdateAnnotationSetGroupPermission, stateUpdateAnnotationSetUserPermission,
    stateDeleteAnnotationSetGroupPermission, stateDeleteAnnotationSetUserPermission
} = organisationsInfoSlice.actions;

export const selectOrganisationsInfo = state => state.organisationsInfo;
export default organisationsInfoSlice.reducer