import React from 'react'
import { unwrapResult } from '@reduxjs/toolkit'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../css/navbar.css';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, selectOrganisationsInfo, signOut, getOrganisations, clearOrganisationsInfo } from './organisationsInfoSlice';
import { canUserSeeUtilsPage, canUserSeeOrgsPage, CanReadBuildLogs } from './UserPermissions';
import { Tooltip } from 'react-tooltip';


import logo from './main-logo-no-text.png';
import { InitSystem } from '../Api//SystemApi';

import NavBar from 'react-bootstrap/NavBar';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

import { UserMenu } from '../Components/UserMenu'
import { OrgMenu } from '../Components/OrgMenu'
import { HelpMenu } from '../Components/HelpMenu'

import { Authenticator, useTheme, View, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Toaster } from 'react-hot-toast';
import { ToastError, dismissAllToasts } from '../Components/ToastDisplay';
import AssetBuilder from './AssetBuilder';
import { selectSystemInfo, getApiSettings } from '../App/systemInfoSlice';
import WebSocketHandler from './WebSocketHandler';
import { Reveal4DErrors } from '../Api/ErrorCodes';

import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import {
    Route,
    Routes,
    NavLink,
    HashRouter,
    Navigate
} from "react-router-dom"

import OrganisationPage from "../HomePage/OrganisationPage";
import UtilsPage from "../UtilsPage/UtilsPage";
import UsersPage from "../UsersPage/UsersPage";
import GroupsPage from "../GroupsPage/GroupsPage";
import ProjectsPage from "../ProjectsPage/ProjectsPage";
import BuildLogsPage from "../BuildLogsPage/BuildLogsPage";
import { Logger } from 'aws-amplify';

import ReactErrorBoundary from "../Components/ErrorBoundary";

console.log("%c============", 'font-weight: bold; font-size: 30px;color: black; text-shadow: 2px 2px 0 rgb(100,100,100)');
console.log('%c| Reveal4D |', 'font-weight: bold; font-size: 30px;color: black; text-shadow: 2px 2px 0 rgb(100,100,100)');
console.log("%c============", 'font-weight: bold; font-size: 30px;color: black; text-shadow: 2px 2px 0 rgb(100,100,100)');
console.log("Version: " + process.env.REACT_APP_VERSION?.replace("dirty", "d"));
console.log("Env: " + process.env.REACT_APP_ENV);
console.log("");
if (process.env.REACT_APP_ENV.startsWith("dev")) {
    console.log("Dev version, showing debug messages, enter the following in the console to change to info:");
    console.log("%cwindow.LOG_LEVEL = 'INFO';", "color: red");
}
else {
    console.log("To show debug messages, enter the following in the console:");
    console.log("%cwindow.LOG_LEVEL = 'DEBUG';", "color: red");

}
console.log("");


const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="BHA logo"
                    src={logo}
                />
                <Text fontSize={"2em"} color={tokens.colors.neutral[80]}>
                    Reveal4D
                </Text>
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; Bill Harvey Associates
                </Text>
            </View>
        );
    },

}


const logger = new Logger('App');
if (process.env.REACT_APP_ENV.startsWith("dev")) {
    Logger.LOG_LEVEL = 'DEBUG';
}
else {
    Logger.LOG_LEVEL = 'INFO';
}

var fileStore = [];


function App() {


    const [user, setUser] = React.useState();
    const dispatch = useDispatch();
    const orgsInfo = useSelector(selectOrganisationsInfo);
    const systemInfo = useSelector(selectSystemInfo);
    const [systemUninitialsed, setSystemUninitialsed] = React.useState(false);
    const [getOrgsError, setGetOrgsError] = React.useState(null);
    const [mainPageHelpContext, setMainPageHelpContext] = React.useState(BeaconDialogMap.SignInPage);


    //make sure apisettings is in system state
    React.useEffect(() => {
        if (!systemInfo.apiSettings) {
            dispatch(getApiSettings())
                .then(unwrapResult)
                .then(res => {
                })
                .catch((err) => {
                    console.error("Error getting api settings", err);
                }
                );
        }
    }, [dispatch, systemInfo.apiSettings]);

    //get current user stuff and useful things if user has signed in
    React.useEffect(() => {
        if (!orgsInfo.currentUser && systemInfo.apiSettings && !orgsInfo.organisationsRetrieved && user?.username && Auth?.user) {
            dispatch(getCurrentUser())
                .then(unwrapResult)
                .then(res => {

                    if (res === null) {
                        //fail - logout current user
                        onSignOut();
                    }


                })
                .catch((err) => {
                    logger.debug("getCurrentUser error", err);

                    //user not found - might need to init system
                    if (err.message === "Table doesn't exist") {
                        setSystemUninitialsed(true);
                    }
                    else {
                        logger.debug("Error getting Current user", err);
                        ToastError("Error getting current user", err.message + " " + err.cause);
                    }
                }
                );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemInfo.apiSettings, dispatch, user?.username, orgsInfo.organisationsRetrieved, orgsInfo.currentUser]);


    //get orgs if user has signed in
    React.useEffect(() => {
        logger.debug("Getting Orgs", systemInfo.apiSettings, orgsInfo?.currentUser, orgsInfo?.organisationsRetrieved);

        if (systemInfo.apiSettings && !orgsInfo.organisationsRetrieved && orgsInfo.currentUser) {
            dispatch(getOrganisations())
                .then(unwrapResult)
                .then(res => {
                    logger.debug("GOT orgs");
                })
                .catch((err) => {
                    logger.error("Error getting Orgs", err);
                    setGetOrgsError("Error Getting Data: " + err.message);
                }
                );
        }

    }, [dispatch, systemInfo.apiSettings, orgsInfo?.currentUser, orgsInfo?.organisationsRetrieved]);




    function onSignOut() {
        BeaconSetSuggestions(BeaconDialogMap.SignInPage);
        dispatch(signOut())
            .then(unwrapResult)
            .then(res => {
                dismissAllToasts();
                setUser(null);
                dispatch(clearOrganisationsInfo());
            })
            .catch((err) => {
                logger.error("Error signing out", err);
            }
            );

    }
    function onInitialise() {

        InitSystem().then(response => {

            setSystemUninitialsed(false);
        }
        ).catch(error => {
            if (error.code === Reveal4DErrors.UserMustBeRootAdmin) {
                //log out
                onSignOut();
                dispatch(clearOrganisationsInfo());
                setUser(null);
            }
            logger.error("Error initialising system ", error);
        });

    }

    //logger.debug("organisationsInfo ", orgsInfo);


    function MainApp(signOut, authuser) {

        logger.debug("mainapp", authuser);

        setUser(authuser);

        //redirect to a specific model?
        function GetNavigateToUrl() {

            //parse url
            const url = new URL(window.location.href);

            if (url.search) {
                //org, project, model?
                const orgId = url.searchParams.get('orgid');
                const projectId = url.searchParams.get('projectid');
                const modelId = url.searchParams.get('modelid');
                const annotationSetId = url.searchParams.get('annotationsetid');

                if (orgId && projectId && modelId) {
                    localStorage.setItem("currentOrgId", orgId);
                    localStorage.setItem("projectsPageSelectedModelId", modelId);
                    localStorage.setItem("projectsPageSelectedProjectId", projectId);
                }

                if (annotationSetId) {
                    localStorage.setItem("projectsPageSelectedAnnotationSetId", annotationSetId);
                }

                url.search = "";
                window.location.href = url;
            }


            return (
                <Navigate to="/" />
            );
        }



        return (
            <React.Fragment>
                <Toaster
                    position="bottom-right"
                    toastOptions={{
                        className: '',
                        style: {
                            border: '1px solid #555555',
                            padding: '16px',
                            color: '#000000',
                            maxWidth: '1000px'
                        },
                    }}
                    containerStyle={{
                        top: 0,
                        left: 0,
                        bottom: 110,
                        right: 0,
                    }}

                />
                {!orgsInfo?.organisationsRetrieved ? (
                    <div>
                        <div className="divLoader">
                            {getOrgsError ?
                                <>

                                    <h1>{getOrgsError}</h1>
                                    <Button className="ms-2" onClick={() => { setGetOrgsError(false); dispatch(clearOrganisationsInfo()); }}>Retry</Button>
                                    <Button className="ms-2" onClick={() => signOut()}>Sign Out</Button>

                                </>
                                :
                                (!orgsInfo?.currentUser ?
                                    <h1>Loading User Information...</h1> :
                                    <h1>Loading Data...</h1>)}
                        </div>
                        <div className="divLoader">
                            <img src='main-logo-with-text.png' className="App-logo" alt="logo" />
                        </div>
                    </div>
                ) : (


                    <HashRouter>
                        <NavBar sticky="top" className="nav-bar">
                            <NavBar.Brand as={NavLink} to="/">
                                <div data-tooltip-id="nav-tooltip" data-tooltip-content="View Projects and Models">
                                    <div className="nav-logo-container">
                                        <Image className="nav-logo" src={logo} height={40} alt="logo" />
                                        <div className="home-text">Home</div>
                                    </div>
                                </div>
                            </NavBar.Brand>

                            {/* <Nav fill variant="pill" activeKey="/" onSelect={(selectedKey) => { alert(`selected ${selectedKey}`); }} >
                                {canUserSeeOrgsPage(orgsInfo.currentUser, orgsInfo?.currentOrgId) && <Nav.Link as={NavLink} to="/organisation"  >Organisation</Nav.Link>}
                                {canUserSeeUsersPage(orgsInfo.currentUser, orgsInfo?.currentOrgId) && <Nav.Link as={NavLink} to="/users">Users</Nav.Link>}
                                {canUserSeeGroupsPage(orgsInfo.currentUser, orgsInfo?.currentOrgId) && <Nav.Link as={NavLink} to="/groups">Groups</Nav.Link>}
                                <Nav.Link as={NavLink} to="/" >Projects</Nav.Link>
                                {canUserSeeUtilsPage(orgsInfo.currentUser) &&
                                    <Nav.Link as={NavLink} to="/utils" >System Utils</Nav.Link>
                                }
                            </Nav> */}
                            {/* {process.env.REACT_APP_ENV.startsWith("dev") && <div className="dev-display">!! Development Version !! : {process.env.REACT_APP_VERSION}</div>} */}

                            <NavBar.Collapse className="justify-content-end">
                                <div className='nav-menu-container'>
                                    {process.env.REACT_APP_ENV.startsWith("dev") &&
                                        <div className="home-text mt-3">Development Environment API</div>
                                    }
                                    <HelpMenu className="help-menu" />

                                    {(orgsInfo?.organisations.length > 1 || canUserSeeOrgsPage(orgsInfo?.currentUser, orgsInfo?.currentOrgId)) &&

                                        <div data-tooltip-id="nav-tooltip" data-tooltip-content={canUserSeeOrgsPage(orgsInfo?.currentUser, orgsInfo?.currentOrgId) ? ((orgsInfo?.organisations.length > 1) ? "Select org and configure org, users & groups" : "Administer org, users & groups") : "Select organisation"} data-tooltip-delay-show={500}>
                                            <OrgMenu parentHelpContext={mainPageHelpContext} className="orgs-menu" user={orgsInfo?.currentUser} orgsInfo={orgsInfo} />
                                        </div>
                                    }

                                    <div data-tooltip-id="nav-tooltip" data-tooltip-content="User information, and Sign Out" data-tooltip-delay-show={500}>
                                        <UserMenu className="user-menu" user={orgsInfo?.currentUser} userOrg={orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo?.currentUser?.OrganisationId)} signOut={onSignOut} />
                                    </div>
                                </div>
                            </NavBar.Collapse>
                            <Tooltip className="tooltip-style" id="nav-tooltip" place="right" />

                        </NavBar>
                        <Routes>
                            {canUserSeeOrgsPage(orgsInfo.currentUser, orgsInfo.currentOrgId) &&
                                <>
                                    <Route exact path="/organisation" element={<OrganisationPage setMainPageHelpContext={setMainPageHelpContext} />} />
                                    <Route path="/users" element={<UsersPage setMainPageHelpContext={setMainPageHelpContext} />} />
                                    <Route path="/groups" element={<GroupsPage setMainPageHelpContext={setMainPageHelpContext} />} />
                                </>
                            }
                            <Route path="/" element={<ProjectsPage fileStore={fileStore} setMainPageHelpContext={setMainPageHelpContext} />} />
                            {canUserSeeUtilsPage(orgsInfo.currentUser) &&
                                <Route path="/utils" element={<UtilsPage setMainPageHelpContext={setMainPageHelpContext} />} />
                            }
                            {CanReadBuildLogs(orgsInfo.currentUser) &&
                                <Route path="/importlogs" element={<BuildLogsPage setMainPageHelpContext={setMainPageHelpContext} />} />
                            }
                            <Route path="*"
                                element={GetNavigateToUrl()}
                            />
                        </Routes>


                    </HashRouter>)
                }
                <AssetBuilder fileStore={fileStore} />
            </React.Fragment >
        );


    }


    return (
        systemUninitialsed ? (
            <div>
                <div className="divLoader">
                    <h1>System Uninitialised...</h1>
                </div>
                <div className="divLoader">
                    <Button onClick={() => onInitialise()}>Initialise System</Button>
                </div>
            </div>
        ) : (
            (
                <ReactErrorBoundary>
                    <WebSocketHandler user={user} />

                    <Authenticator hideSignUp={true} components={components}>
                        {({ signOut, user }) => (
                            MainApp(signOut, user)
                        )}
                    </Authenticator>
                </ReactErrorBoundary>
            )
        )
    );

}



export default App;
