import React from "react";
//import { UpgradeDatabaseContainer } from './UpgradeDatabase';
import Container from 'react-bootstrap/Container'
import BroadcastTextMessageComponent from "./BroadcastTextMessageComponent";
import { EditSystemSettings } from "./EditSystemSettings"
import SendSystemEmailComponent from "./SendSystemEmailComponent";
import CopyProjectComponent from "./CopyProjectComponent";
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';
function UtilsPage(props) {

    const { setMainPageHelpContext } = props;


    React.useEffect(() => {
        BeaconSetSuggestions(BeaconDialogMap.SystemSettingsPage);
        setMainPageHelpContext(BeaconDialogMap.SystemSettingsPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container fluid className="mx-2 my-2">
            <EditSystemSettings />
            <CopyProjectComponent />
            <SendSystemEmailComponent />
            <BroadcastTextMessageComponent />
        </Container>
    );

}

export default UtilsPage;