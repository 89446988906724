
import { TokenList } from '../Components/TokenList'
import { CanAssignSystemPermissions } from './UserPermissions';
// import { Logger } from 'aws-amplify';
// const logger = new Logger('Permissions');
export const UserStatus = {
    Active: 0,
    Inactive: 1
}

export const GroupTypes = {
    Normal: 0,
    Everyone: 1
}

export const LicenseTypes = {
    None: 0,
    ReadAccess: 1,
    WriteAccess: 2,
    AllowUploadModels: 3,
    ModelSpecificRead: 4,
    ModelSpecificWrite: 5,
    ProjectSpecificRead: 6,
    ProjectSpecificWrite: 7
}

export const SystemPermissionFlags = {
    None: 0,
    OrganisationAdmin: 1,
    RootUser: 2,
    BuildStandalone: 8
}

export const ModelPermissionFlags = {
    None: 0,
    AllowAnnotationsEdit: 1,
    AllowCrsManagement: 2,
    AllowLockingUnLocking: 4,
    AllowUploadModelArchive: 8,
    AllowWrite: 16,
    AllowRead: 32
}


export function LicensePermissionToText(license) {

    switch (license.Type) {
        case LicenseTypes.ReadAccess:
            return "Read";
        case LicenseTypes.WriteAccess:
            return "Write";
        case LicenseTypes.AllowUploadModels:
            return "Import Models";
        case LicenseTypes.ModelSpecificRead:
            return "Read Model";

        case LicenseTypes.ModelSpecificWrite:
            return "Read/Write Model";
        case LicenseTypes.ProjectSpecificRead:
            return "Read Project";
        case LicenseTypes.ProjectSpecificWrite:
            return "Read/Write Project";
        default:
            break;
    }
}

export function PermissionToTokens(flags, reverseColour, showUploadPermission) {

    const items = [];
    if (flags & ModelPermissionFlags.AllowRead) {
        items.push("Read");
    }
    if (flags & ModelPermissionFlags.AllowWrite) {
        items.push("Write");
    }
    if (flags & ModelPermissionFlags.AllowAnnotationsEdit) {
        items.push("Edit Annotations");
    }
    if (flags & ModelPermissionFlags.AllowCrsManagement) {
        items.push("Edit CRS");
    }
    if (flags & ModelPermissionFlags.AllowLockingUnLocking) {
        items.push("Edit Locks");
    }
    if ((flags & ModelPermissionFlags.AllowUploadModelArchive) && showUploadPermission) {
        items.push("Allow Import");
    }

    return <TokenList tokens={items} reverseColour={reverseColour} />;

}

export function availableLicenseTypeOptionsOrg() {
    return [
        { value: LicenseTypes.AllowUploadModels, label: "Import Models" },
        { value: LicenseTypes.ModelSpecificRead, label: "Model Specific Read" },
        { value: LicenseTypes.ModelSpecificWrite, label: "Model Specific Write" },
        { value: LicenseTypes.ProjectSpecificRead, label: "Project Specific Read" },
        { value: LicenseTypes.ProjectSpecificWrite, label: "Project Specific Write" }
    ]
}

export function availableLicenseTypeOptionsUser() {
    return [
        { value: LicenseTypes.ReadAccess, label: "Read Access" },
        { value: LicenseTypes.WriteAccess, label: "Write Access" },
    ]
}

export function availableSystemPermissions(user) {

    let perms = [];
    if (CanAssignSystemPermissions(user, SystemPermissionFlags.OrganisationAdmin)) {
        perms.push({ value: SystemPermissionFlags.OrganisationAdmin, label: 'Organisation Admin' });
    }

    if (CanAssignSystemPermissions(user, SystemPermissionFlags.RootUser)) {
        perms.push({ value: SystemPermissionFlags.RootUser, label: 'System Admin' });
    }
    if (CanAssignSystemPermissions(user, SystemPermissionFlags.BuildStandalone)) {
        perms.push({ value: SystemPermissionFlags.BuildStandalone, label: 'Build Standalone' });
    }

    return perms;
};
export function availableSystemPermissionsGroup(user) {

    let perms = [];
    if (CanAssignSystemPermissions(user, SystemPermissionFlags.OrganisationAdmin)) {
        perms.push({ value: SystemPermissionFlags.OrganisationAdmin, label: 'Organisation Admin' });
    }

    if (CanAssignSystemPermissions(user, SystemPermissionFlags.BuildStandalone)) {
        perms.push({ value: SystemPermissionFlags.BuildStandalone, label: 'Build Standalone' });
    }

    return perms;
};


export function availableModelPermissionOptions(showUploadPermission) {
    let perms = [];

    perms.push({ value: ModelPermissionFlags.AllowRead, label: 'Read' });
    perms.push({ value: ModelPermissionFlags.AllowWrite, label: 'Write' });
    perms.push({ value: ModelPermissionFlags.AllowAnnotationsEdit, label: 'Edit Annotations' });
    perms.push({ value: ModelPermissionFlags.AllowCrsManagement, label: 'Edit CRS' });
    perms.push({ value: ModelPermissionFlags.AllowLockingUnLocking, label: 'Edit Locks' });
    if (showUploadPermission) {
        perms.push({ value: ModelPermissionFlags.AllowUploadModelArchive, label: 'Allow Import' });
    }

    return perms;
};

export function optionsToModelPermissionFlag(options) {
    let permissions = 0;

    if (options) {
        options?.forEach(option => {
            permissions |= option.value;
        });
    }
    return permissions;
}

export function modelPermissionsToOptions(permissions, showUploadPermission) {

    let options = [];

    if (permissions & ModelPermissionFlags.AllowRead) {
        options.push({ value: ModelPermissionFlags.AllowRead, label: 'Read' });
    }
    if (permissions & ModelPermissionFlags.AllowWrite) {
        options.push({ value: ModelPermissionFlags.AllowWrite, label: 'Write' });
    }
    if (permissions & ModelPermissionFlags.AllowAnnotationsEdit) {
        options.push({ value: ModelPermissionFlags.AllowAnnotationsEdit, label: 'Edit Annotations' });
    }
    if (permissions & ModelPermissionFlags.AllowCrsManagement) {
        options.push({ value: ModelPermissionFlags.AllowCrsManagement, label: 'Edit CRS' });
    }
    if (permissions & ModelPermissionFlags.AllowLockingUnLocking) {
        options.push({ value: ModelPermissionFlags.AllowLockingUnLocking, label: 'Edit Lock' });
    }
    if ((permissions & ModelPermissionFlags.AllowUploadModelArchive) && showUploadPermission) {
        options.push({ value: ModelPermissionFlags.AllowUploadModelArchive, label: 'Allow Import' });
    }

    return options;

}

export function filterPermissionsOptions(options) {

    //management but no write flag
    if ((options.find(o => o.value === ModelPermissionFlags.AllowAnnotationsEdit) ||
        options.find(o => o.value === ModelPermissionFlags.AllowCrsManagement) ||
        options.find(o => o.value === ModelPermissionFlags.AllowLockingUnLocking) ||
        options.find(o => o.value === ModelPermissionFlags.AllowUploadModelArchive)) &&
        !options.find(o => o.value === ModelPermissionFlags.AllowWrite)) {
        //need to add in a write access. just check we have a read too

        const readIndex = options.findIndex(o => o.value === ModelPermissionFlags.AllowRead);
        if (readIndex === -1) {
            options.splice(0, 0, { value: ModelPermissionFlags.AllowRead, label: 'Read' });
            options.splice(1, 0, { value: ModelPermissionFlags.AllowWrite, label: 'Write' });
        }
        else {
            options.splice(1, 0, { value: ModelPermissionFlags.AllowWrite, label: 'Write' });
        }

    }

    //write but no read
    if (options.find(o => o.value === ModelPermissionFlags.AllowWrite) && !options.find(o => o.value === ModelPermissionFlags.AllowRead)) {
        options.splice(0, 0, { value: ModelPermissionFlags.AllowRead, label: 'Read' });
    }


    //fix
    const readIndex = options.findIndex(o => o.value === ModelPermissionFlags.AllowRead);
    if (readIndex >= 0) {
        //we have a read. if we have a write too, then fix it
        if (options.find(o => o.value === ModelPermissionFlags.AllowWrite)) {
            options[readIndex].isFixed = true;
        }
        else {
            options[readIndex].isFixed = false;
        }
    }

    const writeIndex = options.findIndex(o => o.value === ModelPermissionFlags.AllowWrite);
    if (writeIndex >= 0) {
        //we have a write. if we have a management too, then fix it        
        if ((options.find(o => o.value === ModelPermissionFlags.AllowAnnotationsEdit) ||
            options.find(o => o.value === ModelPermissionFlags.AllowCrsManagement) ||
            options.find(o => o.value === ModelPermissionFlags.AllowLockingUnLocking) ||
            options.find(o => o.value === ModelPermissionFlags.AllowUploadModelArchive))) {
            options[writeIndex].isFixed = true;
        }
        else {
            options[writeIndex].isFixed = false;
        }
    }

    return options;
}
