import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit'
import { AnnotationTable } from "../Components/AnnotationTable"
import { AnnotationSetSelect } from "../Components/AnnotationSetSelect"
import { ModelSelect } from "../Components/ModelSelect"
import { ProjectSelect } from "../Components/ProjectSelect"
import { selectOrganisationsInfo, getProject, getAnnotations } from '../App/organisationsInfoSlice';
import { ToastError } from '../Components/ToastDisplay'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CrsView } from './CrsView';
import { BeaconDialogMap } from '../Components/BeaconDialogMap';


import { Logger } from 'aws-amplify';
const logger = new Logger('ProjectPage');



export function ProjectsPage(props) {
    logger.debug(props);

    const orgsInfo = useSelector(selectOrganisationsInfo);
    const currentOrg = orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId);

    const dispatch = useDispatch();

    const { fileStore, setMainPageHelpContext } = props;

    const [projectId, setProjectId] = useState(localStorage.getItem("projectsPageSelectedProjectId"));
    const [modelId, setModelId] = useState(localStorage.getItem("projectsPageSelectedModelId"));
    const [annotationSetId, setAnnotationSetId] = useState(localStorage.getItem("projectsPageSelectedAnnotationSetId"));

    const [currentProject, setCurrentProject] = useState({});
    const [currentModel, setCurrentModel] = useState({});
    const [currentAnnotationSet, setCurrentAnnotationSet] = useState({});

    const [loadingProjectContents, setLoadingProjectContents] = useState(false);
    const [loadingAnnotationSetContents, setLoadingAnnotationSetContents] = useState(false);

    const [tabKey, setTabKey] = useState('annotations');

    const [helpContext, setHelpContext] = useState(BeaconDialogMap.HomePageAnnotationsTab);


    React.useEffect(() => {
        if (tabKey === 'annotations') {
            setHelpContext(BeaconDialogMap.HomePageAnnotationsTab);
            setMainPageHelpContext(BeaconDialogMap.HomePageAnnotationsTab);

        }
        else {
            setHelpContext(BeaconDialogMap.HomePageCRSTab);
            setMainPageHelpContext(BeaconDialogMap.HomePageCRSTab);

        }

    }, [setMainPageHelpContext, tabKey])

    //look for org change, select the current project.
    React.useEffect(() => {
        if (currentOrg) {


            //load from storage - to survive a page refresh
            const proposedProjectId = localStorage.getItem("projectsPageSelectedProjectId");

            if (currentOrg?.Projects?.find(p => p.ProjectId === proposedProjectId)) {
                setProjectId(proposedProjectId);
            }
            else if (currentOrg?.Projects?.length) {
                setProjectId(currentOrg?.Projects[0].ProjectId);
            }
            else {
                setProjectId(null);
            }

        }

    }, [currentOrg, setProjectId])


    //load project extra detail - but only when the selected project changes
    React.useEffect(() => {

        let project = currentOrg?.Projects.find(item => item.ProjectId === projectId);
        if (project) {
            if (!project.IsFullyLoaded) {

                //get contents of project if not already there
                setLoadingProjectContents(true);

                dispatch(getProject(project.ProjectId))
                    .then(unwrapResult)

                    .then(res => {
                        setLoadingProjectContents(false);
                    })
                    .catch(err => {
                        setLoadingProjectContents(false);
                        ToastError(err.message, err.cause);
                    });
            }
        }
    }, [dispatch, projectId, currentOrg?.Projects]);

    useEffect(() => {
        setCurrentProject(currentOrg?.Projects?.find(p => p.ProjectId === projectId));
    }, [currentOrg?.Projects, projectId])

    useEffect(() => {
        setCurrentModel(currentProject?.Models?.find(m => m.ModelId === modelId));
    }, [currentProject?.Models, modelId])

    useEffect(() => {
        setCurrentAnnotationSet(currentModel?.AnnotationSets?.find(as => as.AnnotationSetId === annotationSetId));
    }, [annotationSetId, currentModel?.AnnotationSets])


    //load annotations - if not 000 then annotations haven't been fully loaded
    React.useEffect(() => {
        if (currentAnnotationSet?.AnnotationSetId && currentAnnotationSet?.AnnotationCursorId !== "00000000-0000-0000-0000-000000000000") {

            //get contents of project if not already there
            setLoadingAnnotationSetContents(true);

            dispatch(getAnnotations(
                {
                    AnnotationSetId: currentAnnotationSet.AnnotationSetId,
                    AnnotationCursorId: currentAnnotationSet?.AnnotationCursorId ? currentAnnotationSet?.AnnotationCursorId : "00000000-0000-0000-0000-000000000000"
                }))
                .then(unwrapResult)

                .then(res => {
                    setLoadingAnnotationSetContents(res?.AnnotationCursorId !== "00000000-0000-0000-0000-000000000000");
                })
                .catch(err => {
                    setLoadingAnnotationSetContents(false);
                    ToastError(err.message, err.cause);
                });
        }
    }, [dispatch, currentAnnotationSet]);

    //save to local storage so that controls can be repopulated if page is reloaded.
    React.useEffect(() => {
        localStorage.setItem("projectsPageSelectedModelId", modelId);
        localStorage.setItem("projectsPageSelectedAnnotationSetId", annotationSetId);
        localStorage.setItem("projectsPageSelectedProjectId", projectId);
    }, [annotationSetId, modelId, projectId])

    return (
        <div className="annotations-view">
            <div className="project-top-row">
                <div className="project-select-controls">
                    <ProjectSelect
                        parentHelpContext={helpContext}
                        currentOrg={currentOrg}
                        selectedProjectId={projectId}
                        setSelectedProjectId={setProjectId}
                        orgsInfo={orgsInfo}
                        loadingProjectContents={loadingProjectContents}
                    />
                    <ModelSelect
                        parentHelpContext={helpContext}
                        project={currentProject}
                        selectedModelId={modelId}
                        setSelectedModelId={setModelId}
                        orgsInfo={orgsInfo}
                        selectedProjectId={projectId}
                        fileStore={fileStore}
                    />
                    <AnnotationSetSelect
                        parentHelpContext={helpContext}
                        project={currentProject}
                        model={currentModel}
                        selectedAnnotationSetId={annotationSetId}
                        setSelectedAnnotationSetId={setAnnotationSetId}
                        orgsInfo={orgsInfo}
                        loadingAnnotationSetContents={loadingAnnotationSetContents}
                    />
                </div>
                <div className="tabs-container">
                    <Tabs
                        id="project-tabs"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        fill
                        className="projectTabs"
                    >
                        <Tab eventKey="annotations" title="Annotations" />
                        <Tab eventKey="crss" title="Coordinate Systems" />

                    </Tabs>
                </div>
            </div>
            {(tabKey === "annotations") &&
                <AnnotationTable helpContext={BeaconDialogMap.HomePageAnnotationsTab} orgsInfo={orgsInfo} currentUser={orgsInfo?.currentUser} project={currentProject} model={currentModel} annotationSet={currentAnnotationSet} />
            }
            {(tabKey === "crss") &&
                <CrsView helpContext={BeaconDialogMap.HomePageCRSTab} orgsInfo={orgsInfo} currentOrg={currentOrg} selectedProjectId={projectId} project={currentProject} />
            }
        </div>
    );
}


export default ProjectsPage;