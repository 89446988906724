let itemClassName = "me-1 mt-1 p-1 border border-light";
let styleitem = { fontSize: 12, backgroundColor: "#EEEEEE" };
let styleItemReversed = { fontSize: 12, backgroundColor: "#555555", foregroundColor: "#FFFFFF" };

export function TokenList(props) {

    const { tokens, reverseColour } = props;

    const items = tokens ? tokens.map((token) =>
        <div key={token} className={itemClassName} style={reverseColour ? styleItemReversed : styleitem}>
            {token}
        </div>
    ) : <></>

    return (
        <div className="d-flex flex-wrap ps-0">
            {items}
        </div>
    );
}
