import React, { useState, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
// import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
// import * as Constants from '../constants.js';

//import { Logger } from 'aws-amplify';
//const logger = new Logger('EditProject');


export function EditOrganisation(props) {
    // //logger.debug("props", props);

    // const orgsInfo = useSelector(selectOrganisationsInfo);

    const form = useRef(null);

    const [title, setTitle] = useState();

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (props.show) {

            setTitle(props.org.Title);

            if (props.helpContext) {
                BeaconSetSuggestions(props.helpContext);
            }
        }
        else {
            if (props.parentHelpContext) {
                BeaconSetSuggestions(props.parentHelpContext);
            }
        }
    }, [props.helpContext, props.org.Title, props.parentHelpContext, props.show])


    React.useEffect(() => {

        if (props.show) {
            setTitle(props.org.Title);
        }
    }, [props?.org?.Title, props.show])

    function onSave(event) {
        event.preventDefault();

        if (form.current.checkValidity()) {

            //copy organisation
            let newOrg = JSON.parse(JSON.stringify(props.org));

            //update with form values
            newOrg.Title = title;
            props.onSave(newOrg, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        props.setShow(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group className="my-2" controlId="formProjectTitle" as={Row}>
                            <Form.Label column sm={2} >Title:</Form.Label>
                            <Col sm={10}>
                                <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Organisation Title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default EditOrganisation;