import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { ToastLoading, ToastError, ToastSuccess, ToastClose } from '../Components/ToastDisplay'
import { SystemPermissionFlags, availableSystemPermissions, GroupTypes } from '../App/Permissions'
import { DeleteUser, DeactivateUser, CreateUser } from '../Api/UserApi'

import { CanChangeUserName } from "../App/UserPermissions";
import { UpdateUser, ResetUser, CanDeleteUser } from '../Api/UserApi'
import { ConfirmModal, ConfirmModalWithExtraButton } from '../Components/ConfirmModal'
import { UserStatus } from '../App/Permissions'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditUser');


function OptionsToPermission(options) {
    let permissions = 0;

    if (options) {
        options.forEach(option => {
            permissions |= option.value;
        });
    }
    return permissions;
}

function PermissionsToOptions(permissions) {

    let options = [];

    if (permissions & SystemPermissionFlags.OrganisationAdmin) {
        options.push({ value: SystemPermissionFlags.OrganisationAdmin, label: 'Organisation Admin' });
    }
    if (permissions & SystemPermissionFlags.RootUser) {
        options.push({ value: SystemPermissionFlags.RootUser, label: 'System Admin' });
    }
    if (permissions & SystemPermissionFlags.BuildStandalone) {
        options.push({ value: SystemPermissionFlags.BuildStandalone, label: 'Build Standalone' });
    }

    return options;

}

function GroupsToOptions(groups) {
    let options = [];

    if (groups) {
        groups.forEach(group =>
            options.push({ value: group.GroupId, label: group.Title })
        );
    }
    return options;
}

function OptionsToGroupIds(options) {
    let groups = [];
    if (options) {
        options.forEach(option => groups.push(option.value));
    }
    return groups;
}

//so we can have a fixed options item
const multiSelectStyles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};


export function EditUser(props) {

    logger.debug("props", props);

    const { currentOrg, show, setShow, selectedUserId, users, currentUser, helpContext, parentHelpContext } = props;

    const user = users.find(item => item.UserId === selectedUserId);


    const toastid = useRef(null);
    const form = useRef(null);

    const [selectedPermissions, setSelectedPermissions] = useState();
    const [selectedGroups, setSelectedGroups] = useState();
    const [userName, setUserName] = useState();
    const [attributes, setAttributes] = useState();
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [showConfirmReactivateDialog, setShowConfirmReactivateDialog] = useState(false);
    const [showConfirmMustDeactivateDialog, setShowConfirmMustDeactivateDialog] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);
    const [validated, setValidated] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);

            setSelectedPermissions(PermissionsToOptions(user ? user.Permissions : []));
            setSelectedGroups(GroupsToOptions(user ? user.Groups : []));
            setAttributes(user ? user.Attributes : []);
            setUserName(user ? user.UserName : "");
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show, user])


    let availableGroupsOptions = GroupsToOptions(currentOrg?.Groups);

    function allUsersOption() {

        let allUsersGroup = currentOrg?.Groups?.find(g => g.Type === GroupTypes.Everyone);

        if (allUsersGroup) {

            return { isFixed: true, label: allUsersGroup.Title, value: allUsersGroup.GroupId };
        }
        else {
            return null;
        }

    }

    function setGroupOptionsIntercept(options) {
        //make sure all users is fixed, and present

        const allUsersGroup = currentOrg?.Groups?.find(g => g.Type === GroupTypes.Everyone)
        if (allUsersGroup) {
            let allIndex = options.findIndex(g => g.value === allUsersGroup.GroupId);
            if (allIndex >= 0) {
                options[allIndex].isFixed = true;
            }
            else {
                options.splice(0, 0, allUsersOption());
            }

        }
        setSelectedGroups(options);
    }



    async function onReset(event) {
        event.preventDefault();

        toastid.current = ToastLoading("Contacting server");

        setUiDisabled(true);
        try {
            //call then provoke exception on result
            await ResetUser(user);
            ToastSuccess("User [" + user.UserName + "] reset", toastid.current, 5000);
            //close modal
            setShow(false);
        } catch (error) {
            ToastError(error.message, error.cause, toastid.current);
        }
        finally {
            setUiDisabled(false);
        }

    }


    async function onDelete() {

        toastid.current = ToastLoading("Checking...");
        try {
            //call then provoke exception on result
            if (await CanDeleteUser(user.UserId)) {
                ToastClose(toastid.current);
                setShowConfirmDeleteDialog(true);
            }
            else {
                ToastClose(toastid.current);
                setShowConfirmMustDeactivateDialog(true);
            }
        } catch (error) {
            ToastError(error.message, error.cause, toastid.current);
        }
        finally {
            setUiDisabled(false);
            ToastClose(toastid.current);
        }
    }

    async function onDeleteConfirmed(deactivateUser) {


        if (deactivateUser) {
            onDeactivateConfirmed();
        }
        else {

            setUiDisabled(true);
            toastid.current = ToastLoading("Deleting User");

            try {
                //call then provoke exception on result
                await DeleteUser(selectedUserId);
                ToastSuccess("Deleted [" + user.UserName + "]", toastid.current, 5000);
                setShow(false);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
        }
    }

    function onReactivate() {
        setShowConfirmReactivateDialog(true);
    }

    async function onReactivateConfirmed() {
        setUiDisabled(true);
        toastid.current = ToastLoading("Reactivating User");
        CreateUser(user, false)
            .then(res => {
                ToastSuccess("User [" + userName + "] reactivated.", toastid.current, 5000);
                setShow(false);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastid.current);
                setUiDisabled(false);
            });

    }

    async function onDeactivateConfirmed() {
        setUiDisabled(true);
        toastid.current = ToastLoading("Deactivating User");

        try {
            //call then provoke exception on result
            await DeactivateUser(selectedUserId, true);
            ToastSuccess("Deactivated [" + user.UserName + "]", toastid.current, 5000);
            setShow(false);
        } catch (error) {
            ToastError(error.message, error.cause, toastid.current);
        }

    }

    async function onSave(event) {
        event.preventDefault();

        let permissions = OptionsToPermission(selectedPermissions);
        let groups = OptionsToGroupIds(selectedGroups);


        if (form.current.checkValidity()) {

            //valid - send command
            setUiDisabled(true);
            toastid.current = ToastLoading("Contacting server");

            try {
                //call then provoke exception on result
                await UpdateUser(
                    {
                        Userid: user.UserId,
                        UserName: userName,
                        Groups: groups.map(g => ({ GroupId: g })),
                        Permissions: permissions,
                        OrganisationId: user.OrganisationId,
                        Attributes: attributes
                    }
                );
                ToastSuccess("User [" + user.UserName + "] updated", toastid.current, 5000);
                //close modal
                setShow(false);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
            finally {
                setUiDisabled(false);
            }

        }
        else {
            //failed validation
            event.stopPropagation();
        }
        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }

    function updateAttribute(name, value) {
        //copy attributes
        var atts = JSON.parse(JSON.stringify(attributes));
        var index = attributes.findIndex(a => a.Name === name);
        if (index !== -1) {
            atts[index].Value = value;
        }

        setAttributes(atts);
    }

    const attributeGroups = attributes?.map((attribute) =>
        <Form.Group controlId={attribute.Name} as={Row} key={attribute.Name}>
            <Form.Label column sm={12} >{attribute.Name}:</Form.Label>
            <Col sm={12}>
                <Form.Control required disabled={uiDisabled} value={attribute.Value} onChange={(e) => updateAttribute(attribute.Name, e.target.value)} placeholder='Value...' />
                <Form.Control.Feedback type="invalid">
                    {"Please enter text for this value."}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );

    return (

        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        {
                            CanChangeUserName(currentUser, user) ?

                                <Form.Group controlId="formGroupUserName" as={Row}>
                                    <Form.Label column sm={12} >User Email (Username for signing in):</Form.Label>
                                    <Col sm={12}>
                                        <Form.Control type="email" disabled={uiDisabled} required value={userName} onChange={(e) => setUserName(e.target.value)} placeholder='User Name...' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid email.
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>

                                :

                                <Form.Group controlId="formGroupUserName" as={Row}>
                                    <Form.Label column sm={12} >User Email:</Form.Label>
                                    <Col sm={12}>
                                        <Form.Label>{user ? user.UserName : ""}</Form.Label>
                                    </Col>
                                </Form.Group>
                        }
                        {attributeGroups}

                        <Form.Group controlId="formGroupPermissions" as={Row}>
                            <Form.Label column sm={12} >System Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedPermissions}
                                    onChange={setSelectedPermissions}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableSystemPermissions(currentUser)} />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupGroups" as={Row}>
                            <Form.Label column sm={12} >Group:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    styles={multiSelectStyles}
                                    value={selectedGroups}
                                    onChange={setGroupOptionsIntercept}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="groups"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select groups..."
                                    options={availableGroupsOptions} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                {user?.Status === UserStatus.Active &&
                    <>
                        <Button variant="primary" disabled={uiDisabled} onClick={onDelete}>Delete/Deactivate</Button>
                        <Button variant="primary" disabled={uiDisabled} onClick={onReset}>Reset Password</Button>
                    </>
                }
                {user?.Status === UserStatus.Inactive &&
                    <Button variant="primary" disabled={uiDisabled} onClick={onReactivate}>Reactivate</Button>
                }
                <Button variant="primary" disabled={uiDisabled} onClick={onSave}>OK</Button>
                <Button variant="secondary" disabled={uiDisabled} onClick={onClose}>Cancel</Button>
            </Modal.Footer>
            <ConfirmModalWithExtraButton
                helpContext={BeaconDialogMap.DeleteDeactivateUserDialog}
                parentHelpContext={helpContext}
                dialogClassName="modal-700w"
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteConfirmed}
                okText={"Delete"}
                buttonText={"Deactivate"}
                dialogBody={"User can be deleted, or you may just deactivate, which will prevent the user from signing in and allow them to be reactivated at a future time."}
                dialogTitle={"Delete/Deactivate User: " + user?.UserName}

            />
            {showConfirmReactivateDialog && <ConfirmModal
                dialogClassName="modal-700w"
                show={showConfirmReactivateDialog}
                setShow={setShowConfirmReactivateDialog}
                onOK={onReactivateConfirmed}
                okText={"Reactivate"}
                dialogBody={"User will be reactivated and sent an email with a temporary password. "}
                dialogTitle={"Reactivate User: " + user?.UserName}

            />}
            <ConfirmModal
                helpContext={BeaconDialogMap.MustDeactivateUserDialog}
                parentHelpContext={helpContext}
                dialogClassName="modal-700w"
                show={showConfirmMustDeactivateDialog}
                setShow={setShowConfirmMustDeactivateDialog}
                onOK={onDeactivateConfirmed}
                okText={"Deactivate"}
                dialogBody={"User cannot be deleted as there is data referencing the user. You may deactivate the user which will prevent the user from signing in."}
                dialogTitle={"Deactivate User: " + user?.UserName}

            />
        </Modal>
    );
}


export default EditUser;