import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUrlForModelUpload, AddModelAndBuildUnityAssetBundle, BuildUnityAssetBundle } from '../Api/BuildApi'
import { selectAssetBuildQueue, popQueueItem, updateProgress, updateState, clearCurrentBuild } from '../App/queueAssetBuildSlice';

import ProgressBar from 'react-bootstrap/ProgressBar'
import request from "superagent";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { ToastError, ToastSuccess } from '../Components/ToastDisplay'
import PropTypes from 'prop-types'
import * as Constants from '../constants.js'
import { Logger } from 'aws-amplify';


const logger = new Logger('Reveal4D-AssetBuild');

export function AssetBuilder(props) {



    const assetBuildQueue = useSelector(selectAssetBuildQueue);

    const dispatch = useDispatch();

    const [putRequest, setPutRequest] = useState(null);


    //look for asset build queue change
    React.useEffect(() => {
        //if there is a build in the queue and we are not already building it, tell store to pop a build item into current buil
        if (assetBuildQueue.queue.length && !assetBuildQueue.currentBuild) {
            dispatch(popQueueItem());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetBuildQueue.queue, assetBuildQueue.currentBuild])

    //is there a current item to build
    React.useEffect(() => {
        //if there is a build in the queue and we are not already building it...
        if (assetBuildQueue.currentBuild) {

            console.log("addmodel assetBuildQueue.currentBuild", assetBuildQueue.currentBuild);

            switch (assetBuildQueue.currentBuild.AssetBundleBuildCommand.CommandType) {
                case Constants.BuildTypes.AddModelAndBuildUnityAssetBundle:


                    //pop the first file from the store (can't be stored in the state as it is not serializable)
                    let file = props.fileStore.shift();
                    logger.debug("addandbuild", file);

                    addModelAndQueueUnityAssetBuild(
                        assetBuildQueue.currentBuild.AssetBundleBuildCommand, file);
                    break;
                case Constants.BuildTypes.BuildUnityAssetBundle:
                    logger.debug("buildonly", assetBuildQueue.currentBuild.AssetBundleBuildCommand);

                    queueUnityAssetBuild(
                        assetBuildQueue.currentBuild.AssetBundleBuildCommand);
                    break;

                default:

            }


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetBuildQueue.currentBuild])

    function queueUnityAssetBuild(assetBundleBuildCommand) {

        logger.debug("queueUnityAssetBuild1", assetBundleBuildCommand);
        dispatch(updateState("Queuing Build"));
        logger.debug("queueUnityAssetBuild2", assetBundleBuildCommand);
        BuildUnityAssetBundle(assetBundleBuildCommand).then((response) => {
            ToastSuccess("Queued for import successfully");
            dispatch(clearCurrentBuild());
            dispatch(updateProgress(0));
            dispatch(updateState(""));
        }).catch((err) => {
            ToastError(err.message, err.cause);
            dispatch(clearCurrentBuild());
            dispatch(updateProgress(0));
            dispatch(updateState(""));
            setPutRequest(null);
        });
    }

    function addModelAndQueueUnityAssetBuild(assetBundleBuildCommand, file) {
        //get url for file upload
        //then start upload

        logger.debug("getting url for upload");

        dispatch(updateState("Getting URL for Upload"));
        GetUrlForModelUpload(file.name, assetBundleBuildCommand.ProjectId).then((url) => {

            logger.debug("url for upload: ", url);

            dispatch(updateState("Uploading"));

            let req = request.put(url)
                .set('Content-Type', 'application/x-zip-compressed')
                .set('Content-Disposition', 'inline')
                .timeout({
                    //response: 25000,  // Wait 25 seconds for the server to start sending,
                    deadline: 60000 * 120, // but allow 2 hours for the file to finish loading.
                });

            setPutRequest(req);

            req.send(file)
                .on('progress', event => {
                    //uploading - set progress bar
                    dispatch(updateProgress(event.percent));
                    logger.debug("Upload progress event", event);

                })
                .on('abort', () => {
                    logger.debug("Upload abort event");

                }).then(res => {
                    logger.debug("Upload finish event", res);

                    //finished uploading
                    setPutRequest(null);
                    dispatch(updateProgress(0));
                    dispatch(updateState("Queuing Build"));
                    AddModelAndBuildUnityAssetBundle(assetBundleBuildCommand).then((response) => {
                        ToastSuccess(assetBundleBuildCommand.ModelTitle + " - Queued for import successfully");
                        dispatch(clearCurrentBuild());
                        dispatch(updateProgress(0));
                        dispatch(updateState(""));
                    }).catch((err) => {
                        ToastError(err.message, err.cause);
                        dispatch(clearCurrentBuild());
                        dispatch(updateProgress(0));
                        dispatch(updateState(""));
                        setPutRequest(null);
                    });

                }).catch(err => {

                    logger.debug("Upload error event", err);

                    if (err.message !== "Aborted") {
                        ToastError(err.message, err.cause);
                    }
                    dispatch(clearCurrentBuild());
                    dispatch(updateProgress(0));
                    dispatch(updateState(""));
                    setPutRequest(null);
                });


        }).catch(err => {

            logger.debug("GetUrl error", err);

            dispatch(clearCurrentBuild());
            dispatch(updateProgress(0));
            dispatch(updateState(""));
            setPutRequest(null);
            ToastError(err.message, err.cause);
        });

    }


    const onAbortUpload = () => {
        if (putRequest) {
            putRequest.abort();
        }
    }

    return (
        <>
            {assetBuildQueue.currentBuild &&
                (<Alert variant="primary" className="fixed-bottom">

                    <Container fluid >
                        <Row className="align-items-center">
                            <Col sm={"auto"}>
                                {"Importing model: "}
                            </Col>
                            <Col sm={"auto"}>
                                {assetBuildQueue.currentBuild.AssetBundleBuildCommand.ModelTitle}
                            </Col>
                            <Col sm={"auto"}>
                                {assetBuildQueue.status}
                            </Col>
                            <Col sm={2}>
                                {(assetBuildQueue.progress > 0) &&
                                    (<ProgressBar now={assetBuildQueue.progress} />)
                                }
                            </Col>
                            <Col sm={"auto"}>
                                {(assetBuildQueue.progress > 0) &&
                                    (<Button size="sm" onClick={onAbortUpload}>Cancel</Button>)
                                }
                            </Col>
                            <Col sm={"auto"}>
                                {(assetBuildQueue.queue.length > 0) &&
                                    ("[+" + assetBuildQueue.queue.length + " more queued]")
                                }
                            </Col>

                        </Row>
                    </Container>
                </Alert>)}
        </>
    );
}

AssetBuilder.propTypes = {
    fileStore: PropTypes.array.isRequired,
};

export default AssetBuilder;