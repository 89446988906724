import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { ToastLoading, ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { StartCopyProjectStateMachine } from '../Api/ProjectApi'
import Select from 'react-select'
import { Logger } from 'aws-amplify';
const logger = new Logger('CopyProjectComponent');


export function CopyProjectComponent() {
    const orgsInfo = useSelector(selectOrganisationsInfo);

    const toastid = useRef(null);

    const [formDisabled, setFormDisabled] = useState(false);
    const [validated, setValidated] = useState(false);

    const [sourceOrg, setSourceOrg] = useState(null);
    const [sourceOrgValid, setSourceOrgValid] = useState(false);

    const [destOrg, setDestOrg] = useState(null);
    const [destOrgValid, setDestOrgValid] = useState(false);

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectValid, setSelectedProjectValid] = useState(false);

    const [defaultUser, setDefaultUser] = useState(null);
    const [defaultUserValid, setDefaultUserValid] = useState(false);

    const [modelIds, setModelIds] = useState([]);
    const [modelIdsValid, setModelIdsValid] = useState(false);


    function onSubmitClicked(event) {
        event.preventDefault();

        const form = event.currentTarget;

        setSourceOrgValid(sourceOrg !== null);
        setDestOrgValid(destOrg !== null);
        setSelectedProjectValid(selectedProject !== null);
        setDefaultUserValid(defaultUser !== null);
        setModelIdsValid(modelIds !== null);


        if (
            form.checkValidity() &&
            sourceOrg !== null &&
            destOrg !== null &&
            selectedProject !== null &&
            defaultUser !== null &&
            modelIds !== null
        ) {
            setFormDisabled(true);

            toastid.current = ToastLoading("Contacting server");

            StartCopyProjectStateMachine(selectedProject.value.ProjectId, destOrg.value.OrganisationId, defaultUser.value, modelIds.map(mi => mi.value))
                .then(res => {
                    ToastSuccess("Project copy started. Wait for progress messages.", toastid.current, 2000);
                })
                .catch(err => {
                    ToastError("Failed to start project copy: " + err.message, err.cause, toastid.current);
                });



            setFormDisabled(false);

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    logger.debug("sourceOrg", sourceOrg);

    return (
        <Container className="border border-dark mx-2 my-2">
            <h2>Copy Project and Models</h2>
            <Form noValidate validated={validated} onSubmit={onSubmitClicked}>
                <Form.Group controlId="formGroupDbAddress" as={Row}>
                    <Form.Label column sm={12} >
                        Copy a project and selected models from one organisation to another<br />
                        <br />
                        No permissions will be copied<br />
                        To assign ownership of annotations, select a user in the destination organisation.<br />
                        <br />
                    </Form.Label>
                </Form.Group>
                <Form.Group controlId="formGroupSourceOrg" as={Row}>
                    <Form.Label column sm={2} >Organisation to copy from:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={sourceOrg}
                            onChange={setSourceOrg}
                            closeMenuOnSelect={true}
                            name="sourceOrg"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Organisation to copy from..."
                            options={orgsInfo.organisations.map(o => ({ value: o, label: o.Title }))} />
                        {(!sourceOrgValid && validated) &&
                            <Form.Text className="text-danger">Please select org to copy from.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupDestOrg" as={Row}>
                    <Form.Label column sm={2} >Organisation to copy to:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={destOrg}
                            onChange={setDestOrg}
                            closeMenuOnSelect={true}
                            name="destOrg"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Destination Organisation..."
                            options={orgsInfo.organisations.map(o => ({ value: o, label: o.Title }))} />
                        {(!destOrgValid && validated) &&
                            <Form.Text className="text-danger">Please select org to copy project to.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupProject" as={Row}>
                    <Form.Label column sm={2} >Project to copy:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={selectedProject}
                            onChange={setSelectedProject}
                            closeMenuOnSelect={true}
                            name="project"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Project to copy..."
                            options={sourceOrg?.value?.Projects?.map(p => ({ value: p, label: p.Title }))} />
                        {(!selectedProjectValid && validated) &&
                            <Form.Text className="text-danger">Please select project to copy.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupModels" as={Row}>
                    <Form.Label column sm={2} >Models to copy:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={modelIds}
                            onChange={setModelIds}
                            closeMenuOnSelect={false}
                            isMulti
                            name="models"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Models to copy..."
                            options={selectedProject?.value?.Models?.map(p => ({ value: p.ModelId, label: p.Title }))} />
                        {(!modelIdsValid && validated) &&
                            <Form.Text className="text-danger">Please select models to copy.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupDefaultUser" as={Row}>
                    <Form.Label column sm={2} >User to own annotations:</Form.Label>
                    <Col sm={8}>
                        <Select
                            value={defaultUser}
                            onChange={setDefaultUser}
                            closeMenuOnSelect={true}
                            name="defaultUser"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Click to Select Default User..."
                            options={destOrg?.value?.Users?.map(o => ({ value: o.UserId, label: o.UserName }))} />
                        {(!defaultUserValid && validated) &&
                            <Form.Text className="text-danger">Please select default user.</Form.Text>}
                    </Col>
                </Form.Group>
                <Form.Group controlId="formGroupButton" as={Row}>
                    <Col sm={{ offset: 2, span: 2 }} >
                        <Button disabled={formDisabled} type='submit'>Copy</Button>
                    </Col>
                </Form.Group>
            </Form>
        </Container>
    );

}

export default CopyProjectComponent;