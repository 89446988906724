import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import '../css/crs.css';
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { IconContext } from "react-icons";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";
import * as Constants from "../constants"


export default function CustomNode(props) {
    const { data } = props;

    const [buttonsVisible, setButtonsVisible] = useState(false);

    function buttonPanelClassName() {
        return buttonsVisible ? "crs-node-buttons-panel crs-node-buttons-panel-open" : "crs-node-buttons-panel crs-node-buttons-panel-closed";
    }

    return (
        <>
            <Handle
                type="target"
                position="left"
                style={{ bottom: 30, top: 'auto', background: '#555' }}
            />
            <div className="crs-node" onMouseLeave={() => setButtonsVisible(false)} onMouseEnter={() => { setButtonsVisible(true); data.onMouseOver(data.crs); }}>
                {(data.permissionEdit() || data.permissionLock())  &&
                    <div className="crs-node-buttons-panel-parent">
                        <div className={buttonPanelClassName()}>
                            <IconContext.Provider value={{ className: 'button-icons' }}>
                                {(buttonsVisible && data.permissionLock()) &&
                                    <button onMouseEnter={() => setButtonsVisible(true)} className="icon-button" onClick={() => data.onToggleCrsLock(data.crs)}>
                                        {data.crs.IsLocked ? <BsLockFill className="node-button-icon" /> : <BsUnlockFill className="node-button-icon" />}
                                    </button>
                                }
                                {(buttonsVisible && !data.crs.IsLocked && data.permissionEdit()) &&
                                    <button onMouseEnter={() => setButtonsVisible(true)} className="icon-button" onClick={() => data.onEditCrs(data.crs)}>
                                        <BiEditAlt className="node-button-icon"/>
                                    </button>}
                                {(buttonsVisible && !data.crs.IsNative && !data.crs.IsLocked && data.permissionEdit()) &&
                                    <button onMouseEnter={() => setButtonsVisible(true)} className="icon-button" onClick={() => data.onDeleteCrs(data.crs)}>
                                        <MdDeleteForever className="node-button-icon" />
                                    </button>}
                            </IconContext.Provider>
                        </div>
                    </div>
                }
                <div className="crs-node-title" >
                    {data.crsTitle}
                </div>
                <div className="crs-node-body text-line-breaks" >
                    {data.crs.Notes}
                    <br /><br />
                    <div>
                        Unit: {Constants.CrsUnits[data.crs.Unit]}
                    </div>
                </div>
            </div>
            <Handle
                type="source"
                position="right"
                style={{ bottom: 30, top: 'auto', background: '#555' }}

            />
        </>
    );
}