import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../css/drag-drop.css";
import "../css/crs.css";

import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { TransformStepTypeElement, TransformStepElement, createStepObject } from './TransformSteps'
import { v4 as uuid } from "uuid";
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

const stepTypes = Array.from({ length: 7 }, (v, k) => ({
    id: "id:" + uuid(),
    Type: k,
    isTypeOnly: true
}));

//when dragging - show if it is about to be deleted or in the
//right place to drop - or static
function getListRowClassName(snapshot) {

    if (snapshot.isDragging) {
        if (!snapshot.draggingOver) {
            if (snapshot.isDropAnimating) {
                return " hideDropAnimation "
            }
            else {
                return " draggingItemToDelete ";
            }
        }
        else {
            return " transformStepTypeItemDragging ";
        }
    }
    return " transformStepTypeItem ";

}





//################################################################################################
// render fn
//################################################################################################
export function CrsTransformDialog(props) {
    const [steps, setSteps] = useState([]);
    const [listInValid, setListInValid] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {

        //copy steps
        //add ids for list reordereing
        if (props.show) {
            BeaconSetSuggestions(props.helpContext);
            let copiedSteps = JSON.parse(JSON.stringify(props.crsTransform.TransformSteps));
            copiedSteps.forEach(element => {
                element.id = "id:" + uuid();
                element.isInvalid = false;
            });
            setSteps(copiedSteps);
        }
        else {
            BeaconSetSuggestions(props.parentHelpContext);
            setSteps([]);
        }
    }, [props.crsTransform, props.helpContext, props.parentHelpContext, props.show])


    //transform step render
    function StepItem(props) {
        return (

            props.item.isTypeOnly ?
                <TransformStepTypeElement className={props.snapshot.isDragging ? "transformStepTypeItemDragging" : "transformStepTypeItem"} transformStep={props.item} /> :
                <TransformStepElement className={getListRowClassName(props.snapshot)} transformStep={props.item} />
        );
    }

    //static row in the list
    function ListRow(props) {
        const item = props.data[props.index];
        return (
            <Draggable draggableId={item.id} index={props.index} key={item.id}>
                {(provided, snapshot) =>
                (

                    <div
                        // style={getStyle(provided, props.style, snapshot.isDragging)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <StepItem item={item} snapshot={snapshot} />
                    </div>

                )}
            </Draggable>
        );
    }


    // This method is needed for rendering clones of draggables, as they are being dragged about
    const getRenderItem = (items, style) => (provided, snapshot, rubric) => {
        const item = items[rubric.source.index];
        return (
            <>
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <StepItem item={item} snapshot={snapshot} />
                </div>
            </>
        );
    };


    function reorderSteps(startIndex, endIndex) {
        const reordered = Array.from(steps);
        const [removed] = reordered.splice(startIndex, 1);
        reordered.splice(endIndex, 0, removed);

        setSteps(reordered);
    }

    function insertIntoSteps(item, insertIndex) {

        const inserted = Array.from(steps);
        let newItem = createStepObject(item.Type);
        inserted.splice(insertIndex, 0, newItem);

        setSteps(inserted);
    }

    function removeFromSteps(index) {

        const removed = Array.from(steps);
        removed.splice(index, 1);

        setSteps(removed);
    }

    function isValid() {

        //are there any invlid transform steps to
        for (let index = 0; index < steps.length; index++) {
            const step = steps[index];
            if (step.isInvalid) {
                return false;
            }
        }

        return true;
    }


    function onDragEnd(result) {

        //source - transform steps in the crs transform
        if (result.source.droppableId === "transformSteps") {

            if (!result.destination || result.destination.droppableId === "transformStepTypes") {
                removeFromSteps(result.source.index);
                return;
            }
            else if (result.destination.droppableId === "transformSteps") {
                if (result.source.index !== result.destination.index) {
                    reorderSteps(result.source.index, result.destination.index);
                    return;
                }
            }
        }
        //source - transform step toolbox
        else if (result.source.droppableId === "transformStepTypes") {

            if (!result.destination) {
                return;
            }
            else if (result.destination.droppableId === "transformSteps") {
                insertIntoSteps(stepTypes[result.source.index], result.destination.index);
                return;
            }
        }

    }
    //just hide the dialog - cancel.
    function onClose() {
        props.setShow(false);
    }

    function onSave() {

        if (!isValid()) {
            setListInValid(true);
            setTimeout(() => { setListInValid(false) }, 200);
            return;
        };

        //copy new steps to the original conversion and pass up to the parent
        let copiedCrsTransform = JSON.parse(JSON.stringify(props.crsTransform));
        copiedCrsTransform.TransformSteps = steps;
        props.onSave(copiedCrsTransform, setUiDisabled);

    }

    function getDraggingOverStepListClassName(snapshot) {

        if (snapshot.isDraggingOver && !snapshot.draggingFromThisWith) {
            return " isDraggingOverStepList "
        }

        return "";
    }

    function getValidityClassName() {

        return !listInValid ? "" : " steplist-glow-red ";

    }


    function StepList(props) {
        return (
            <Droppable
                renderClone={getRenderItem(props.items, props.className)}
                droppableId="transformSteps"
            >
                {(provided, snapshot) => (
                    <ul
                        ref={provided.innerRef}
                        className={"steplist" + getDraggingOverStepListClassName(snapshot) + getValidityClassName()}
                    >
                        {props.items.map((item, index) => {
                            return (
                                <li key={item.id}>
                                    <ListRow data={props.items} index={index} />
                                </li>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}

            </Droppable>
        );

    }



    //a fixed size list that copies items dragged from it
    function StepTypeList(props) {

        return (
            <Droppable
                renderClone={getRenderItem(props.items, props.className)}
                droppableId="transformStepTypes"
                isDropDisabled={true}
            >
                {(provided, snapshot) => (
                    <div
                        className={props.className}
                        ref={provided.innerRef}
                    >
                        {props.items.map((item, index) => {
                            const shouldRenderClone = item.id === snapshot.draggingFromThisWith;
                            return (
                                <React.Fragment key={item.id}>
                                    {shouldRenderClone ? (
                                        <TransformStepTypeElement className="react-beatiful-dnd-copy transformStepTypeItem" transformStep={item} isDragging={false} />
                                    ) : (
                                        <ListRow data={props.items} index={index} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}

            </Droppable>
        );
    }


    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
            dialogClassName="modal-850w"
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Container >
                        <Row>
                            <div className="ps-4 pb-4">Drag new transforms onto the list to add them. Drag them away to remove.</div>
                        </Row>

                        <Row>
                            <Col>
                                <StepList items={steps} />
                            </Col>
                            <Col>
                                <StepTypeList items={stepTypes} className="stepTypeList" />
                            </Col>
                        </Row>
                    </Container>
                </DragDropContext>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>Save</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );
}

export default CrsTransformDialog;
