import "../css/error.css"

import { Logger } from 'aws-amplify';
const logger = new Logger('ErrorPage');

export default function ErrorPage(props) {

    logger.info(props);

    return (
        <div className="errorContainer">
            <h1 className="errorItem">An error has occurred</h1>
            <hr class="dashed-line" />

            <p className="errorItem">If this error persists please copy the error details below and send it to us in a message - you can use the message facility on the bottom-right of this page.</p>
            {props.resetErrorBoundary && (
                <div className="mb-3">
                    <p className="errorItem">Or click the button below to attempt the operation again:</p>
                    <button className="retry-button" onClick={props.resetErrorBoundary}>
                        🔄 Try Again...
                    </button>
                </div>
            )}

            <div className="errorMessageContainer">
                <h4 className="errorMessageLabel">Error:</h4>
                <h4 className="errorMessageItem">{props.error.message}</h4>
            </div>
            <div className="errorStack">{props.error.stack}</div>
        </div>
    );
}