import React, { useState, useEffect, useRef } from "react";
import { VariableSizeList as List } from 'react-window';
import Button from 'react-bootstrap/Button'
import { v4 as uuid } from "uuid";
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import '../css/lists.css'
import '../css/annotation-properties.css'

import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import {
    DeleteValuePropertyTemplate, DeleteListPropertyTemplate,
    UpdateValuePropertyTemplate, UpdateListPropertyTemplate,
    CreateValuePropertyTemplate, CreateListPropertyTemplate
} from '../Api/AnnotationPropertiesApi'
import { EditPropertyTemplate } from './EditPropertyTemplate'
import { ConfirmModal } from '../Components/ConfirmModal'

import { CanEditAnnotationTemplatesOrStyleSheets } from '../App/UserPermissions'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('PropertyTemplatesView');

function ValueTypeToString(type) {

    switch (type) {
        case 0:
            return "Text"
        case 1:
            return "Integer"
        case 2:
            return "Float"

        default:
            return "??"
    }

};
export function PropertyTemplateDisplay(props) {

    //which type - value or list
    if (props.propertyTemplate) {
        //value
        if (props.propertyTemplate.PropertyType === 0) {
            return (
                <div className={"annotationPropertyItemContainer " + props.className}>
                    <div className="annotationPropertyItemLabel">
                        {props.propertyTemplate.Title}
                    </div>
                    <div className="annotationPropertyItemValue">
                        Value Type: {"[" + ValueTypeToString(props.propertyTemplate.ValueType) + "]"}
                        {props.propertyTemplate.ValueType > 0 && props.propertyTemplate.PositiveOnly ? "   (Positive Only)" : ""}
                    </div>
                </div>
            );
        }
        //list
        else {
            const items = props.propertyTemplate.ListItems ? props.propertyTemplate.ListItems.map((item) =>
                <div key={uuid()} className={"me-1 mt-1 p-0 ps-1 pe-1 rounded border border-light bg-primary text-white"}>
                    {item.ItemText}
                </div>
            ) : <></>
            //d-flex flex-wrap pl-0 text-black
            return (
                <div className={"annotationPropertyItemContainer " + props.className}>
                    <div className="annotationPropertyItemLabel">
                        {props.propertyTemplate.Title}
                    </div>
                    <div className="annotationPropertyItemValue">
                        <div className="d-flex flex-wrap ps-0 text-black">{items}
                            <div className="">
                                {props.propertyTemplate.MultiSelect ? "(Multi-Select)" : "(Single-Select)"}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
    else {
        return (<></>);
    }
};


export function PropertyTemplatesView(props) {

    logger.debug("PropertyTemplatesView props", props);

    const propertyTemplatesList = useRef();
    const rowHeights = useRef({});
    const [selectedPropertyTemplateId, setSelectedPropertyTemplateId] = useState("");
    const [showPropertyTemplateAddDialog, setShowPropertyTemplateAddDialog] = useState(false);
    const [showPropertyTemplateEditDialog, setShowPropertyTemplateEditDialog] = useState(false);
    const toastId = useRef();

    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    React.useEffect(() => {
        if (props.show) {
            BeaconSetSuggestions(props.helpContext);
        }
        else {
            BeaconSetSuggestions(props.parentHelpContext);
        }
    }, [props.helpContext, props.parentHelpContext, props.show])


    React.useEffect(() => {
        ///select the first Template
        if (props.project) {
            if (props.project.PropertyTemplates?.length) {
                setSelectedPropertyTemplateId(props.project.PropertyTemplates[0].PropertyTemplateId);
            }
        }
    }, [props.project]);


    function onAdd(newPropertyTemplate, setUiDisabled) {


        toastId.current = ToastLoading("Adding Property Template");
        setUiDisabled(true);

        if (newPropertyTemplate.PropertyType === 1) {
            CreateListPropertyTemplate(newPropertyTemplate)
                .then(res => {
                    ToastSuccess("Added [" + newPropertyTemplate.Title + "]", toastId.current, 5000);
                    setShowPropertyTemplateAddDialog(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
        else {
            CreateValuePropertyTemplate(newPropertyTemplate)
                .then(res => {
                    ToastSuccess("Added [" + newPropertyTemplate.Title + "]", toastId.current, 5000);
                    setShowPropertyTemplateAddDialog(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
    };


    function onUpdate(updatedPropertyTemplate, setUiDisabled) {
        toastId.current = ToastLoading("Updating Property Template");
        setUiDisabled(true);


        if (updatedPropertyTemplate.PropertyType === 1) {
            UpdateListPropertyTemplate(updatedPropertyTemplate)
                .then(res => {
                    ToastSuccess("Updated [" + updatedPropertyTemplate.Title + "]", toastId.current, 5000);
                    setShowPropertyTemplateEditDialog(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
        else {
            UpdateValuePropertyTemplate(updatedPropertyTemplate)
                .then(res => {
                    ToastSuccess("Updated [" + updatedPropertyTemplate.Title + "]", toastId.current, 5000);
                    setShowPropertyTemplateEditDialog(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }

    };



    async function onDelete() {

        if (selectedPropertyTemplateId !== "") {
            let propertyToDelete = props.project.PropertyTemplates.find(item => item.PropertyTemplateId === selectedPropertyTemplateId);

            toastId.current = ToastLoading("Deleting Property Template");
            if (propertyToDelete.PropertyType === 1) {
                DeleteListPropertyTemplate(selectedPropertyTemplateId)
                    .then(res => {
                        ToastSuccess("Deleted [" + propertyToDelete.Title + "]", toastId.current, 5000);
                    })
                    .catch(err => {
                        ToastError(err.message, err.cause, toastId.current);
                    });
            }
            else {
                DeleteValuePropertyTemplate(selectedPropertyTemplateId)
                    .then(res => {
                        ToastSuccess("Deleted [" + propertyToDelete.Title + "]", toastId.current, 5000);
                    })
                    .catch(err => {
                        ToastError(err.message, err.cause, toastId.current);
                    });
            }
        }
    }

    function SelectedPropertyTemplate() {
        return props.project?.PropertyTemplates?.find(item => item.PropertyTemplateId === selectedPropertyTemplateId);
    }


    function setRowHeight(index, size) {
        propertyTemplatesList.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }

    function getRowHeight(index) {
        if (rowHeights?.current[index]) {
            return rowHeights.current[index] + 1;
        }
        else {
            return 60;
        }
    }



    function ListRowPropertyTemplate({ index, style }) {

        const rowRef = useRef({});

        useEffect(() => {
            if (rowRef.current) {
                setRowHeight(index, rowRef.current.clientHeight);
            }
            // eslint-disable-next-line
        }, [rowRef]);

        return (
            <div style={style}>
                <div role="button" className="m-0 p-0"

                    onClick={() => {
                        //set group and prompt re-draw
                        setSelectedPropertyTemplateId(props.project.PropertyTemplates[index].PropertyTemplateId);
                    }}
                    onDoubleClick={() => {
                        //set group and prompt re-draw
                        setSelectedPropertyTemplateId(props.project.PropertyTemplates[index].PropertyTemplateId);
                        setShowPropertyTemplateEditDialog(true);
                    }}
                    ref={rowRef}
                >
                    <PropertyTemplateDisplay propertyTemplate={props.project.PropertyTemplates[index]} className={selectedPropertyTemplateId === props.project.PropertyTemplates[index].PropertyTemplateId ?
                        "p-2 mt-0 border border-top-0 border-left-0 rounded border-dark bg-primary text-white" :
                        "p-2 mt-0 border border-top-0 border-left-0 rounded border-dark bg-light"} />

                </div>
            </div>
        );
    }

    function onClose() {
        props.setShow(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="false"
            keyboard={false}
            centered
            dialogClassName="modal-500w"
        >
            <Modal.Header closeButton>
                <Modal.Title>Annotation Property Templates:</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Row>
                    <div className="ps-3">
                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project?.ProjectId, props.project?.OrganisationId) ?
                            <Button style={{ float: "right" }} className="list-top-button" onClick={() => setShowPropertyTemplateAddDialog(true)}>Add</Button>
                            : <></>
                        }
                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project?.ProjectId, props.project?.OrganisationId) ?
                            <Button style={{ float: "right" }} className="list-top-button" onClick={() => { if (SelectedPropertyTemplate()) setShowPropertyTemplateEditDialog(true) }}>Edit</Button>
                            : <></>
                        }
                        {CanEditAnnotationTemplatesOrStyleSheets(props.currentUser, props.project?.ProjectId, props.project?.OrganisationId) ?
                            <Button style={{ float: "right" }} className="list-top-button" onClick={() => {
                                setShowConfirmDeleteDialog(true);
                            }}>Delete</Button>
                            : <></>
                        }

                    </div>
                </Row>


                <div className="properties-view">
                    <List
                        ref={propertyTemplatesList}
                        height={600}
                        itemCount={props?.project?.PropertyTemplates ? props.project.PropertyTemplates.length : 0}
                        itemSize={getRowHeight}
                        width={470}
                        style={{ backgroundColor: "#EEEEEE" }}
                        className="mt-2 border border-dark rounded"
                    >
                        {ListRowPropertyTemplate}
                    </List>

                </div>
                <EditPropertyTemplate
                    helpContext={BeaconDialogMap.EditAnnotationPropertyTemplateDialog} parentHelpContext={props.helpContext}
                    onSave={onAdd}
                    isEditing={false}
                    show={showPropertyTemplateAddDialog}
                    setShow={setShowPropertyTemplateAddDialog}
                    dialogTitle="Add Property Template"
                    existingPropertyTemplates={props.project?.PropertyTemplates}
                    propertyTemplate={{
                        PropertyTemplateId: "00000000-0000-0000-0000-000000000000",
                        Title: "",
                        PositiveOnly: false,
                        ValueType: 0,
                        PropertyType: 0,
                        ProjectId: props.project?.ProjectId
                    }}
                />
                {SelectedPropertyTemplate() &&
                    <EditPropertyTemplate
                        helpContext={BeaconDialogMap.EditAnnotationPropertyTemplateDialog} parentHelpContext={props.helpContext}
                        onSave={onUpdate}
                        isEditing={true}
                        show={showPropertyTemplateEditDialog}
                        setShow={setShowPropertyTemplateEditDialog}
                        dialogTitle="Edit Property Template"
                        existingPropertyTemplates={props.project.PropertyTemplates}
                        propertyTemplate={SelectedPropertyTemplate()}

                    />
                }
                <ConfirmModal
                    helpContext={BeaconDialogMap.DeleteAnnotationPropertyTemplateDialog} parentHelpContext={props.helpContext}
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDelete}
                    okText="Delete"
                    dialogTitle="Delete Property Template"
                    dialogBody="Are you sure you want to delete?"
                />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>


        </Modal>

    );

}

export default PropertyTemplatesView;