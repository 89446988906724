
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button'


import "./style.css"

export function LogView(props) {

    const { visible, setVisible, log } = props;

    return (
        visible &&
        <div className="log-tabs">
            <Button onClick={() => { setVisible(false); }} className="close-button">Close</Button>

            <Tabs defaultActiveKey="unityLog">

                <Tab eventKey="unityLog" title="Unity" >
                    {
                        log.UnityLog.split("\n").map(function (item, i) {
                            return (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })
                    }
                </Tab>

                <Tab eventKey="builderLog" title="Builder" >
                    {
                        log.BuilderLog.split("\n").map(function (item, i) {
                            return (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })
                    }
                </Tab>
                
                <Tab eventKey="stepFunctionLog" title="Step Function Output" >
                    {
                        log.StepFunctionOutput.split("\n").map(function (item, i) {
                            return (
                                <span key={i}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })
                    }
                </Tab>

            </Tabs>
        </div>
    )
}