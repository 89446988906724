import React from 'react'
import { OrgsSelect } from './OrgsSelect';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { canUserSeeOrgsPage, canUserSeeUtilsPage, CanReadBuildLogs } from '../App/UserPermissions'
import '../css/navbar.css';

// import { Logger } from 'aws-amplify';
// const logger = new Logger('OrgMenu');


export function OrgMenu(props) {

    const { user, orgsInfo, className, parentHelpContext } = props;

    const currentOrg = orgsInfo?.organisations?.find(o => o.OrganisationId === orgsInfo.currentOrgId);

    return (
        <div className={className}>
            <DropdownButton id="OrgMenu" title={currentOrg?.Title} align="end" >
                <Dropdown.Header >Current organisation:</Dropdown.Header>
                <Dropdown.ItemText >{currentOrg?.Title}</Dropdown.ItemText>
                {canUserSeeOrgsPage(user, orgsInfo.currentOrgId) &&
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Header >Manage organisation:</Dropdown.Header>
                        <Dropdown.Item href="#/organisation">Settings</Dropdown.Item>
                        <Dropdown.Item href="#/users">Users</Dropdown.Item>
                        <Dropdown.Item href="#/groups">Groups</Dropdown.Item>
                    </>
                }
                {(orgsInfo.organisations.length > 1) &&
                    <>
                        <Dropdown.Divider />
                        <Dropdown.Header>Select organisation:</Dropdown.Header>
                        <Dropdown.ItemText >
                        <OrgsSelect parentHelpContext={parentHelpContext} />
                        </Dropdown.ItemText>
                    </>
                }
                {canUserSeeUtilsPage(user) &&
                <>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/utils">System Settings</Dropdown.Item>

                </>}
                {CanReadBuildLogs(user) &&
                <>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/importlogs">Model Import Logs</Dropdown.Item>

                </>}
            </DropdownButton>
        </div>
    );
}