import React from "react";
import { useSelector } from 'react-redux';
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice'
import { UserLicenseList } from "../Components/UserLicenseList";
import { OrgLicenseList } from "../Components/OrgLicenseList";
import { OrgInfoPanel } from "../Components/OrgInfoPanel";
import { GroupsSharedInList, UsersSharedInList, UsersSharedOutList, GroupsSharedOutList } from '../Components/ShareLists';
import { CanEditShares } from '../App/UserPermissions'
import { BeaconDialogMap, BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import '../css/page.css'
//import { Logger } from 'aws-amplify';
//const logger = new Logger('HomePage');


function OrganisationPage(props) {

    const { setMainPageHelpContext } = props;

    const helpContext = BeaconDialogMap.OrganisationSettingsPage;
    React.useEffect(() => {
        BeaconSetSuggestions(helpContext);
        setMainPageHelpContext(helpContext);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const orgsInfo = useSelector(selectOrganisationsInfo);
    const currentOrg = orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId);

    return (

        < div className="page-bottom-panel-flex">
            <div className="page-content-panel-flex">
                <OrgInfoPanel currentOrg={currentOrg} />
                <UserLicenseList parentHelpContext={helpContext} currentUser={orgsInfo?.currentUser} currentOrg={currentOrg} orgs={orgsInfo?.organisations} />
                <OrgLicenseList parentHelpContext={helpContext} currentUser={orgsInfo?.currentUser} currentOrg={currentOrg} orgs={orgsInfo?.organisations} />
                <GroupsSharedOutList parentHelpContext={helpContext} currentOrg={currentOrg} canEdit={CanEditShares(orgsInfo.currentUser)} />
                <GroupsSharedInList parentHelpContext={helpContext} currentOrg={currentOrg} canEdit={CanEditShares(orgsInfo.currentUser)} />
                <UsersSharedOutList parentHelpContext={helpContext} currentOrg={currentOrg} canEdit={CanEditShares(orgsInfo.currentUser)} />
                <UsersSharedInList parentHelpContext={helpContext} currentOrg={currentOrg} canEdit={CanEditShares(orgsInfo.currentUser)} />
            </div>
        </div>
    );
}

export default OrganisationPage;