import React, { useState, useRef } from 'react'

import * as Constants from '../constants.js'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('CRSDialog');

const crsUnitsOptions = [];
Constants.CrsUnits.forEach((element, index) => {
    crsUnitsOptions.push({ value: index, label: element })
});


export function CrsDialog(props) {

    const form = useRef(null);

    const [title, setTitle] = useState();
    const [notes, setNotes] = useState();
    const [unit, setUnit] = useState();

    const [validated, setValidated] = useState(false);
    const [unitValid, setUnitValid] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (props.show) {
            BeaconSetSuggestions(props.helpContext);
            if (!uiDisabled) {
                setTitle(props.crs.Title);
                setNotes(props.crs.Notes);
                setUnit({ value: props.crs.Unit, label: props.crs.Unit > -1 ? Constants.CrsUnits[props.crs.Unit] : "" });
            }
        }
    }, [props.crs, props.helpContext, props.parentHelpContext, props.show, uiDisabled])

    function onSave(event) {
        event.preventDefault();

        setUnitValid(unit.value > -1 ? true : false);
        if (form.current.checkValidity() && unit.value > -1) {
            //copy crs
            let newCrs = JSON.parse(JSON.stringify(props.crs));

            //update with form values
            newCrs.Title = title;
            newCrs.Notes = notes;
            newCrs.Unit = unit.value;
            props.onSave(newCrs, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        props.setShow(false);
        BeaconSetSuggestions(props.parentHelpContext);
    }

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="border border-dark mx-2 my-2">
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupTitle" as={Row}>
                            <Form.Label column sm={2} >Title:</Form.Label>
                            <Col sm={10}>
                                {props.crs?.IsNative ?
                                    <Form.Control readOnly plaintext value={props.crsTitle} />
                                    :
                                    <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='CRS Title...' />
                                }
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupNotes" as={Row}>
                            <Form.Label column sm={2} >Notes:</Form.Label>
                            <Col sm={10}>
                                <Form.Control as="textarea" rows={3} value={notes} onChange={(e) => setNotes(e.target.value)} placeholder='CRS Notes...' />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupUnits" as={Row}>
                            <Form.Label column sm={2} >Model Units:</Form.Label>
                            <Col sm={10}>
                                <Select
                                    value={unit}
                                    onChange={setUnit}
                                    closeMenuOnSelect={true}
                                    name="crsUnit"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select a Unit..."
                                    options={crsUnitsOptions} />
                                {(!unitValid && validated) &&
                                    <Form.Text className="text-danger">
                                        Please select a unit.
                                    </Form.Text>
                                }
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default CrsDialog;