import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api-Group');


export async function CreateGroup(group) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: group
    }

    let response = await API.post('Reveal4DApi', '/CreateGroup', options);
    logger.debug("CreateGroup , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function UpdateGroup(group) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: group
    }

    let response = await API.put('Reveal4DApi', '/UpdateGroup', options);
    logger.debug("UpdateGroup , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return group;
    }

}


export async function DeleteGroup(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            groupid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteGroup', options);
    logger.debug("DeleteGroup , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}

export async function AddUserToGroup(userId, groupId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            userid: userId,
            groupid: groupId
        }
    }

    let response = await API.put('Reveal4DApi', '/AddUserToGroup', options);
    logger.debug("AddUserToGroup , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}
