import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');

export async function CreateGroupShare(groupShare) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: groupShare
    }

    let response = await API.post('Reveal4DApi', '/CreateGroupShare', options);
    logger.debug("CreateGroupShare , response: ", options, response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function DeleteGroupShare(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            groupshareid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteGroupShare', options);
    logger.debug("DeleteGroupShare , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}

export async function CreateUserShare(userShare) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: userShare
    }

    let response = await API.post('Reveal4DApi', '/CreateUserShare', options);
    logger.debug("CreateUserShare , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function ShareAnnotationSetWithExternalUser(UserName, Permissions, ModelId) {
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            username: UserName,
            permissions: Permissions,
            modelid: ModelId
        }
    }

    let response = await API.post('Reveal4DApi', '/ShareAnnotationSetWithExternalUser', options);
    logger.debug("ShareAnnotationSetWithExternalUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function ShareModelWithExternalUser(UserName, Permissions, ModelId, Propagate) {
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            username: UserName,
            permissions: Permissions,
            modelid: ModelId,
            propagate: Propagate
        }
    }

    let response = await API.post('Reveal4DApi', '/ShareModelWithExternalUser', options);
    logger.debug("ShareModelWithExternalUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function ShareProjectWithExternalUser(UserName, Permissions, ProjectId, Propagate) {
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            username: UserName,
            permissions: Permissions,
            projectid: ProjectId,
            propagate: Propagate
        }
    }

    logger.debug("ShareProjectWithExternalUser , body: ", options);
    let response = await API.post('Reveal4DApi', '/ShareProjectWithExternalUser', options);
    logger.debug("ShareProjectWithExternalUser , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function DeleteUserShare(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            usershareid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteUserShare', options);
    logger.debug("DeleteUserShare , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}
