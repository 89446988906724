import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';

import Button from 'react-bootstrap/Button'
import { GetBuildLogRecordLog, DeleteBuildLogRecord } from '../Api/BuildApi'
import { kaReducer, Table } from 'ka-table';
import { DataType, FilteringMode, SortingMode, ActionType } from 'ka-table/enums';
import {
    deselectAllFilteredRows, deselectRow,
    selectAllFilteredRows, selectRow, selectRowsRange,
    loadData, updateData, setSingleAction
} from 'ka-table/actionCreators';
import { Confirm } from 'react-st-modal';

import { BsLockFill } from "react-icons/bs";

import { kaPropsUtils } from 'ka-table/utils';
import { ToastClose, ToastError, ToastLoading } from '../Components/ToastDisplay';

import '../css/table-style.scss'
import "./style.css"

import { fullUserTitle, fullUsersName } from '../App/Utils';

import { canUserSeeFullBuildLog, canUserSeeAllBuildLogsInOrg } from '../App/UserPermissions';

import { LogView } from './LogView';

import { Logger } from 'aws-amplify';
const logger = new Logger('BuildLogTable');

const ValueCell = (props) => {
    if (props.column.dataType === DataType.Number) {

        if (props?.value === null) {
            return (
                <>
                    {"---"}
                </>
            );
        }
        else {

            return (
                <>
                    {isNaN(props?.value) ? "[Empty]" : props?.value}
                </>
            );
        }
    }
    else {
        return (
            <>
                {props?.value ? props?.value : "[Empty]"}
            </>
        );
    }
};


const SelectionCell = (props) => {


    return (
        <>
            <input
                type='checkbox'
                checked={props.isSelectedRow}
                onChange={(event) => {
                    if (event.nativeEvent.shiftKey) {
                        props.dispatch(selectRowsRange(props.rowKeyValue, [...props.selectedRows].pop()));
                    } else if (event.currentTarget.checked) {
                        props.dispatch(selectRow(props.rowKeyValue));
                    } else {
                        props.dispatch(deselectRow(props.rowKeyValue));
                    }
                }}
            />
            {props.value && <BsLockFill className="ms-1 mb-2" />}
        </>
    );
};

const SelectionHeader = ({ dispatch, areAllRowsSelected }) => {
    return (
        <input
            type='checkbox'
            checked={areAllRowsSelected}
            onChange={(event) => {
                if (event.currentTarget.checked) {
                    dispatch(selectAllFilteredRows()); // also available: selectAllVisibleRows(), selectAllRows()
                } else {
                    dispatch(deselectAllFilteredRows()); // also available: deselectAllVisibleRows(), deselectAllRows()
                }
            }}
        />
    );
};

const deleteLogsToastId = 1;

//====================================================================================

export function BuildLogTable(props) {
    logger.debug("BuildLogTable(props)", props);

    const orgsInfo = useSelector(selectOrganisationsInfo);

    const [logViewVisible, setLogViewVisible] = useState(false);
    const [currentLog, setCurrentLog] = useState(null);
    const [tableProps, setTableProps] = useState(tablePropsInit());

    function currentOrg() {
        return orgsInfo?.organisations?.find(o => o.OrganisationId === orgsInfo?.currentOrgId);
    }

    //load data if org has changed
    useEffect(() => {

        setTableProps(tablePropsInit());
        dispatchTable(setSingleAction(loadData()));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrg()?.BuildLogRecords])


    function getLogText(id) {

        GetBuildLogRecordLog(id)

            .then(res => {
                setCurrentLog(res);
                setLogViewVisible(true);
            })
            .catch(err => {
                ToastError(err.message, err.cause);
            });
    }


    const ButtonCell = (props) => {
        return (
            <>
                <Button onClick={() => getLogText(props.rowData.id)}>Show Log</Button>
            </>
        );
    };



    function getDataItem(buildLogRecord) {

        let item =
        {
            id: buildLogRecord.BuildLogRecordId,
            selection: false,
            dateCreated: buildLogRecord.DateCreated,
            userCreated: fullUserTitle(buildLogRecord.UserId, orgsInfo?.organisations, orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo?.currentOrgId)),
            modelId: buildLogRecord.ModelTitle,
            result: buildLogRecord.ResultText
        };

        return item;
    }

    function dataArray(buildLogRecords) {
        let ret = buildLogRecords?.map((buildLogRecord, index) => (getDataItem(buildLogRecord)));
        return ret;
    }

    function tablePropsInit() {
        let tablePropObj = {

            columns: [
                { key: 'selection', isEditable: false, width: 18, visible: true },
                { key: 'id', title: 'ID', dataType: DataType.String, style: { width: 150 }, isEditable: false, visible: false },
            ],
            singleAction: loadData(),
            rowKeyField: 'id',
            sortingMode: SortingMode.Single,
            columnResizing: true,
            selectedRows: [],
            loading: { enabled: false },
            filteringMode: FilteringMode.None,
            format: ({ column, value }) => {
                if (column.dataType === DataType.Date) {
                    return <>
                        {value?.toLocaleDateString()} {value.toLocaleTimeString()}
                    </>
                }
            }
        }

        //add paging if > 1000 rows
        if (currentOrg()?.BuildLogRecords?.length > 1000) {
            tablePropObj.paging =
            {
                enabled: true,
                pageSize: 1000,
                pageIndex: 0
            };
        }

        //columns
        tablePropObj.columns.push({ key: 'modelId', title: "Model", isEditable: false, dataType: DataType.String, filterRowOperator: 'nofilter', style: { width: 150, minWidth: 100 } });
        tablePropObj.columns.push({ key: 'result', title: "Status", isEditable: false, dataType: DataType.String, filterRowOperator: 'nofilter', style: { width: 150, minWidth: 100 } });
        tablePropObj.columns.push({ key: 'dateCreated', sortDirection: "descend", title: "Created", isEditable: false, dataType: DataType.Date, filterRowOperator: 'nofilter', style: { width: 90, minWidth: 90 } });
        if (canUserSeeAllBuildLogsInOrg(orgsInfo?.currentUser, orgsInfo?.currentOrgId)) {
            tablePropObj.columns.push({ key: 'userCreated', title: "Created by", filterRowOperator: 'nofilter', isEditable: false, dataType: DataType.String, style: { width: 150, minWidth: 100 } });
        }
        if (canUserSeeFullBuildLog(orgsInfo?.currentUser)) {
            tablePropObj.columns.push({ key: 'getLog', title: "Text Log", filterRowOperator: 'nofilter', isEditable: false, dataType: DataType.String, style: { width: 100, minWidth: 100 } });
        }

        return tablePropObj;
    };


    ///reducer ==================================
    ///======= ==================================
    const dispatchTable = async (action) => {

        //built-in actions
        setTableProps((prevState) => kaReducer(prevState, action));

        if (action.type === ActionType.LoadData) {

            dispatchTable(updateData(dataArray(currentOrg()?.BuildLogRecords)));
        }
    };

    function numSelected() {
        const selectedData = kaPropsUtils.getSelectedData(tableProps);
        return selectedData.length;
    }

    async function onDeleteSelected() {

        const selectedData = kaPropsUtils.getSelectedData(tableProps);
        if (selectedData.length > 0) {

            const result = await Confirm("Are you sure you want to delete the selected Logs ?", "Delete Logs");
            if (result) {

                //get array of log ids
                let buildLogRecordIds = selectedData.map((item) => {
                    return item.id;
                })

                ToastLoading("Deleting Logs", deleteLogsToastId);

                try {
                    for (let index = 0; index < buildLogRecordIds.length; index++) {
                        unwrapResult(await DeleteBuildLogRecord(buildLogRecordIds[index]));
                    }
                    ToastClose(deleteLogsToastId);
                    dispatchTable(deselectAllFilteredRows());
                }
                catch (err) {
                    ToastError(err.message, err.cause, deleteLogsToastId);
                }

            }
        }
    }

    return (
        <>
            {logViewVisible ?
                <LogView visible={logViewVisible} log={currentLog} setVisible={setLogViewVisible} />
                :
                <>
                    <div className="log-view-top-panel">

                        <div className="log-view-title" >Model Import Logs</div>

                        <div >{canUserSeeAllBuildLogsInOrg(orgsInfo?.currentUser, orgsInfo?.currentOrgId) ?
                            "All models imported to organisation: " + currentOrg()?.Title
                            :
                            "Models imported to organisation " + currentOrg()?.Title + " by user: " + fullUsersName(orgsInfo?.currentUser)}
                        </div>


                        <div className="log-view-button-panel" >
                            <Button disabled={(numSelected() === 0)} className="log-view-button-panel-button" variant="primary" onClick={onDeleteSelected}>Delete Selected Logs</Button>
                        </div>

                    </div>

                    <div className="log-table-styles">
                        <Table
                            {...tableProps}

                            filteringMode={FilteringMode.None}
                            dispatch={dispatchTable}
                            childComponents={{
                                cellText: {
                                    content: (props) => {
                                        if (props.column.key === "selection") {
                                            return <SelectionCell {...props} />;
                                        }
                                        else if (props.column.key === "getLog") {
                                            return <ButtonCell {...props} />;
                                        }
                                        else if (props.column?.dataType === DataType.Number || props.column?.dataType === DataType.String) {
                                            return <ValueCell {...props} />
                                        }
                                        else if (props.column.dataType === DataType.Date) {

                                            if (isNaN(props.value.getTime())) {
                                                return <>---</>
                                            }

                                        }
                                    },
                                    elementAttributes: () => ({
                                        className: 'table-cell-text'
                                    })
                                },
                                headCell: {
                                    elementAttributes: (props) => {
                                        if (props.column.key === 'selection') {
                                            return {
                                                style: {
                                                    ...props.column.style,
                                                    position: 'sticky',
                                                    left: 0,
                                                    zIndex: 10,
                                                    border: '1px solid #555555'
                                                }
                                            }
                                        }
                                        else {
                                            return {
                                                style: {
                                                    ...props.column.style,
                                                    position: 'sticky',
                                                    border: '1px solid #555555',
                                                }
                                            }
                                        }
                                    },
                                    content: (props) => {
                                        if (props.column.key === 'selection') {
                                            return (
                                                <SelectionHeader {...props}
                                                    areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(tableProps)}
                                                />
                                            );
                                        }

                                    }
                                },
                                noDataRow: {
                                    content: () => 'No Logs'
                                },
                                cell: {
                                    elementAttributes: (props) => {
                                        if (props.column.key === 'selection') {
                                            return {
                                                style: {
                                                    ...props.column.style,
                                                    position: 'sticky',
                                                    left: 0,
                                                    backgroundColor: '#ffffff',
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                        />

                    </div>
                </>
            }
        </>
    );

}

export default BuildLogTable;