import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
//import { Logger } from 'aws-amplify';
import { GetApiSettings, GetSystemSettings } from '../Api/SystemApi'
//import { Logger } from 'aws-amplify';
//const logger = new Logger('SystemInfoSlice');

export const getApiSettings = createAsyncThunk('systemInfo/getApiSettings', async () => {
    return await GetApiSettings();
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
})
export const getSystemSettings = createAsyncThunk('systemInfo/getSystemSettings', async () => {
    return await GetSystemSettings();
}, {
    serializeError: (err) => {
        return { message: err.message, cause: err.cause };
    }
})


//api settings
// {
//     public int Version;
//     public string SecretsManager;
//     public string UserPoolId;
//     public string UserPoolClientId;
//     public string AwsRegion;
//     public string AssetBundleBucket;
//     public string DBInstanceAddress;
//     public string UserPoolArn;
//     public string Name;
//     public string AssetBuildQueueUrl;
//     public string AssetBuildQueueBucket;
//     public string ModelBucket;
//     public string WebSocketUrl;
// }

export const systemInfoSlice = createSlice({
    name: 'systemInfo',
    initialState: {
        apiSettings: null,
        systemInfo: {},
        showBuiltAssets: false
    },
    reducers: {

        clearSystemInfo: state => {
            state.apiSettings = null;
        },
        showBuiltAssets: (state, action) => {
            state.showBuiltAssets = action.payload;
        }
    },
    extraReducers: {
        [getApiSettings.fulfilled]: (state, action) => {
            state.apiSettings = action.payload;
        },
        [getSystemSettings.fulfilled]: (state, action) => {
            state.systemInfo = action.payload;
        },
    }
});

export const { clearSystemInfo,  showBuiltAssets } = systemInfoSlice.actions;

export const selectSystemInfo = state => state.systemInfo;
export default systemInfoSlice.reducer