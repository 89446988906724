import React, { useState } from 'react';
import { getBezierPath, getMarkerEnd, getEdgeCenter } from 'react-flow-renderer';
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import PropTypes from 'prop-types'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { TiInfoLarge } from "react-icons/ti";


import '../css/crs.css'
import { BsLockFill, BsUnlockFill } from "react-icons/bs";


function renderStepType(Type) {
    switch (Type) {
        case 0:
            return "Rotate About X";
        case 1:
            return "Rotate About Y";
        case 2:
            return "Rotate About Z";
        case 3:
            return "Scale";
        case 4:
            return "Translate";
        case 5:
            return "Matrix";
        case 6:
            return "Euler XYZ Right-Handed";
        default:
            return "Unknown";

    }
}

function renderTransform(transformStep) {

    switch (transformStep.Type) {
        //rotate about x, y z
        case 0:
        case 1:
        case 2:
            return transformStep.Angle;
        //scale
        case 3:
            return "( " + transformStep.Scale.x + " , " + transformStep.Scale.y + " , " + transformStep.Scale.z + " )";
        //translate
        case 4:
            return "( " + transformStep.Translation.x + " , " + transformStep.Translation.y + " , " + transformStep.Translation.z + " )";
        //matrix
        case 5:
            return (
                <>

                    ( {transformStep.Matrix.m00} , {transformStep.Matrix.m01} , {transformStep.Matrix.m02} , {transformStep.Matrix.m03} )
                    <br></br>
                    ( {transformStep.Matrix.m10} , {transformStep.Matrix.m11} , {transformStep.Matrix.m12} , {transformStep.Matrix.m13} )
                    <br></br>
                    ( {transformStep.Matrix.m20} , {transformStep.Matrix.m21} , {transformStep.Matrix.m22} , {transformStep.Matrix.m23} )
                    <br></br>
                    ( {transformStep.Matrix.m30} , {transformStep.Matrix.m31} , {transformStep.Matrix.m32} , {transformStep.Matrix.m33} )

                </>
            );
        //euler
        case 6:
            return "( " + transformStep.Rotation.x + " , " + transformStep.Rotation.y + " , " + transformStep.Rotation.z + " )";
        default:
            return "Unknown";

    }
}






export function CustomEdge({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
}) {


    const panelWidth = 88;
    const panelHeight = 26;


    const edgePath = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
    const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
    const edgeCentre = getEdgeCenter({ sourceX, sourceY, targetX, targetY });

    const [buttonsVisible, setButtonsVisible] = useState(false);


    function panelClassName() {

        if (!data.permissionEdit() && !data.permissionLock()) {
            return "custom-edge-button-panel custom-edge-button-panel-closed";
        }

        return buttonsVisible ?
            (data.crsTransform.IsLocked ?
                "custom-edge-button-panel custom-edge-button-panel-open-small" : "custom-edge-button-panel custom-edge-button-panel-open-large") :
            ("custom-edge-button-panel custom-edge-button-panel-closed");
    }

    return (
        <>
            <path
                id={id}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                style={{ stroke: "black" }}
            />



            <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                placement="top"
                overlay={
                    <Popover id="popover-transform" style={{ margin: 0, padding: 5 }} className="custom-edge-popover">
                        <Popover.Header >CRS Transform</Popover.Header>
                        <Popover.Body>
                            {data.crsTransform?.TransformSteps.length > 0 ?
                                data.crsTransform?.TransformSteps.map((transformStep, index) => (
                                    <React.Fragment key={index} >
                                        <Row style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                                            <Col sm="1">
                                                <div style={{ fontSize: 9 }} >{index + 1}</div>
                                            </Col>
                                            <Col sm="9">
                                                <div style={{ fontSize: 9 }}>{renderStepType(transformStep.Type)}</div>
                                            </Col>
                                        </Row>
                                        <Row style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#EEEEEE' }}>
                                            <Col md={{ span: 9, offset: 3 }}>
                                                <div style={{ fontSize: 9 }}>{renderTransform(transformStep)}</div>
                                            </Col>
                                        </Row>

                                    </React.Fragment>
                                )) :
                                <div style={{ fontSize: 9 }}>No Transform Steps</div>
                            }
                        </Popover.Body>
                    </Popover>
                }>
                <foreignObject
                    x={edgeCentre[0] - (panelWidth / 2)}
                    y={edgeCentre[1] - (panelHeight / 2)}
                    width={panelWidth}
                    height={panelHeight}
                    requiredExtensions="http://www.w3.org/1999/xhtml"
                    className="custom-edge-foreignobject"
                >
                    <div className="custom-edge-panel-parent">
                        <div onMouseEnter={() => setButtonsVisible(true)} onMouseLeave={() => setButtonsVisible(false)} className={panelClassName()}>
                            <TiInfoLarge onMouseEnter={() => setButtonsVisible(true)} className="custom-edge-icon"></TiInfoLarge>

                            {(buttonsVisible && !data.crsTransform.IsLocked && data.permissionEdit()) && <button className="custom-edge-button" onClick={() => data.onTransformEdit(data.crsTransform)}><BiEditAlt className="edge-button-icon" /></button>}
                            {(buttonsVisible && !data.crsTransform.IsLocked && data.permissionEdit()) && <button className="custom-edge-button" onClick={() => data.onTransformDelete(data.crsTransform)}><MdDeleteForever className="edge-button-icon" /></button>}
                            {(buttonsVisible && data.permissionLock()) && <button className="custom-edge-button" onClick={() => data.onToggleCrsTransformLock(data.crsTransform)}>{data.crsTransform.IsLocked ? <BsLockFill className="edge-button-icon" /> : <BsUnlockFill className="edge-button-icon" />}</button>}

                        </div>
                    </div>
                </foreignObject>

            </OverlayTrigger>



        </>
    );
}


CustomEdge.propTypes = {

    id: PropTypes.string,
    sourceX: PropTypes.number,
    sourceY: PropTypes.number,
    targetX: PropTypes.number,
    targetY: PropTypes.number,
    sourcePosition: PropTypes.string,
    targetPosition: PropTypes.string,
    style: PropTypes.object,
    data: PropTypes.object,
    arrowHeadType: PropTypes.string,
    markerEndId: PropTypes.number,
};

export default CustomEdge;

