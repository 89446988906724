import React, { useState, useRef } from 'react'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import FormCheck from 'react-bootstrap/FormCheck'
import { ToastLoading, ToastError, ToastSuccess } from '../Components/ToastDisplay'
import { availableSystemPermissions, SystemPermissionFlags, GroupTypes } from '../App/Permissions'
import { CreateUser } from '../Api/UserApi'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('AddUser');

function OptionsToPermission(options) {
    let permissions = 0;

    if (options) {
        options.forEach(option => {
            permissions |= option.value;
        });
    }
    return permissions;
}

function GroupsToOptions(groups) {
    let options = [];

    if (groups) {
        groups.forEach(group =>
            options.push({ value: group.GroupId, label: group.Title })
        );
    }
    return options;
}

function OptionsToGroups(options) {
    let groups = [];
    if (options) {
        options.forEach(option => groups.push(option.value));
    }
    return groups;
}

//so we can have a fixed options item
const multiSelectStyles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};


export function AddUser(props) {

    const { show, setShow, setSelectedUserId, currentOrg, currentUser, helpContext, parentHelpContext } = props;


    function allUsersOption() {

        let allUsersGroup = currentOrg?.Groups?.find(g => g.Type === GroupTypes.Everyone);

        if (allUsersGroup) {

            return { isFixed: true, label: allUsersGroup.Title, value: allUsersGroup.GroupId };
        }
        else {
            return null;
        }

    }

    function defaultGroupOptions() {

        if (allUsersOption()) {
            return [allUsersOption()];
        }
        else {
            return [];
        }
    }

    function setGroupOptionsIntercept(options) {
        //make sure all users is fixed, and present

        const allUsersGroup = currentOrg?.Groups?.find(g => g.Type === GroupTypes.Everyone)
        if (allUsersGroup) {
            let allIndex = options.findIndex(g => g.value === allUsersGroup.GroupId);
            if (allIndex >= 0) {
                options[allIndex].isFixed = true;
            }
            else {
                options.splice(0, 0, allUsersOption());
            }

        }


        setSelectedGroups(options);

    }


    logger.debug("props", props);

    const toastid = useRef(null);

    const form = useRef(null);

    const [userName, setUserName] = useState("");
    const [attributes, setAttributes] = useState([{ Name: "Name", Value: "" }, { Name: "Surname", Value: "" }, { Name: "Initials", Value: "" }]);
    const [selectedPermissions, setSelectedPermissions] = useState([{ value: SystemPermissionFlags.OrganisationAdmin, label: 'Organisation Admin' }]);
    const [selectedGroups, setSelectedGroups] = useState(defaultGroupOptions());
    const [suppressEmail, setSuppressEmail] = useState(false);

    const [formDisabled, setFormDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])

    function clearForm() {
        setValidated(false);
        setFormDisabled(false);
        setSelectedPermissions([]);
        setSelectedGroups([]);
        setAttributes([{ Name: "Name", Value: "" }, { Name: "Surname", Value: "" }, { Name: "Initials", Value: "" }]);
        setUserName("");
    }
    const availableGroupsOptions = GroupsToOptions(currentOrg?.Groups);


    function onSave(event) {
        event.preventDefault();

        let permissions = OptionsToPermission(selectedPermissions);
        let groups = OptionsToGroups(selectedGroups);

        if (form.current.checkValidity()) {
            setFormDisabled(true);

            toastid.current = ToastLoading("Contacting server");

            setUiDisabled(true);

            CreateUser(
                {
                    UserName: userName,
                    Groups: groups.map(g => ({ GroupId: g })),
                    Permissions: permissions,
                    OrganisationId: currentOrg.OrganisationId,
                    Attributes: attributes
                },
                suppressEmail
            )
                .then(res => {
                    setFormDisabled(false);
                    clearForm();
                    ToastSuccess("User [" + userName + "] added.", toastid.current, 5000);
                    setSelectedUserId(res.Id);
                    setShow(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    setFormDisabled(false);
                    ToastError(err.message, err.cause, toastid.current);
                    setUiDisabled(false);
                });

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }

    function updateAttribute(name, value) {
        //copy attributes
        var atts = JSON.parse(JSON.stringify(attributes));
        var index = attributes.findIndex(a => a.Name === name);
        if (index !== -1) {
            atts[index].Value = value;
        }

        setAttributes(atts);
    }

    const attributeGroups = attributes.map((attribute) =>
        <Form.Group controlId={attribute.Name} as={Row} key={attribute.Name}>
            <Form.Label column sm={12} >{attribute.Name}:</Form.Label>
            <Col sm={12}>
                <Form.Control required disabled={formDisabled} value={attribute.Value} onChange={(e) => updateAttribute(attribute.Name, e.target.value)} placeholder={attribute.Name} />
                <Form.Control.Feedback type="invalid">
                    {"Please enter text for this value."}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={12} >Email Address (Username for signing in):</Form.Label>
                            <Col sm={12}>
                                <Form.Control type="email" disabled={formDisabled} required value={userName} onChange={(e) => setUserName(e.target.value)} placeholder='Username / email address...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        {attributeGroups}
                        {((currentUser?.Permissions & SystemPermissionFlags.RootUser) !== 0) &&
                            <Form.Group controlId="formPositiveOnly" as={Row}>
                                <Form.Label column sm={12} >{"Don't send email"}</Form.Label>
                                <Col sm={12}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="suppressEmail"
                                        label=""
                                        checked={suppressEmail}

                                        onChange={(e) => setSuppressEmail(!suppressEmail)} />
                                </Col>
                            </Form.Group>}
                        <Form.Group controlId="formGroupPermissions" as={Row}>
                            <Form.Label column sm={12} >Permissions:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedPermissions}
                                    onChange={setSelectedPermissions}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="permissions"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select permissions..."
                                    options={availableSystemPermissions(currentUser)} />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={12} >Group:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    styles={multiSelectStyles}
                                    isDisabled={formDisabled}
                                    value={selectedGroups}
                                    onChange={setGroupOptionsIntercept}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="groups"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select groups..."
                                    options={availableGroupsOptions} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}


export default AddUser;