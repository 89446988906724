import React, { useState, useRef } from 'react'

import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { ToastLoading, ToastError, ToastSuccess } from './ToastDisplay'
import { CreateGroupShare, CreateUserShare } from '../Api/Sharing'
import { fullUsersName } from '../App/Utils'
import { UserStatus } from '../App/Permissions'
import '../css/modal.css'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('ShareDialog');

function isUUID(uuid) {
    let s = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (s === null) {
        return false;
    }
    return true;
}

function GroupsToOptions(groups) {
    let options = [];

    if (groups) {
        groups.forEach(group =>
            options.push({ value: group.GroupId, label: group.Title })
        );
    }
    return options;
}


export function ShareGroup(props) {
    logger.debug(props);

    const { currentOrg, show, setShow, helpContext, parentHelpContext } = props;

    const toastid = useRef(null);

    const [otherOrgId, setOtherOrgId] = useState("");
    const [otherOrgIdValid, setOtherOrgIdValid] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupValid, setSelectedGroupValid] = useState(false);

    const [uiDisabled, setUiDisabled] = useState(false);

    const [validated, setValidated] = useState(false);

    React.useEffect(() => {
        if (props.show) {
            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [helpContext, parentHelpContext, props.show])


    let availableGroupsOptions = GroupsToOptions(currentOrg?.Groups);

    async function onSave(event) {
        event.preventDefault();

        setSelectedGroupValid(selectedGroup?.value);
        setOtherOrgIdValid(isUUID(otherOrgId.trim()));

        //valid - send command
        if (selectedGroup?.value && isUUID(otherOrgId.trim())) {

            toastid.current = ToastLoading("Contacting server");

            setUiDisabled(true);
            try {
                //call then provoke exception on result
                await CreateGroupShare(
                    {
                        GroupId: selectedGroup.value,
                        OrgSharedToId: otherOrgId.trim(),
                        OrgSharedFromId: currentOrg.OrganisationId
                    }
                );
                ToastSuccess("Shared Successfully", toastid.current, 5000);
                //close modal
                setShow(false);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
            finally {
                setUiDisabled(false);
            }
        }
        //failed validation
        else {
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }


    return (

        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <h4 class="modal-title">Share Group
                    <br />
                    <div className="subtitle">Share a group with another org.
                        That org will then be able to give your group access to their models.
                        Enter the ID of the other org (get one of their admins to tell you what it is).</div>
                </h4>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    <Form noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={12} >Share to Organisation I.D.:</Form.Label>
                            <Col sm={12}>
                                <Form.Control required value={otherOrgId} onChange={(e) => setOtherOrgId(e.target.value)} placeholder='Organisation Id...' />
                                {(!otherOrgIdValid && validated) &&
                                    <Form.Text className="text-danger">Please enter Organisation Id.</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupGroups" as={Row}>
                            <Form.Label column sm={12} >Share Group:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedGroup}
                                    onChange={setSelectedGroup}
                                    closeMenuOnSelect={true}
                                    name="groups"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select groups..."
                                    options={availableGroupsOptions} />
                                {(!selectedGroupValid && validated) &&
                                    <Form.Text className="text-danger">Please select a group to share.</Form.Text>}
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={uiDisabled} onClick={onSave}>OK</Button>
                <Button variant="secondary" disabled={uiDisabled} onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

}


function UsersToOptions(users) {
    let options = [];

    if (users) {
        users.forEach(user =>
            options.push({ value: user.UserId, label: fullUsersName(user, null, false) })
        );
    }
    return options;
}


export function ShareUser(props) {

    const { currentOrg, show, setShow, helpContext, parentHelpContext } = props;

    const toastid = useRef(null);

    const [otherOrgId, setOtherOrgId] = useState("");
    const [otherOrgIdValid, setOtherOrgIdValid] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserValid, setSelectedUserValid] = useState(false);

    const [uiDisabled, setUiDisabled] = useState(false);

    const [validated, setValidated] = useState(false);

    React.useEffect(() => {
        if (props.show) {
            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [helpContext, parentHelpContext, props.show])



    let availableUsersOptions = UsersToOptions(currentOrg?.Users.filter(u => u.Status === UserStatus.Active));
    async function onSave(event) {
        event.preventDefault();

        setSelectedUserValid(selectedUser?.value);
        setOtherOrgIdValid(otherOrgId !== "");

        //valid - send command
        if (selectedUser?.value && otherOrgId !== "") {

            toastid.current = ToastLoading("Contacting server");

            setUiDisabled(true);
            try {
                //call then provoke exception on result
                await CreateUserShare(
                    {
                        UserId: selectedUser.value,
                        OrgSharedToId: otherOrgId,
                        OrgSharedFromId: currentOrg.OrganisationId
                    }
                );
                ToastSuccess("Shared Successfully", toastid.current, 5000);
                //close modal
                setShow(false);
            } catch (error) {
                ToastError(error.message, error.cause, toastid.current);
            }
            finally {
                setUiDisabled(false);
            }
        }
        //failed validation
        else {
            event.stopPropagation();
        }

        setValidated(true);
    }

    function onClose() {
        setShow(false);
    }


    return (

        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >

            <div class="modal-header" >
                <h4 class="modal-title">Share User
                    <br />
                    <div className="subtitle">Share a user with another org.
                        That org will then be able to give your user access to their models.
                        Enter the ID of the other org (get one of their admins to tell you what it is).</div>
                </h4>
            </div>
            <Modal.Body>
                <Container>
                    <Form noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formUserUserName" as={Row}>
                            <Form.Label column sm={12} >Share to Organisation ID:</Form.Label>
                            <Col sm={12}>
                                <Form.Control required value={otherOrgId} onChange={(e) => setOtherOrgId(e.target.value)} placeholder='Organisation Id...' />
                                {(!otherOrgIdValid && validated) &&
                                    <Form.Text className="text-danger">Please enter Organisation ID.</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formUserUsers" as={Row}>
                            <Form.Label column sm={12} >Share User:</Form.Label>
                            <Col sm={12}>
                                <Select
                                    value={selectedUser}
                                    onChange={setSelectedUser}
                                    closeMenuOnSelect={true}
                                    name="users"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Click to Select users..."
                                    options={availableUsersOptions} />
                                {(!selectedUserValid && validated) &&
                                    <Form.Text className="text-danger">Please select a user to share.</Form.Text>}
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={uiDisabled} onClick={onSave}>OK</Button>
                <Button variant="secondary" disabled={uiDisabled} onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

}

export default ShareUser;