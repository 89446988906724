import React, { useState, useRef } from "react";
import { unwrapResult } from '@reduxjs/toolkit'
import Button from 'react-bootstrap/Button'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { PermissionToTokens } from '../App/Permissions'
import '../css/lists.css'
import { Logger } from 'aws-amplify';
import { EditUserPermissions, EditGroupPermissions } from "./EditModelPermissions";
import { fullUserTitle, fullGroupTitle } from '../App/Utils'
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { ConfirmModalWithExtraButton } from '../Components/ConfirmModal'
import { ConfirmModal } from '../Components/ConfirmModal'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';
import { v4 as uuid } from "uuid";
import {
    CreateModelGroupPermission,
    DeleteModelGroupPermission,
    UpdateModelGroupPermission,

    CreateProjectGroupPermission,
    DeleteProjectGroupPermission,
    UpdateProjectGroupPermission,

    CreateModelUserPermission,
    DeleteModelUserPermission,
    UpdateModelUserPermission,

    CreateProjectUserPermission,
    DeleteProjectUserPermission,
    UpdateProjectUserPermission,

    CreateAnnotationSetUserPermission,
    UpdateAnnotationSetUserPermission,
    DeleteAnnotationSetUserPermission,

    CreateAnnotationSetGroupPermission,
    UpdateAnnotationSetGroupPermission,
    DeleteAnnotationSetGroupPermission

} from '../Api/PermissionsApi'

const logger = new Logger('PermissionList');



export function UserPermissionsInModelList(props) {
    logger.debug("UserPermissionsInModelList", props);
    const { userPermissions, setUserPermissions, canEdit, userOrg, orgs, isAdding, model, short, parentHelpContext } = props;

    const toastId = useRef();

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = userPermissions?.find(p => p.ModelUserPermissionId === selectedItemId);


    async function onDeletePermission() {
        if (isAdding) {

            const itemToDel = userPermissions.findIndex(up => up.ModelUserPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions.splice(itemToDel, 1);
                setUserPermissions(newUserPermissions);
            }
        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed(propagate) {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteModelUserPermission(selectedItemId, propagate)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = userPermissions.findIndex(up => up.ModelUserPermissionId === selectedItemId);
            if (item >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions[item] = permission;
                setUserPermissions(newUserPermissions);
                setShowEditPermissions(false);
            }
        }
        else {

            toastId.current = ToastLoading("Updating Permission");
            setUiDisabled(true);
            UpdateModelUserPermission(permission, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Updated", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowEditPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            permission.ModelUserPermissionId = uuid();
            let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
            newUserPermissions.push(permission);
            setUserPermissions(newUserPermissions);
            setShowAddPermissions(false);
        }
        else {
            toastId.current = ToastLoading("Adding Permission");
            setUiDisabled(true);
            CreateModelUserPermission(permission, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Added", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowAddPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === userPermissions[index].ModelUserPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(userPermissions[index].ModelUserPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(userPermissions[index].ModelUserPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullUserTitle(userPermissions[index].UserId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(userPermissions[index].Permissions, selectedItemId === userPermissions[index].ModelUserPermissionId, false)}
            </div>
        </div>
    );


    const listClass = "list-title-and-list " + (short ? "list-vvshort" : "list-vshort") + " list-wide";

    return (
        <div className={listClass} >
            <div className="list-title-and-buttons">
                <div className="list-title" >User Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={userPermissions ? userPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                showpropagateToAnSet={!isAdding}
                showpropagateToModel={false}
                userOrg={userOrg}
                orgs={orgs}
                existingUserIds={[]}
                userPermission={selectedItem}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}
            />

            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                showpropagateToAnSet={!isAdding}
                showpropagateToModel={false}
                userOrg={userOrg}
                orgs={orgs}
                existingUserIds={userPermissions?.map(p => p?.UserId)}
                userPermission={{
                    ModelId: model.ModelId ? model.ModelId : "00000000-0000-0000-0000-000000000000",
                    Permissions: 32
                }}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}
            />

            {selectedItem &&
                <ConfirmModalWithExtraButton
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteConfirmed}
                    okText={"Delete"}
                    dialogBody={"Delete permission for [" + fullUserTitle(selectedItem.UserId, orgs, userOrg) + "] ?"}
                    dialogTitle={"Delete Permissions"}
                    buttonText="Also Delete in Annotation Sets?"
                    helpContext={BeaconDialogMap.DeletePermissionsDialog} parentHelpContext={parentHelpContext}

                />
            }

        </div>
    );

}


export function GroupPermissionsInModelList(props) {

    logger.debug("GroupPermissionsInModelList", props);
    const { groupPermissions, setGroupPermissions, canEdit, userOrg, model, isAdding, orgs, short, parentHelpContext } = props;

    const toastId = useRef();

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = groupPermissions?.find(p => p.ModelGroupPermissionId === selectedItemId);


    async function onDeletePermission() {
        if (isAdding) {
            const itemToDel = groupPermissions.findIndex(up => up.ModelGroupPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions.splice(itemToDel, 1);
                setGroupPermissions(newGroupPermissions);
            }

        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed(propagate) {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteModelGroupPermission(selectedItemId, propagate)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = groupPermissions.findIndex(p => p.ModelGroupPermissionId === selectedItemId);
            if (item >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions[item] = permission;
                setGroupPermissions(newGroupPermissions);
                setShowEditPermissions(false);
            }

        }
        else {
            setUiDisabled(true);
            toastId.current = ToastLoading("Updating Permission");

            UpdateModelGroupPermission(permission, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Updated", toastId.current, 2000);
                    setShowEditPermissions(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            permission.ModelGroupPermissionId = uuid();
            let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
            newGroupPermissions.push(permission);
            setGroupPermissions(newGroupPermissions);
            setShowAddPermissions(false);

        }
        else {
            setUiDisabled(true);
            toastId.current = ToastLoading("Adding Permission");

            CreateModelGroupPermission(permission, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Added", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowAddPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                backgroundColor: "#FAFAFA",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === groupPermissions[index].ModelGroupPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].ModelGroupPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].ModelGroupPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullGroupTitle(groupPermissions[index].GroupId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(groupPermissions[index].Permissions, selectedItemId === groupPermissions[index].ModelGroupPermissionId, false)}
            </div>
        </div>
    );

    const listClass = "list-title-and-list " + (short ? "list-vvshort" : "list-vshort") + " list-wide";

    return (
        <div className={listClass}>
            <div className="list-title-and-buttons">
                <div className="list-title" >Group Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={groupPermissions ? groupPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog}
                parentHelpContext={parentHelpContext}
                showpropagateToModel={false}
                showpropagateToAnSet={!isAdding}
                currentOrg={userOrg}
                groupPermission={selectedItem}
                existingGroupIds={[]}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}
                orgs={orgs}
            />
            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog}
                parentHelpContext={parentHelpContext}
                showpropagateToModel={false}
                showpropagateToAnSet={!isAdding}
                currentOrg={userOrg}
                groupPermission={
                    {
                        ModelId: model.ModelId ? model.ModelId : "00000000-0000-0000-0000-000000000000",
                        Permissions: 32
                    }
                }
                existingGroupIds={groupPermissions?.map(p => p.GroupId)}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}
                orgs={orgs}
                subject="Annotation Sets in the Model"
            />
            {selectedItem &&
                <ConfirmModalWithExtraButton
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteConfirmed}
                    okText={"Delete"}
                    dialogBody={"Delete permission for [" + fullGroupTitle(selectedItem.GroupId, orgs, userOrg) + "] ?"}
                    dialogTitle={"Delete Permissions"}
                    buttonText="Also Delete in Annotation Sets?"
                    helpContext={BeaconDialogMap.DeletePermissionsDialog}
                    parentHelpContext={parentHelpContext}
                />}

        </div>
    );

}


export function UserPermissionsInProjectList(props) {
    const { canEdit, userOrg, showUploadPermission, orgs, userPermissions, setUserPermissions, projectId, isAdding, parentHelpContext } = props;

    const toastId = useRef();

    logger.debug("UserPermissionsInProjectList", props);

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = userPermissions?.find(p => p.ProjectUserPermissionId === selectedItemId);


    async function onDeletePermission() {

        if (isAdding) {

            const itemToDel = userPermissions.findIndex(up => up.ProjectUserPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions.splice(itemToDel, 1);
                setUserPermissions(newUserPermissions);
            }
        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed(propagate) {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteProjectUserPermission(selectedItemId, propagate)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
                setShowConfirmDeleteDialog(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });
    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = userPermissions.findIndex(up => up.ProjectUserPermissionId === selectedItemId);
            if (item >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions[item] = permission;
                setUserPermissions(newUserPermissions);
                setShowEditPermissions(false);
            }
        }
        else {
            setUiDisabled(true);
            toastId.current = ToastLoading("Updating Permission");

            UpdateProjectUserPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    setUiDisabled(false);
                    ToastSuccess("Updated", toastId.current, 2000);
                    setShowEditPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {

            permission.ProjectUserPermissionId = uuid();
            let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
            newUserPermissions.push(permission);
            setUserPermissions(newUserPermissions);
            setShowAddPermissions(false);
        }
        else {
            toastId.current = ToastLoading("Adding Permission");
            setUiDisabled(true);

            CreateProjectUserPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Added", toastId.current, 2000);
                    setShowAddPermissions(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,

                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === userPermissions[index].ProjectUserPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(userPermissions[index].ProjectUserPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(userPermissions[index].ProjectUserPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullUserTitle(userPermissions[index].UserId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(userPermissions[index].Permissions, selectedItemId === userPermissions[index].ProjectUserPermissionId, true)}
            </div>
        </div>
    );

    return (
        <div className="list-title-and-list list-short list-wide">
            <div className="list-title-and-buttons">
                <div className="list-title" >User Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={userPermissions ? userPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                orgs={orgs}
                showUploadPermission={showUploadPermission}
                showpropagateToModel={!isAdding}
                showpropagateToAnSet={!isAdding}
                userOrg={userOrg}
                userPermission={selectedItem}
                existingUserIds={[]}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}
            />
            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                orgs={orgs}
                showUploadPermission={showUploadPermission}
                showpropagateToModel={!isAdding}
                showpropagateToAnSet={!isAdding}
                userOrg={userOrg}
                userPermission={{
                    ProjectId: projectId ? projectId : "00000000-0000-0000-0000-000000000000",
                    Permissions: 32
                }}
                existingUserIds={userPermissions?.map(p => p.UserId)}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}
            />
            {selectedItem &&
                <ConfirmModalWithExtraButton
                    helpContext={BeaconDialogMap.DeletePermissionsDialog} parentHelpContext={parentHelpContext}
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteConfirmed}
                    okText={"Delete"}
                    dialogBody={"Delete permission for [" + fullUserTitle(selectedItem.UserId, orgs, userOrg) + "] ?"}
                    dialogTitle={"Delete Permissions"}
                    buttonText="Also Delete in Models?"
                />
            }
        </div>
    );

}


export function GroupPermissionsInProjectList(props) {
    const { groupPermissions, setGroupPermissions, canEdit, userOrg, project, showUploadPermission, isAdding, orgs, parentHelpContext } = props;

    const toastId = useRef();

    logger.debug("GroupPermissionsInProjectList props", props);

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = groupPermissions?.find(p => p.ProjectGroupPermissionId === selectedItemId);

    async function onDeletePermission() {
        if (isAdding) {
            const itemToDel = groupPermissions.findIndex(up => up.ProjectGroupPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions.splice(itemToDel, 1);
                setGroupPermissions(newGroupPermissions);
            }

        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed(propagate) {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteProjectGroupPermission(selectedItemId, propagate)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
                setShowConfirmDeleteDialog(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });


    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = groupPermissions.findIndex(p => p.ProjectGroupPermissionId === selectedItemId);
            if (item >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions[item] = permission;
                setGroupPermissions(newGroupPermissions);
                setShowEditPermissions(false);
            }

        }
        else {

            setUiDisabled(true);
            toastId.current = ToastLoading("Updating Permission");

            UpdateProjectGroupPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Updated", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowEditPermissions(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }

    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            permission.ProjectGroupPermissionId = uuid();
            let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
            newGroupPermissions.push(permission);
            setGroupPermissions(newGroupPermissions);
            setShowAddPermissions(false);

        }
        else {
            toastId.current = ToastLoading("Adding Permission");
            setUiDisabled(true);
            CreateProjectGroupPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Added", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowAddPermissions(false);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }

    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                backgroundColor: "#FAFAFA",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === groupPermissions[index].ProjectGroupPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].ProjectGroupPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].ProjectGroupPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullGroupTitle(groupPermissions[index].GroupId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(groupPermissions[index].Permissions, selectedItemId === groupPermissions[index].ProjectGroupPermissionId, true)}
            </div>
        </div>
    );

    return (
        <div className="list-title-and-list  list-short list-wide">
            <div className="list-title-and-buttons">
                <div className="list-title" >Group Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={groupPermissions ? groupPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>

            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog}
                parentHelpContext={parentHelpContext}
                showUploadPermission={showUploadPermission}
                showpropagateToModel={!isAdding}
                showpropagateToAnSet={!isAdding}
                currentOrg={userOrg}
                groupPermission={selectedItem}
                existingGroupIds={[]}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}
                orgs={orgs}
            />
            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog}
                parentHelpContext={parentHelpContext}
                showUploadPermission={showUploadPermission}
                showpropagateToModel={!isAdding}
                showpropagateToAnSet={!isAdding}
                currentOrg={userOrg}
                groupPermission={{
                    ProjectId: project.ProjectId ? project.ProjectId : "00000000-0000-0000-0000-000000000000",
                    Permissions: 32
                }}
                existingGroupIds={groupPermissions?.map(p => p.GroupId)}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}
                orgs={orgs}
            />
            {selectedItem &&
                <ConfirmModalWithExtraButton
                    show={showConfirmDeleteDialog}
                    setShow={setShowConfirmDeleteDialog}
                    onOK={onDeleteConfirmed}
                    okText={"Delete"}
                    dialogBody={"Delete permission for [" + fullGroupTitle(selectedItem.GroupId, orgs, userOrg) + "] ?"}
                    dialogTitle={"Delete Permissions"}
                    buttonText="Also Delete in Models?"
                    helpContext={BeaconDialogMap.DeletePermissionsDialog}
                    parentHelpContext={parentHelpContext}
                />}
        </div>
    );

}











export function UserPermissionsInAnnotationSetList(props) {
    const { canEdit, userOrg, orgs, userPermissions, setUserPermissions, annotationSetId, isAdding, parentHelpContext } = props;

    const toastId = useRef();

    logger.debug("UserPermissionsInProjectList", props);

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = userPermissions?.find(p => p.AnnotationSetUserPermissionId === selectedItemId);


    async function onDeletePermission() {

        if (isAdding) {

            const itemToDel = userPermissions.findIndex(up => up.AnnotationSetUserPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions.splice(itemToDel, 1);
                setUserPermissions(newUserPermissions);
            }
        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed(propagate) {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteAnnotationSetUserPermission(selectedItemId, propagate)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
                setShowConfirmDeleteDialog(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });
    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = userPermissions.findIndex(up => up.AnnotationSetUserPermissionId === selectedItemId);
            if (item >= 0) {
                let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
                newUserPermissions[item] = permission;
                setUserPermissions(newUserPermissions);
                setShowEditPermissions(false);
            }
        }
        else {
            toastId.current = ToastLoading("Updating Permission");
            setUiDisabled(true);
            UpdateAnnotationSetUserPermission(permission)
                .then(unwrapResult)
                .then(res => {
                    ToastSuccess("Updated", toastId.current, 2000);
                    setUiDisabled(false);
                    setShowEditPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {

            permission.AnnotationSetUserPermissionId = uuid();
            let newUserPermissions = JSON.parse(JSON.stringify(userPermissions));
            newUserPermissions.push(permission);
            setUserPermissions(newUserPermissions);
            setShowAddPermissions(false);
        }
        else {
            toastId.current = ToastLoading("Adding Permission");
            setUiDisabled(true);
            CreateAnnotationSetUserPermission(permission)
                .then(unwrapResult)
                .then(res => {
                    setUiDisabled(false);
                    ToastSuccess("Added", toastId.current, 2000);
                    setShowAddPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,

                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === userPermissions[index].AnnotationSetUserPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(userPermissions[index].AnnotationSetUserPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {

                    setSelectedItemId(userPermissions[index].AnnotationSetUserPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullUserTitle(userPermissions[index].UserId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(userPermissions[index].Permissions, selectedItemId === userPermissions[index].AnnotationSetUserPermissionId, true)}
            </div>
        </div>
    );

    return (
        <div className="list-title-and-list list-short list-wide">
            <div className="list-title-and-buttons">
                <div className="list-title" >User Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItem} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={userPermissions ? userPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                orgs={orgs}
                showUploadPermission={false}
                showpropagateToModel={false}
                showpropagateToAnSet={false}
                userOrg={userOrg}
                userPermission={selectedItem}
                existingUserIds={[]}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}

            />
            <EditUserPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                orgs={orgs}
                showUploadPermission={false}
                showpropagateToModel={false}
                showpropagateToAnSet={false}
                userOrg={userOrg}
                userPermission={{
                    AnnotationSetId: annotationSetId,
                    Permissions: 32
                }}
                existingUserIds={userPermissions?.map(p => p.UserId)}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}

            />
            <ConfirmModal
                helpContext={BeaconDialogMap.DeletePermissionsDialog} parentHelpContext={parentHelpContext}
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteConfirmed}
                okText="Delete"
                dialogBody={"Delete permission for [" + fullUserTitle(selectedItem?.UserId, orgs, userOrg) + "] ?"}
                dialogTitle="Delete Permission"
            />
        </div>
    );

}


export function GroupPermissionsInAnnotationSetList(props) {
    const { groupPermissions, setGroupPermissions, canEdit, userOrg, annotationSetId, isAdding, orgs, parentHelpContext } = props;

    const toastId = useRef();

    const [selectedItemId, setSelectedItemId] = useState(null);
    const [showEditPermissions, setShowEditPermissions] = useState(false);
    const [showAddPermissions, setShowAddPermissions] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    const permissionList = useRef();

    const selectedItem = groupPermissions?.find(p => p.AnnotationSetGroupPermissionId === selectedItemId);


    async function onDeletePermission() {
        if (isAdding) {
            const itemToDel = groupPermissions.findIndex(up => up.AnnotationSetGroupPermissionId === selectedItemId);
            if (itemToDel >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions.splice(itemToDel, 1);
                setGroupPermissions(newGroupPermissions);
            }

        }
        else {
            setShowConfirmDeleteDialog(true);
        }
    }

    async function onDeleteConfirmed() {
        toastId.current = ToastLoading("Deleting Permission");

        DeleteAnnotationSetGroupPermission(selectedItemId)
            .then(unwrapResult)
            .then(res => {
                ToastSuccess("Deleted", toastId.current, 2000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });

    }

    function onUpdatePermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            const item = groupPermissions.findIndex(p => p.AnnotationSetGroupPermissionId === selectedItemId);
            if (item >= 0) {
                let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
                newGroupPermissions[item] = permission;
                setGroupPermissions(newGroupPermissions);
                setShowEditPermissions(false);
            }

        }
        else {
            setUiDisabled(true);
            toastId.current = ToastLoading("Updating Permission");

            UpdateAnnotationSetGroupPermission(permission)
                .then(unwrapResult)
                .then(res => {
                    setUiDisabled(false);
                    ToastSuccess("Updated", toastId.current, 2000);
                    setShowEditPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    function onAddPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet, setUiDisabled) {
        if (isAdding) {
            permission.AnnotationSetGroupPermissionId = uuid();
            let newGroupPermissions = JSON.parse(JSON.stringify(groupPermissions));
            newGroupPermissions.push(permission);
            setGroupPermissions(newGroupPermissions);
            setShowAddPermissions(false);

        }
        else {

            toastId.current = ToastLoading("Adding Permission");
            setUiDisabled(true);

            CreateAnnotationSetGroupPermission(permission)
                .then(unwrapResult)
                .then(res => {
                    setUiDisabled(false);

                    ToastSuccess("Added", toastId.current, 2000);
                    setShowAddPermissions(false);
                })
                .catch(err => {
                    setUiDisabled(false);
                    ToastError(err.message, err.cause, toastId.current);
                });
        }
    }

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                backgroundColor: "#FAFAFA",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={selectedItemId === groupPermissions[index].AnnotationSetGroupPermissionId ?
                "list-item list-item-selected" :
                "list-item list-item-not-selected"}
            onClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].AnnotationSetGroupPermissionId);
                }
            }}
            onDoubleClick={() => {
                if (canEdit) {
                    //set group and prompt re-draw
                    setSelectedItemId(groupPermissions[index].AnnotationSetGroupPermissionId);
                    setShowEditPermissions(true);
                }
            }}
        >
            <div className="list-item-title">{fullGroupTitle(groupPermissions[index].GroupId, orgs, userOrg)}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(groupPermissions[index].Permissions, selectedItemId === groupPermissions[index].AnnotationSetGroupPermissionId, false)}
            </div>
        </div>
    );

    return (
        <div className="list-title-and-list  list-short list-wide">
            <div className="list-title-and-buttons">
                <div className="list-title" >Group Permissions:</div>
                {(canEdit) &&
                    <div className="list-buttons">
                        <Button className="list-top-button" onClick={() => setShowAddPermissions(true)}>Add</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={() => setShowEditPermissions(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={!selectedItemId} onClick={onDeletePermission}>Delete</Button>
                    </div>
                }
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={groupPermissions ? groupPermissions.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                showpropagateToModel={false}
                showpropagateToAnSet={false}
                currentOrg={userOrg}
                groupPermission={selectedItem}
                existingGroupIds={[]}
                showDialog={showEditPermissions}
                setShowDialog={setShowEditPermissions}
                onSavePermission={onUpdatePermission}
                isAdding={false}
                orgs={orgs}
            />
            <EditGroupPermissions
                helpContext={BeaconDialogMap.EditPermissionsDialog} parentHelpContext={parentHelpContext}
                showpropagateToModel={false}
                showpropagateToAnSet={false}
                currentOrg={userOrg}
                groupPermission={{
                    AnnotationSetId: annotationSetId,
                    Permissions: 32
                }}
                existingGroupIds={groupPermissions.map(p => p.GroupId)}
                showDialog={showAddPermissions}
                setShowDialog={setShowAddPermissions}
                onSavePermission={onAddPermission}
                isAdding={true}
                orgs={orgs}
            />
            <ConfirmModal
                helpContext={BeaconDialogMap.DeletePermissionsDialog} parentHelpContext={parentHelpContext}
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteConfirmed}
                okText="Delete"
                dialogBody={"Delete permission for [" + fullGroupTitle(selectedItem?.GroupId, orgs, userOrg) + "] ?"}
                dialogTitle="Delete Permission"
            />

        </div>
    );
}





//===================================================================
//===================================================================
//===================================================================
//===================================================================
//===================================================================
//===================================================================
//===================================================================





//list of model permission attached to this  group
export function GenericPermissionsList(props) {
    const { permissionArray, title, showUploadPermission } = props;

    //logger.debug("GenericPermissionsList", props);

    const permissionList = useRef();

    const ListRowItem = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}
            className="list-item list-item-not-selected"
        >

            <div className="list-item-title">{permissionArray[index].title}</div>
            <div className="list-item-sub-item">
                {PermissionToTokens(permissionArray[index].Permissions, false, showUploadPermission)}
            </div>
        </div>
    );

    return (
        <div className="list-title-and-list list-wide list-short">
            <div className="list-title-and-buttons">
                <div className="list-title" >{title}</div>
            </div>

            <div className="list-container">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            ref={permissionList}
                            height={height}
                            itemCount={permissionArray ? permissionArray.length : 0}
                            itemSize={78}
                            width={390}
                            style={{ backgroundColor: "#EEEEEE" }}
                            className="border border-dark rounded"
                        >
                            {ListRowItem}
                        </List>
                    )}
                </AutoSizer>
            </div>

        </div>
    );

}
