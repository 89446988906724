import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button'
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CanAssignLicenseToUser } from "../App/UserPermissions";
import { LicenseTypes } from '../App/Permissions'
import { ToastError, ToastSuccess } from './ToastDisplay'
import { AssignLicenseToUser, UnassignLicenseFromUser } from '../Api/License'
import { ListSelector } from "./ListSelector";
import { fullUserTitle } from '../App/Utils'
import { UserStatus } from '../App/Permissions'
import '../css/lists.css'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('LicenseListUser');


export function LicenseListUser(props) {
    logger.debug("props", props);
    const { licenses, currentUser, user, currentOrg, orgs, parentHelpContext } = props;

    logger.debug(props);


    const [selectedLicenseId, setSelectedLicenseId] = useState(null);
    const [showAssignLicense, setShowAssignLicense] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    const licenseList = useRef();
    const toastId = useRef(null);


    function assignLicense(licenseId, setUiDisabled) {

        setUiDisabled(true);

        AssignLicenseToUser(licenseId, user.UserId)
            .then(res => {
                ToastSuccess("License assigned", toastId.current, 5000);
                setUiDisabled(false);
                setShowAssignLicense(false);
            })
            .catch(err => {
                setUiDisabled(false);
                ToastError(err.message, err.cause, toastId.current);
            });
    }

    function onUnAssignLicenseButton() {

        setUiDisabled(true);

        UnassignLicenseFromUser(selectedLicenseId, user.UserId)
            .then(res => {
                ToastSuccess("License un-assigned", toastId.current, 5000);
                setSelectedLicenseId(null);
                setUiDisabled(false);

            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);

            });
    }

    function onAssignLicenseButton() {
        setShowAssignLicense(true);
    }

    function itemClassName(license, selected) {
        if (selected) {
            return "list-item list-item-selected";
        }
        else if (!license.IsActive) {
            return "list-item list-item-warning";
        }
        else {
            if (license.UserId === "00000000-0000-0000-0000-000000000000") {
                return "list-item list-item-highlighted";
            }
            else {
                return "list-item list-item-not-selected";
            }
        }
    }

    function licenseTitle(license) {

        let title = license?.Title;

        if (license?.OrganisationId !== currentOrg?.OrganisationId) {
            const org = orgs?.find(o => o.OrganisationId === license.OrganisationId);
            if (org) {
                title = org.Title + " : " + title;
            }
        }

        return title;

    }

    const ListRowLicense = ({ index, style }) => (

        <div role="button"
            style={{
                ...style,
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflowX: "hidden"
            }}


            className={itemClassName(licenses[index], selectedLicenseId === licenses[index].LicenseId)}
            onClick={() => {
                //set group and prompt re-draw
                setSelectedLicenseId(licenses[index].LicenseId);
            }}
        >
            {licenses[index].IsActive ?
                <div className="list-item-title">
                    <div>{licenseTitle(licenses[index])}</div>
                </div>
                :
                <div className="list-item-split-row-spaced">
                    <div className="list-item-title list-item-split-row-item-left">
                        <div>{licenses[index].Title}</div>
                    </div>
                    <div className="list-item-title list-item-split-row-item-right">INACTIVE</div>
                </div>
            }

            {(!licenses[index].IsActive && licenses[index].StatusText) &&
                <div className="list-item-normal-item">
                    {"Status: " + licenses[index].StatusText}
                </div>}

            {(licenses[index].UserId !== "00000000-0000-0000-0000-000000000000") ?
                <div className="list-item-sub-item">
                    {"Assigned to: " + fullUserTitle(licenses[index].UserId, orgs, currentOrg)}
                </div>
                :
                <div className="list-item-sub-item">
                    {"Not Assigned"}
                </div>

            }
            {/* 
            <div className="list-item-title">{licenses[index].Title}</div>
            <div className="list-item-sub-item">
                {"Permission: " + LicensePermissionToText(licenses[index])}
            </div>
            {(licenses[index].UserId !== "00000000-0000-0000-0000-000000000000") &&
                <div className="list-item-sub-item">
                    {"Assigned to: " + fullUserTitle(licenses[index].UserId, currentOrg)}
                </div>
            } */}
        </div>
    );

    return (
        currentUser != null ?
            <div className="list-title-and-list list-narrow list-short">
                <div className="list-title-and-buttons">
                    <div className="list-title" >Licenses:</div>
                    <div className="list-buttons">
                        {(CanAssignLicenseToUser(currentUser, user, currentOrg.UsersSharedIn)) && <Button disabled={user?.Status !== UserStatus.Active || uiDisabled} className="list-top-button" onClick={onAssignLicenseButton}>Assign License</Button>}
                        {(CanAssignLicenseToUser(currentUser, user, currentOrg.UsersSharedIn)) && <Button disabled={selectedLicenseId === null || uiDisabled} className="list-top-button" onClick={onUnAssignLicenseButton}>Un-Assign</Button>}
                    </div>
                </div>

                <div className="list-container">
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                ref={licenseList}
                                height={height}
                                itemCount={licenses ? licenses.length : 0}
                                itemSize={78}
                                width={340}
                                className="list-background-style"
                            >
                                {ListRowLicense}
                            </List>
                        )}
                    </AutoSizer>
                </div>
                <ListSelector
                    helpContext={BeaconDialogMap.AssignLicenseToUserDialog} parentHelpContext={parentHelpContext}
                    title={"Select License"}
                    itemName={"License"}
                    show={showAssignLicense}
                    setShow={setShowAssignLicense}
                    onSaveItem={assignLicense}
                    items={currentOrg?.Licenses.filter(l =>
                        l.UserId === "00000000-0000-0000-0000-000000000000" &&
                        (l.Type === LicenseTypes.WriteAccess || l.Type === LicenseTypes.ReadAccess) &&
                        l.IsActive).map(l => ({ id: l.LicenseId, label: l.Title }))}
                />

            </div>
            :
            <></>
    );

}