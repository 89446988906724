import React, { useState } from 'react'

import Spinner from 'react-bootstrap/Spinner'
import { GenerateUserApiKey } from '../Api/UserApi'
import { unwrapResult } from '@reduxjs/toolkit'
import { ToastError } from '../Components/ToastDisplay'
import Button from 'react-bootstrap/Button'
import '../css/user-details.css'
import { CanGenerateUserApiKey } from '../App/UserPermissions';

import { CanGetUserCognitoInfo } from '../App/UserPermissions'
import { GetUserCognitoInfo, GetUserCognitoDevices } from '../Api/UserApi'


export function UserDetails(props) {
    const { user, currentUser, cognitoInfo, setCognitoInfo, setCognitoDevices } = props;

    const [isGenerating, setIsGenerating] = useState(false);

    const [gettingCognitoInfo, setGettingCognitoInfo] = useState(false);


    async function getCognitoInfo() {

        setGettingCognitoInfo(true);
        try {
            GetUserCognitoInfo(user.UserId)
                .then(res => {
                    GetUserCognitoDevices(user.UserId)
                        .then(devs => {
                            setCognitoInfo(res);
                            setCognitoDevices(devs.Devices);
                            setGettingCognitoInfo(false);
                        })
                        .catch(err => {
                            setGettingCognitoInfo(false);
                        });
                })
                .catch(err => {
                    setGettingCognitoInfo(false);
                });
        }
        catch (err) {
        }
    }

    React.useEffect(() => {

        if (cognitoInfo) {
            let emailAtt = cognitoInfo.UserAttributes.find(item => item.Name === "email");

            if (user?.UserName !== emailAtt.Value) {
                setCognitoInfo(null);
            }
        }

    }, [user, cognitoInfo, setCognitoInfo]);


    async function generateApiKey() {
        setIsGenerating(true);


        try {
            //call then provoke exception on result
            unwrapResult(await GenerateUserApiKey(user.UserId));
        } catch (error) {
            ToastError(error.message, error.cause);
        }
        finally {
            setIsGenerating(false);
        }
    }

    return (

        <div className="user-details">
            <div className="user-title-container">
                <div className="user-details-title">Details</div>
            </div>
            <div  >
                {CanGenerateUserApiKey(currentUser) &&
                    <>
                        <div className="row">
                            <div className="label">Api Key:</div>
                            <div className="content">{user?.ApiKey}</div>
                        </div>
                        <div className="row">
                            <div className="label" />
                            {
                                isGenerating ?
                                    <div className="mx-2 my-2">
                                        <Spinner animation="border" />
                                    </div>
                                    :
                                    <div className="content">
                                        <Button size="sm" onClick={generateApiKey} >Generate</Button>
                                    </div>
                            }
                        </div>
                    </>
                }
                {(CanGetUserCognitoInfo(currentUser)) &&
                    <div className=".user-details">
                        <div>Account Status:</div>
                        <div>{user?.AuthStatus}</div>
                        <Button size="sm" onClick={gettingCognitoInfo ? null : getCognitoInfo} >{gettingCognitoInfo ? "Loading..." : "Get Status"}</Button>
                    </div>
                }

            </div>
        </div >

    );
}