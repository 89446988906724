
import { Amplify } from 'aws-amplify';

const endpointName = 'Reveal4DApi';
const endpoint = process.env.REACT_APP_ENV.startsWith("dev") ? 'https://api.reveal4d.com/dev' : 'https://api.reveal4d.com/prod';

Amplify.configure({
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-2',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        //cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //    domain: 'surveyserver.billharveyassociates.com',
        // OPTIONAL - Cookie path
        //     path: '/',
        // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //    secure: false
        //},

    },
    API: {
        endpoints: [
            {
                name: endpointName,
                endpoint: endpoint
            }
        ]
    }
});
// You can get the current config object
//const currentConfig = Auth.configure();