import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FormCheck from 'react-bootstrap/FormCheck'
import { ToastLoading, ToastError, ToastSuccess } from './ToastDisplay'
import { selectOrganisationsInfo } from '../App/organisationsInfoSlice';
import { StartCopyOrganisationStateMachine } from '../Api/OrganisationApi'
import Select from 'react-select'
import Modal from 'react-bootstrap/Modal'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('AddOrg');


export function CopyOrganisation(props) {
    logger.debug("props", props);

    // const orgsInfo = useSelector(selectOrganisationsInfo);

    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [uiDisabled, setUiDisabled] = useState(false);

    const orgsInfo = useSelector(selectOrganisationsInfo);

    const toastid = useRef(null);

    const [selectedOrg, setSelectedOrg] = useState(null);
    const [selectedOrgValid, setSelectedOrgValid] = useState([]);
    const [orgTitle, setOrgTitle] = useState("");
    const [userName, setUserName] = useState("");
    const [attributes, setAttributes] = useState([
        {
            "Name": "Name",
            "Value": "",
            "IsFixed": true
        },
        {
            "Name": "Surname",
            "Value": "",
            "IsFixed": true
        },
        {
            "Name": "Initials",
            "Value": "",
            "IsFixed": true
        }
    ]);
    const [sendEmail, setSendEmail] = useState(true);

    React.useEffect(() => {
        if (props.show) {
            if (props.helpContext) {
                BeaconSetSuggestions(props.helpContext);
            }
        }
        else {
            if (props.parentHelpContext) {
                BeaconSetSuggestions(props.parentHelpContext);
            }
        }
    }, [props.helpContext, props.parentHelpContext, props.show])


    function onClose() {
        props.setShow(false);
    }


    function onSubmitClicked(event) {
        event.preventDefault();

        const form = event.currentTarget;


        setSelectedOrgValid(selectedOrg !== null);

        if (form.checkValidity() && selectedOrg !== null) {
            setUiDisabled(true);
            toastid.current = ToastLoading("Contacting server");

            StartCopyOrganisationStateMachine(selectedOrg.value.OrganisationId, orgTitle, userName, attributes, !sendEmail)
                .then(res => {
                    ToastSuccess("Organisation copy started. Wait for progress messages.", toastid.current, 8000);
                    props.setShow(false);
                    setUiDisabled(false);
                })
                .catch(err => {
                    ToastError("Failed to start organisation copy: " + err.message, err.cause, toastid.current);
                    setUiDisabled(false);
                });

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    function updateAttribute(name, value) {

        //copy attributes
        var atts = JSON.parse(JSON.stringify(attributes));
        var index = attributes.findIndex(a => a.Name === name);
        if (index !== -1) {
            atts[index].Value = value;
        }

        setAttributes(atts);
    }

    const attributeGroups = attributes?.map((attribute) =>
        <Form.Group controlId={attribute.Name} as={Row} key={attribute.Name}>
            <Form.Label column sm={3} >User's {attribute.Name}:</Form.Label>
            <Col sm={8}>
                <Form.Control required value={attribute.Value} onChange={(e) => updateAttribute(attribute.Name, e.target.value)} placeholder={attribute.Name + '...'} />
                <Form.Control.Feedback type="invalid">
                    {"Please enter text for this value."}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );

    return (
        <Modal
            show={props.show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-800w"
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Create Organisation from a Template"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Form ref={form} noValidate validated={validated} onSubmit={onSubmitClicked}>

                        <Form.Group controlId="formGroupDbAddress" as={Row}>
                            <Form.Label column sm={12} >
                                Create a new organisation by copying an existing one.<br />
                                <br />
                                Enter an email address for the administrator user for the new organisation. Other users can be added later.<br />
                                This user will be sent an email to invite them to the Reveal4D system.<br />
                                You can delay this email by unchecking the 'Send invite email' checkbox. The invite email can be sent later.<br />
                                Enter the prospective user's first and last names plus the initials. This is used to identify the user in the system.<br />
                                <br />

                            </Form.Label>
                        </Form.Group>

                        <Form.Group controlId="formGroupDbAddress" as={Row}>
                            <Form.Label column sm={3} >Organisation to copy:</Form.Label>
                            <Col sm={8}>
                                <Select
                                    value={selectedOrg}
                                    onChange={setSelectedOrg}
                                    closeMenuOnSelect={true}
                                    name="orgs"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Select organisation to copy..."
                                    options={orgsInfo.organisations.map(o => ({ value: o, label: o.Title }))} />
                                {(!selectedOrgValid && validated) &&
                                    <Form.Text className="text-danger">Please select org to copy.</Form.Text>}
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formMessage" as={Row}>
                            <Form.Label column sm={3} >New organisation title:</Form.Label>
                            <Col sm={8}>
                                <Form.Control disabled={uiDisabled} required value={orgTitle} onChange={(e) => setOrgTitle(e.target.value)} placeholder='New organisation title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupUserName" as={Row}>
                            <Form.Label column sm={3} >New user email:</Form.Label>
                            <Col sm={8}>
                                <Form.Control type="email" required value={userName} onChange={(e) => setUserName(e.target.value)} placeholder='User Email...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formPositiveOnly" as={Row}>
                            <Form.Label column sm={3} >Send invite email</Form.Label>
                            <Col sm={8}>
                                <FormCheck
                                    className="mt-2"
                                    type="switch"
                                    id="suppressEmail"
                                    label=""
                                    checked={sendEmail}

                                    onChange={(e) => setSendEmail(!sendEmail)} />
                            </Col>
                        </Form.Group>

                        {attributeGroups}
                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSubmitClicked}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default CopyOrganisation;