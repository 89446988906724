
import React from "react";
import '../css/components.css'

export function ColourSwatch(props) {

    const { colour, onClick, small } = props;

    let classNameContainer = "swatch-container-big swatch-container-alpha z-behind";
    if(small) {
        classNameContainer = "swatch-container-small swatch-container-alpha-small z-behind";
    }
    
    return (
        <div className={classNameContainer} onClick={onClick}>
            <div className="swatch-container-fill" style={{ backgroundColor: colour }}  />
        </div>
    );
};

export default ColourSwatch;