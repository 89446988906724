import React, { useState, useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import Button from 'react-bootstrap/Button'
import { Confirm } from 'react-st-modal';
import { DeleteAnnotations } from '../Api/AnnotationApi'
import { UpdateAnnotationProperties } from '../Api/AnnotationPropertiesApi'
import { EditAnnotationProperties } from '../ProjectsPage/EditAnnotationProperties'
import { StyleSheetsDialog } from '../ProjectsPage/StyleSheetsDialog'
import { PropertyTemplatesView } from '../ProjectsPage/PropertyTemplatesView'
import { ToastClose, ToastError, ToastLoading } from './ToastDisplay';
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import '../css/annotation-table.css'

import { kaReducer, Table } from 'ka-table';
import { DataType, FilteringMode, SortingMode, ActionType, SortDirection } from 'ka-table/enums';
import {
    deselectAllFilteredRows, deselectRow,
    selectAllFilteredRows, selectRow, selectRowsRange,
    loadData, updateData, setSingleAction,
    updateFilterRowValue, updateEditorValue,
    updateFilterRowOperator
} from 'ka-table/actionCreators';

import { CanWriteAnnotationSet, CanEditAnnotationTemplatesOrStyleSheets } from '../App/UserPermissions'
import { fullUsersName } from '../App/Utils'
import { kaPropsUtils } from 'ka-table/utils';
import { newRowId } from "ka-table/const";
import { v4 as uuid } from "uuid";
import { BsLockFill } from "react-icons/bs";
import { CSVLink } from 'react-csv';
import * as Constants from '../constants.js';
import Select from 'react-select'
import '../css/table-style.scss'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('AnnotationTable');


const deleteAnnotationsToastId = 1;
const updatePropertiesToastId = 6;


function ListDropDown(props) {

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
        }),
        control: (provided) => ({
            ...provided,
        }),
        singleValue: (provided, state) => ({
            ...provided
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: 0
        })
    }
    return (
        <Select
            menuPortalTarget={document.body}
            styles={customStyles}
            value={props.items}
            onChange={props.setItems}
            closeMenuOnSelect={props.multiSelect ? false : true}
            name="parameterList"
            className="list-property-select"
            isMulti={props.multiSelect}
            placeholder={props.multiSelect ? "Select Item(s)..." : "Select Item..."}
            options={props.availableItems} />
    );
}

const ListCell = (props) => {

    const items = props?.value?.items?.length > 0 ? props?.value?.items.map((item) =>
        <div key={uuid()} className={"me-1 mt-1 p-0 ps-1 pe-1 rounded border border-light bg-primary text-white"}>
            {item.label}
        </div>
    ) : <>[Empty]</>

    return (
        <div className={"annotationPropertyItemContainer"}>
            <div className="d-flex flex-wrap ps-0 text-black">
                {props?.value?.valid ? items : "---"}
            </div>
        </div>
    );
};

const ValueCell = (props) => {
    if (props.column.dataType === DataType.Number) {

        if (props?.value === null) {
            return (
                <>
                    {"---"}
                </>
            );
        }
        else {

            return (
                <>
                    {isNaN(props?.value) ? "[Empty]" : props?.value}
                </>
            );
        }
    }
    else {
        return (
            <>
                {props?.value ? props?.value : "[Empty]"}
            </>
        );
    }
};


const SelectionCell = (props) => {


    return (
        <>
            <input
                type='checkbox'
                checked={props.isSelectedRow}
                onChange={(event) => {
                    if (event.nativeEvent.shiftKey) {
                        props.dispatch(selectRowsRange(props.rowKeyValue, [...props.selectedRows].pop()));
                    } else if (event.currentTarget.checked) {
                        props.dispatch(selectRow(props.rowKeyValue));
                    } else {
                        props.dispatch(deselectRow(props.rowKeyValue));
                    }
                }}
            />
            {props.value && <BsLockFill className="ms-1 mb-2" />}
        </>
    );
};

const SelectionHeader = ({ dispatch, areAllRowsSelected }) => {
    return (
        <input
            type='checkbox'
            checked={areAllRowsSelected}
            onChange={(event) => {
                if (event.currentTarget.checked) {
                    dispatch(selectAllFilteredRows()); // also available: selectAllVisibleRows(), selectAllRows()
                } else {
                    dispatch(deselectAllFilteredRows()); // also available: deselectAllVisibleRows(), deselectAllRows()
                }
            }}
        />
    );
};


const FilterOperators = ({
    column, dispatch,
}) => {
    if ((column?.propertyTemplate?.PropertyType === 0 && column?.propertyTemplate?.ValueType === 0) ||
        column.dataType === DataType.String) {
        //text cell
        return (
            <select
                className='annotation-table-filter-operator-text'
                defaultValue={column.filterRowOperator}
                onChange={(event) => {
                    dispatch(updateFilterRowOperator(column.key, event.currentTarget.value));
                    if (event.currentTarget.value === "empty" || event.currentTarget.value === "notempty" || event.currentTarget.value === "nofilter") {
                        dispatch(updateFilterRowValue(column.key, " "));
                    }
                }}>
                <option value={'nofilter'}>{'Not Filtered'}</option>
                <option value={'='}>{'='}</option>
                <option value={'!'}>{'!='}</option>
                <option value={'contains'}>{'Contains'}</option>
                <option value={'not contains'}>{'! Contains'}</option>
                <option value={'empty'}>{'Empty'}</option>
                <option value={'notempty'}>{'Not Empty'}</option>
            </select>
        );
    }
    else if (column?.dataType === "List") {
        //multi list
        if (column.filterRowValue.multiSelect) {
            return (
                <select
                    className='annotation-table-filter-operator-text'
                    defaultValue={column.filterRowOperator}
                    onChange={(event) => {
                        dispatch(updateFilterRowOperator(column.key, event.currentTarget.value));
                        if (event.currentTarget.value === "empty" || event.currentTarget.value === "notempty" || event.currentTarget.value === "nofilter") {
                            dispatch(updateFilterRowValue(column.key, { ...column.filterRowValue, items: [] }));
                        }
                    }}>
                    <option value={'nofilter'}>{'Not Filtered'}</option>
                    <option value={'='}>{'='}</option>
                    <option value={'!='}>{'!='}</option>
                    <option value={'anyof'}>{'Any Of'}</option>
                    <option value={'allof'}>{'All Of'}</option>
                    <option value={'noneof'}>{'None Of'}</option>
                    <option value={'empty'}>{'Empty'}</option>
                    <option value={'notempty'}>{'Not Empty'}</option>
                </select>
            );
        }
        else {
            return (
                <select
                    className='annotation-table-filter-operator-text'
                    defaultValue={column.filterRowOperator}
                    onChange={(event) => {
                        dispatch(updateFilterRowOperator(column.key, event.currentTarget.value));
                        if (event.currentTarget.value === "empty" || event.currentTarget.value === "notempty" || event.currentTarget.value === "nofilter") {
                            dispatch(updateFilterRowValue(column.key, { ...column.filterRowValue, items: null }));
                        }

                    }}>
                    <option value={'nofilter'}>{'Not Filtered'}</option>
                    <option value={'='}>{'='}</option>
                    <option value={'!='}>{'!='}</option>
                    <option value={'empty'}>{'Empty'}</option>
                    <option value={'notempty'}>{'Not Empty'}</option>
                    <option value={'anyof'}>{'Any Of'}</option>
                    <option value={'noneof'}>{'None Of'}</option>
                </select>
            );

        }
    }
    else if (column?.dataType === "date") {
        return (
            <select
                className='annotation-table-filter-operator-text'
                defaultValue={column.filterRowOperator}
                onChange={(event) => {
                    dispatch(updateFilterRowOperator(column.key, event.currentTarget.value));
                    if (event.currentTarget.value === "nofilter") {
                        dispatch(updateFilterRowValue(column.key, null));
                    }

                }}>
                <option value={'nofilter'}>{'Not Filtered'}</option>
                <option value={'='}>{'='}</option>
                <option value={'>'}>{'>'}</option>
                <option value={'>='}>{'>='}</option>
                <option value={'<'}>{'<'}</option>
                <option value={'<='}>{'<='}</option>
            </select>
        );

    }
    else {
        //number
        return (
            <select
                className='annotation-table-filter-operator-text'
                defaultValue={column.filterRowOperator}
                onChange={(event) => {
                    dispatch(updateFilterRowOperator(column.key, event.currentTarget.value));
                    if (event.currentTarget.value === "empty" || event.currentTarget.value === "notempty") {
                        dispatch(updateFilterRowValue(column.key, 0));
                    }
                    column.stopFiltering = false;
                }}>
                <option value={'nofilter'}>{'Not Filtered'}</option>
                <option value={'='}>{'='}</option>
                <option value={'!'}>{'!='}</option>
                <option value={'>'}>{'>'}</option>
                <option value={'>='}>{'>='}</option>
                <option value={'<'}>{'<'}</option>
                <option value={'<='}>{'<='}</option>
                <option value={'empty'}>{'Empty'}</option>
                <option value={'notempty'}>{'Not Empty'}</option>
            </select>
        );

    };

};

//for filtering a list property
const FilterList = ({
    column, dispatch,
}) => {

    function setItems(items) {
        if (column.filterRowValue.multiSelect || column.filterRowOperator === "anyof" || column.filterRowOperator === "noneof") {
            dispatch(updateFilterRowValue(column.key, { ...column.filterRowValue, items: items }));
        }
        else {
            //single doesn't return an array, so arrayerise it
            dispatch(updateFilterRowValue(column.key, { ...column.filterRowValue, items: [items] }));
        }
    }

    return (
        <div className="annotation-table-filter-container-list">
            <FilterOperators column={column} dispatch={dispatch} />
            {!(column.filterRowOperator === "empty" || column.filterRowOperator === "notempty" || column.filterRowOperator === "nofilter") &&
                <ListDropDown items={column.filterRowValue.items} multiSelect={column.filterRowValue.multiSelect || column.filterRowOperator === "anyof" || column.filterRowOperator === "noneof"} availableItems={column.filterRowValue.availableItems} setItems={setItems} />
            }
        </div>
    );
};


//for filtering a number property
const FilterNumber = ({
    column, dispatch,
}) => {
    return (
        <div className="annotation-table-filter-container">
            <FilterOperators column={column} dispatch={dispatch} />
            {!(column.filterRowOperator === "empty" || column.filterRowOperator === "notempty" || column.filterRowOperator === "nofilter") &&
                <input
                    defaultValue={column.filterRowValue}
                    className="annotation-table-filter-number"
                    onChange={(event) => {
                        const filterRowValue = event.currentTarget.value !== '' ? Number(event.currentTarget.value) : null;
                        dispatch(updateFilterRowValue(column.key, filterRowValue));
                    }}
                    type='number'
                />}
        </div>
    );
};

const FilterDate = ({
    column, dispatch,
}) => {
    return (
        <div className="annotation-table-filter-container">
            <FilterOperators column={column} dispatch={dispatch} />
            {!(column.filterRowOperator === "empty" || column.filterRowOperator === "notempty" || column.filterRowOperator === "nofilter") &&
                <input
                    type='date'
                    className="annotation-table-filter-date"
                    onChange={(event) => {
                        const targetValue = event.currentTarget.value;
                        const filterRowValue = targetValue ? new Date(targetValue) : null;
                        dispatch(updateFilterRowValue(column.key, filterRowValue));
                    }}
                />
            }
        </div>
    );
};

const FilterText = ({
    column, dispatch,
}) => {

    return (
        <div className="annotation-table-filter-container">
            <FilterOperators column={column} dispatch={dispatch} />
            {!(column.filterRowOperator === "empty" || column.filterRowOperator === "notempty" || column.filterRowOperator === "nofilter") &&
                <input
                    value={column.filterRowValue}
                    className="annotation-table-filter-text"
                    onChange={(event) => {
                        dispatch(updateFilterRowValue(column.key, event.currentTarget.value));
                    }}
                    type='text'
                />
            }
        </div>
    );
};

function getUserName(orgs, userId) {

    if (!userId || userId === "00000000-0000-0000-0000-000000000000") return "---";

    for (let orgIndex = 0; orgIndex < orgs.length; orgIndex++) {
        var user = orgs[orgIndex].Users.find(u => u.UserId === userId);
        if (user) {
            return fullUsersName(user, orgs, true);
        }
    }
    return "Unknown";
}


//----------------------------------------------------------------
//----------------------------------------------------------------
//----------------------------------------------------------------
//----------------------------------------------------------------
//----------------------------------------------------------------
//----------------------------------------------------------------
//table start
//----------------------------------------------------------------
export function AnnotationTable(props) {

    const { project, model, annotationSet, currentUser, orgsInfo, helpContext } = props;

    logger.debug("AnnotationTable(props)", props);


    function getDataItem(annotation) {
        let item =
        {
            id: annotation.AnnotationId,
            dateCreated: annotation.DateCreated,
            dateChanged: Date.parse(annotation.DateUpdated) > 0 ? annotation.DateUpdated : "",
            userCreated: getUserName(orgsInfo.organisations, annotation.UserCreated),
            userChanged: getUserName(orgsInfo.organisations, annotation.UserUpdated)
        };

        for (let index = 0; index < project?.PropertyTemplates?.length; index++) {

            //does this annotation have one of these?, if not show a '---' text
            const anProp = annotation?.Properties.find(prop => prop.PropertyTemplate.PropertyTemplateId === project?.PropertyTemplates[index].PropertyTemplateId);

            if (project?.PropertyTemplates[index].PropertyType === Constants.propertyType.Value) {
                if (project?.PropertyTemplates[index].ValueType !== Constants.valueType.TextProperty) {
                    //number - can't use text - it gets converted to a number
                    if (anProp) {
                        if (anProp.Value === "" || anProp.Value === null) {
                            //no number entered
                            item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = NaN;// anProp.Value;
                        }
                        else {
                            item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = anProp.Value;
                        }

                    }
                    else {
                        //no property entered
                        item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = null;
                    }
                }
                else {
                    //text
                    if (anProp) {
                        if (anProp.Value === "" || anProp.Value === null) {
                            //nothing entered
                            item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = null;
                        }
                        else {
                            item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = anProp.Value;
                        }

                    }
                    else {
                        //no property entered
                        item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] = "---";
                    }
                }
            }
            else {
                if (anProp) {
                    item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] =
                    {
                        valid: true,
                        items: anProp.Items.map(
                            item => ({
                                label: item.ItemText,
                                value: item.ListPropertyTemplateListItemId
                            })
                        )
                    }
                }
                else {
                    item["property" + project?.PropertyTemplates[index].PropertyTemplateId.replaceAll('-', '')] =
                    {
                        valid: false,
                        items: []
                    }
                }
            }
        }


        return item;

    }

    function dataArray(annotationSet) {
        return annotationSet?.Annotations?.map((annotation, index) => (getDataItem(annotation)));
    }


    //for the properties edit modal
    const [showPropertiesDialog, setShowPropertiesDialog] = useState(false);
    const [showPropertyTemplatesDialog, setShowPropertyTemplatesDialog] = useState(false);
    const [annotationPropertiesParam, setAnnotationPropertiesParam] = useState({});
    const [showStyleSheetsDialog, setShowStyleSheetsDialog] = useState(false);


    const [filterVisible, setFilterVisible] = useState(false);
    function onToggleFilter() {
        if (filterVisible) {
            logger.debug("clear filters");
            //clear filters
            tablePropsInit().columns.forEach((column) => {
                if (column?.dataType === "List") {
                    column.filterRowValue.items = [];
                    dispatchTable(updateFilterRowValue(column.key, column.filterRowValue));
                }
                else {
                    dispatchTable(updateFilterRowValue(column.key, ""));
                }
            })
        }
        setFilterVisible(!filterVisible);
    }



    function tablePropsInit() {
        let tablePropObj = {

            columns: [
                { key: 'selection', isEditable: false, width: 45 },
                //                { key: 'editColumn', style: { width: 60 } },
                { key: 'id', title: 'ID', dataType: DataType.String, style: { width: 150 }, isEditable: false, visible: false },
            ],
            singleAction: loadData(),
            rowKeyField: 'id',
            sortingMode: SortingMode.Single,
            columnResizing: true,
            selectedRows: [],
            loading: { enabled: false },
            filteringMode: FilteringMode.FilterRow,
            format: ({ column, value }) => {
                if (column.dataType === DataType.Date) {
                    return <>
                        {value?.toLocaleDateString()} {value.toLocaleTimeString()}
                    </>
                }
            },
            sort: ({ column }) => {
                if (column.dataType === "List") {
                    //sort on first item
                    return (a, b) => {
                        let retVal = 0;
                        if (a?.items.length === 0 && b?.items.length === 0) {
                            retVal = 0;
                        }
                        else if (a?.items.length !== 0 && b?.items.length === 0) {
                            retVal = 1;
                        }
                        else if (a?.items.length === 0 && b?.items.length !== 0) {
                            retVal = -1;
                        }
                        else {
                            retVal = a.items[0].label.localeCompare(b.items[0].label);
                        }

                        if (column.sortDirection === SortDirection.Ascend) {
                            retVal *= -1;
                        }

                        return retVal;


                    }
                }
            },
            filter: ({ column }) => {
                if (column.dataType === DataType.Date) {
                    if (column.filterRowOperator === "nofilter") {
                        return (value, filterRowValue) => true;
                    }
                }
                else if (column.dataType === DataType.Number) {

                    switch (column.filterRowOperator) {
                        case "nofilter":
                            return (value, filterRowValue) => true;
                        case "=":
                            return (value, filterRowValue) => Number(filterRowValue) === Number(value);
                        case ">":
                            return (value, filterRowValue) => Number(value) > Number(filterRowValue);
                        case "<":
                            return (value, filterRowValue) => Number(value) < Number(filterRowValue);
                        case ">=":
                            return (value, filterRowValue) => Number(value) >= Number(filterRowValue);
                        case "<=":
                            return (value, filterRowValue) => Number(value) <= Number(filterRowValue);
                        case "!":
                            return (value, filterRowValue) => Number(value) !== Number(filterRowValue);
                        case "empty":
                            return (value, filterRowValue) => isNaN(value);
                        case "notempty":
                            return (value, filterRowValue) => !isNaN(value);
                        default:
                            break;
                    }
                }
                //text
                else if (column.dataType === DataType.String) {

                    switch (column.filterRowOperator) {
                        case "nofilter":
                            return (value, filterRowValue) => true;
                        case "contains":
                            return (value, filterRowValue) => value?.toUpperCase()?.includes(filterRowValue.toUpperCase());
                        case "not contains":
                            return (value, filterRowValue) => !value?.toUpperCase()?.includes(filterRowValue.toUpperCase());
                        case "=":
                            return (value, filterRowValue) => value?.toUpperCase() === filterRowValue?.toUpperCase();
                        case "!":
                            return (value, filterRowValue) => value?.toUpperCase() !== filterRowValue?.toUpperCase();
                        case "empty":
                            return (value, filterRowValue) => {
                                return value === "" || value === null;
                            }
                        case "notempty":
                            return (value, filterRowValue) => !(value === "" || value === null);
                        default:
                            break;
                    }
                }
                else if (column.dataType === "List") {
                    //lists

                    return (value, filterRowValue) => {

                        switch (column.filterRowOperator) {
                            case "nofilter":
                                return true;
                            case "allof":
                                let isFound = true;

                                if (value) {
                                    filterRowValue?.items?.forEach(filterItem => {
                                        if (value?.items.findIndex(valueItem => valueItem.value === filterItem.value) === -1) {
                                            isFound = false;
                                        }
                                    });
                                }

                                return isFound;
                            case "anyof":
                                let foundAny = false;

                                if (value) {
                                    filterRowValue?.items?.forEach(filterItem => {
                                        if (value?.items.findIndex(valueItem => valueItem.value === filterItem.value) > -1) {
                                            foundAny = true;
                                        }
                                    });
                                }

                                return foundAny;
                            case "noneof":
                            case "!=":
                                let contains = false;

                                if (value) {
                                    filterRowValue?.items?.forEach(filterItem => {
                                        if (value?.items.findIndex(valueItem => valueItem.value === filterItem.value) > -1) {
                                            contains = true;
                                        }
                                    });
                                }

                                return !contains;
                            case "=":
                                let allFound = true;

                                if (value && filterRowValue?.items) {
                                    filterRowValue?.items?.forEach(filterItem => {
                                        if (value?.items.findIndex(valueItem => valueItem.value === filterItem.value) === -1) {
                                            allFound = false;
                                        }
                                    });
                                    value?.items?.forEach(valueItem => {
                                        if (filterRowValue.items.findIndex(filterItem => valueItem.value === filterItem.value) === -1) {
                                            allFound = false;
                                        }
                                    });
                                }

                                return allFound;
                            case "empty":
                                if (!value?.items?.length) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            case "notempty":
                                if (!value?.items?.length) {
                                    return false;
                                }
                                else {
                                    return true;
                                }
                            default:
                                break;

                        }
                    }
                }

            }
        }

        //add annotation property templates
        for (let index = 0; index < project?.PropertyTemplates?.length; index++) {
            const template = project.PropertyTemplates[index];

            logger.debug("adding col");

            let col = {
                key: "property" + template.PropertyTemplateId.replaceAll('-', ''),
                isEditable: false,
                title: template.Title,
                width: 150,
                filterRowOperator: 'nofilter',
                filterRowValue: ""
            }

            //text items 
            if (template.PropertyType === 0 && template?.ValueType === Constants.valuePropertyTypeType.Text) {
                col.dataType = DataType.String;
            }
            else if (template.PropertyType === 0 && template?.ValueType > 0) {
                col.dataType = DataType.Number;
            }

            //lists have an object the filter value
            if (template.PropertyType === 1) {
                col.dataType = "List";
                col.filterRowValue = {
                    items: [],
                    multiSelect: template.MultiSelect,
                    availableItems: template.ListItems.map(item => ({
                        label: item.ItemText,
                        value: item.ListPropertyTemplateListItemId
                    }))
                };
            }

            tablePropObj.columns.push(col);
        }

        //add paging if > 1000 rows
        if (annotationSet?.Annotations?.length > 1000) {
            tablePropObj.paging =
            {
                enabled: true,
                pageSize: 1000,
                pageIndex: 0
            };
        }

        //meta info
        tablePropObj.columns.push({ key: 'dateCreated', title: "Created", isEditable: false, dataType: DataType.Date, filterRowOperator: 'nofilter', style: { width: 150, minWidth: 100 } });
        tablePropObj.columns.push({ key: 'dateChanged', title: "Last modified", isEditable: false, dataType: DataType.Date, filterRowOperator: 'nofilter', style: { width: 150, minWidth: 100 } });
        tablePropObj.columns.push({ key: 'userCreated', title: "Created by", filterRowOperator: 'nofilter', isEditable: false, dataType: DataType.String, style: { width: 150, minWidth: 100 } });
        tablePropObj.columns.push({ key: 'userChanged', title: "Modified by", filterRowOperator: 'nofilter', isEditable: false, dataType: DataType.String, style: { width: 150, minWidth: 100 } });


        return tablePropObj;
    };

    const [tableProps, setTableProps] = useState(tablePropsInit());

    ///reducer ==================================
    ///======= ==================================
    const dispatchTable = async (action) => {

        //built-in actions
        setTableProps((prevState) => kaReducer(prevState, action));

        if (action.type === ActionType.LoadData) {
            dispatchTable(updateData(dataArray(annotationSet)));
        }
        else if (action.type === ActionType.ShowNewRow) {
            //add defaults
            dispatchTable(updateEditorValue(newRowId, "backgroundColour", "FFFFFFFF"));
            dispatchTable(updateEditorValue(newRowId, "foregroundColour", "000000FF"));
        }
    };


    const currentAnSet = React.useRef(null);


    //load data if annotationSet contents have changed
    //init table if annotationset is a different one
    useEffect(() => {

        logger.debug("useEffect");

        if (currentAnSet.current !== annotationSet?.AnnotationSetId) {
            logger.debug("anset id changed ");
            currentAnSet.current = annotationSet?.AnnotationSetId;
            setTableProps(tablePropsInit());
            dispatchTable(setSingleAction(loadData()));

            if (filterVisible) {
                onToggleFilter();
            }

        }
        else {
            logger.debug("anset id NO change ");
            dispatchTable(setSingleAction(loadData()));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotationSet])

    //load data if annotationSet or PropertyTemplates have changed
    useEffect(() => {

        logger.debug("useEffect project?.PropertyTemplates");

        setTableProps(tablePropsInit());
        dispatchTable(setSingleAction(loadData()));

        if (filterVisible) {
            onToggleFilter();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project?.PropertyTemplates])



    async function onDeleteSelected() {

        const selectedData = kaPropsUtils.getSelectedData(tableProps);
        if (selectedData.length > 0) {

            const result = await Confirm("Are you sure you want to delete the selected Annotations ?", "Delete Annotations");
            if (result) {

                //get array of annotation ids
                let annotationIds = selectedData.map(item => item.id);

                ToastLoading("Deleting Annotations", deleteAnnotationsToastId);

                try {
                    unwrapResult(await DeleteAnnotations({ AnnotationIds: annotationIds }));
                    ToastClose(deleteAnnotationsToastId);
                    dispatchTable(deselectAllFilteredRows());
                }
                catch (err) {
                    ToastError(err.message, err.cause, deleteAnnotationsToastId);
                }
            }
        }
    }

    function canEditAnnotationSet() {
        return !annotationSet?.IsLocked && CanWriteAnnotationSet(currentUser, annotationSet?.AnnotationSetId, model?.ModelId, project?.ProjectId, project?.OrganisationId);
    }

    function canEditTemplatesOrStyleSheets() {
        return CanEditAnnotationTemplatesOrStyleSheets(currentUser, project?.ProjectId, project?.OrganisationId);
    }


    //edited properties now being saved.
    //foreach selected annotation, make equal to all properties

    async function onSaveProperties(propertyConfig) {

        ToastLoading("Updating Properties", updatePropertiesToastId);

        let success = true;
        //loop over the annotations in the selection
        for (let index = 0; index < propertyConfig.selectedAnnotationIds.length; index++) {

            const annotation = annotationSet?.Annotations.find(a => a.AnnotationId === propertyConfig.selectedAnnotationIds[index]);


            logger.debug("Annotation saving properties", annotation);

            //loop over all the properties returned
            let newProperties = [];
            for (let propertyIndex = 0; propertyIndex < propertyConfig.properties.length; propertyIndex++) {

                logger.debug("Property", propertyConfig.properties[propertyIndex]);

                //is this property on this annotation
                if (propertyConfig.properties[propertyIndex].inAllAnnotations ||
                    propertyConfig.properties[propertyIndex].annotationList.findIndex(a => a === annotation.AnnotationId) > -1) {

                    logger.debug("Property on annotation");

                    let newProperty = (JSON.parse(JSON.stringify(propertyConfig.properties[propertyIndex])));

                    //value hasn't been edited, so use the existing value for this annotation
                    if (!newProperty.valueHasBeenEdited) {

                        //find existing property in this annotation
                        const annotationPropIndex = annotation?.Properties?.findIndex(p => p.PropertyTemplate.PropertyTemplateId === newProperty.PropertyTemplate.PropertyTemplateId);
                        if (annotationPropIndex > -1) {
                            //copy it over - to load existing value
                            newProperty = JSON.parse(JSON.stringify(annotation?.Properties[annotationPropIndex]));
                        }
                    }

                    //make id unique
                    newProperty.PropertyId = uuid();
                    //assign to this annotation
                    newProperty.AnnotationId = annotation.AnnotationId;
                    newProperties.push(newProperty);
                }
            }

            logger.debug("new properties", newProperties);

            try {
                unwrapResult(await UpdateAnnotationProperties(annotation.AnnotationId, newProperties));
            }
            catch (err) {
                ToastError(err.message, err.cause, updatePropertiesToastId);
                success = false;
                setShowPropertiesDialog(true);
                break;
            }
        }
        if (success) {
            ToastClose(updatePropertiesToastId);
            setShowPropertiesDialog(false);
        }



    }

    //call the edit properties dialog
    function onEditProperties() {


        const selectedData = kaPropsUtils.getSelectedData(tableProps);
        if (selectedData.length > 0) {

            let anParam = { annotations: [] };
            selectedData.forEach(d => {

                //pass all selected annotations to property edit dialog
                const ann = annotationSet?.Annotations.find((a) => a.AnnotationId === d.id);
                anParam.annotations.push(ann);
            });

            setAnnotationPropertiesParam(anParam)
            setShowPropertiesDialog(true);
        }
    }

    const selectedDataLength = kaPropsUtils.getSelectedData(tableProps)?.length;

    function numAnnotationsTitle() {

        return (
            annotationSet?.Annotations ?
                ("(" + annotationSet?.Annotations?.length + ")")
                :
                <></>);

    }

    function numAnnotationsVisibleTitle() {

        return (
            kaPropsUtils.getData(tableProps).length !== annotationSet?.Annotations?.length ?
                ("(Visible: " + kaPropsUtils.getData(tableProps).length + ")")
                :
                <></>);

    }

    function numAnnotationsSelectedTitle() {

        return (
            (selectedDataLength > 0) ?
                ("(Selected: " + selectedDataLength + ")")
                :
                <></>
        );

    }

    function getCsvData() {
        const tableData = kaPropsUtils.getData(tableProps);
        let data = [];
        tableData.forEach(d => {
            logger.info(d);
            let entry = {};
            for (const [key, value] of Object.entries(d)) {

                if (key.startsWith("property")) {
                    if (value?.items) {
                        if (value?.items.length === 0) {
                            entry[key] = "";
                        }
                        else {
                            let list = "";
                            entry[key] = value?.items.map(l => list += (l.label + " "));
                            entry[key] = list;
                        }
                    }
                    else if (typeof value === 'string' || value instanceof String) {
                        entry[key] = value;
                    }
                    else if (value === null) {
                        entry[key] = "";
                    }
                    else if (isNaN(value)) {
                        entry[key] = "";
                    }
                }
                else if (key.startsWith("date")) {
                    if (isNaN(value.getTime())) {
                        entry[key] = "";
                    }
                    else {
                        entry[key] = value;
                    }
                }
                else if (key !== "id") {
                    entry[key] = value;
                }
            }
            data.push(entry);
        });

        return data;
    }

    function getCsvHeaders() {
        const data = [];
        tableProps.columns.forEach(c => {
            if (c.key !== 'selection' && c.key !== 'id') {
                data.push({ label: c.title, key: c.key });
            }
        });
        return data;
    }

    return (

        <>
            <div className="view-top-panel">
                <div className="view-title" >
                    Annotations {numAnnotationsTitle()} {numAnnotationsVisibleTitle()} {numAnnotationsSelectedTitle()}
                </div>

                <div className="view-button-panel" >
                    <Button disabled={!canEditAnnotationSet() || selectedDataLength === 0} className="annotation-view-button-panel-button" variant="primary" onClick={onEditProperties}>
                        {kaPropsUtils?.getSelectedData(tableProps)?.length > 1 ? "Edit Properties (All Selected)" : "Edit Properties"}
                    </Button>
                    <Button disabled={!canEditAnnotationSet() || selectedDataLength === 0} className="annotation-view-button-panel-button" variant="primary" onClick={onDeleteSelected}>
                        {kaPropsUtils?.getSelectedData(tableProps)?.length > 1 ? "Delete (All Selected)" : "Delete"}
                    </Button>
                    <Button className="annotation-view-button-panel-button" variant="primary" onClick={onToggleFilter}>{filterVisible ? <RiFilterOffLine /> : <RiFilterLine />}</Button>
                    <CSVLink
                        data={getCsvData()}
                        headers={getCsvHeaders()}
                        filename='annotations.csv'
                        className="btn btn-primary annotation-view-button-panel-button"
                        target="_blank"
                    >
                        Download CSV
                    </CSVLink>
                    <Button className="annotation-view-button-panel-button" variant="primary" onClick={() => setShowStyleSheetsDialog(true)}>Style Sheets</Button>
                    <Button disabled={!canEditTemplatesOrStyleSheets()} className="annotation-view-button-panel-button" onClick={() => setShowPropertyTemplatesDialog(true)}>Property Templates</Button>
                </div>
            </div>

            <div className="annotation-table-styles">
                <Table
                    {...tableProps}

                    filteringMode={filterVisible ? FilteringMode.FilterRow : FilteringMode.None}
                    dispatch={dispatchTable}
                    childComponents={{
                        cellText: {
                            content: (props) => {
                                if (props.column.key === "selection" && canEditAnnotationSet()) {
                                    return <SelectionCell {...props} />;
                                }
                                //list type template column
                                //if props.value is not a property object then just display it
                                else if (props.column?.dataType === "List") {
                                    return <ListCell {...props} />
                                }
                                //value type template column
                                else if (props.column?.dataType === DataType.Number || props.column?.dataType === DataType.String) {
                                    return <ValueCell {...props} />
                                }
                                else if (props.column.dataType === DataType.Date) {
                                    if (isNaN(props.value.getTime())) {
                                        return <>---</>
                                    }
                                }
                            },
                            elementAttributes: () => ({
                                className: 'table-cell-text'
                            })
                        },
                        filterRowCell: {
                            content: (props) => {

                                logger.debug("filterRowCell", props);

                                if ((props.column.key === 'selection' || props.column.key === 'editColumn') && canEditAnnotationSet()) {
                                    return <></>;
                                }
                                else if (props.column.key === 'dateChanged' || props.column.key === 'dateCreated') {
                                    return <FilterDate {...props} />;
                                }
                                else if (props.column.key === 'userChanged' || props.column.key === 'userCreated') {
                                    return <FilterText {...props} />;
                                }
                                else if (props.column?.dataType === DataType.Number) {
                                    return <FilterNumber {...props} />;
                                }
                                else if (props.column?.dataType === DataType.String) {
                                    return <FilterText {...props} />;
                                }
                                else if (props.column?.dataType === "List") {
                                    return <FilterList {...props} />;
                                }
                            }
                        },
                        headCell: {
                            elementAttributes: (props) => {
                                if (props.column.key === 'selection' && canEditAnnotationSet()) {
                                    return {
                                        style: {
                                            ...props.column.style,
                                            position: 'sticky',
                                            left: 0,
                                            zIndex: 1,
                                            border: '1px solid #555555',
                                        }
                                    }
                                }
                                else if (props.column.key === 'editColumn' && canEditAnnotationSet()) {
                                    return {
                                        style: {
                                            ...props.column.style,
                                            position: 'sticky',
                                            left: 1,
                                            zIndex: 10,
                                            border: '1px solid #555555',
                                        }
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            ...props.column.style,
                                            position: 'sticky',
                                            border: '1px solid #555555',
                                        }
                                    }
                                }
                            },
                            content: (props) => {
                                if (props.column.key === 'selection' && canEditAnnotationSet()) {
                                    return (
                                        <SelectionHeader {...props}
                                            areAllRowsSelected={kaPropsUtils.areAllFilteredRowsSelected(tableProps)}
                                        />
                                    );
                                }

                            }
                        },
                        noDataRow: {
                            content: () => 'No Annotations'
                        },
                        cell: {
                            elementAttributes: (props) => {
                                if (props.column.key === 'selection' && canEditAnnotationSet()) {
                                    return {
                                        style: {
                                            ...props.column.style,
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: '#ffffff',
                                        }
                                    }
                                }
                            }
                        }
                    }}
                />
            </div>
            <EditAnnotationProperties
                helpContext={annotationPropertiesParam?.annotations?.length === 1 ? BeaconDialogMap.EditSingleAnnotationPropertiesDialog : BeaconDialogMap.EditMultipleAnnotationPropertiesDialog}
                parentHelpContext={helpContext}
                show={showPropertiesDialog}
                setShow={setShowPropertiesDialog}
                onSave={onSaveProperties}
                propertiesParam={annotationPropertiesParam}
                templates={project?.PropertyTemplates}

            />
            <PropertyTemplatesView helpContext={BeaconDialogMap.AnnotationPropertyTemplatesDialog} parentHelpContext={helpContext} project={project} show={showPropertyTemplatesDialog} setShow={setShowPropertyTemplatesDialog} currentUser={currentUser} />
            <StyleSheetsDialog helpContext={BeaconDialogMap.AnnotationStyleSheetsDialog} parentHelpContext={helpContext} project={project} show={showStyleSheetsDialog} setShow={setShowStyleSheetsDialog} currentUser={currentUser} />

        </>
    );
}

export default AnnotationTable;