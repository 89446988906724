import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select'
import { selectOrganisationsInfo, stateSetCurrentOrg } from '../App/organisationsInfoSlice';
import { CanEditOrganisations } from '../App/UserPermissions'
import Button from 'react-bootstrap/Button'
import EditOrganisation from "../HomePage/EditOrganisation";
import CopyOrganisation from './CopyOrganisation'
import { DeleteOrganisation, CreateOrganisation, UpdateOrganisation } from '../Api/OrganisationApi'
import { ToastError, ToastLoading, ToastSuccess } from './ToastDisplay'
import { ConfirmModal } from './ConfirmModal'
import '../css/lists.css'
import { BeaconDialogMap } from '../Components/BeaconDialogMap';

function OrgsToOptions(orgs) {
    return orgs?.map(o => ({ value: o.OrganisationId, label: o.Title }));
}

export function OrgsSelect(props) {

    const { parentHelpContext } = props;

    const dispatch = useDispatch();
    const toastId = useRef();

    const orgsInfo = useSelector(selectOrganisationsInfo);
    const [selectedOrgOption, setSelectedOrgOption] = useState(orgsInfo?.currentOrgId ? { value: orgsInfo?.currentOrgId, label: orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId)?.Title } : null);
    const [availableOrgOptions, setAvailableOrgOptions] = useState(orgsInfo?.organisations ? OrgsToOptions(orgsInfo?.organisations) : []);
    const [showOrgAddDialog, setShowOrgAddDialog] = useState(false);
    const [showOrgAddTemplateDialog, setShowOrgAddTemplateDialog] = useState(false);
    const [showOrgEditDialog, setShowOrgEditDialog] = useState(false);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

    async function onUpdateOrg(org, setUiDisabled) {

        toastId.current = ToastLoading("Updating Organisation");
        setUiDisabled(true);

        UpdateOrganisation(org)
            .then(res => {
                ToastSuccess("Updated [" + org.Title + "]", toastId.current, 5000);
                setShowOrgEditDialog(false);
                setUiDisabled(false);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });

    }


    async function onCreateOrg(org, setUiDisabled) {

        toastId.current = ToastLoading("Adding Organisation " + org.Title);

        setUiDisabled(true);

        CreateOrganisation(org)
            .then(res => {
                ToastSuccess("Added [" + org.Title + "]", toastId.current, 5000);
                setShowOrgAddDialog(false);
                setUiDisabled(false);

            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
                setUiDisabled(false);
            });

    }


    async function onDeleteOrg() {
        setShowConfirmDeleteDialog(true);
    }

    async function onDeleteOrgConfirmed() {

        toastId.current = ToastLoading("Deleting Organisation " + selectedOrgOption?.label);

        DeleteOrganisation(selectedOrgOption?.value)
            .then(res => {
                ToastSuccess("Deleted " + selectedOrgOption?.label, toastId.current, 5000);
            })
            .catch(err => {
                ToastError(err.message, err.cause, toastId.current);
            });
    }

    function setSelectedOrg(option) {
        dispatch(stateSetCurrentOrg(option.value));
        setSelectedOrgOption(option);
    }

    React.useEffect(() => {

        if (orgsInfo.organisations) {
            setAvailableOrgOptions(OrgsToOptions(orgsInfo?.organisations));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgsInfo.organisations])

    React.useEffect(() => {

        setSelectedOrgOption(orgsInfo?.currentOrgId ? { value: orgsInfo?.currentOrgId, label: orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId)?.Title } : null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgsInfo.currentOrgId])


    return (
        <div style={{ width: '350px' }}>
            <div className="list-buttons-short">
                {CanEditOrganisations(orgsInfo.currentUser) &&
                    <>
                        <Button className="list-top-button" onClick={() => setShowOrgAddDialog(true)}>Add Empty</Button>
                        <Button className="list-top-button" onClick={() => setShowOrgAddTemplateDialog(true)}>Add From Template</Button>
                        <Button className="list-top-button" disabled={selectedOrgOption?.value === null} onClick={() => setShowOrgEditDialog(true)}>Edit</Button>
                        <Button className="list-top-button" disabled={selectedOrgOption?.value === null} onClick={onDeleteOrg}>Delete</Button>
                    </>
                }
            </div>
            <Select
                value={selectedOrgOption}
                onChange={setSelectedOrg}
                closeMenuOnSelect={true}
                name="group"
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select an Organisation..."
                options={availableOrgOptions}
            />
            <CopyOrganisation
                helpContext={BeaconDialogMap.AddOrganisationFromTemplateDialog}
                parentHelpContext={parentHelpContext}
                show={showOrgAddTemplateDialog}
                setShow={setShowOrgAddTemplateDialog}
            />
            <EditOrganisation
                helpContext={BeaconDialogMap.AddEmptyOrganisationDialog}
                parentHelpContext={parentHelpContext}
                isAdding={true}
                onSave={onCreateOrg}
                show={showOrgAddDialog}
                setShow={setShowOrgAddDialog}
                dialogTitle="Add Organisation"
                org={{ Title: "" }}
            />
            <EditOrganisation
                helpContext={BeaconDialogMap.EditOrganisationDialog}
                parentHelpContext={parentHelpContext}
                onSave={onUpdateOrg}
                show={showOrgEditDialog}
                setShow={setShowOrgEditDialog}
                dialogTitle="Edit Organisation"
                org={orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId)}
            />
            {showConfirmDeleteDialog && <ConfirmModal
                show={showConfirmDeleteDialog}
                setShow={setShowConfirmDeleteDialog}
                onOK={onDeleteOrgConfirmed}
                okText={"Delete"}
                dialogBody={"Caution! All Users, Projects, Models, Model archive files, Annotation Sets and Annotations will be Deleted!"}
                dialogTitle={"Delete Organisation: " + orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId)?.Title}
                passPhrase={orgsInfo?.organisations.find(o => o.OrganisationId === orgsInfo.currentOrgId)?.Title}
                passPhrasePrompt="Enter Organisation title to confirm deletion:"

            />}

        </div>
    );
}