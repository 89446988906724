import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');



export async function UpdateModel(model) {
    logger.debug("UpdateModel model: ", model);
    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: model
    }

    let response = await API.put('Reveal4DApi', '/UpdateModel', options);
    logger.debug("UpdateModel , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return response.Data;
    }
}

export async function DeleteModel(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            modelid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteModel', options);
    logger.debug("DeleteModel , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}
