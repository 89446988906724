import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux';
import '../css/modal.css'
import { BeaconSetSuggestions} from '../Components/BeaconDialogMap'


export function ConfirmModal(props) {
    const { onOK, setShow, dialogTitle, dialogBody, okText, passPhrase, passPhrasePrompt, dialogClassName, helpContext, parentHelpContext } = props;

    const dispatch = useDispatch();

    const [passPhraseEntered, setPassPhraseEntered] = React.useState("");
    const [okEnabled, setOkEnabled] = React.useState();

    //enable delete button if passphrase is correct (or is not being used at all)
    React.useEffect(() => {

        setOkEnabled(!passPhrase || passPhraseEntered === passPhrase);

    }, [dispatch, passPhraseEntered, passPhrase])

    React.useEffect(() => {
        if (props.show) {
            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [helpContext, parentHelpContext, props.show])


    function onCancelButton() {
        setShow(false);
    }

    function onOKButton() {
        onOK();
        setShow(false);
    }

    return (
        <Modal
            show={props.show}
            onHide={onCancelButton}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName={dialogClassName}
        >
            <Modal.Header closeButton>
                <Modal.Title>{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dialogBody}
                {passPhrase &&

                    <div>
                        <br />
                        <div>{passPhrasePrompt}</div>
                        <input
                            value={passPhraseEntered}
                            className="annotation-table-filter-text"
                            placeholder={passPhrase}
                            onChange={(event) => {
                                setPassPhraseEntered(event.currentTarget.value);
                            }}
                            type='text'
                        />
                    </div>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" disabled={!okEnabled} onClick={onOKButton}>{okText}</Button>
                <Button variant="secondary" onClick={onCancelButton}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export function ConfirmModalWithExtraButton(props) {
    const { onOK, show, setShow, dialogTitle, buttonText, okText, dialogBody, dialogClassName, helpContext, parentHelpContext } = props;

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    function onCancelButton() {
        setShow(false);
    }

    function onOKButton() {
        onOK(false);
        setShow(false);
    }

    function onExtraButton() {
        onOK(true);
        setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={onCancelButton}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName={dialogClassName}
        >
            <Modal.Header closeButton>
                <Modal.Title>{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dialogBody}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onExtraButton}>{buttonText}</Button>
                <Button variant="primary" onClick={onOKButton}>{okText}</Button>
                <Button variant="secondary" onClick={onCancelButton}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default ConfirmModal;