import PropTypes from 'prop-types'
import React from 'react'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Logger } from 'aws-amplify';
const logger = new Logger('CognitoInfo');

export function CognitoInfo(props) {

    logger.debug(props);



    return (
        <div className="border border-dark mx-2 my-2 rounded p-3">

            <Row>
                <Col sm={2}>Enabled:</Col>
                <Col sm={"auto"} >{props.cognito.Enabled ? "Yes" : "No"}</Col>
            </Row>
            <Row>
                <Col sm={2}>Attributes:</Col>
            </Row>
            <br />
            {props.cognito.UserAttributes.map((item) => (
                <Row key={item.Name}>
                    <Col sm={3}>{item.Name}</Col>
                    <Col sm={"auto"} >{item.Value}</Col>
                </Row>
            ))
            }
            <br />
            <Row>
                <Col sm={2}>Created:</Col>
                <Col sm={"auto"} >{props.cognito.UserCreateDate}</Col>
            </Row>
            <br />
            <Row>
                <Col sm={2}>Last Modified:</Col>
                <Col sm={"auto"} >{props.cognito.UserLastModifiedDate}</Col>
            </Row>
            <br />
            <Row>
                <Col sm={2}>User Name:</Col>
                <Col sm={"auto"} >{props.cognito.Username}</Col>
            </Row>
            <br />
            <Row>
                <Col sm={2}>Status:</Col>
                <Col sm={"auto"} >{props.cognito.UserStatus.Value}</Col>
            </Row>
            <br />
            <Row>
                <Col sm={2}>Devices:</Col>
            </Row>
            {props.devices.map((item) => (
                <Row key={item.DeviceKey}>
                    <Row key={"created"}>
                        <Col sm={3}>{"Created Date"}</Col>
                        <Col sm={"auto"} >{item.DeviceCreateDate}</Col>
                    </Row>
                    <Row key={"DeviceLastAuthenticatedDate"}>
                        <Col sm={3}>{"Last authenticated Date"}</Col>
                        <Col sm={"auto"} >{item.DeviceLastAuthenticatedDate}</Col>
                    </Row>
                    <Row key={"DeviceLastModifiedDate"}>
                        <Col sm={3}>{"Last Modified Date"}</Col>
                        <Col sm={"auto"} >{item.DeviceLastModifiedDate}</Col>
                    </Row>
                    <Row key={"DeviceKey"}>
                        <Col sm={3}>{"Device Key"}</Col>
                        <Col sm={"auto"} >{item.DeviceKey}</Col>
                    </Row>
                    {item.DeviceAttributes.map((attrib) => (
                        <Row key={attrib.Name}>
                            <Col sm={3}>{attrib.Name}</Col>
                            <Col sm={"auto"} >{attrib.Value}</Col>
                        </Row>
                    ))}
                    <br />
                    <br />
                </Row>
            ))}


        </div>
    );
}

CognitoInfo.propTypes = {
    cognito: PropTypes.object.isRequired
};


export default CognitoInfo;