import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectSystemInfo } from '../App/systemInfoSlice';
import {
    stateUpdateOrganisation, stateDeleteOrganisation,
    stateUpdateUser, stateDeleteUser,
    stateDeleteGroup, stateUpdateGroup,
    stateUpdateProject, stateDeleteProject,
    stateUpdateAnnotation, stateDeleteAnnotation,
    stateUpdateModel, stateDeleteModel,
    stateUpdateCrs, stateDeleteCrs,
    stateUpdateCrsTransform, stateDeleteCrsTransform,
    stateUpdateAssetBundle, stateDeleteAssetBundle,
    stateUpdatePropertyTemplate, stateDeletePropertyTemplate,
    stateUpdateAnnotationStyleRule, stateDeleteAnnotationStyleRule,
    stateUpdateAnnotationStyleSheet, stateDeleteAnnotationStyleSheet,
    stateUpdateAnnotationProperties,
    selectOrganisationsInfo,
    stateUpdateModelGroupPermission, stateUpdateModelUserPermission,
    stateUpdateProjectGroupPermission, stateUpdateProjectUserPermission,
    stateDeleteModelGroupPermission, stateDeleteModelUserPermission,
    stateDeleteProjectGroupPermission, stateDeleteProjectUserPermission,
    stateUpdateUserShare, stateDeleteUserShare,
    stateUpdateGroupShare, stateDeleteGroupShare,
    stateDeleteLicense, stateUpdateLicense,
    stateUpdateAnnotationSet, stateDeleteAnnotationSet,
    stateUpdateAnnotationSetUserPermission, stateUpdateAnnotationSetGroupPermission,
    stateDeleteAnnotationSetUserPermission, stateDeleteAnnotationSetGroupPermission, 
    stateUpdateBuildLogRecord, stateDeleteBuildLogRecord


} from './organisationsInfoSlice';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { ToastMessage } from '../Components/ToastDisplay'
import { Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Websocket');


export function WebSocketHandler(props) {

    const {user} = props;

    const dispatch = useDispatch();
    const systemInfo = useSelector(selectSystemInfo);
    const orgInfo = useSelector(selectOrganisationsInfo);

    // const usersInfo = useSelector(selectUsersInfo);
    const webSocket = useRef(null);

    async function buildWebSocketUrl() {
        let session = await Auth.currentSession();

        const jwt = session.getAccessToken().getJwtToken();

        logger.debug("buildWebSocketUrl()", systemInfo.apiSettings.WebSocketUrl + "?token=" + jwt);

        return systemInfo.apiSettings.WebSocketUrl + "?token=" + jwt;
    }

    function databaseAddRow(table, data) {
        logger.debug("### databaseAddRow", table, data);
        switch (table) {
            case "Organisations":
                dispatch(stateUpdateOrganisation(data))
                break;
            case "Annotations":
                dispatch(stateUpdateAnnotation(data))
                break;
            case "AnnotationSets":
                dispatch(stateUpdateAnnotationSet(data))
                break;
            case "AssetBundles":
                dispatch(stateUpdateAssetBundle(data))
                break;
            case "CrsTransforms":
                dispatch(stateUpdateCrsTransform(data))
                break;
            case "Crss":
                dispatch(stateUpdateCrs(data))
                break;
            case "Groups":
                dispatch(stateUpdateGroup(data))
                break;
            case "Models":
                dispatch(stateUpdateModel(data))
                break;
            case "Projects":
                dispatch(stateUpdateProject(data))
                break;
            case "Users":
                dispatch(stateUpdateUser(data))
                break;
            case "ValuePropertyTemplates":
            case "ListPropertyTemplates":
                dispatch(stateUpdatePropertyTemplate(data))
                break;
            case "AnnotationStyleRules":
                dispatch(stateUpdateAnnotationStyleRule(data))
                break;
            case "AnnotationStyleSheets":
                dispatch(stateUpdateAnnotationStyleSheet(data))
                break;
            case "BuildLogRecords":
                dispatch(stateUpdateBuildLogRecord(data))
                break;
            case "ProjectUserPermissions":
                dispatch(stateUpdateProjectUserPermission(data))
                break;
            case "ProjectGroupPermissions":
                dispatch(stateUpdateProjectGroupPermission(data))
                break;
            case "AnnotationSetUserPermissions":
                dispatch(stateUpdateAnnotationSetUserPermission(data))
                break;
            case "AnnotationSetGroupPermissions":
                dispatch(stateUpdateAnnotationSetGroupPermission(data))
                break;
            case "ModelUserPermissions":
                dispatch(stateUpdateModelUserPermission(data))
                break;
            case "ModelGroupPermissions":
                dispatch(stateUpdateModelGroupPermission(data))
                break;
            case "UserShares":
                dispatch(stateUpdateUserShare(data))
                break;
            case "GroupShares":
                dispatch(stateUpdateGroupShare(data))
                break;
            case "Licenses":
                dispatch(stateUpdateLicense(data))
                break;
            default:
        }
    }

    function databaseDeleteRow(table, data) {
        logger.debug("databaseDeleteRow", table, data);

        switch (table) {
            case "Organisations":
                dispatch(stateDeleteOrganisation(data))
                break;
            case "Annotations":
                dispatch(stateDeleteAnnotation(data));
                break;
            case "AnnotationSets":
                dispatch(stateDeleteAnnotationSet(data));
                break;
            case "AssetBundles":
                dispatch(stateDeleteAssetBundle(data));
                break;
            case "CrsTransforms":
                dispatch(stateDeleteCrsTransform(data));
                break;
            case "Crss":
                dispatch(stateDeleteCrs(data));
                break;
            case "Groups":
                dispatch(stateDeleteGroup(data));
                break;
            case "Models":
                dispatch(stateDeleteModel(data));
                break;
            case "Projects":
                dispatch(stateDeleteProject(data));
                break;
            case "Users":
                dispatch(stateDeleteUser(data));
                break;
            case "ValuePropertyTemplates":
            case "ListPropertyTemplates":
                dispatch(stateDeletePropertyTemplate(data))
                break;
            case "AnnotationStyleRules":
                dispatch(stateDeleteAnnotationStyleRule(data))
                break;
            case "AnnotationStyleSheets":
                dispatch(stateDeleteAnnotationStyleSheet(data))
                break;
            case "BuildLogRecords":
                dispatch(stateDeleteBuildLogRecord(data))
                break;
            case "ProjectUserPermissions":
                dispatch(stateDeleteProjectUserPermission(data))
                break;
            case "ProjectGroupPermissions":
                dispatch(stateDeleteProjectGroupPermission(data))
                break;
            case "AnnotationSetUserPermissions":
                dispatch(stateDeleteAnnotationSetUserPermission(data))
                break;
            case "AnnotationSetGroupPermissions":
                dispatch(stateDeleteAnnotationSetGroupPermission(data))
                break;
            case "ModelUserPermissions":
                dispatch(stateDeleteModelUserPermission(data))
                break;
            case "ModelGroupPermissions":
                dispatch(stateDeleteModelGroupPermission(data))
                break;
            case "UserShares":
                dispatch(stateDeleteUserShare(data))
                break;
            case "GroupShares":
                dispatch(stateDeleteGroupShare(data))
                break;
            case "Licenses":
                dispatch(stateDeleteLicense(data))
                break;

            default:
        }
    }

    function databaseUpdateRow(table, data) {
        logger.debug("### datbase update row, table: ", table, data);
        switch (table) {
            case "Organisations":
                dispatch(stateUpdateOrganisation(data))
                break;
            case "Annotations":
                dispatch(stateUpdateAnnotation(data));
                break;
            case "AnnotationSets":
                dispatch(stateUpdateAnnotationSet(data));
                break;
            case "BuildLogRecords":
                dispatch(stateUpdateBuildLogRecord(data))
                break;
            case "AssetBundles":
                dispatch(stateUpdateAssetBundle(data));
                break;
            case "CrsTransforms":
                dispatch(stateUpdateCrsTransform(data));
                break;
            case "Crss":
                dispatch(stateUpdateCrs(data));
                break;
            case "Groups":
                dispatch(stateUpdateGroup(data));
                break;
            case "Models":
                dispatch(stateUpdateModel(data));
                break;
            case "Projects":
                dispatch(stateUpdateProject(data));
                break;
            case "Users":
                dispatch(stateUpdateUser(data));
                break;
            case "ValuePropertyTemplates":
            case "ListPropertyTemplates":
                dispatch(stateUpdatePropertyTemplate(data))
                break;
            case "AnnotationStyleRules":
                dispatch(stateUpdateAnnotationStyleRule(data))
                break;
            case "AnnotationStyleSheets":
                dispatch(stateUpdateAnnotationStyleSheet(data))
                break;
            case "AnnotationProperties":
                dispatch(stateUpdateAnnotationProperties(data))
                break;
            case "ProjectUserPermissions":
                dispatch(stateUpdateProjectUserPermission(data))
                break;
            case "ProjectGroupPermissions":
                dispatch(stateUpdateProjectGroupPermission(data))
                break;
            case "ModelUserPermissions":
                dispatch(stateUpdateModelUserPermission(data))
                break;
            case "ModelGroupPermissions":
                dispatch(stateUpdateModelGroupPermission(data))
                break;
            case "UserShares":
                dispatch(stateUpdateUserShare(data))
                break;
            case "GroupShares":
                dispatch(stateUpdateGroupShare(data))
                break;
            case "Licenses":
                dispatch(stateUpdateLicense(data))
                break;
            case "AnnotationSetUserPermissions":
                dispatch(stateUpdateAnnotationSetUserPermission(data))
                break;
            case "AnnotationSetGroupPermissions":
                dispatch(stateUpdateAnnotationSetGroupPermission(data))
                break;

            default:
        }
    }

    React.useEffect(() => {

        //no user but websocket active - close it
        if (user === null && webSocket.current){
            logger.debug("user sign out, websocket close");
            webSocket.current.close();
            webSocket.current = null;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    React.useEffect(() => {

        logger.debug("open websocket??", systemInfo?.apiSettings?.WebSocketUrl, !webSocket.current, orgInfo.organisationsRetrieved);

        //wait till things are in place but the socket isn't open then - open it
        if (systemInfo?.apiSettings?.WebSocketUrl && !webSocket.current && orgInfo.organisationsRetrieved) {

            logger.debug("openning websocket");

            //get session token and send, to auth websocket connection
            const options = {
                connectionTimeout: 80000,
            };
            webSocket.current = new ReconnectingWebSocket(buildWebSocketUrl, [], options);

            webSocket.current.onerror = function (event) {
                logger.debug('Websocket onerror', event);
            }

            webSocket.current.onopen = function (event) {
                logger.debug('Websocket onopen', event);
            }

            webSocket.current.onclose = function (event) {
                logger.debug("websocket onclose", event);
            }

            webSocket.current.onmessage = function (event) {
                logger.debug("websocket onmessage", event);
                const data = JSON.parse(event.data);

                if (data?.action === "text") {
                    ToastMessage(data.Text, data.From, data.TextMessageType);
                }
                else if (data?.action === "db") {
                    switch (data.ChangedType) {
                        case "add":
                            databaseAddRow(data.Table, data.RowData);
                            break;
                        case "del":
                            databaseDeleteRow(data.Table, data.Id);
                            break;
                        case "update":
                            databaseUpdateRow(data.Table, data.RowData);
                            break;
                        default:
                    }
                }
            }
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemInfo?.apiSettings?.WebSocketUrl, orgInfo.organisationsRetrieved]);




    return (<></>);

}



export default WebSocketHandler;