import { SystemPermissionFlags, ModelPermissionFlags, LicenseTypes, GroupTypes } from './Permissions';

import { Logger } from 'aws-amplify';
const logger = new Logger('UserPermissions');


export function userTypeText(user) {
    if (HasSystemPermission(user, SystemPermissionFlags.RootUser)) {
        return "System Admin";
    }
    else if (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) {
        return "Org Admin";
    }
    else {
        return "User";
    }
}



export function canUserSeeGroupsPage(user, currentOrgId) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser) || (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && user.OrganisationId === currentOrgId);
}

export function canUserSeeOrgsPage(user, currentOrgId) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser) || (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && user.OrganisationId === currentOrgId);
}

export function canUserSeeUtilsPage(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function canUserSeeFullBuildLog(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function canUserSeeAllBuildLogsInOrg(user, orgId) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser) || (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && user.OrganisationId === orgId);
}


export function PermissionIncludesWriteAccess(permissions) {
    return (permissions & (ModelPermissionFlags.AllowAnnotationsEdit |
        ModelPermissionFlags.AllowCrsManagement |
        ModelPermissionFlags.AllowLockingUnLocking |
        ModelPermissionFlags.AllowWrite |
        ModelPermissionFlags.AllowUploadModelArchive)) > 0;
}

export function PermissionIncludesReadAccess(permissions) {
    return (permissions & (ModelPermissionFlags.AllowRead)) > 0;
}

export function CanAssignSystemPermissions(user, permissions) {
    if (HasSystemPermission(user, SystemPermissionFlags.RootUser)) {
        return true;
    }

    //noone else can assign a root user permission
    if ((permissions & SystemPermissionFlags.RootUser) > 0) {
        return false;
    }
    //or build a standalone client
    if ((permissions & SystemPermissionFlags.BuildStandalone) > 0) {
        return false;
    }

    //only org admin can assign other system permissions
    return HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin);
}

export function CanAssignLicenseToUser(user, subjectUser, usersSharedIn) {
    return (((user.OrganisationId === subjectUser?.OrganisationId || usersSharedIn?.find(u => u.UserId === user.UserId)) && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanAssignLicenses(user, orgId) {
    return ((user.OrganisationId === orgId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanEditShares(user) {
    return HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin);
}

export function CanEditModelPermissions(user, modelId, projectId, orgId) {
    const et = CanUpdateModel(user, modelId, orgId, projectId) || (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && CanAccessModel(user, modelId, orgId, projectId));
    return et;
}

export function CanEditProjectPermissions(user, projectId, orgId) {
    return CanUpdateProject(user, projectId, orgId) || (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && CanAccessProject(user, projectId, orgId));
}

export function CanEditPermissionsGeneral(user, orgId) {
    return ((user.OrganisationId === orgId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanBroadcastTextMessage(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanReadBuildLogs(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser) || HasUploadLicenseInOrganisation(user, user.OrganisationId);
}

export function CanDeleteBuildLogs(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanSeeLicenses(user, orgId) {
    return ((user.OrganisationId === orgId) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanEditLicenses(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanSeeGroups(user, orgId) {
    return ((user.OrganisationId === orgId) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanSeeUsers(user, orgId) {
    return ((user.OrganisationId === orgId) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanDeleteProject(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowWrite);
}

export function CanDeleteModel(user, modelId, modelOrgId, modelProjectId) {
    return HasUserModelPermission(user, modelId, modelOrgId, modelProjectId, ModelPermissionFlags.AllowWrite);
}

export function CanAddUserToGroup(user, group) {
    return ((user.OrganisationId === group.OrganisationId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanCreateProject(user, orgId) {
    return ((user.OrganisationId === orgId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanCreateUser(user, orgId) {
    return ((user.OrganisationId === orgId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanSeeAllWebsocketUsers(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

//annotations ==============

export function CanChangeAnnotationSetLock(user, annotationSetId, modelId, projectId, orgId) {
    return HasUserAnnotationSetPermission(user, annotationSetId, modelId, projectId, orgId, ModelPermissionFlags.AllowLockingUnLocking);
}

export function CanCreateAnnotationSet(user, modelId, modelProjectId, modelOrgId) {
    return HasUserModelPermission(user, modelId, modelOrgId, modelProjectId, ModelPermissionFlags.AllowAnnotationsEdit);
}

export function CanWriteAnnotationSet(user, annotationSetId, modelId, projectId, orgId) {
    return HasUserAnnotationSetPermission(user, annotationSetId, modelId, projectId, orgId, ModelPermissionFlags.AllowWrite);
}

export function CanReadAnnotationSet(user, annotationSetId, modelId, projectId, orgId) {
    return HasUserAnnotationSetPermission(user, annotationSetId, modelId, projectId, orgId, ModelPermissionFlags.AllowRead);
}

export function CanEditAnnotationTemplatesOrStyleSheets(user, projectId, orgId) {
    return HasUserProjectPermission(user, projectId, orgId, ModelPermissionFlags.AllowAnnotationsEdit);
}

//============================================================================

export function CanAccessProject(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowRead);
}


export function CanAccessModel(user, modelId, modelOrgId, modelProjectId) {
    return HasUserModelPermission(user, modelId, modelOrgId, modelProjectId, ModelPermissionFlags.AllowRead);
}

export function CanUploadModelArchive(user, projectId, projectOrgId) {
    logger.debug("CanUploadModelArchive? ");

    const can = HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowUploadModelArchive);
    logger.debug("CanUploadModelArchive? ", can);
    return can;
}

export function CanCreateCrs(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowCrsManagement);
}

export function CanUpdateCrs(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowCrsManagement);
}

export function CanChangeCrsLock(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowLockingUnLocking);
}

export function CanDeleteCrs(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowCrsManagement);
}

export function CanUpdateModel(user, modelId, modelOrgId, modelProjectId) {
    return HasUserModelPermission(user, modelId, modelOrgId, modelProjectId, ModelPermissionFlags.AllowWrite);
}


export function CanEditGroup(user, orgId) {
    return (user.OrganisationId === orgId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) || HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanDeleteGroup(user, group) {
    return (user.OrganisationId === group?.OrganisationId && HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) && group?.Type !== GroupTypes.Everyone) ||
        HasSystemPermission(user, SystemPermissionFlags.RootUser);
}


export function CanEditOrganisations(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanUpdateProject(user, projectId, projectOrgId) {
    return HasUserProjectPermission(user, projectId, projectOrgId, ModelPermissionFlags.AllowWrite);
}

export function CanGetUserCognitoInfo(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanGetUserCognitoDevices(user) {
    return HasSystemPermission(user, SystemPermissionFlags.RootUser);
}

export function CanEditUser(user, userToDelete) {

    if (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) {
        if (HasSystemPermission(user, SystemPermissionFlags.RootUser)) {
            return true;
        }
        else {
            return user.OrganisationId === userToDelete?.OrganisationId;
        }
    }

    return false;
}

export function CanAddUser(user) {

    return (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin) || HasSystemPermission(user, SystemPermissionFlags.RootUser));
}

export function CanChangeUserName(user, userToDelete) {

    if (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) {
        if (HasSystemPermission(user, SystemPermissionFlags.RootUser)) {
            return true;
        }
        else {
            return user.OrganisationId === userToDelete?.OrganisationId;
        }
    }

    return false;
}

export function CanGenerateUserApiKey(user, userToEdit)//todo needs to be in same org as user if not root
{
    // if (HasSystemPermission(user, SystemPermissionFlags.OrganisationAdmin)) {
    if (HasSystemPermission(user, SystemPermissionFlags.RootUser)) {
        return true;
    }
    // else {
    //     return user?.OrganisationId === userToEdit?.OrganisationId;
    // }
    //}

    return false;
}

//todo this needs to include other orgs the user has been given access to
export function CanAccessOrganisation(user, orgId) {
    if ((user.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        return true;
    }

    return user.OrganisationId === orgId;
}


export function HasSystemPermission(user, permission) {

    //a root user always has the permission
    if ((user?.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        return true;
    }

    //first compare against user perms
    if ((permission & user?.Permissions) !== 0) {
        return true;
    }

    //now do permission from groups
    let groupPermissions = 0;
    user?.Groups?.forEach(userGroup => {
        //these are cumulative
        groupPermissions |= userGroup.Permissions;
    });

    return (permission & groupPermissions) !== 0;
}


//find a write permission license for the org
export function HasWriteLicenseInOrganisation(user, organisationId) {
    return user.Licenses.find(l => (l.Type === LicenseTypes.WriteAccess && l.OrganisationId === organisationId && l.IsActive)) != null;
}

//find a read permission license for the org
export function HasReadLicenseInOrganisation(user, organisationId) {
    return user.Licenses.find(l => (l.Type === LicenseTypes.ReadAccess && l.OrganisationId === organisationId && l.IsActive)) != null;
}

export function HasUploadLicenseInOrganisation(user, organisationId) {
    if ((user.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        return true;
    }
    return user?.OrgLicenses?.find(l => (l.Type === LicenseTypes.AllowUploadModels && l.OrganisationId === organisationId && l.IsActive)) != null;
}

export function HasUserModelPermission(user, modelId, modelOrgId, modelProjectId, permissionsToTest) {

    //if user is root - always has permission
    if ((user.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        return true;
    }

    //org user has permission as long as they have required license
    if (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === modelOrgId) {
        if ((PermissionIncludesWriteAccess(permissionsToTest) && (HasWriteLicenseInOrganisation(user, modelOrgId) || HasReadLicenseInOrganisation(user, modelOrgId))) ||
            (((permissionsToTest && ModelPermissionFlags.AllowRead) !== 0) && HasReadLicenseInOrganisation(user, modelOrgId))) {
            return true;
        }
    }



    //If there is a specific read/write license for this model and the user has been granted permission to read/write it
    //If the user has an appropriate license type in the user’s org or the model’s org and the user has been granted permission to read/write it

    if (PermissionIncludesWriteAccess(permissionsToTest)) {
        if (IsThereAWriteLicenseForModel(user, modelId, modelOrgId) || HasWriteLicenseInOrganisation(user, modelOrgId) || HasWriteLicenseInOrganisation(user, user.OrganisationId)) {
            return AreThereModelPermissions(user, modelId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === modelOrgId);
        }
    }
    else if (permissionsToTest === ModelPermissionFlags.AllowRead) {
        if (IsThereAReadLicenseForModel(user, modelId, modelProjectId) || HasReadLicenseInOrganisation(user, modelOrgId) || HasReadLicenseInOrganisation(user, user.OrganisationId)) {
            return AreThereModelPermissions(user, modelId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === modelOrgId);
        }
    }

    return false;

}

export function HasUserProjectPermission(user, projectId, projectOrgId, permissionsToTest) {

    logger.debug("HasUserProjectPermission", user, projectId, projectOrgId, permissionsToTest)

    //if user is root - always has permission
    if ((user.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        logger.debug("Root user");
        return true;
    }

    //testing for allow upload? Check there is a license for it
    if ((permissionsToTest & ModelPermissionFlags.AllowUploadModelArchive) !== 0) {
        logger.debug("Checking for upload");

        if (!HasUploadLicenseInOrganisation(user, user.OrganisationId)) {
            logger.debug("!Has upload lic in org");
            return false;
        }
    }


    //org user has permission as long as they have required license
    if (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === projectOrgId) {
        logger.debug("org admin in this project's org");

        if ((PermissionIncludesWriteAccess(permissionsToTest) && (HasWriteLicenseInOrganisation(user, projectOrgId) || HasReadLicenseInOrganisation(user, projectOrgId))) ||
            (((permissionsToTest && ModelPermissionFlags.AllowRead) !== 0) && HasReadLicenseInOrganisation(user, projectOrgId))) {
            logger.debug("Is org admin");
            return true;
        }
    }

    //LICENSE CHECKS
    if (PermissionIncludesWriteAccess(permissionsToTest)) {
        if (IsThereAWriteLicenseForProject(user, projectId) || HasWriteLicenseInOrganisation(user, projectOrgId) || HasWriteLicenseInOrganisation(user, user.OrganisationId)) {
            logger.debug("Thereis A WriteLicenseForProject");

            return AreThereProjectPermissions(user, projectId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === projectOrgId);
        }
    }
    else if (permissionsToTest === ModelPermissionFlags.AllowRead) {
        if (IsThereAReadLicenseForProject(user, projectId) || HasReadLicenseInOrganisation(user, projectOrgId) || HasReadLicenseInOrganisation(user, user.OrganisationId)) {
            return AreThereProjectPermissions(user, projectId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === projectOrgId);
        }
    }

    logger.debug("no");

    return false;
}



function AreThereModelPermissions(user, modelId, permissionsToTest) {
    if (((user.ModelPermissions.find(p => p.ModelId === modelId)?.Permissions) & permissionsToTest) > 0) {
        return true;
    }

    //now go through permissions by virtue of the groups the user is in
    for (let gIndex = 0; gIndex < user.Groups.length; gIndex++) {
        const group = user.Groups[gIndex];
        if (((group.ModelPermissions.find(p => p.ModelId === modelId)?.Permissions) & permissionsToTest) > 0) {
            return true;
        }
    }

    return false;
}

function AreThereProjectPermissions(user, projectId, permissionsToTest) {
    logger.debug("AreThereProjectPermissions", user, projectId, permissionsToTest);
    if (((user.ProjectPermissions.find(p => p.ProjectId === projectId)?.Permissions) & permissionsToTest) > 0) {
        logger.debug("yes user");
        return true;
    }


    //now go through permissions by virtue of the groups the user is in
    for (let gIndex = 0; gIndex < user.Groups.length; gIndex++) {
        const group = user.Groups[gIndex];
        if (((group.ProjectPermissions.find(p => p.ProjectId === projectId)?.Permissions) & permissionsToTest) > 0) {
            logger.debug("yes group");
            return true;
        }

    }
    logger.debug("no");

    return false;

}


export function HasUserAnnotationSetPermission(user, annotationSetId, modelId, projectId, orgId, permissionsToTest) {
    //if user is root - always has permission
    if ((user.Permissions & SystemPermissionFlags.RootUser) !== 0) {
        return true;
    }

    //org user has permission as long as they have required license
    if (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === orgId) {
        if ((PermissionIncludesWriteAccess(permissionsToTest) && (HasWriteLicenseInOrganisation(user, orgId) || HasReadLicenseInOrganisation(user, orgId))) ||
            (((permissionsToTest && ModelPermissionFlags.AllowRead) !== 0) && HasReadLicenseInOrganisation(user, orgId))) {
            return true;
        }
    }

    //LICENSE CHECKS
    if (PermissionIncludesWriteAccess(permissionsToTest)) {
        if (
            IsThereAWriteLicenseForModel(user, modelId, projectId) ||
            HasWriteLicenseInOrganisation(user, orgId) ||
            HasWriteLicenseInOrganisation(user, user.OrganisationId)
        ) {
            return AreThereAnnotationSetPermissions(user, annotationSetId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === orgId);
        }
    }
    else if (permissionsToTest === ModelPermissionFlags.AllowRead) {
        if (
            IsThereAReadLicenseForModel(user, modelId, projectId) ||
            HasReadLicenseInOrganisation(user, orgId) ||
            HasReadLicenseInOrganisation(user, user.OrganisationId) ||
            IsThereAWriteLicenseForModel(user, modelId, projectId) ||
            HasWriteLicenseInOrganisation(user, orgId) ||
            HasWriteLicenseInOrganisation(user, user.OrganisationId)
        ) {
            return AreThereAnnotationSetPermissions(user, annotationSetId, permissionsToTest) || (((user.Permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && user.OrganisationId === orgId);
        }
    }

    return false;
}

function AreThereAnnotationSetPermissions(user, annotationSetId, permissionsToTest) {
    if (((user.AnnotationSetPermissions.find(p => p.AnnotationSetId === annotationSetId)?.Permissions) & permissionsToTest) > 0) {
        return true;
    }


    //now go through permissions by virtue of the groups the user is in
    for (let gIndex = 0; gIndex < user.Groups.length; gIndex++) {
        const group = user.Groups[gIndex];
        if (((group.AnnotationSetPermissions.find(p => p.AnnotationSetId === annotationSetId)?.Permissions) & permissionsToTest) > 0) {
            return true;
        }

    }

    return false;

}


function IsThereAReadLicenseForModel(user, modelId, modelProjectId) {
    for (let lIndex = 0; lIndex < user.OrgLicenses.length; lIndex++) {
        const license = user.OrgLicenses[lIndex];

        if (license.Type === LicenseTypes.ModelSpecificRead || license.Type === LicenseTypes.ModelSpecificWrite) {
            if (license.Payload.ItemId === modelId) {
                return true;
            }
        }
        else if (license.Type === LicenseTypes.ProjectSpecificRead || license.Type === LicenseTypes.ProjectSpecificWrite) {
            if (license.Payload.ItemId === modelProjectId) {
                return true;
            }
        }
    }

    return false;
}

function IsThereAWriteLicenseForModel(user, modelId, modelProjectId) {
    for (let lIndex = 0; lIndex < user.OrgLicenses.length; lIndex++) {
        const license = user.OrgLicenses[lIndex];

        if (license.Type === LicenseTypes.ModelSpecificRead) {
            if (license.Payload.ItemId === modelId) {
                return true;
            }
        }
        else if (license.Type === LicenseTypes.ProjectSpecificRead) {
            if (license.Payload.ItemId === modelProjectId) {
                return true;
            }
        }
    }

    return false;
}

function IsThereAReadLicenseForProject(user, projectId) {
    for (let lIndex = 0; lIndex < user.OrgLicenses.length; lIndex++) {
        const license = user.OrgLicenses[lIndex];
        if (license.Type === LicenseTypes.ProjectSpecificRead || license.Type === LicenseTypes.ProjectSpecificWrite) {
            if (license.Payload.ItemId === projectId) {
                return true;
            }
        }
    }

    return false;
}

function IsThereAWriteLicenseForProject(user, projectId) {
    for (let lIndex = 0; lIndex < user.OrgLicenses.length; lIndex++) {
        const license = user.OrgLicenses[lIndex];
        if (license.Type === LicenseTypes.ProjectSpecificWrite) {
            if (license.Payload.ItemId === projectId) {
                return true;
            }
        }
    }

    return false;
}
