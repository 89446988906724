import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');



export async function CreateModelUserPermission(permission, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateModelUserPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("CreateModelUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateModelUserPermission(permission, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateModelUserPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("UpdateModelUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteModelUserPermission(permissionId, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteModelUserPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("DeleteModelUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}


export async function CreateAnnotationSetUserPermission(permission) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateAnnotationSetUserPermission', options);
    logger.debug("CreateAnnotationSetUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateAnnotationSetUserPermission(permission) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationSetUserPermission', options);
    logger.debug("UpdateAnnotationSetUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteAnnotationSetUserPermission(permissionId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotationSetUserPermission', options);
    logger.debug("DeleteAnnotationSetUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}


export async function CreateAnnotationSetGroupPermission(permission) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateAnnotationSetGroupPermission', options);
    logger.debug("CreateAnnotationSetGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateAnnotationSetGroupPermission(permission) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateAnnotationSetGroupPermission', options);
    logger.debug("UpdateAnnotationSetGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteAnnotationSetGroupPermission(permissionId) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteAnnotationSetGroupPermission', options);
    logger.debug("DeleteAnnotationSetGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}


export async function CreateModelGroupPermission(permission, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateModelGroupPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("CreateModelGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateModelGroupPermission(permission, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateModelGroupPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("UpdateModelGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteModelGroupPermission(permissionId, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteModelGroupPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("DeleteModelGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}



export async function CreateProjectUserPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateProjectUserPermission?propagatetomodel=' + (propogatePermissionToModel ? "true" : "false") + '&propagatetoanset=' + (propogatePermissionToAnSet ? "true" : "false"), options);
    logger.debug("CreateProjectUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateProjectUserPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateProjectUserPermission?propagatetomodel=' + (propogatePermissionToModel ? "true" : "false") + '&propagatetoanset=' + (propogatePermissionToAnSet ? "true" : "false"), options);
    logger.debug("UpdateProjectUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteProjectUserPermission(permissionId, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteProjectUserPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("DeleteProjectUserPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}




export async function CreateProjectGroupPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.post('Reveal4DApi', '/CreateProjectGroupPermission?propagatetomodel=' + (propogatePermissionToModel ? "true" : "false") + '&propagatetoanset=' + (propogatePermissionToAnSet ? "true" : "false"), options);
    logger.debug("CreateProjectGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}

export async function UpdateProjectGroupPermission(permission, propogatePermissionToModel, propogatePermissionToAnSet) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: permission
    }

    let response = await API.put('Reveal4DApi', '/UpdateProjectGroupPermission?propagatetomodel=' + (propogatePermissionToModel ? "true" : "false") + '&propagatetoanset=' + (propogatePermissionToAnSet ? "true" : "false"), options);
    logger.debug("UpdateProjectGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {

        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }

}


export async function DeleteProjectGroupPermission(permissionId, propagatePermissions) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            permissionid: permissionId
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteProjectGroupPermission?propagate=' + (propagatePermissions ? "true" : "false"), options);
    logger.debug("DeleteProjectGroupPermission , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return permissionId;
    }

}


























