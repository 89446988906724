import React, { useState, useRef } from 'react'

import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import FormCheck from 'react-bootstrap/FormCheck'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';
import { Logger } from 'aws-amplify';
const logger = new Logger('EditLicense');

export function EditLicense(props) {
    logger.debug("props", props)
    const { show, setShow, editLicense, licenseToEdit, helpContext, parentHelpContext } = props;

    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [licenseTitle, setLicenseTitle] = useState();

    const [isActive, setIsActive] = useState();
    const [statusText, setStatusText] = useState();
    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (props.show) {

            setLicenseTitle(licenseToEdit?.Title);
            setIsActive(licenseToEdit?.IsActive);
            setStatusText(licenseToEdit?.StatusText);

            if (helpContext) {
                BeaconSetSuggestions(helpContext);
            }
        }
        else {
            if (parentHelpContext) {
                BeaconSetSuggestions(parentHelpContext);
            }
        }
    }, [helpContext, licenseToEdit, parentHelpContext, props.show])


    function onClose() {
        setShow(false);
    }

    function onSave(event) {

        event.preventDefault();


        if (form.current.checkValidity()) {

            let license = JSON.parse(JSON.stringify(licenseToEdit));

            license.Title = licenseTitle;
            license.IsActive = isActive;
            license.StatusText = statusText;


            editLicense(license, setUiDisabled);

        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit License</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container >
                    <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                        <Form.Group controlId="formGroupTitle" as={Row}>
                            <Form.Label column sm={12} >License Title:</Form.Label>
                            <Col sm={12}>
                                <Form.Control required value={licenseTitle} onChange={(e) => setLicenseTitle(e.target.value)} placeholder='License title...' />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a title.
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupActive" as={Row}>
                            <Form.Label column sm={12} >License Active:</Form.Label>
                            <Col sm={12}>
                                <FormCheck
                                    className="mt-2"
                                    type="switch"
                                    id="isPositive"
                                    label=""
                                    checked={isActive}

                                    onChange={(e) => setIsActive(!isActive)} />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formGroupStatusText" as={Row}>
                            <Form.Label column sm={12} >Status Text / reason for being disabled:</Form.Label>
                            <Col sm={12}>
                                <Form.Control value={statusText} onChange={(e) => setStatusText(e.target.value)} placeholder='Status text...' />
                            </Col>
                        </Form.Group>

                    </Form>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );
}