import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#888888',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '150px'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


export function Uploader(props) {


    function fileValidator(file) {
        if (file.size > props.maxFileSize) {
            return {
                code: "size-too-large",
                message: `File is larger than ${props.maxFileSize}`
            };
        }
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        fileRejections
    } = useDropzone({
        validator: fileValidator,
        disabled: props.disabled,
        maxFiles: 1, accept: {
            'model/gltf-binary': ['.glb'],
            'application/glb': ['.zip'],
            'application/x-zip-compressed': ['.zip'],
            'multipart/x-zip': ['.zip'],
        },
        onDrop: files => onDrop(files)
    });

    function onDrop(acceptedFiles) {
        props.setSelectedFile(acceptedFiles[0]);
    }

    //style is recalculated when one of the drag properties changes:
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    const selectedFileComponent = (props.selectedFile != null) && (
        <Container fluid >
            <Row className="align-items-center">
                <Col sm={"auto"}>
                    <label className="text-dark text-monospace">{props.selectedFile.path} ({humanFileSize(props.selectedFile.size, false, 0)}) </label>
                </Col>
            </Row>
        </Container>
    );


    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {(fileRejections?.length > 0) && (<label className="text-danger">File must be smaller than {humanFileSize(props.maxFileSize, false, 0)}</label>)}
            {isDragReject ? (<label className="text-danger">Wrong file type: Only .zip or .glb files will be accepted</label>) : (

                (props.selectedFile) ? (selectedFileComponent) : (<label className="text-dark">Drag and drop a model .zip or .glb file here, or click to select file.<br /> Files must be smaller than {humanFileSize(props.maxFileSize, false, 0)}.</label>)

            )}
        </div>
    );
}



export default Uploader;