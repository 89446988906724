import React, { useState, useRef } from 'react'


import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import FormCheck from 'react-bootstrap/FormCheck'
import '../css/annotation-properties.css'
import '../css/modal.css'
import { GroupPermissionsInAnnotationSetList, UserPermissionsInAnnotationSetList } from './PermissionList'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap';

import { Logger } from 'aws-amplify';
const logger = new Logger('EditAnnotationSet');

export function EditAnnotationSet(props) {
    logger.debug("props", props)

    const { show, setShow, save, annotationSet, title, isAdding, orgsInfo, helpContext, parentHelpContext } = props;

    const form = useRef(null);


    const [validated, setValidated] = useState(false);

    const [annotationSetTitle, setAnnotationSetTitle] = useState("");
    const [isLocked, setIsLocked] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const [groupPermissions, setGroupPermissions] = useState([]);

    const [uiDisabled, setUiDisabled] = useState(false);

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    React.useEffect(() => {

        if (show) {
            setIsLocked(annotationSet?.IsLocked);
            setAnnotationSetTitle(annotationSet?.Title);
            setUserPermissions(annotationSet.UserPermissions);
            setGroupPermissions(annotationSet.GroupPermissions);
        }
    }, [annotationSet, show])



    function onClose() {
        setShow(false);
    }


    function onSave(event) {

        event.preventDefault();


        if (form.current.checkValidity()) {

            let newAnotationSet = JSON.parse(JSON.stringify(annotationSet));

            newAnotationSet.Title = annotationSetTitle;
            newAnotationSet.IsLocked = isLocked;

            if (isAdding) {
                newAnotationSet.GroupPermissions = groupPermissions;
                newAnotationSet.UserPermissions = userPermissions
            }


            save(newAnotationSet, setUiDisabled);
        }
        else {
            //failed validation
            event.stopPropagation();
        }

        setValidated(true);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-900w"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Row className="border-bottom-only pb-2">

                        <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                            <Form.Group controlId="formGroupTitle" as={Row}>
                                <Form.Label column sm={2} >Annotation Set Title:</Form.Label>
                                <Col sm={8}>
                                    <Form.Control required value={annotationSetTitle} onChange={(e) => setAnnotationSetTitle(e.target.value)} placeholder='Annotation Set Title...' />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid title.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="formGroupActive" as={Row}>
                                <Form.Label column sm={2} >Locked:</Form.Label>
                                <Col sm={8}>
                                    <FormCheck
                                        className="mt-2"
                                        type="switch"
                                        id="isPositive"
                                        label=""
                                        checked={isLocked}

                                        onChange={(e) => setIsLocked(!isLocked)} />
                                </Col>
                                {!isAdding &&
                                    <Col sm={2}>
                                        <Button disabled={uiDisabled} variant="primary" onClick={onSave}>Save</Button>
                                    </Col>
                                }

                            </Form.Group>

                        </Form>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <UserPermissionsInAnnotationSetList
                                parentHelpContext={helpContext}
                                userPermissions={userPermissions}
                                setUserPermissions={setUserPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo.currentUser?.OrganisationId)}
                                orgs={orgsInfo?.organisations}
                                annotationSetId={annotationSet?.AnnotationSetId}
                                isAdding={isAdding}
                            />
                        </Col>
                        <Col>
                            <GroupPermissionsInAnnotationSetList
                                parentHelpContext={helpContext}
                                groupPermissions={groupPermissions}
                                setGroupPermissions={setGroupPermissions}
                                canEdit={true}
                                userOrg={orgsInfo?.organisations?.find(o => o?.OrganisationId === orgsInfo.currentUser?.OrganisationId)}
                                annotationSetId={annotationSet?.AnnotationSetId}
                                isAdding={isAdding}
                                orgs={orgsInfo?.organisations}
                            />
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                {isAdding ? <>
                    <Button disabled={uiDisabled} variant="primary" onClick={onSave}>OK</Button>
                    <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
                </> :
                    <Button disabled={uiDisabled} variant="primary" onClick={onClose}>Close</Button>}
            </Modal.Footer>
        </Modal>

    );
}