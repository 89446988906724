import React, { useState, useRef } from 'react'
import Select from 'react-select'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import '../css/annotation-properties.css'
import '../css/modal.css'
import { CopyAnnotationSet as CopyAnnotationSetApi, CopyAnnotations } from '../Api/AnnotationApi'
import { ToastError, ToastLoading, ToastSuccess } from '../Components/ToastDisplay'
import { BeaconSetSuggestions } from '../Components/BeaconDialogMap'
import { Logger } from 'aws-amplify';
const logger = new Logger('CopyAnnotationSet');

function ModelsToOptions(project) {
    return project?.Models?.map(m => ({ value: m, label: m.Title }));
}

function AnSetsToOptions(model) {
    return model?.AnnotationSets?.map(a => ({ value: a.AnnotationSetId, label: a.Title }));
}

//add a model if it has a CRS in common with source model
function CompatibleModelsToOptions(project, sourceModel) {
    let options = [];

    if (sourceModel) {

        project?.Models?.forEach(destModel => {

            //go through prospective dest model's crss
            for (let destModelCrsIndex = 0; destModelCrsIndex < destModel?.CRSs?.length; destModelCrsIndex++) {
                const destModelCrs = destModel.CRSs[destModelCrsIndex];

                if (sourceModel?.CRSs?.find(sourceCrs => sourceCrs.CrsId === destModelCrs.CrsId)) {
                    options.push({ value: destModel, label: destModel.Title });
                    break;
                }

            }


        });
    }
    return options;

}

export function CopyAnnotationSet(props) {
    const { project, currentModel, currentAnnotationSet, show, setShow, helpContext, parentHelpContext } = props;

    logger.debug("props", props);

    const form = useRef(null);

    const [validated, setValidated] = useState(false);
    const [availableModelOptions, setAvailableModelOptions] = useState();
    const [availableAnSetOptions, setAvailableAnSetOptions] = useState();
    const [availableDestModelOptions, setAvailableDestModelOptions] = useState();
    const [selectedSourceModelOption, setSelectedSourceModelOption] = useState({ value: currentModel, label: currentModel?.Title });
    const [selectedDestModelOption, setSelectedDestModelOption] = useState();
    const [selectedAnSetOption, setSelectedAnSetOption] = useState({ value: currentAnnotationSet?.AnnotationSetId, label: currentAnnotationSet?.Title });
    const [title, setTitle] = useState("");
    const [sourceModelValid, setSourceModelValid] = useState();
    const [destModelValid, setDestModelValid] = useState();
    const [anSetValid, setAnSetValid] = useState();
    const [annotationCopyProgress, setAnnotationCopyProgress] = useState(0);

    const [annotationIdCursor, setAnnotationIdCursor] = useState(null);
    const [annotationSetIdTo, setAnnotationSetIdTo] = useState(null);



    const [uiDisabled, setUiDisabled] = useState(false);
    const toastId = useRef();

    React.useEffect(() => {
        if (show) {
            BeaconSetSuggestions(helpContext);
        }
        else {
            BeaconSetSuggestions(parentHelpContext);
        }
    }, [helpContext, parentHelpContext, show])


    React.useEffect(() => {
        if (show) {
            setAvailableModelOptions(ModelsToOptions(project));
            if (!selectedSourceModelOption?.value?.ModelId) {
                setSelectedSourceModelOption({ value: currentModel, label: currentModel?.Title })
            }
        }
    }, [show, project, selectedSourceModelOption, currentModel])



    React.useEffect(() => {
        if (show) {
            setAvailableModelOptions(project ? ModelsToOptions(project) : null);
            setAvailableAnSetOptions(selectedSourceModelOption?.value ? AnSetsToOptions(selectedSourceModelOption?.value) : null);
            const availableDestOptions = CompatibleModelsToOptions(project, selectedSourceModelOption?.value);

            setAvailableDestModelOptions(availableDestOptions);
            //make sure selected dest is in the list
            if (!availableDestOptions.find(o => o?.value?.ModelId === selectedDestModelOption?.value?.ModelId)) {
                setSelectedDestModelOption(null);
            }

        }
    }, [project, selectedDestModelOption, selectedSourceModelOption, show])

    React.useEffect(() => {
        if (show) {
            setTitle(selectedAnSetOption?.label ? selectedAnSetOption?.label + " [copy]" : "");
        }
    }, [selectedAnSetOption?.label, selectedAnSetOption?.value, show])

    React.useEffect(() => {
        if (show) {
            if (!selectedSourceModelOption?.value?.AnnotationSets?.find(a => a.AnnotationSetId === selectedAnSetOption?.value)) {
                if (selectedSourceModelOption?.value?.AnnotationSets?.length) {
                    setSelectedAnSetOption({ value: selectedSourceModelOption?.value?.AnnotationSets[0]?.AnnotationSetId, label: selectedSourceModelOption?.value?.AnnotationSets[0]?.Title });
                }
                else {
                    setSelectedAnSetOption(null);
                }
            }
        }
    }, [show, selectedSourceModelOption, selectedAnSetOption])



    React.useEffect(() => {

        if (show) {
            setAvailableModelOptions(project ? ModelsToOptions(project) : null);
        }
    }, [project, show])

    React.useEffect(() => {
        //copy a page of annotations
        if (annotationSetIdTo !== null && annotationIdCursor !== null) {

            CopyAnnotations(selectedAnSetOption.value, annotationSetIdTo, annotationIdCursor)
                .then(res => {
                    //finished?
                    if (res === "00000000-0000-0000-0000-000000000000") {
                        ToastSuccess("Copied", toastId.current, 2000);
                        setUiDisabled(false);
                        setShow(false);
                    }
                    else {
                        setAnnotationCopyProgress(annotationCopyProgress + 1);
                        setAnnotationIdCursor(res);//this will cause this useEffect to fire and do the next page
                    }
                })
                .catch(err => {
                    logger.debug("CopyAnnotations fail err", err);
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotationSetIdTo, annotationIdCursor])

    function onSave(event) {
        event.preventDefault();

        setAnSetValid(selectedAnSetOption ? true : false);
        setSourceModelValid(selectedSourceModelOption ? true : false);
        setDestModelValid(selectedDestModelOption ? true : false);


        if (form.current.checkValidity() && selectedAnSetOption && selectedSourceModelOption && selectedDestModelOption) {

            setUiDisabled(true);
            toastId.current = ToastLoading("Copying Annotation Set");

            //copy
            CopyAnnotationSetApi(selectedAnSetOption.value, selectedDestModelOption.value.ModelId, title)
                .then(res => {
                    //ready to start copying pages - will be picked up by useEffect() above
                    setAnnotationIdCursor("00000000-0000-0000-0000-000000000000");
                    setAnnotationSetIdTo(res);
                })
                .catch(err => {
                    ToastError(err.message, err.cause, toastId.current);
                    setUiDisabled(false);
                });

        }
        else {
            //failed validation
            event.stopPropagation();

        }

        setValidated(true);
    }

    function onClose() {
        props.setShow(false);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-700w"
        >
            <Modal.Header closeButton>
                <Modal.Title>Copy Annotation Set</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="mx-2 my-2">
                    <Row>
                        <Form ref={form} noValidate validated={validated} onSubmit={onSave}>
                            <Form.Group className="my-2" controlId="formModelTitle" as={Row}>
                                <Form.Label column sm={12}>Note: Models must be connected by a CRS Transform path to allow copying.</Form.Label>
                            </Form.Group>

                            <Form.Group controlId="formGroupSource" as={Row}>
                                <Form.Label column sm={4}>Source Model:</Form.Label>
                                <Col sm={6}>
                                    <Select
                                        value={selectedSourceModelOption}
                                        onChange={setSelectedSourceModelOption}
                                        closeMenuOnSelect={true}
                                        name="models"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Source Model..."
                                        options={availableModelOptions} />
                                    {(!sourceModelValid && validated) &&
                                        <Form.Text className="text-danger">
                                            Please select a model to copy from.
                                        </Form.Text>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="formGroupAnSetSource" as={Row}>
                                <Form.Label column sm={4}>Annotation Set to Copy:</Form.Label>
                                <Col sm={6}>
                                    <Select
                                        value={selectedAnSetOption}
                                        onChange={setSelectedAnSetOption}
                                        closeMenuOnSelect={true}
                                        name="models"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Annotation Set..."
                                        options={availableAnSetOptions} />
                                    {(!anSetValid && validated) &&
                                        <Form.Text className="text-danger">
                                            Please select an Annotation Set to copy.
                                        </Form.Text>
                                    }
                                </Col>
                            </Form.Group>
                            <Form.Group controlId="formGroupDest" as={Row}>
                                <Form.Label column sm={4}>Destination Model:</Form.Label>
                                <Col sm={6}>
                                    <Select
                                        value={selectedDestModelOption}
                                        onChange={setSelectedDestModelOption}
                                        closeMenuOnSelect={true}
                                        name="models"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Destination Model..."
                                        options={availableDestModelOptions} />
                                    {(!destModelValid && validated) &&
                                        <Form.Text className="text-danger">
                                            Please select a model to copy to.
                                        </Form.Text>
                                    }

                                </Col>
                            </Form.Group>

                            <Form.Group className="my-2" controlId="formModelTitle" as={Row}>
                                <Form.Label column sm={4}>New Annotation Set Title:</Form.Label>
                                <Col sm={6}>
                                    <Form.Control required value={title} onChange={(e) => setTitle(e.target.value)} placeholder='New Annotation Set Title...' />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a title.
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {(annotationCopyProgress > 0) &&
                                <Form.Group className="my-2" controlId="formModelTitle" as={Row}>
                                    <Form.Label column sm={4}>Annotation page copied:</Form.Label>
                                    <Col sm={6} className="my-2">
                                        {annotationCopyProgress}
                                    </Col>
                                </Form.Group>
                            }
                        </Form>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={uiDisabled} variant="primary" onClick={onSave}>Copy</Button>
                <Button disabled={uiDisabled} variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );

}

export default CopyAnnotationSet;