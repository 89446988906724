import { API, Auth, Logger } from 'aws-amplify';
const logger = new Logger('Reveal4D-Api');


export async function CreateCrs(crs) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: crs

    }

    let response = await API.post('Reveal4DApi', '/CreateCrs', options);
    logger.debug("CreateCrs , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        crs.CrsId = response.Data;
        return crs;
    }
}

export async function UpdateCrs(crs) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: crs
    }

    let response = await API.put('Reveal4DApi', '/UpdateCrs', options);
    logger.debug("UpdateCrs , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return crs;
    }
}

export async function DeleteCrs(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            crsid: id
        }
    }

    let response = await API.del('Reveal4DApi', '/DeleteCrs', options);
    logger.debug("DeleteCrs , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}
export async function CreateCrsTransform(crsTransform) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: crsTransform
    }

    
    let response = await API.post('Reveal4DApi', '/CreateCrsTransform', options);
    logger.debug("CreateCrsTransform , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return response.Data;
    }
}

export async function UpdateCrsTransform(crsTransform) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: crsTransform
    }

    let response = await API.put('Reveal4DApi', '/UpdateCrsTransform', options);
    logger.debug("UpdateCrsTransform , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return crsTransform;
    }
}

export async function DeleteCrsTransform(id) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken
        },
        body: {
            crstransformid: id
        }
    }

    
    let response = await API.del('Reveal4DApi', '/DeleteCrsTransform', options);
    logger.debug("DeleteCrsTransform , response: ", response);


    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {

        return id;
    }

}





export async function ChangeCrsLock(crsLockParam) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken,
        },
        body: {
            crsid: crsLockParam.crsId,
            isLocked: (crsLockParam.isLocked ? "true" : "false")
        }
    }
    let response = await API.put('Reveal4DApi', '/ChangeCrsLock', options);
    logger.debug("ChangeCrsLock , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return crsLockParam;
    }
}

export async function ChangeCrsTransformLock(crsTransformLockParam) {

    const session = await Auth.currentSession();

    const options = {
        headers: {
            Authorization: session.idToken.jwtToken,
        },
        body: {
            crstransformid: crsTransformLockParam.crsTransformId,
            isLocked: (crsTransformLockParam.isLocked ? "true" : "false")
        }
    }
    let response = await API.put('Reveal4DApi', '/ChangeCrsTransformLock', options);
    logger.debug("ChangeCrsTransformLock , response: ", response);

    if (response.ErrorData.ErrorCode) {
        throw new Error(response.ErrorData.ErrorName, { cause: response.ErrorData.ErrorDetails });
    }
    else {
        return crsTransformLockParam;
    }
}
