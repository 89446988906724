import '@aws-amplify/ui-react/styles.css';
import { Amplify, API } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import "ka-table/style.scss";
import React from 'react';
import { Provider } from 'react-redux';
import App from './App/App';
import store from './App/store';
import './index.css';
import './ManualAmplifyConfig.js';

import { createRoot } from 'react-dom/client';



//const store = createStore(reducer, applyMiddleware(thunk));
//get initial configurtion (sic) from the default api location
API.get('Reveal4DApi', '/GetApiSettings').then(apiSettings => {

    if (apiSettings != null) {

        if (apiSettings.ErrorData.ErrorCode !== 0) {
            console.log("Error getting api settings ", apiSettings.ErrorData.ErrorDetails);
        }

        Amplify.configure({
            Auth: {
                region: apiSettings.Data.AwsRegion,
                userPoolId: apiSettings.Data.UserPoolId,
                userPoolWebClientId: apiSettings.Data.UserPoolClientId,
            }
        });

        //---------------------------------- 
        //start the react app

        const container = document.getElementById("root");
        const root = createRoot(container);

        root.render(
            // <React.StrictMode>
                <Provider store={store}>
                    <App />
                </Provider>
            // </React.StrictMode>
        );
        //----------------------------------          



    }

}).catch(err => {
    console.log("GetApiSettings exception", err);

    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                Failed to contact system. See console for details.
            </Provider>
        </React.StrictMode>    
    );
});


