import { SystemPermissionFlags } from '../App/Permissions'
import { Logger } from 'aws-amplify';
const logger = new Logger('SystemPermissionsDisplay');


let itemClassName = "me-1 mt-0 ms-4 p-1 border border-light";
const styleItemNormal = { fontSize: 12, backgroundColor: "#EEEEEE" };
const styleItemSelected = { fontSize: 12, foregroundColor: "#FFFFFF", backgroundColor: "#111111" };

export function SystemPermissionsDisplay(props) {
    logger.debug("props", props);

    const { isSelected } = props;

    const styleitem = isSelected ? styleItemSelected : styleItemNormal;

    return (
        <div className="d-flex flex-wrap ps-0">
            {(props.permissions === 0) && (<div key="3" className={itemClassName} style={styleitem}>None</div>)}
            {((props.permissions & SystemPermissionFlags.OrganisationAdmin) !== 0) && (<div key="0" className={itemClassName} style={styleitem}>Organisation Admin</div>)}
            {((props.permissions & SystemPermissionFlags.RootUser) !== 0) && (<div key="1" variant="secondary" className={itemClassName} style={styleitem}>System Admin</div>)}
            {((props.permissions & SystemPermissionFlags.BuildStandalone) !== 0) && <div key="2" variant="secondary" className={itemClassName} style={styleitem}>Build Standalone</div>}
        </div>
    );
}
